import {ERROR_MESSAGES, useRecordWebcam} from "react-record-webcam";
import React, {Component} from "react";
import {VideoError} from "./VideoInterviewOrchestrator";

class Webcam extends Component {
    constructor(props) {
        super(props);
        this.state = {
            recording: null, didStartClosing: false, modal: null
        };
        this.recordWebcam = props.recordWebcam;
        this.dismissModal = this.dismissModal.bind(this)
        this.showModal = this.showModal.bind(this)
        this.handleRecording = this.handleRecording.bind(this)
    }

    showModal() {
        this.setState((s) => ({...s, modal: <VideoError onCloseUpper={this.dismissModal}/>}));
    }

    dismissModal() {
        this.setState((s) => ({...s, modal: null}));
    }

    async finishRecording() {
        try {
            if (!this.state.didStartClosing) {
                this.setState({didStartClosing: true});
                this.props.recordWebcam.clearError();
                await this.props.recordWebcam.stopRecording(this.state.recording.id);
                console.log("recording_id", this.state.recording.id, this.props.recordWebcam.errorMessage);
                await this.props.saveRecording(this.state.recording);
                console.log("saved", this.props.recordWebcam.errorMessage);
            }
        } catch (e) {
            console.error(e); // should never happen, so no need of an modal here
        }
    }

    async handleRecording() {
        if (this.state.recording === null && this.props.shouldRecord && !this.state.modal) {
            let recording = await this.props.recordWebcam.createRecording();
            if (!recording && !(this.props.recordWebcam.errorMessage === ERROR_MESSAGES.SESSION_EXISTS)) {
                console.log("failed to create recording", this.props.recordWebcam.errorMessage)
                this.showModal();
                return;
            } else if (!recording && this.props.recordWebcam.errorMessage === ERROR_MESSAGES.SESSION_EXISTS) {
                await this.props.recordWebcam.stopRecording(this.props.recordWebcam.activeRecordings[0].id);
                await this.props.recordWebcam.clearAllRecordings();
                recording = await this.props.recordWebcam.createRecording();
                if (!recording) {
                    console.log("error", this.props.recordWebcam.errorMessage)
                    this.showModal();
                    return;
                }
                console.log("recovered")
            }
            recording = await this.props.recordWebcam.openCamera(recording.id)
            if (!recording) {
                this.showModal();
                return;
            }
            recording = await this.props.recordWebcam.startRecording(recording.id);
            if (!recording) {
                this.showModal();
                return;
            }
            this.setState({recording});
        } else if (this.state.recording && !this.props.shouldRecord && !this.state.didStartClosing) {
            await this.finishRecording();
        }
    }

    componentDidUpdate(prevProps) {
        this.handleRecording()
    }

    componentWillUnmount() {
        if (this.state.recording) {
            this.props.recordWebcam.closeCamera(this.state.recording.id).then((r) => {
                console.log("camera closed", r.status, this.props.recordWebcam.errorMessage);
                this.props.recordWebcam.clearAllRecordings().then(() => {
                    console.log("all recordings cleared", this.props.recordWebcam.errorMessage);
                });
            })
        }
    }

    render() {
        if (this.state.modal) {
            return this.state.modal;
        }
        if (this.props.recordWebcam.activeRecordings.length === 0 && this.props.shouldRecord) {
            return <div>Starting Recording</div>
        } else if (!this.props.shouldRecord) {
            return null;
        }

        return <video className={"w-100% h-100%"} ref={this.props.recordWebcam.activeRecordings[0].webcamRef}
                      autoPlay muted={true}/>
    }
}

const WebcamHOC = (props) => {
    const recordWebcam = useRecordWebcam({
        mediaTrackConstraints: {
            video: true,
            audio: {
                echoCancellation: true
            }
        }
    })
    return (
        <div className="">
            <Webcam {...props} recordWebcam={recordWebcam} key={props} stlye={{'borderRadius': '6px !important'}}/>
        </div>
    )
}

export default WebcamHOC;