import React, {useState} from "react";
import gallery from '../../assets/gallery-line.svg';

function Uploader(props) {
    const [fileName, setFileName] = useState(null);
    const [fileTooLarge, setFileTooLarge] = useState(false);

    const handleFileChange = (files) => {
        if (files[0]) {
            setFileName(files[0].name);
            if (files[0].size > 1024 * 1024) { // Check if file size is greater than 1MB
                setFileTooLarge(true);
            } else {
                setFileTooLarge(false);
                handleFileUpload(files[0]);
            }
        }
    };

    const handleFileUpload = async (file) => {
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${props.token}`,
                },
                body: formData,
            });
            if (response.ok) {
                const data = await response.json();
                props.onFilePathChange(data.data);
            } else {
                console.error('File upload failed with status:', response.status);
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    return (
        <div>
            <div
                tabIndex={0}
                className="uploader bg-white hover:text-blue border-textBoxBorders border rounded-md pr-2 pl-2 flex items-center justify-center"
                style={{width: props.width, height: props.height}}
                onClick={() => document.querySelector('.input-field').click()}
            >
                <img src={gallery} className='w-[3rem]' alt="Gallery Icon"/>
                <input
                    type="file"
                    accept="image/svg+xml, image/jpeg, image/png"
                    className="input-field hidden"
                    id="fileInput"
                    onChange={({target: {files}}) => handleFileChange(files)}
                />
                <p className="textUploader text-center text-sm text-[#004EEC] font-semibold font-['Inter'] leading-tight mt-1 cursor-pointer">
                    {fileName ? fileName : 'Upload your company logo here'}
                </p>
            </div>
            {fileTooLarge && (
                <p className="text-[#e90c44] text-[0.9rem] mt-[-1rem] ">The file is too large, max file size is 1MB</p>
            )}
        </div>
    );
}

export default Uploader;
