import React, { useEffect, useRef, useState } from "react";
import '../candidateTable.css';
import '../../../assets/font.css';
import { Button } from "../button";
import { Textbox } from "../textbox";
import { Textarea } from "../textarea";
import alertTriangle from "../../../assets/alert-triangle.svg";
import { FormValidator } from "@syncfusion/ej2-react-inputs";;

const AddInterviewQuestionLayout = ({ isOpen, onClose, onSave }) => {
    const accessToken = localStorage.getItem("accessToken");
    const [thinkTime, setThinkTime] = useState('1');
    const [answerTime, setAnswerTime] = useState('');
    const [question, setQuestion] = useState('');
    const [expectedAnswer, setExpectedAnswer] = useState('');
    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const hasNumber = (value) => {
        const answerTime = /^\d+$/
        return answerTime.test(value.value || '');
    };

    useEffect(() => {
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                rules: {
                    'Thinktime': {
                        range: [1, 2, 'Think Time should not exceed 2 minutes'],
                        hasNumber: [hasNumber, 'Think Time cannot have decimal places']
                    },
                    'AnswerTime': {
                        range: [1, 5, 'Answer Time should be between 1 and 5 minutes'],
                        hasNumber: [hasNumber, 'Answer Time cannot have decimal places']
                    },
                    'question': {
                        required: [true, 'Question cannot be empty.'],
                        maxLength: [245, 'The question cannot exceed 245 characters.'],
                        minLength: [25, 'The question must be a minimum of 25 characters.']
                    },
                    'ExpectedAnswer': {
                        required: [true, 'Expected answer cannot be empty.'],
                        maxLength: [245, 'Expected answer cannot exceed 245 characters.'],
                        minLength: [25, 'Expected answer must be a minimum of 25 characters.']
                    }
                },
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
            });
        }
    }, [formRef, validatorRef]);

    const handleSaveClick = () => {
        if (validatorRef.current.validate()) {
            const customQuestion = {
                Question: question,
                ExpectedAnswer: expectedAnswer,
                Thinktime: thinkTime,
                AnswerTime: answerTime,
                selectedCategoryID: 15
            };
            onSave(customQuestion);
            onClose(); // Optionally close the modal on save
        }
    };

    const handleAddAnotherClick = () => {
        if (validatorRef.current.validate()) {
            const customQuestion = {
                Question: question,
                ExpectedAnswer: expectedAnswer,
                Thinktime: 1,
                AnswerTime: answerTime,
                selectedCategoryID: 15
            };
            onSave(customQuestion);
            console.log("this is the question value", customQuestion)
            setQuestion(prevQuestion => '');
            setExpectedAnswer(prevExpectedAnswer => '');
            setThinkTime(prevThinkTime => '');
            setAnswerTime(prevAnswerTime => '');
        }
    }


    if (!isOpen) return null;

    return (
        <div
            className={`fixed z-30 top-0 right-0 transition-all duration-700 w-[65%] h-screen overflow-y-scroll bg-white shadow-2xl `}>
            <div className="border shadow-sm">
                <div className="flex items-center justify-between px-8 py-3" style={{ marginBottom: 0 }}>
                    <div>
                        <span
                            className="text-2xl font-bold font-serif">Add your own</span>
                    </div>
                    <button onClick={onClose}
                        className="flex items-center justify-center w-6 h-6 p-1 text-sm text-white bg-[#004eec] rounded-full">
                        X
                    </button>
                </div>
            </div>
            <div className="px-8 mx-auto">
                <div className="mt-5">
                    <form id="addInterviewQuestion" ref={formRef}>
                        <div className="grid grid-cols-2 gap-y-6 mt-5">
                            {/*<div className="col-span-1 flex flex-col gap-y-2">*/}
                            {/*<label htmlFor="Thinktime" className="text-sm font-semibold text-black">Think Time</label>*/}
                            <Textbox
                                label="Think Time"
                                key="thinkTime"
                                type="number"
                                id="Thinktime"
                                width="23rem"
                                placeholder="Think Time (in minutes)"
                                value={thinkTime}
                                onChange={(e) => setThinkTime(e.target.value)}
                            // disabled={true}
                            />
                            {/*</div>*/}
                            {/*<div className="col-span-1 flex flex-col gap-y-2">*/}
                            {/*<label htmlFor="AnswerTime" className="text-sm font-semibold text-black">Answer Time</label>*/}
                            <Textbox
                                label="Answer Time"
                                key="answerTime"
                                type="number"
                                id="AnswerTime"
                                // className="border text-sm border-gray-400 focus:border-gray-500 focus:outline-none w-full h-10 rounded-sm"
                                width="23rem"
                                placeholder="Answer Time (in minutes)"
                                value={answerTime}
                                onChange={(e) => setAnswerTime(e.target.value)}
                            />
                            {/*</div>*/}
                            <div className="col-span-2 flex flex-col gap-y-2">
                                {/*<label htmlFor="title" className="text-sm font-semibold text-black">Question</label>*/}
                                <Textarea
                                    key="question"
                                    id="question"
                                    label="Question"
                                    // disabled={loading}
                                    // onChange={(e) => setJobDescription(e.target.value)}
                                    // className="w-full p-3 border border-gray-700 rounded-sm outline-none focus:border-gray-900 shadow-md"
                                    name="question"
                                    widthOverride="52rem"
                                    rows="4"
                                    value={question}
                                    onChange={(e) => setQuestion(e.target.value)}
                                />
                                {/* <span className="text-xs text-gray-500">245 Characters Left</span> */}
                            </div>
                            <div className="col-span-2 flex flex-col gap-y-2">
                                {/*<label htmlFor="answer_time" className="text-sm font-semibold text-black">Expected*/}
                                {/*    Answer</label>*/}
                                <Textarea
                                    key="ExpectedAnswer"
                                    id="ExpectedAnswer"
                                    label="Expected Answer"
                                    // disabled={loading}
                                    // onChange={(e) => setJobDescription(e.target.value)}
                                    // className="w-full p-3 border border-gray-700 rounded-sm outline-none focus:border-gray-900 shadow-md"
                                    name="ExpectedAnswer"
                                    widthOverride="52rem"
                                    rows="5"
                                    value={expectedAnswer}
                                    onChange={(e) => setExpectedAnswer(e.target.value)}
                                />
                                {/* <span className="text-xs text-gray-500">245 Characters Left</span> */}
                            </div>
                            <div className="flex items-center pt-4 gap-x-8">
                                <Button
                                    className="h-[2rem] text-[0.725rem]"
                                    label="SAVE"
                                    variant="bright-blue"
                                    width="10.438rem"
                                    onClick={handleSaveClick}
                                />
                                <Button
                                    className="h-[2rem] text-[0.725rem]"
                                    label="ADD ANOTHER"
                                    width="10.438rem"
                                    variant="white"
                                    onClick={handleAddAnotherClick}
                                />
                            </div>
                        </div>
                    </form>
                    <div className="flex items-center w-full py-2 rounded-md mt-6">
                        <img src={alertTriangle} className="w-6 mx-2" alt="info" />
                        <div className="w-full">
                            <p className="mx-2 text-sm text-[#e90c44]">
                                Add your own questions to the list of questions asked during the interview. We strictly
                                recommend you to keep the answer time between 1-5 minutes.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddInterviewQuestionLayout;