import React, {useEffect, useRef, useState} from "react"
import {FormValidator} from "@syncfusion/ej2-react-inputs";
import {Button} from "../../../components/button";
import {Textbox} from "../../../components/textbox";
import Modal from "../../../components/popup";
import TaxDropdown from "../../../components/taxDropdown";
import card from "../../../../assets/Credit Card copy.svg";


const BillingInfo = () => {
    const [email, setEmail] = useState('');
    const [companyName, setCompanyName] = useState("");
    const [taxId, setTaxId] = useState("123456");
    const [address, setAddress] = useState("");
    const [city, setCity] = useState("");
    const [country, setCountry] = useState("");
    const [zip, setZip] = useState("");
    const [teamId, setTeamId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [taxType, setTaxType] = useState('GST');
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('id');

    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const addressValidator = (value) => {
        const addressExp = /^[\w\s\d#.,\-/]+$/;
        return addressExp.test(value.value.trim() || '');
    };
    const nameValidator = (value) => {
        const nameExp = /^[A-Za-z�-�]+(?: [A-Za-z�-�]+)*$/;
        return nameExp.test(value.value.trim() || '');
    };
    const cityValidator = (value) => {
        const cityExp = /^[A-Za-z�-�]+(?: [A-Za-z�-�]+)*$/;
        return cityExp.test(value.value.trim() || '');
    };
    const countryValidator = (value) => {
        const countryExp = /^[A-Za-z�-�]+(?: [A-Za-z�-�]+)*$/;
        return countryExp.test(value.value.trim() || '');
    };
    const zipValidator = (value) => {
        const zipExp = /^\d{2}[ -]?\d{2}[ -]?\d{2}$/;
        return zipExp.test(value.value.trim() || '');
    };
    const phoneValidator = (value) => {
        const phoneExp = /^\d{10}$/;
        return phoneExp.test(value.value.trim() || '');
    };
    const institutionalEmailValidator = (args) => {
        args.value = args.value.trim(); // Trim the input value
        const disallowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'aol.com',
            'icloud.com', 'protonmail.com', 'zoho.com', 'yandex.com', 'mail.com', 'gmx.com',
            'tutanota.com', 'fastmail.com', 'hushmail.com', 'gawab.com', 'inbox.com', 'aim.com',
            'fastmail.com', 'tutanota.com', 'pobox.com', 'yandex.ru', 'yandex.mail',
            'aol.com', 'gmx.net', 'rediff.com']; // Add more as needed
        const emailDomain = args.value.split('@')[1];

        return !disallowedDomains.includes(emailDomain);
    };

    const handleTaxTypeChange = (newTaxType) => {
        setTaxType(newTaxType);
    };

    useEffect(() => {
        if (formRef.current) {
            const options = {
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
                rules: {
                    'companyName': {
                        required: true,
                        nameValidator: [nameValidator, 'Enter a valid name'],
                    },
                    'email': {
                        required: [true, 'Email is required'],
                        email: [true, 'Please enter a valid email'],
                        institutionalEmailValidator: [institutionalEmailValidator, 'Please enter a valid organisational email address']
                    },
                    'taxId': {
                        required: [true, 'This field is required']
                    },
                    'address': {
                        required: true,
                        addressValidator: [addressValidator, 'Address is not valid']
                    },
                    'city': {
                        required: true,
                        cityValidator: [cityValidator, 'Enter a valid city']
                    },
                    'country': {
                        required: true,
                        countryValidator: [countryValidator, 'Enter a valid country']
                    },
                    'zip': {
                        required: true,
                        zipValidator: [zipValidator, 'Enter a valid zip code']
                    },
                },
            };
            validatorRef.current = new FormValidator(formRef.current, options);
        }
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const teamIdResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!teamIdResponse.ok) {
                throw new Error(`HTTP error! Status: ${teamIdResponse.status}`);
            }

            const teamIdData = await teamIdResponse.json();
            if (teamIdData.status === 201 && teamIdData.data && teamIdData.data.teamId) {
                setTeamId(teamIdData.data.teamId);

                // Second API call using the retrieved teamId
                const billingDetailsResponse = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getBillingDetails?teamId=${teamIdData.data.teamId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (!billingDetailsResponse.ok) {
                    throw new Error(`HTTP error! Status: ${billingDetailsResponse.status}`);
                }

                const billingDetailsData = await billingDetailsResponse.json();

                if (billingDetailsData.status === 200) {
                    setCompanyName(billingDetailsData.data.billingName);
                    setAddress(billingDetailsData.data.billingAddress);
                    setCity(billingDetailsData.data.billingCity);
                    setCountry(billingDetailsData.data.billingCountry);
                    setEmail(billingDetailsData.data.billingEmail);
                    setZip(billingDetailsData.data.billingZip);
                    setTaxId(billingDetailsData.data.taxId)
                }
            }
        } catch (error) {
            // Handle error response
            // console.error("Error fetching data:", error);
            setShowModal(true);
            setModalContent({
                heading: 'Failed to fetch data.',
                message: error.message, // Display specific error message
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setIsLoading(false); // Set loading to false after fetching data
        }
    };

    useEffect(() => {
        fetchData();
    }, [teamId, accessToken]);

    const handleCancelClick = (e) => {
        e.preventDefault();
        fetchData();
    };


    const handleUpdateClick = async (e) => {
        e.preventDefault();
        if (validatorRef.current && validatorRef.current.validate()) {
            if (!teamId) {
                return;
            }

            try {
                const modifyBillingDetails = {
                    billingDetailsName: companyName,
                    billingDetailsAddress: address,
                    billingDetailsCity: city,
                    billingDetailsCountry: country,
                    billingDetailsZip: zip,
                    taxId: taxId,
                    invoiceEmail: email,
                    taxType: taxType
                };

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/changeBillingDetails?teamId=${teamId}`,
                    {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(modifyBillingDetails),
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();
                fetchData();
                setModalContent({
                    heading: 'Success',
                    message: 'Billing details updated',
                    confirmButtonText: 'OK',
                });
                setShowModal(true);
            } catch (error) {
                console.error("Error updating billing details:", error);
            }
        } else {
            // Show modal instead of alert
            setModalContent({
                heading: 'Error',
                message: 'Please fill all fields',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        }
    };

    const handleModalConfirm = () => {
        setShowModal(false);
        window.location.reload()
    };

    return (
        <div>
            {/* {isLoading && <LoadingOverlay />} */}
            <div className="max-h-[78vh] overflow-y-auto"
                 style={{
                     scrollbarWidth: 'none',
                     msOverflowStyle: 'none',
                     '&::-webkit-scrollbar': {
                         display: 'none',
                     },
                 }}
            >
                <form id="billingInfo" ref={formRef}>
                    <div class='space-y-[2.188rem]'>
                        <div class="flex justify-start gap-[2.063rem]">
                            <Textbox width="20rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Company Name
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="Boltcode"
                                     type="companyName"
                                     name="companyName"
                                     value={companyName}
                                     onChange={(e) => setCompanyName(e.target.value)}
                            ></Textbox>
                            <TaxDropdown
                                label={(
                                    <label className="flex items-center">
                                        Tax ID
                                        <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                    </label>
                                )}
                                showLabel={true}
                                name="taxId"
                                type="taxId"
                                placeholder="123456"
                                width="20rem"
                                value={taxId}
                                setValue={setTaxId}
                                onChange={(e) => setTaxId(e.target.value)}
                                onTaxTypeChange={handleTaxTypeChange}
                            ></TaxDropdown>
                        </div>
                        <div>
                            <Textbox width="42.063rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Address
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     name="address"
                                     type="address"
                                     placeholder="abc, town."
                                     value={address}
                                     onChange={(e) => setAddress(e.target.value)}

                            ></Textbox>
                        </div>
                        <div class="flex justify-start gap-[2.063rem]">
                            <Textbox width="20rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             City
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="Alabama"
                                     type="city"
                                     name="city"
                                     value={city}
                                     onChange={(e) => setCity(e.target.value)}
                            ></Textbox>
                            <Textbox width="20rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Country
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="USA"
                                     type="country"
                                     name="country"
                                     value={country}
                                     onChange={(e) => setCountry(e.target.value)}
                            ></Textbox>
                        </div>
                        <div class="flex justify-start gap-[2.063rem]">
                            <Textbox width="20rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Send invoice to email ID
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="olivia@untitledui.com"
                                     type="email"
                                     name="email"
                                     value={email}
                                     onChange={(e) => setEmail(e.target.value)}
                            ></Textbox>
                            <Textbox width="20rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Zip Code
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="123456"
                                     type="zipcode"
                                     name="zip"
                                     value={zip}
                                     onChange={(e) => setZip(e.target.value)}
                            ></Textbox>
                        </div>
                    </div>
                    <div class="mt-[3.313rem] flex justify-start gap-[1rem]">
                        <Button width="9.188rem"
                                label="Cancel"
                                variant="white"
                                onClick={handleCancelClick}
                        ></Button>
                        <Button width="9.188rem"
                                label={isLoading ? "PLEASE WAIT..." : "UPDATE"}
                                type="submit"
                                variant="bright-blue"
                                onClick={handleUpdateClick}
                        ></Button>
                    </div>
                    <Modal isOpen={showModal}
                           onClose={() => setShowModal(false)}
                           onConfirm={handleModalConfirm}
                           heading={modalContent.heading}
                           message={modalContent.message}
                           confirmButtonText={modalContent.confirmButtonText}
                    />
                </form>
            </div>
            <img src={card}
                 alt="dave holding a credit card"
                 className="
                         absolute
                         bottom-8
                         right-0
                         xl:w-[17.313rem] xl:h-[17.313rem]
                         2xl:w-[17.313rem] 2xl:h-[17.313rem]
                         3xl:w-[17.313rem] 3xl:h-[17.313rem]
                         lg:w-[13.313rem] lg:h-[13.313rem]
                         md:w-[13.313rem] md:h-[13.313rem]"/>
        </div>
    );
    // return (
    //     <div class="text-center">
    //         Contact sales for billing info by <u><a href="https://ovii.ai/contact-us/" class="text-blue" target="_blank">clicking here</a></u>.
    //     </div>
    // )
}

export default BillingInfo