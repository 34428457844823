// actions.js
export const loginUser = (credentials, onSuccess, onFailure) => {
    return async (dispatch) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/login`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(credentials),
                }
            );

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.message || "Error during login");
            }

            dispatch({
                type: "LOGIN",
                payload: {
                    id: data.data.id,
                    accessToken: data.data.accessToken,
                    email: data.data.email,
                    role: data.data.roles
                },
            });
            localStorage.setItem("accessToken", data.data.accessToken)
            localStorage.setItem("id", data.data.id)
            localStorage.setItem("email", data.data.email)
            localStorage.setItem("adminName", data.data.name)
            localStorage.setItem("role", data.data.roles[0])
            onSuccess(); // Call the success callback
        } catch (error) {
            onFailure(error); // Call the failure callback
        }
    };
};
