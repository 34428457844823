import React, {useEffect, useState, useRef} from "react";
import '../../../assets/font.css';
import alertTriangle from "../../../assets/alert-triangle.svg";
import {Button} from "../button";
import LineTabs from "../LineTabs";
import ChatWindow from "../chat";
import Checkbox from "../checkbox";
import SentimentBarChart from './sentimentBarChart';
import NotesHandler1 from "./NotesHandler";
import print from "../../../assets/print.svg"
import { useReactToPrint } from 'react-to-print';
import Modal from "../popup";

const AsyncVideoSlider = ({onRefresh, candidateId, isOpen, onClose}) => {
    const [candidateScoreDetails, setCandidateScoreDetails] = useState();
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [averageScore, setAverageScore] = useState(0);
    const [starRating, setStarRating] = useState(0);
    const accessToken = localStorage.getItem("accessToken");
    const assessmentId = localStorage.getItem("assessmentId");
    const [isParaphrasedExpanded, setIsParaphrasedExpanded] = useState(false);
    const [interviewSummary, setInterviewSummary] = useState(null);
    const [overallSentiment, setOverallSentiment] = useState(null);
    const [answersLength, setAnswersLength] = useState(0);
    const [isAnswerExpanded, setIsAnswerExpanded] = useState(false);
    const [initialPrompt, setInitialPrompt] = useState("based on this data, answer the following questions" + candidateScoreDetails);
    const [isScoreExplanationExplanded, setIsScoreExplanationExplanded] = useState(false);
    const [positive, setPositive] = useState();
    const [neutral, setNeutral] = useState();
    const [negative, setNegative] = useState();
    const [highConfidence, setHighConfidence] = useState(0);
    const [mediumConfidence, setMediumConfidence] = useState(0);
    const [lowConfidence, setLowConfidence] = useState(0);
    const [allNotes, setAllNotes] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [selectedEval, setSelectedEval] = useState();

    const componentRef = useRef();

    const handleRadioChange = (event) => {
        if (event.target.value === "shortlist") {
            handleEvaluationChange(5);
        } else if (event.target.value === "hold") {
            handleEvaluationChange(2);
        } else if (event.target.value === "reject") {
            handleEvaluationChange(4);
        }
    };


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    useEffect(() => {
        const print = () => {
            setInitialPrompt(JSON.stringify(candidateScoreDetails));
        }
        print();
    }, [initialPrompt, candidateScoreDetails]);

    const handleEvaluationChange = async (evalID) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/updateCandidateEvaluation?candidateId=${candidateId}&evaluationId=${evalID}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data && data.status === 200) {
                setModalContent({
                    heading: 'Success', 
                    message: 'Candidate evaluation updated.', 
                    confirmButtonText: 'OK', 
                });
                setShowModal(true);
            } else {
                setModalContent({
                    heading: 'Success', 
                    message: 'Candidate evaluation updated.', 
                    confirmButtonText: 'OK', 
                });
                setShowModal(true);
            }
        } catch (error) {
            setModalContent({
                heading: 'Success', 
                message: 'Candidate evaluation updated.', 
                confirmButtonText: 'OK', 
            });
            setShowModal(true);
        }
    }

    const [isActiveLineTab, setIsActiveLineTab] = useState({
        1: true,
        2: false,
        3: false,
    });

    const tabConfig = [
        {
            key: 1,
            tabName: "Video interview details",
            isActive: isActiveLineTab[1],
        },
        {
            key: 2,
            tabName: "Additional info",
            isActive: isActiveLineTab[2],
        },
        {
            key: 3,
            tabName: "Notes",
            isActive: isActiveLineTab[3],
        }
    ];

    const handleTabChange = (key) => {
        setIsActiveLineTab({[key]: true});
    }

    const [note, setNote] = useState('');
    const [savedNotes, setSavedNotes] = useState([]);

    const handleChange = (event) => {
        setNote(event.target.value);
    };

    const handleSaveNote = async () => {
        if (note.trim() !== '') {
            const newSavedNotes = [...savedNotes, note];
            setSavedNotes(newSavedNotes);
            setNote('');
            try {
                await sendNotesToAPI(newSavedNotes);
            } catch (error) {
                console.error('Error sending notes to API:', error);
            }
        }
    };

    const sendNotesToAPI = async (notes) => {
        const notesString = notes;
        if (candidateScoreDetails && candidateScoreDetails.notes) {
            const existingNotes = candidateScoreDetails.notes.split('\n');
            const allNotes = [...existingNotes, ...notesString];
            const updatedNotes = allNotes.join('\n');
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/setNotes?candidateId=${candidateId}&notes=${encodeURIComponent(updatedNotes)}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to send notes to API');
            }
        } else {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/setNotes?candidateId=${candidateId}&notes=${encodeURIComponent(notesString)}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error('Failed to send notes to API');
            }
        }
    };

    useEffect(() => {
        const fetchCandidateScoreDetails = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getInterviewEvalForCandidate?candidateId=${candidateId}&assessmentId=${assessmentId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                const data = await response.json();
                if (data && data.status === 200) {
                    setCandidateScoreDetails(data.data);
                    const str = data.data.notes
                    if (str === null) {
                        setAllNotes([])
                    } else {
                        setAllNotes(str.split('\n'))
                    }
                    const scores = data.data.answers.map(answer => answer.score);
                    const average = parseFloat((scores.reduce((acc, curr) => acc + curr, 0) / scores?.length).toFixed(1));
                    setAverageScore(average);
                    const starRatingValue = Math.round((average * 0.1) * 5);
                    setStarRating(starRatingValue);
                    setAnswersLength(data.data.answers.length);
                    const interviewSummary = data.data.additionalInfo.overallInterviewSummary.interviewSummary ?? "Cannot fetch summary";
                    const overallSentiment = data.data.additionalInfo.overallInterviewSummary.overallSentiment ?? "Neutral";
                    setInterviewSummary(interviewSummary);
                    setOverallSentiment(overallSentiment);
                    const sentimentPercentages = calculateSentimentPercentages(data.data);
                    setPositive(sentimentPercentages.positivePercentage);
                    setNeutral(sentimentPercentages.neutralPercentage);
                    setNegative(sentimentPercentages.negativePercentage);

                    const highConfidence = data.data.answers.filter(answer => answer.eval?.evaluation?.confidence?.toLowerCase().includes('high')).length;
                    const mediumConfidence = data.data.answers.filter(answer => answer.eval?.evaluation?.confidence?.toLowerCase().includes('medium')).length;
                    const lowConfidence = data.data.answers.filter(answer => answer.eval?.evaluation?.confidence?.toLowerCase().includes('low')).length;
                    setHighConfidence(highConfidence);
                    setMediumConfidence(mediumConfidence);
                    setLowConfidence(lowConfidence);
                    setSelectedEval(data.data.evaluation.evaluationStatusId)
                    const existingNotes = data.data.notes ? data.data.notes.split('\n') : [];
                    setSavedNotes(existingNotes);
                } else {
                    console.error("Error fetching candidate score details: ", data.message);
                }
            } catch (error) {
                console.error("Error fetching candidate score details: ", error);
            }
        };
        if (!!candidateId) fetchCandidateScoreDetails();
    }, [accessToken, candidateId, assessmentId]);

    const UserDetails = ({name, averageScore}) => {
        return (
            <div className="flex items-center m-0 my-0 gap-x-4">
                <div className="flex flex-col gap-y-0">
                    <span className="text-xl font-bold text-slate-800 font-serif leading-[1.26rem]">{name}</span>
                </div>
                <div>
                    {renderStars()}
                    <span className="text-sm font-semibold ml-2">{averageScore}/10</span>
                </div>
            </div>
        );
    };

    const renderStars = () => {
        const stars = [];
        for (let i = 0; i < 5; i++) {
            stars.push(
                <span key={i} className={i < starRating ? "text-[#004eec]" : "text-gray-300"}>&#9733;</span>
            );
        }
        return stars;
    };

    const toggleQuestionExpansion = (questionId, answer) => {
        if (selectedQuestion === questionId) {
            setSelectedQuestion(null);
        } else {
            setSelectedQuestion(questionId);
        }
    };

    const handleRemoveNote = async (indexToRemove) => {
        const updatedNotes = savedNotes.filter((_, index) => index !== indexToRemove);
        setSavedNotes(updatedNotes);
        try {
            await sendNotesToAPI(updatedNotes);
        } catch (error) {
            console.error('Error sending notes to API:', error);
        }
    };

    const openQuestionDetails = (answer, candidateId) => {
        localStorage.setItem("selectedAsyncVideoQuestion", JSON.stringify(answer));
        window.open(`/question-details`, '_blank');
    };

    useEffect(() => {
        if (candidateScoreDetails) {
            const sentimentPercentages = calculateSentimentPercentages(candidateScoreDetails);
            setPositive(sentimentPercentages.positivePercentage);
            setNeutral(sentimentPercentages.neutralPercentage);
            setNegative(sentimentPercentages.negativePercentage);
        }
    }, [candidateScoreDetails]);

    function calculateSentimentPercentages(data) {
        const answers = data.answers;
        let positiveCount = 0;
        let neutralCount = 0;
        let negativeCount = 0;

        if (answers != null) {
            const totalAnswers = answers.length;
            answers.forEach(answer => {
                const sentiment = answer.eval?.evaluation?.sentimentAnalysis;
                if (sentiment === "Positive") {
                    positiveCount++;
                } else if (sentiment === "Neutral") {
                    neutralCount++;
                } else if (sentiment === "Negative") {
                    negativeCount++;
                }
            });

            const positivePercentage = ((positiveCount / totalAnswers) * 100).toFixed(2);
            const neutralPercentage = ((neutralCount / totalAnswers) * 100).toFixed(2);
            const negativePercentage = ((negativeCount / totalAnswers) * 100).toFixed(2);

            return {
                positivePercentage,
                neutralPercentage,
                negativePercentage
            };
        } else {
            return {
                positivePercentage: 0,
                neutralPercentage: 0,
                negativePercentage: 0
            };
        }
    }

    function formatCriteria(criteria) {
        return criteria.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
    }

    const handleModalConfirm = () => {
        setShowModal(false);
        onRefresh();
    };

    return (
        <div
            className={`fixed top-0 ${
                isOpen ? "-right-0" : "-right-[100%]"
            } transition-all duration-700 w-3/4 h-screen bg-white overflow-y-scroll shadow-2xl rounded-l-2xl z-50`}
        >
            {candidateScoreDetails && (
                <div ref={componentRef}>
                    <div
                        className="flex items-center justify-between px-10 py-5 w-full p-0 m-0 border-b-[1px] shadow-md">
                        <UserDetails
                            name={candidateScoreDetails.name}
                            averageScore={averageScore}
                        />
                        <div className="flex justify-end">
                            <Button width="3rem" variant="no-outline" className="mr-[2.5rem]" onClick={handlePrint}><img src={print} alt="download"/></Button>
                            <button
                                onClick={onClose}
                                className="flex items-center justify-center w-6 h-6 p-1 mt-[0.3rem] text-sm text-white bg-blue rounded-full"
                            >
                                X
                            </button>
                        </div>
                    </div>
                    <div className="flex justify-between items-start ml-[2rem] mt-[2rem]">
                        <LineTabs handleTabChange={handleTabChange} tabConfig={tabConfig}/>
                    </div>
                    {isActiveLineTab[1] && (
                    <div>
                    {candidateScoreDetails && candidateScoreDetails.additionalInfo != null ? (
                        <div className="">
                            {candidateScoreDetails && candidateScoreDetails.additionalInfo != null && (
                                <>
                                    <div className="w-[100%] pb-[1rem]">
                                        <div className="mt-2 px-10">
                                            {/*<h3 className="w-full mb-3 font-sans font-bold text-charcoal text-md">Sentiment*/}
                                            {/*    Analysis</h3>*/}
                                            {/*<div className="flex flex-row gap-4">*/}
                                            {/*    <div*/}
                                            {/*        className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'*/}
                                            {/*        style={{display: 'flex', alignItems: 'stretch'}}>*/}
                                            {/*        <div className="flex justify-between items-center">*/}
                                            {/*            <div>*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <div*/}
                                            {/*                        className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"*/}
                                            {/*                    >*/}
                                            {/*                        NUMBER OF RESPONSES*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}

                                            {/*                <div*/}
                                            {/*                    className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"*/}
                                            {/*                >*/}
                                            {/*                    <span*/}
                                            {/*                        className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>*/}
                                            {/*                        {answersLength}*/}
                                            {/*                    </span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <div*/}
                                            {/*        className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'*/}
                                            {/*        style={{display: 'flex', alignItems: 'stretch'}}>*/}
                                            {/*        <div className="flex justify-between items-center">*/}
                                            {/*            <div>*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <div*/}
                                            {/*                        className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"*/}
                                            {/*                    >*/}
                                            {/*                        POSITIVE SENTIMENT*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}

                                            {/*                <div*/}
                                            {/*                    className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"*/}
                                            {/*                >*/}
                                            {/*                    <span*/}
                                            {/*                        className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>*/}
                                            {/*                        {positive}%*/}
                                            {/*                    </span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*    <div*/}
                                            {/*        className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'*/}
                                            {/*        style={{display: 'flex', alignItems: 'stretch'}}>*/}
                                            {/*        <div className="flex justify-between items-center">*/}
                                            {/*            <div>*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <div*/}
                                            {/*                        className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"*/}
                                            {/*                    >*/}
                                            {/*                        NEUTRAL SENTIMENT*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}

                                            {/*                <div*/}
                                            {/*                    className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"*/}
                                            {/*                >*/}
                                            {/*                    <span*/}
                                            {/*                        className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>*/}
                                            {/*                        {neutral}%*/}
                                            {/*                    </span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}
                                            {/*</div>*/}
                                            {/*<div className="flex flex-row gap-4 mt-4">*/}
                                            {/*    <div*/}
                                            {/*        className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'*/}
                                            {/*        style={{display: 'flex', alignItems: 'stretch'}}>*/}
                                            {/*        <div className="flex justify-between items-center">*/}
                                            {/*            <div>*/}
                                            {/*                <div className="flex items-center">*/}
                                            {/*                    <div*/}
                                            {/*                        className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"*/}
                                            {/*                    >*/}
                                            {/*                        NEGATIVE SENTIMENT*/}
                                            {/*                    </div>*/}
                                            {/*                </div>*/}

                                            {/*                <div*/}
                                            {/*                    className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"*/}
                                            {/*                >*/}
                                            {/*                    <span*/}
                                            {/*                        className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>*/}
                                            {/*                        {negative}%*/}
                                            {/*                    </span>*/}
                                            {/*                </div>*/}
                                            {/*            </div>*/}
                                            {/*        </div>*/}
                                            {/*    </div>*/}

                                            {/*</div>*/}
                                            <div className="">
                                                {candidateScoreDetails && true ? (
                                                    <div className='flex gap-[1.375rem] items-center justify-end'>
                                                       <div className="mb-4">
                                                            <h3 className="mb-3 font-bold text-black text-md">
                                                                Evaluation
                                                            </h3>
                                                            <div className="flex gap-4">
                                                                <label className="flex items-center border border-gray-400 w-[10rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                                                                    <input 
                                                                        type="radio" 
                                                                        value="shortlist" 
                                                                        checked={selectedEval === 5} 
                                                                        onChange={handleRadioChange} 
                                                                        className="" 
                                                                    />
                                                                    <span className="ml-2">Shortlist</span>
                                                                </label>
                                                                <label className="flex items-center border border-gray-400 w-[10rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                                                                    <input 
                                                                        type="radio" 
                                                                        value="hold" 
                                                                        checked={selectedEval === 2} 
                                                                        onChange={handleRadioChange} 
                                                                        className=""
                                                                    />
                                                                    <span className="ml-2">Hold</span>
                                                                </label>
                                                                <label className="flex items-center border border-gray-400 w-[10rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                                                                    <input 
                                                                        type="radio" 
                                                                        value="reject" 
                                                                        checked={selectedEval === 4} 
                                                                        onChange={handleRadioChange} 
                                                                        className=""
                                                                    />
                                                                    <span className="ml-2">Reject</span>
                                                                </label>
                                                            </div>
                                                        </div> 
                                                    </div>
                                                ) : (<></>)}
                                                <div className='flex flex-row gap-4 h-full'>
                                                    <div className='flex flex-col space-y-4 w-1/3 h-full'>
                                                        <div
                                                            className='w-full h-full bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'>
                                                            <div className="flex justify-between items-center">
                                                                <div>
                                                                    <div className="flex items-center">
                                                                        <div
                                                                            className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                                            style={{'fontFamily': 'helvetica'}}>
                                                                            TOTAL RESPONSES
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3">
                                                                        <span
                                                                            className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>
                                                                            {answersLength}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='w-full h-full bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'>
                                                            <div className="flex justify-between items-center">
                                                                <div>
                                                                    <div className="flex items-center">
                                                                        <div
                                                                            className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                                            style={{'fontFamily': 'helvetica'}}>
                                                                            AVERAGE SCORE
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3">
                                                                            <span
                                                                                className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>
                                                                                {averageScore}/10
                                                                            </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-col w-2/3 h-full border px-4 py-4">
                                                        <p className='font-serif font-bold pl-2'>Sentiment Analysis</p>
                                                        <div className="flex w-full flex-row gap-4 pl-2">
                                                            <div className="w-1/3">
                                                                <p className="font-serif font-medium">
                                                                    Positive
                                                                </p>
                                                                <p className='text-green-500'>
                                                                    {positive}% <span>responses</span>
                                                                </p>
                                                            </div>
                                                            <div className="w-1/3">
                                                                <p className="font-serif font-medium">
                                                                    Neutral
                                                                </p>
                                                                <p className='text-yellow-500'>
                                                                    {neutral}% <span>responses</span>
                                                                </p>
                                                            </div>
                                                            <div className="w-1/3">
                                                                <p className="font-serif font-medium">
                                                                    Negative
                                                                </p>
                                                                <p className='text-red-500'>
                                                                    {negative}% <span>responses</span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="flex-grow">
                                                            <SentimentBarChart
                                                                positive={positive}
                                                                neutral={neutral}
                                                                negative={negative}/> {/* Use the new component here */}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="flex items-center px-2 py-3 rounded-md mb-8">
                                                    <img src={alertTriangle} className="w-6 mx-2" alt="info"/>
                                                    <div className="w-full">
                                                        <p className="mx-2 text-sm text-[#004eec]"
                                                           style={{fontFamily: 'helvetica'}}>
                                                            Please note that AI-driven sentiment analysis is an initial
                                                            tool
                                                            to
                                                            assist
                                                            in
                                                            understanding candidate responses. For comprehensive
                                                            evaluation,
                                                            review the
                                                            full
                                                            video interview and consider the context. AI insights should
                                                            complement, not
                                                            replace, human judgment
                                                        </p>
                                                    </div>
                                                </div>

                                                <div
                                                    className="w-full border-collapse border rounded-[6px] border-solid border-bgrey mt-9 mb-10">
                                                    <div className="w-full border-collapse rounded-[6px] mt-0">
                                                        <table className="w-full">
                                                            <thead className="bg-[#FAFAFA]">
                                                            <tr className="h-9">
                                                                <th className="text-charcoal text-[13px] font-regular cursor-pointer">Criteria</th>
                                                                <th className="text-charcoal text-[13px] font-regular cursor-pointer">Evaluation</th>
                                                                <th className="text-charcoal text-[13px] font-regular cursor-pointer">Notes</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {Object.entries(candidateScoreDetails.additionalInfo?.overallInterviewSummary?.evaluationDetails ?? {}).map(([criteria, details], index) => (
                                                                <tr key={index}
                                                                    className="font-sans text-[#000] text-[0.95rem] border-b tracking-wide"
                                                                >
                                                                    <td className="px-7"
                                                                        style={{width: "30%"}}>{formatCriteria(criteria)}</td>
                                                                    <td className="text-center"
                                                                        style={{width: "15%"}}>{details.evaluation}</td>
                                                                    <td className="pl-7 py-3 pr-6"
                                                                        style={{width: "55%"}}>{details.notes}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                <div className="mb-10">
                                                    <h3 className="w-full mb-3 font-sans font-bold text-charcoal text-md">Summary</h3>
                                                    <p className="font-sans text-[0.95rem] leading-7"
                                                       style={{fontFamily: 'HelveticaNeue'}}>
                                                        {interviewSummary}
                                                    </p>
                                                </div>

                                                <h3 className="w-full mb-3 font-sans font-bold text-charcoal text-md">Confidence
                                                    Level</h3>
                                                <div
                                                    className="w-full border-collapse border rounded-[6px] border-solid border-bgrey mt-3 mb-10">
                                                    <div className="w-full border-collapse rounded-[6px] mt-0">
                                                        <table className="w-full">
                                                            <thead className="bg-[#FAFAFA]">
                                                            <tr className="h-9">
                                                                <th className="text-charcoal text-[13px] font-regular cursor-pointer">Question</th>
                                                                <th className="text-charcoal text-[13px] font-regular cursor-pointer">Confidence</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {candidateScoreDetails.answers.map((answer, index) => (
                                                                <tr key={index}
                                                                    className="font-sans text-[#000] text-[0.9rem] border-b tracking-[0.2px] leading-[1.65rem]"
                                                                    style={{fontFamily: 'helvetica'}}>
                                                                    <td className="pl-7 py-3"
                                                                        style={{width: "55%"}}>{answer.question}</td>
                                                                    <td className="text-center"
                                                                        style={{width: "20%"}}>{answer.eval?.evaluation?.confidence}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>

                                                {candidateScoreDetails.additionalInfo != null ? (
                                                    candidateScoreDetails.answers.map((answers) => (
                                                        <div key={answers.questionId}
                                                             className="flex flex-col min-h-24 cursor-pointer mt-6">
                                                            <div className=""
                                                                 onClick={() => toggleQuestionExpansion(answers.questionId)}>
                                                                <div className="w-full">
                                                                    <p className="w-[90%] font-medium text-s break-word mb-3 text-blue"
                                                                       style={{fontFamily: 'HelveticaNeue'}}>
                                                                        {answers.question}
                                                                    </p>
                                                                    <p className=" text-[0.95rem] text-gray-600 line-clamp-2"
                                                                       style={{fontFamily: 'HelveticaNeue'}}
                                                                       dangerouslySetInnerHTML={{__html: answers.questionDetails}}/>
                                                                </div>
                                                                <div className="flex wrap gap-[1rem] mt-[1rem]">
                                                                    <p className="text-sm font-semibold bg-[#F3F3F3] text-[#020818] rounded-m px-2.5 py-1.5">
                                                                        <span
                                                                            className="text-sm">Score:</span> {answers.score}
                                                                    </p>
                                                                    <p className="text-sm font-semibold bg-[#F3F3F3] text-[#020818] rounded-m px-2.5 py-1.5">
                                                                        <span
                                                                            className="text-sm">Confidence:</span> {answers.eval?.evaluation?.confidence}
                                                                    </p>
                                                                    <p className="text-sm font-semibold bg-[#F3F3F3] text-[#020818] rounded-m px-2.5 py-1.5">
                                                                        <span
                                                                            className="text-sm">Sentiment Analysis:</span> {answers.eval?.evaluation?.sentimentAnalysis}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            {selectedQuestion === answers.questionId && (
                                                                <div>
                                                                    <div
                                                                        className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-8'>
                                                                        <div
                                                                            className='flex justify-between text-[#16193a] cursor-pointer'
                                                                            style={{'fontFamily': 'helvetica'}}
                                                                            onClick={() => {
                                                                                setIsAnswerExpanded(!isAnswerExpanded)
                                                                                setIsParaphrasedExpanded(false)
                                                                                setIsScoreExplanationExplanded(false)
                                                                            }}>
                                                                            <h3 className='font-sans text-[1rem] font-medium text-s break-word text-blue'>Candidates
                                                                                answer</h3>
                                                                            <p className='text-[#000] font-bold'>{isAnswerExpanded ? "-" : "+"}</p>
                                                                        </div>
                                                                        {isAnswerExpanded && (
                                                                            <p className="mt-3 font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem]">{answers.answer}</p>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>
                                                                        <div
                                                                            className='flex justify-between text-[#16193a] cursor-pointer'
                                                                            style={{'fontFamily': 'helvetica'}}
                                                                            onClick={() => {
                                                                                setIsAnswerExpanded(false)
                                                                                setIsParaphrasedExpanded(!isParaphrasedExpanded)
                                                                                setIsScoreExplanationExplanded(false)
                                                                            }}>
                                                                            <h3 className='font-sans font-medium text-s break-word text-blue'>Paraphrased
                                                                                answer</h3>
                                                                            <p className='text-[#000] font-bold'>{isParaphrasedExpanded ? "-" : "+"}</p>
                                                                        </div>
                                                                        {isParaphrasedExpanded && (
                                                                            <p className="mt-3 font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem]">{answers.eval?.evaluation?.paraphrasedAnswer}</p>
                                                                        )}
                                                                    </div>
                                                                    <div
                                                                        className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>
                                                                        <div
                                                                            className='flex justify-between text-[#16193a] cursor-pointer'
                                                                            style={{'fontFamily': 'helvetica'}}
                                                                            onClick={() => {
                                                                                setIsAnswerExpanded(false)
                                                                                setIsParaphrasedExpanded(false)
                                                                                setIsScoreExplanationExplanded(!isScoreExplanationExplanded)
                                                                            }}>
                                                                            <h3 className='font-sans font-medium text-s break-word text-blue'>Score
                                                                                Explanation</h3>
                                                                            <p className='text-[#000] font-bold'>{isScoreExplanationExplanded ? "-" : "+"}</p>
                                                                        </div>
                                                                        {isScoreExplanationExplanded && (
                                                                            <p className="mt-3 font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem]">{answers.eval?.evaluation?.scoreExplanation}</p>
                                                                        )}
                                                                    </div>
                                                                    <div className="flex justify-start">
                                                                        <Button className="mt-[1rem]"
                                                                                label="VIEW DETAILS"
                                                                                width="7rem"
                                                                            // variant='bright-blue'
                                                                                onClick={() => openQuestionDetails(answers)}/>
                                                                    </div>
                                                                </div>
                                                            )}
                                                            <hr className="mt-7 border"/>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <div className="text-center text-gray-500">This candidate is yet to
                                                        take
                                                        the
                                                        assesment. Please come back when the candidate has completed the
                                                        interview to see their results.</div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    ) : (
                        <div className="text-center mx-auto font-serif text-gray-500 text-lg mt-[5rem] w-[80%]">This
                            candidate is yet to take the assesment. Please come back when the candidate has completed
                            the interview to see their results.</div>
                    )
                    }
                    </div>
                    )}
                    {isActiveLineTab[2] && (
                        <div className="">
                            {candidateScoreDetails && candidateScoreDetails.additionalInfo != null ? (
                                <>
                                    <div className="w-full">
                                        <div className="max-h-[80vh] overflow-y-auto">
                                            <ChatWindow initialPrompt={initialPrompt}/>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className="text-center mx-auto font-serif text-gray-500 text-lg mt-[5rem] w-[80%]">This
                                    candidate is yet to take the assesment. Please come back when the candidate has completed
                                    the interview to see their results.</div>
                            )}
                        </div>
                    )}
                    {isActiveLineTab[3] && (
                        <div>
                            <div className="w-full border-l border-grey">
                                <div className="px-[1rem]">
                                    <NotesHandler1 assessmentId={assessmentId} candidateId={candidateId}/>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
            )}
            <Modal
                isOpen={showModal}
                onClose={handleModalConfirm}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
        </div>
    );
};

export default AsyncVideoSlider;
