// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pagination-number {
    width: 30px;
    height: 30px;
    font-size: 0.85rem;
    font-weight: bold;
    background-color: white;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-color: gainsboro;
    border-width: 1px;
    color: black;
}

.pagination-number.active {
    background-color: white;
    color: blue;
    border-color: blue;
}

.pagination-arrow {
    width: 30px;
    height: 30px;
    background: none;
    border-color: gainsboro;
    color: #d4d9dd;
    border-width: 1px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s, color 0.3s;
}

.pagination-arrow:hover {
    background-color: gray;
    color: white;
}

.pagination-arrow svg {
    width: 20px;
    height: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/mainContent/DashBoard/Jobs/pagination.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,QAAQ;AACZ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,iBAAiB;IACjB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,uBAAuB;IACvB,iBAAiB;IACjB,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,uBAAuB;IACvB,cAAc;IACd,iBAAiB;IACjB,kBAAkB;IAClB,eAAe;IACf,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,6CAA6C;AACjD;;AAEA;IACI,sBAAsB;IACtB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":[".pagination-container {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n}\n\n.pagination-number {\n    width: 30px;\n    height: 30px;\n    font-size: 0.85rem;\n    font-weight: bold;\n    background-color: white;\n    cursor: pointer;\n    border-radius: 4px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-color: gainsboro;\n    border-width: 1px;\n    color: black;\n}\n\n.pagination-number.active {\n    background-color: white;\n    color: blue;\n    border-color: blue;\n}\n\n.pagination-arrow {\n    width: 30px;\n    height: 30px;\n    background: none;\n    border-color: gainsboro;\n    color: #d4d9dd;\n    border-width: 1px;\n    border-radius: 4px;\n    cursor: pointer;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    transition: background-color 0.3s, color 0.3s;\n}\n\n.pagination-arrow:hover {\n    background-color: gray;\n    color: white;\n}\n\n.pagination-arrow svg {\n    width: 20px;\n    height: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
