import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';
import "./phoneInputs.css";
import React, { forwardRef, useEffect } from 'react';

const PhoneNumberInput = forwardRef(({
    label,
    value,
    dropDownValue,
    setDropDownValue,
    setValue,
    width,
    showLabel,
    id,
    placeholder,
    name
}, ref) => {

    const containerStyle = {
        // boxShadow: '0 0 10px rgba(16, 24, 40, 0.1)',
        border: '1.25px solid #ABACAE',
        borderRadius: '3px',
        width: width,
    };

    const prefixStyle = {
        height: '2.6rem',
        width: 'auto',
        display: 'flex',
        alignItems: 'center',
        paddingLeft: '0.5rem', // Adjust padding as needed
        paddingRight: '0.5rem', // Adjust padding as needed
        backgroundColor: '#fff', // Light grey background for the prefix section
        borderTopLeftRadius: '3px', // Match the border radius with the container
        borderBottomLeftRadius: '3px',
    };

    const inputStyle = {
        height: '2.6rem', // Match the height with the prefix
        width: '100%',
        paddingLeft: '0.5rem', // Adjust padding as needed
        paddingRight: '0.5rem', // Adjust padding as needed
        border: 'none', // No border for the input, it's on the container
    };

    const defaultCountryCode = '+91';

    useEffect(() => {
        // Set the default country code when the component mounts
        // This ensures that the default value is shown even before any interaction
        if (!dropDownValue) {
            setDropDownValue(defaultCountryCode);
        }
    }, [dropDownValue, setDropDownValue]);

    const countryCodesWithLabels = [
        { text: 'AF', value: '+93', label: 'Afghanistan' },
        { text: 'AL', value: '+355', label: 'Albania' },
        { text: 'DZ', value: '+213', label: 'Algeria' },
        { text: 'AS', value: '+1-684', label: 'American Samoa' },
        { text: 'AD', value: '+376', label: 'Andorra' },
        { text: 'AO', value: '+244', label: 'Angola' },
        { text: 'AI', value: '+1-264', label: 'Anguilla' },
        { text: 'AG', value: '+1-268', label: 'Antigua and Barbuda' },
        { text: 'AR', value: '+54', label: 'Argentina' },
        { text: 'AM', value: '+374', label: 'Armenia' },
        { text: 'AW', value: '+297', label: 'Aruba' },
        { text: 'AU', value: '+61', label: 'Australia' },
        { text: 'AT', value: '+43', label: 'Austria' },
        { text: 'AZ', value: '+994', label: 'Azerbaijan' },
        { text: 'BS', value: '+1-242', label: 'Bahamas' },
        { text: 'BH', value: '+973', label: 'Bahrain' },
        { text: 'BD', value: '+880', label: 'Bangladesh' },
        { text: 'BB', value: '+1-246', label: 'Barbados' },
        { text: 'BY', value: '+375', label: 'Belarus' },
        { text: 'BE', value: '+32', label: 'Belgium' },
        { text: 'BZ', value: '+501', label: 'Belize' },
        { text: 'BJ', value: '+229', label: 'Benin' },
        { text: 'BT', value: '+975', label: 'Bhutan' },
        { text: 'BO', value: '+591', label: 'Bolivia' },
        { text: 'BA', value: '+387', label: 'Bosnia and Herzegovina' },
        { text: 'BW', value: '+267', label: 'Botswana' },
        { text: 'BR', value: '+55', label: 'Brazil' },
        { text: 'BN', value: '+673', label: 'Brunei Darussalam' },
        { text: 'BG', value: '+359', label: 'Bulgaria' },
        { text: 'BF', value: '+226', label: 'Burkina Faso' },
        { text: 'BI', value: '+257', label: 'Burundi' },
        { text: 'KH', value: '+855', label: 'Cambodia' },
        { text: 'CM', value: '+237', label: 'Cameroon' },
        { text: 'CA', value: '+1', label: 'USA & Canada' },
        { text: 'CV', value: '+238', label: 'Cape Verde' },
        { text: 'KY', value: '+1-345', label: 'Cayman Islands' },
        { text: 'CF', value: '+236', label: 'Central African Republic' },
        { text: 'TD', value: '+235', label: 'Chad' },
        { text: 'CL', value: '+56', label: 'Chile' },
        { text: 'CN', value: '+86', label: 'China' },
        { text: 'CO', value: '+57', label: 'Colombia' },
        { text: 'KM', value: '+269', label: 'Comoros' },
        { text: 'CR', value: '+506', label: 'Costa Rica' },
        { text: 'HR', value: '+385', label: 'Croatia' },
        { text: 'CU', value: '+53', label: 'Cuba' },
        { text: 'CW', value: '+599', label: 'Cura�ao' },
        { text: 'CY', value: '+357', label: 'Cyprus' },
        { text: 'CZ', value: '+420', label: 'Czech Republic' },
        { text: 'CD', value: '+243', label: 'Democratic Republic of the Congo' },
        { text: 'DK', value: '+45', label: 'Denmark' },
        { text: 'DJ', value: '+253', label: 'Djibouti' },
        { text: 'DM', value: '+1-767', label: 'Dominica' },
        { text: 'DO', value: '+1-809', label: 'Dominican Republic' },
        { text: 'EC', value: '+593', label: 'Ecuador' },
        { text: 'EG', value: '+20', label: 'Egypt' },
        { text: 'SV', value: '+503', label: 'El Salvador' },
        { text: 'GQ', value: '+240', label: 'Equatorial Guinea' },
        { text: 'ER', value: '+291', label: 'Eritrea' },
        { text: 'EE', value: '+372', label: 'Estonia' },
        { text: 'ET', value: '+251', label: 'Ethiopia' },
        { text: 'FK', value: '+500', label: 'Falkland Islands (Malvinas)' },
        { text: 'FO', value: '+298', label: 'Faroe Islands' },
        { text: 'FJ', value: '+679', label: 'Fiji' },
        { text: 'FI', value: '+358', label: 'Finland' },
        { text: 'FR', value: '+33', label: 'France' },
        { text: 'PF', value: '+689', label: 'French Polynesia' },
        { text: 'GA', value: '+241', label: 'Gabon' },
        { text: 'GM', value: '+220', label: 'Gambia' },
        { text: 'GE', value: '+995', label: 'Georgia' },
        { text: 'DE', value: '+49', label: 'Germany' },
        { text: 'GH', value: '+233', label: 'Ghana' },
        { text: 'GI', value: '+350', label: 'Gibraltar' },
        { text: 'GR', value: '+30', label: 'Greece' },
        { text: 'GL', value: '+299', label: 'Greenland' },
        { text: 'GD', value: '+1-473', label: 'Grenada' },
        { text: 'GU', value: '+1-671', label: 'Guam' },
        { text: 'GT', value: '+502', label: 'Guatemala' },
        { text: 'GG', value: '+44-1481', label: 'Guernsey' },
        { text: 'GN', value: '+224', label: 'Guinea' },
        { text: 'GW', value: '+245', label: 'Guinea-Bissau' },
        { text: 'GY', value: '+592', label: 'Guyana' },
        { text: 'HT', value: '+509', label: 'Haiti' },
        { text: 'HN', value: '+504', label: 'Honduras' },
        { text: 'HK', value: '+852', label: 'Hong Kong' },
        { text: 'HU', value: '+36', label: 'Hungary' },
        { text: 'IS', value: '+354', label: 'Iceland' },
        { text: 'IN', value: '+91', label: 'India' },
        { text: 'ID', value: '+62', label: 'Indonesia' },
        { text: 'IR', value: '+98', label: 'Iran' },
        { text: 'IQ', value: '+964', label: 'Iraq' },
        { text: 'IE', value: '+353', label: 'Ireland' },
        { text: 'IM', value: '+44-1624', label: 'Isle of Man' },
        { text: 'IL', value: '+972', label: 'Israel' },
        { text: 'IT', value: '+39', label: 'Italy' },
        { text: 'CI', value: '+225', label: 'Ivory Coast' },
        { text: 'JM', value: '+1-876', label: 'Jamaica' },
        { text: 'JP', value: '+81', label: 'Japan' },
        { text: 'JE', value: '+44-1534', label: 'Jersey' },
        { text: 'JO', value: '+962', label: 'Jordan' },
        { text: 'KZ', value: '+7', label: 'Kazakhstan' },
        { text: 'KE', value: '+254', label: 'Kenya' },
        { text: 'KI', value: '+686', label: 'Kiribati' },
        { text: 'KW', value: '+965', label: 'Kuwait' },
        { text: 'KG', value: '+996', label: 'Kyrgyzstan' },
        { text: 'LA', value: '+856', label: 'Laos' },
        { text: 'LV', value: '+371', label: 'Latvia' },
        { text: 'LB', value: '+961', label: 'Lebanon' },
        { text: 'LS', value: '+266', label: 'Lesotho' },
        { text: 'LR', value: '+231', label: 'Liberia' },
        { text: 'LY', value: '+218', label: 'Libya' },
        { text: 'LI', value: '+423', label: 'Liechtenstein' },
        { text: 'LT', value: '+370', label: 'Lithuania' },
        { text: 'LU', value: '+352', label: 'Luxembourg' },
        { text: 'MO', value: '+853', label: 'Macao' },
        { text: 'MK', value: '+389', label: 'North Macedonia' },
        { text: 'MG', value: '+261', label: 'Madagascar' },
        { text: 'MW', value: '+265', label: 'Malawi' },
        { text: 'MY', value: '+60', label: 'Malaysia' },
        { text: 'MV', value: '+960', label: 'Maldives' },
        { text: 'ML', value: '+223', label: 'Mali' },
        { text: 'MT', value: '+356', label: 'Malta' },
        { text: 'MH', value: '+692', label: 'Marshall Islands' },
        { text: 'MR', value: '+222', label: 'Mauritania' },
        { text: 'MU', value: '+230', label: 'Mauritius' },
        { text: 'YT', value: '+262', label: 'Mayotte' },
        { text: 'MX', value: '+52', label: 'Mexico' },
        { text: 'FM', value: '+691', label: 'Micronesia' },
        { text: 'MD', value: '+373', label: 'Moldova' },
        { text: 'MC', value: '+377', label: 'Monaco' },
        { text: 'MN', value: '+976', label: 'Mongolia' },
        { text: 'ME', value: '+382', label: 'Montenegro' },
        { text: 'MS', value: '+1-664', label: 'Montserrat' },
        { text: 'MA', value: '+212', label: 'Morocco' },
        { text: 'MZ', value: '+258', label: 'Mozambique' },
        { text: 'MM', value: '+95', label: 'Myanmar' },
        { text: 'NA', value: '+264', label: 'Namibia' },
        { text: 'NR', value: '+674', label: 'Nauru' },
        { text: 'NP', value: '+977', label: 'Nepal' },
        { text: 'NL', value: '+31', label: 'Netherlands' },
        { text: 'AN', value: '+599', label: 'Netherlands Antilles' },
        { text: 'NC', value: '+687', label: 'New Caledonia' },
        { text: 'NZ', value: '+64', label: 'New Zealand' },
        { text: 'NI', value: '+505', label: 'Nicaragua' },
        { text: 'NE', value: '+227', label: 'Niger' },
        { text: 'NG', value: '+234', label: 'Nigeria' },
        { text: 'NU', value: '+683', label: 'Niue' },
        { text: 'KP', value: '+850', label: 'North Korea' },
        { text: 'MP', value: '+1-670', label: 'Northern Mariana Islands' },
        { text: 'NO', value: '+47', label: 'Norway' },
        { text: 'OM', value: '+968', label: 'Oman' },
        { text: 'PK', value: '+92', label: 'Pakistan' },
        { text: 'PW', value: '+680', label: 'Palau' },
        { text: 'PS', value: '+970', label: 'Palestine' },
        { text: 'PA', value: '+507', label: 'Panama' },
        { text: 'PG', value: '+675', label: 'Papua New Guinea' },
        { text: 'PY', value: '+595', label: 'Paraguay' },
        { text: 'PE', value: '+51', label: 'Peru' },
        { text: 'PH', value: '+63', label: 'Philippines' },
        { text: 'PL', value: '+48', label: 'Poland' },
        { text: 'PT', value: '+351', label: 'Portugal' },
        { text: 'PR', value: '+1-787', label: 'Puerto Rico' },
        { text: 'QA', value: '+974', label: 'Qatar' },
        { text: 'CG', value: '+242', label: 'Republic of the Congo' },
        { text: 'RO', value: '+40', label: 'Romania' },
        { text: 'RU', value: '+7', label: 'Russia' },
        { text: 'RW', value: '+250', label: 'Rwanda' },
        { text: 'BL', value: '+590', label: 'Saint Barth�lemy' },
        { text: 'WS', value: '+685', label: 'Samoa' },
        { text: 'SM', value: '+378', label: 'San Marino' },
        { text: 'ST', value: '+239', label: 'Sao Tome and Principe' },
        { text: 'SA', value: '+966', label: 'Saudi Arabia' },
        { text: 'SN', value: '+221', label: 'Senegal' },
        { text: 'RS', value: '+381', label: 'Serbia' },
        { text: 'SC', value: '+248', label: 'Seychelles' },
        { text: 'SL', value: '+232', label: 'Sierra Leone' },
        { text: 'SG', value: '+65', label: 'Singapore' },
        { text: 'SX', value: '+1-721', label: 'Sint Maarten' },
        { text: 'SK', value: '+421', label: 'Slovakia' },
        { text: 'SI', value: '+386', label: 'Slovenia' },
        { text: 'SB', value: '+677', label: 'Solomon Islands' },
        { text: 'SO', value: '+252', label: 'Somalia' },
        { text: 'ZA', value: '+27', label: 'South Africa' },
        { text: 'KR', value: '+82', label: 'South Korea' },
        { text: 'SS', value: '+211', label: 'South Sudan' },
        { text: 'ES', value: '+34', label: 'Spain' },
        { text: 'LK', value: '+94', label: 'Sri Lanka' },
        { text: 'KN', value: '+1-869', label: 'Saint Kitts and Nevis' },
        { text: 'LC', value: '+1-758', label: 'Saint Lucia' },
        { text: 'MF', value: '+590', label: 'Saint Martin' },
        { text: 'VC', value: '+1-784', label: 'Saint Vincent and the Grenadines' },
        { text: 'SD', value: '+249', label: 'Sudan' },
        { text: 'SR', value: '+597', label: 'Suriname' },
        { text: 'SZ', value: '+268', label: 'Eswatini' },
        { text: 'SE', value: '+46', label: 'Sweden' },
        { text: 'CH', value: '+41', label: 'Switzerland' },
        { text: 'SY', value: '+963', label: 'Syria' },
        { text: 'TW', value: '+886', label: 'Taiwan' },
        { text: 'TJ', value: '+992', label: 'Tajikistan' },
        { text: 'TZ', value: '+255', label: 'Tanzania' },
        { text: 'TH', value: '+66', label: 'Thailand' },
        { text: 'TL', value: '+670', label: 'Timor-Leste' },
        { text: 'TG', value: '+228', label: 'Togo' },
        { text: 'TK', value: '+690', label: 'Tokelau' },
        { text: 'TO', value: '+676', label: 'Tonga' },
        { text: 'TT', value: '+1-868', label: 'Trinidad and Tobago' },
        { text: 'TN', value: '+216', label: 'Tunisia' },
        { text: 'TR', value: '+90', label: 'Turkey' },
        { text: 'TM', value: '+993', label: 'Turkmenistan' },
        { text: 'TC', value: '+1-649', label: 'Turks and Caicos Islands' },
        { text: 'TV', value: '+688', label: 'Tuvalu' },
        { text: 'UG', value: '+256', label: 'Uganda' },
        { text: 'UA', value: '+380', label: 'Ukraine' },
        { text: 'AE', value: '+971', label: 'United Arab Emirates' },
        { text: 'GB', value: '+44', label: 'United Kingdom' },
        { text: 'US', value: '+1', label: 'United States' },
        { text: 'UY', value: '+598', label: 'Uruguay' },
        { text: 'UZ', value: '+998', label: 'Uzbekistan' },
        { text: 'VU', value: '+678', label: 'Vanuatu' },
        { text: 'VA', value: '+379', label: 'Vatican City' },
        { text: 'VE', value: '+58', label: 'Venezuela' },
        { text: 'VN', value: '+84', label: 'Vietnam' },
        { text: 'VG', value: '+1-284', label: 'British Virgin Islands' },
        { text: 'VI', value: '+1-340', label: 'U.S. Virgin Islands' },
        { text: 'WF', value: '+681', label: 'Wallis and Futuna' },
        { text: 'YE', value: '+967', label: 'Yemen' },
        { text: 'ZM', value: '+260', label: 'Zambia' },
        { text: 'ZW', value: '+263', label: 'Zimbabwe' },
    ];

    const handleSelect = (e) => {
        setDropDownValue(e.itemData.value);
    };


    return (
        <div className={`flex flex-col items-start ${width} mb-0`}>
            {showLabel &&
                <label htmlFor={id} className="font-sans text-sm font-bold tracking-wide text-charcoal">{label}</label>}
            <div className="relative flex w-full mt-2" style={containerStyle}>
                <span style={prefixStyle}>
                    <DropDownListComponent
                        id="countryCodeDropdown"
                        dataSource={countryCodesWithLabels}
                        fields={{ text: 'label', value: 'value' }}
                        placeholder="India"
                        width={"6rem"}
                        popupWidth={"19rem"}
                        value={dropDownValue}
                        change={handleSelect}
                    />
                </span>
                <span style={prefixStyle}>{dropDownValue}</span>
                <input
                    id={id}
                    name={name}
                    type="text"
                    maxLength={10}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="flex-1 w-auto outline-none text-text-grey"
                    style={inputStyle}
                    ref={ref}
                />
            </div>
        </div>
    )
});
export default PhoneNumberInput;