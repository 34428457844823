import React, { useState } from 'react';

export const Textarea = (props) => {
    const shouldShowBoxShadow=true;
    const {
        id,
        label,
        placeholder,
        value,
        onChange,
        rows = 4, // Default number of rows
        className = '',
        labelClassName = 'text-black', // Default label class
        textareaClassName = 'textBoxBorders border rounded-m pr-2 pl-2', // Default textarea class
        heightOverride, // Optional prop for height override
        widthOverride, // Optional prop for width override
    } = props;

    const [isTextareaBold, setIsTextareaBold] = useState(false);

    const textareaStyle = {
        height: heightOverride || 'auto',
        width: widthOverride || '20rem', // Set default width to 20rem
        resize: 'none', // Disable the resize option
        border: '1.25px solid #ABACAE',
        boxShadow: shouldShowBoxShadow ? '0 0 10px rgba(16, 24, 40, 0.1)' : 'none',
        fontWeight: isTextareaBold ? 'normal' : 'normal',
        color: '#78798C',
        fontFamily: 'helvetica'
    };

    const handleTextareaClick = () => {
        setIsTextareaBold(true);
    };

    return (
        <div className={`flex flex-col items-start ${className} mt-2`}>
            {/* Added mt-4 class for 1rem (16px) margin-top and shadow-md for shadow */}
            <label
                htmlFor={id}
                className={`text-sm ${labelClassName} mb-2 font-bold font-sans text-charcoal tracking-wide`}
            >
                {label}
            </label>
            <textarea
                id={id}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onClick={handleTextareaClick}
                rows={rows}
                className={`${textareaClassName} ${heightOverride ? `h-${heightOverride}` : ''}mt-1`}
                style={{ ...textareaStyle, boxShadow: '0 0 10px rgba(16, 24, 40, 0.1)' }}
                name={props.name}
            />
        </div>
    );
};