import React, { useState } from 'react';

const DevicePermissionsTester = () => {
    const [microphoneStatus, setMicrophoneStatus] = useState('unknown');
    const [cameraStatus, setCameraStatus] = useState('unknown');

    const requestAndCheckMicrophonePermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            setMicrophoneStatus('granted');
            alert('Microphone access granted.');
        } catch (error) {
            setMicrophoneStatus('denied');
            alert('Microphone access denied.');
        }
    };

    const requestAndCheckCameraPermission = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true });
            setCameraStatus('granted');
            alert('Camera access granted.');
        } catch (error) {
            setCameraStatus('denied');
            alert('Camera access denied.');
        }
    };

    // Sound settings check is omitted as it usually doesn't involve permissions.

    return (
        <div>
            <button onClick={requestAndCheckMicrophonePermission}>
                Check Microphone Permission
            </button>
            <p>Microphone Status: {microphoneStatus}</p>

            <button onClick={requestAndCheckCameraPermission}>
                Check Camera Permission
            </button>
            <p>Camera Status: {cameraStatus}</p>
        </div>
    );
};

export default DevicePermissionsTester;
