// Initial state of the proctoring features
import {TOGGLE_CAMERA, TOGGLE_MIC, TOGGLE_SCREEN_SHARING} from "../actions/proctoringActions";

const initialState = {
    isCameraOn: false,
    isMicOn: false,
    isScreenSharingOn: false,
};

// Reducer
const proctoringReducer = (state = initialState, action) => {
    switch (action.type) {
        case TOGGLE_CAMERA:
            return { ...state, isCameraOn: !state.isCameraOn };
        case TOGGLE_MIC:
            return { ...state, isMicOn: !state.isMicOn };
        case TOGGLE_SCREEN_SHARING:
            return { ...state, isScreenSharingOn: !state.isScreenSharingOn };
        default:
            return state;
    }
};

export default proctoringReducer;
