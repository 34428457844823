import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/button";
import Editor from "@monaco-editor/react";
import {Textarea} from "../../../components/textarea";
import Badge from "../../../components/Badge";
import logo from "../../../../assets/logo_ovii_2.svg"
import TestCaseGraph from "./TestCasesGraph";

const ManagerCode = () => {
    const question = JSON.parse(localStorage.getItem("selectedQuestion"));
    const problemStatement = question.questionResponse.questionDataAsJson.problemStatement;
    const code = question.scoreResponse.answer.answer;
    const sampleInput = question.questionResponse.questionDataAsJson.sampleInput;
    const sampleOutput = question.questionResponse.questionDataAsJson.sampleOutput;
    const inputFormat = question.questionResponse.questionDataAsJson.inputFormat;
    const outputFormat = question.questionResponse.questionDataAsJson.outputFormat;
    const constraints = question.questionResponse.questionDataAsJson.constraints;
    const [isAddEvalExpanded, setIsAddEvalExpanded] = useState(false);

    const codeQualityFactors = question.scoreResponse.chatGPTScore["Code Quality Factors"];

    const scores = Object.values(codeQualityFactors);

    const totalScore = scores.reduce((total, score) => total + score, 0);

    const averageScore = totalScore / scores.length;


    console.log(question)

    const [showSlider, setShowSlider] = useState(false);
    const popupRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowSlider(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);

    const handleOpen = () => {
        setShowSlider(true);
    };

    const handleClose = () => {
        setShowSlider(false);
    };

    const [expandedFactors, setExpandedFactors] = useState({});

    const toggleFactor = (factor) => {
        setExpandedFactors(prevFactor => (prevFactor === factor ? null : factor))
    };

    const renderAdditionalEvaluation = (chatGPTScore) => {
        const codeQualityFactors = chatGPTScore["Code Quality Factors"];
        return (
            <div className="mt-4">
                {/*<h3 className="font-sans text-[1rem] text-m font-bold tracking-[0.1px] mt-[1rem]">Code Quality Factors:</h3>*/}
                {Object.entries(codeQualityFactors).map(([factor, score]) => (
                    <div key={factor} className="border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4"
                         style={{'fontFamily': 'helvetica'}}>
                        <div onClick={() => toggleFactor(factor)} className="flex justify-between cursor-pointer">
                            <p className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>{factor}: {score}</p>
                            <p className='text-[#000] font-bold'>{expandedFactors === factor ? '-' : '+'}</p>
                        </div>
                        {expandedFactors === factor && (
                            <p className="text-[0.95rem] tracking-[0.1px] mt-2 text-text-charcoal"
                               style={{'fontFamily': 'helvetica'}}>{chatGPTScore.Justifications[factor]}</p>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div>
            <div className="flex justify-center w-full border items-center py-3 shadow-sm">
                <div className="w-[95%] flex justify-between items-center" style={{marginBottom: 0}}>
                    {/*<div className="text-2xl font-bold" style={{fontFamily: "ITC Clearface"}}>*/}
                    {/*    /!*<h1>Answer details</h1>*!/*/}
                    {/*</div>*/}
                    <div className="flex items-center justify-center" style={{marginBottom: 0}}>
                        <img src={logo} alt="logo" className="h-6 w-12"/>
                    </div>
                </div>
            </div>
            <div className="flex flex-col mx-2 mt-0">
                <div className="flex start h-[50%] 2xl:flex-row xl:flex-row md:flex-col">
                    <div
                        className="w-[50%] p-[2%] border border-gray-200 border-r-0 overflow-auto max-h-[83vh]">
                        <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">
                            Problem statement:
                        </p>
                        <p className="py-[0.75%]" dangerouslySetInnerHTML={{__html: problemStatement}}/>
                        <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">Input Format:</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: inputFormat,
                            }}
                        />
                        {/* Render "Output Format" with outputFormat */}
                        <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">
                            Output Format:
                        </p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: outputFormat,
                            }}
                        />

                        {/* Render constraints */}
                        <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">Constraints:</p>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: constraints,
                            }}
                        />
                        <div className="mt-[5%] space-y-[1%]">
                            <Textarea widthOverride="100%"
                                      height="100%"
                                      label='Sample Input'
                                      disabled={true}
                                      placeholder={sampleInput}
                            />
                            <Textarea widthOverride="100%"
                                      height="100%"
                                      label='Sample output'
                                      disabled={true}
                                      placeholder={sampleOutput}
                            />
                        </div>
                    </div>
                    <div className="h-[90vh] w-[auto] border border-solid border-gray-200 overflow-auto max-h-[83vh]">
                        <Editor
                            height="90vh"
                            width="63rem"
                            theme="vs-dark"
                            language="python"
                            value={code}
                            options={{
                                readOnly: true,
                                minimap: {enabled: false},
                            }}
                        />
                    </div>
                </div>
                <div className="mt-4 flex justify-end">
                    <Button width="15rem" label="View Details" onClick={handleOpen} variant="bright-blue"/>
                </div>
            </div>
            {showSlider && (
                <div className="fixed inset-0 z-100 bg-opacity-25">
                    <div ref={popupRef}
                         className="absolute top-0 right-0 h-full w-[47rem] border border-gray-300 bg-[#fff] transition-all duration-700">
                        <div className="h-[90vh]">
                            <div className="">
                                {/*<h2 className="text-2xl font-serif mb-[0.75rem] px-[1.5rem]">Answer details</h2>*/}
                                <div
                                    className="flex items-center justify-between w-full p-0 m-0 border-b-1 border-textBoxBorders shadow px-7 py-4"
                                    style={{'marginBottom': 0}}
                                >
                                    <h2 className="text-2xl font-serif">Answer
                                        details</h2>
                                    <button
                                        // onClick={onClose}
                                        className="fixed top-4 right-4 w-6 h-6 p-1 m-0 text-xs text-white bg-blue rounded-full"
                                        onClick={handleClose}
                                    >
                                        X{/* TODO: Use an icon */}
                                    </button>
                                </div>
                                <hr/>
                                <div className="px-7">
                                    <div className='flex justify-start items-center gap-2 mt-8'>
                                        {question.questionResponse.questionDataAsJson.tags.split(',').map((badge) => (
                                            <Badge key={badge.trim()} label={badge.trim()} variant="grey"/>
                                        ))}
                                    </div>
                                    {/*<div className='flex flex-row gap-4'>*/}
                                    <div className="flex flex-row gap-2 w-full">
                                        <div className="className='flex flex-col space-y-4 w-[50%]'">
                                            <div
                                                className='w-full bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                                                style={{display: 'flex', alignItems: 'stretch'}}>
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                                style={{'fontFamily': 'helvetica'}}>
                                                                TOTAL SCORE
                                                            </div>
                                                            {/*<img src={questionMark} alt="question mark"*/}
                                                            {/*     className="w-3 h-3 ml-1 cursor-pointer"/>*/}
                                                        </div>

                                                        <div
                                                            className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"
                                                        >
                                                            {/*This is the count of candidates across all assessments*/}
                                                            <span
                                                                className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>
                                                            {question.scoreResponse.score}
                                                        </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className='w-full bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'>
                                                <div className="flex justify-between items-center">
                                                    <div>
                                                        <div className="flex items-center">
                                                            <div
                                                                className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                                style={{'fontFamily': 'helvetica'}}>
                                                                CODE QUALITY SCORE
                                                            </div>
                                                            {/*<img src={questionMark} alt="question mark"*/}
                                                            {/*     className="w-3 h-3 ml-1 cursor-pointer"/>*/}
                                                        </div>

                                                        <div
                                                            className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3">
                                                            <span
                                                                className='py-0 mr-1 text-[0.85rem] font-semibold text-[#33363C] rounded-[3px]'>
                                                                     {/*iterate over the chatGPTScore and find the average code quality score*/}
                                                                {
                                                                    averageScore
                                                                }
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-3/4 h-full border py-4">
                                            <p className='font-serif font-bold pl-2'>Test cases</p>
                                            <div className="flex w-full flex-row gap-4 pl-2">
                                                <div className="w-1/3">
                                                    <p className="font-serif font-medium">
                                                        Passed
                                                    </p>
                                                    <p className='text-green-500'>
                                                        {question.scoreResponse.evalDetails.filter((evalDetail) => evalDetail.status.id === 3).length}
                                                        <span> Test cases</span>
                                                    </p>
                                                </div>
                                                <div className="w-1/3">
                                                    <p className="font-serif font-medium">
                                                        Failed
                                                    </p>
                                                    <p className='text-[#f54f70]'>
                                                        {question.scoreResponse.evalDetails.filter((evalDetail) => evalDetail.status.id !== 3).length}
                                                        <span> Test cases</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="flex-grow">
                                                <TestCaseGraph
                                                    passed={question.scoreResponse.evalDetails.filter((evalDetail) => evalDetail.status.id === 3).length}
                                                    failed={question.scoreResponse.evalDetails.filter((evalDetail) => evalDetail.status.id !== 3).length}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/*</div>*/}
                                    <div className='mt-6'>
                                        {renderAdditionalEvaluation(question.scoreResponse.chatGPTScore)}
                                        {/*<div className="mt-3 md:w-[20%] lg:w-[20%] xl:w-[20%] 2xl:w-[20%] mb-1">*/}
                                        {/*    <Button label="Close" width="100%" onClick={handleClose}*/}
                                        {/*            variant="bright-blue"/>*/}
                                        {/*</div>*/}
                                    </div>
                                    {isAddEvalExpanded && (
                                        <>
                                            {renderAdditionalEvaluation(question.scoreResponse.chatGPTScore)}
                                        </>
                                    )}
                                </div>
                            </div>
                            {/* <div className="mt-3 md:w-[20%] lg:w-[20%] xl:w-[20%] 2xl:w-[20%] mb-1">
                                <Button label="Close" width="100%" onClick={handleClose} variant="bright-blue"/>
                            </div> */}
                        </div>
                    </div>
                </div>
            )}
            {/*<div className='z-0 fixed bottom-0 left-0 w-full text-center'>*/}
            {/*    <p>Powered by<img className="w-10 h-10 inline pl-2 mb-1" src={logo}></img></p>*/}
            {/*</div>*/}
        </div>
    );
}

export default ManagerCode;