// features/answers/answersSlice.js
import { createSlice } from '@reduxjs/toolkit';

export const answersSlice = createSlice({
    name: 'answers',
    initialState: {},
    reducers: {
        setAnswer: (state, action) => {
            const { questionId, answer } = action.payload;
            state[questionId] = answer;
        },
        clearAnswer: (state, action) => {
            const { questionId } = action.payload;
            if (state[questionId]) {
                delete state[questionId];
            }
        },
    },
});

export const { setAnswer, clearAnswer } = answersSlice.actions;

export default answersSlice.reducer;
