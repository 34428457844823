// actions.js
export const SET_EDITOR_CONTENT = 'SET_EDITOR_CONTENT';
export const FETCH_EDITOR_CONTENT = 'FETCH_EDITOR_CONTENT';

export const setEditorContent = (questionId, content) => ({
    type: SET_EDITOR_CONTENT,
    payload: {questionId, content}
});

export const fetchEditorContent = (questionId) => ({
    type: FETCH_EDITOR_CONTENT,
    payload: {questionId}
});
