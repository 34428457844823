// reducers/editorContentReducer.js
import {SET_EDITOR_CONTENT} from "../actions/editorContentAnswers"

const initialState = {};

const editorContentReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_EDITOR_CONTENT:
            return {
                ...state,
                [action.payload.questionId]: action.payload.content
            };
        default:
            return state;
    }
};

export default editorContentReducer;
