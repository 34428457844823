import React, {useState} from 'react';
import TimeBarTest from './TimeBarTest';

const QuestionNavigation = ({
                                currentQuestionId,
                                questions,
                                onSelectQuestion,
                                onEndTest,
                                onTimerTick,
                                initialSeconds
                            }) => {
    const [clickedIndex, setClickedIndex] = useState(0);

    const handleClick = (questionId, index) => {
        onSelectQuestion(questionId);
        setClickedIndex(index);
    };

    return (
        <div className=''>
            <TimeBarTest
                onEndTest={onEndTest}
                onTimerTick={onTimerTick}
                initialSeconds={initialSeconds}
            />
            <div className="question-nav flex flex-col items-start fixed top-[3.875rem] h-[90vh] overflow-y-scroll">
                {questions.map((question, index) => (
                    <div className="w-[4rem] border border-solid" key={question.questionId}>
                        <button
                            onClick={(e) => handleClick(question.questionId, index)}
                            className={`question-nav-button w-full ${question.questionId === currentQuestionId ? 'bg-[#1b9b85] text-white' : ''}`}
                        >
                            {index + 1}
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default QuestionNavigation;
