import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/button";
import CsvUploader from "../../../components/csvUploader";
import Modal from '../../../components/popup';
import close from "../../../../assets/close.svg";
import sending from '../../../../assets/Sending.svg';

function InviteCandidates({onClose, assessmentId, onInvitationSent}) {
    const [showPopup, setShowPopup] = useState(false);
    const fileInputRef = useRef(null);
    const popupRef = useRef(null);
    const accessToken = localStorage.getItem("accessToken");
    const [invitedCandidates, setInvitedCandidates] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isEvent, setIsEvent] = useState(false); // State to track checkbox
    const [isProctoringEnabled, setIsProctoringEnabled] = useState(true); // State to track checkbox

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    // State for raw CSV data
    const [csvData, setCsvData] = useState([
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
        ['', ''],
    ]);

    // State for table data
    const [tableData, setTableData] = useState([...csvData]);

    useEffect(() => {
        const handleKeyPress = (event) => {
            if (event.key === "Enter") {
                event.preventDefault();
                addNewRow();
            }
        };

        document.addEventListener("keydown", handleKeyPress);
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [tableData]); // Updated dependency for more accurate row additions

    let render = 0;
    const addNewRow = () => {
        setTableData((prevData) => { // Update tableData
            const newData = [...prevData, ['', '']];
            return newData;
        });
    };

    const closePopup = () => {
        setShowPopup(false);
    };

    const handleOutsideClick = (event) => {
        if (popupRef.current && !popupRef.current.contains(event.target)) {
            closePopup();
        }
    };

    const handleCellChange = (rowIndex, colIndex, newValue) => {
        setTableData((prevData) => {
            const newData = [...prevData];
            newData[rowIndex][colIndex] = newValue;
            return newData;
        });
    };

    const handleCancel = () => {
        setTableData([ // Reset table data
            ['', ''],
            ['', ''],
            ['', ''],
            ['', ''],
            ['', ''],
        ]);
        closePopup();
    };

    const handleUpload = (data) => {
        setIsLoading(true);
        try {
            setCsvData(data); // Update csvData state with parsed CSV data
            setTableData(data); // Initialize table with the uploaded data
        } catch (error) {
            console.error("Error parsing CSV:", error);
            setShowModal(true);
            setModalContent({
                heading: 'Error',
                message: 'Failed to parse CSV, please try again.', // Display specific error message
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const handleDownloadSampleCSV = () => {
        const blob = new Blob([`name,email
        Aditya B, aditya@ovii.ai
        Manodyna K H, manodyna@ovii.ai
        Pranav S, pranav@ovii.ai`], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'sample.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const nameRegex = /^[a-zA-Z\s]+$/; // Letters and spaces only
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const handleInvite = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        let hasValidationErrors = false; // Initialize the flag

        const candidatesData = tableData.slice(1).map((row) => {
            // Trim the values first
            const name = row[0].trim();
            const email = row[1].trim();

            // Return the object with trimmed values
            return {
                email: email,
                name: name
            };
        }).filter(candidate => {
            const isValidName = nameRegex.test(candidate.name);
            if (!isValidName && candidate.name !== "") {
                hasValidationErrors = true;
            }
            return isValidName;
        }).filter(candidate => {
            const isValidEmail = emailRegex.test(candidate.email);
            if (!isValidEmail && candidate.email !== "") {
                hasValidationErrors = true;
            }
            return isValidEmail;
        });

        if (hasValidationErrors) {
            // Show modal and prevent API call
            setShowModal(true);
            setModalContent({
                heading: 'Error sending Invitation',
                message: 'One or more entries have invalid name or email formats',
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
            return;
        }

        // Set the expiry date based on the checkbox state
        const expiryDate = isEvent
            ? new Date(Date.now() + 3 * 60 * 60 * 1000).toISOString() // 3 hours from now
            : new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString(); // 1 year from now

        const requestBody = {
            assessmentId: assessmentId,
            expiryDate: expiryDate,
            candidates: candidatesData,
            isProctoringEnabled: isProctoringEnabled
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/inviteCandidatesInBulk`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "content-type": "application/json"
                    },
                    body: JSON.stringify(requestBody)
                }
            );

            if (response.ok) {
                const data = await response.json();
                setInvitedCandidates(candidatesData);
                onInvitationSent();
            } else {
                // Handle error response
                const error = await response.json(); // Corrected: Parsing response to get error details
                let heading = 'Error';
                let message = "Failed to send invitation, please try again.";
                if (response.status === 400) {
                    heading = "Not enough credits";
                    message = "You do not have enough credits to complete this action, please buy more credits to continue!";
                }
                // console.error("Error fetching data:", error);
                setShowModal(true);
                setModalContent({
                    heading: heading,
                    message: message, // Display specific error message
                    confirmButtonText: 'OK',
                    onConfirm: () => setShowModal(false),
                });
            }
        } catch (error) {
            // Handle other errors
            setShowModal(true);
            setModalContent({
                heading: 'Error',
                message: 'Failed to send invitation, please try again.', // Display specific error message
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            onClose();
            setIsLoading(false);
        }
    };

    const popupStyle = {
        width: "54.438rem",
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "white",
        // boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        zIndex: 999,
    };

    const overlayStyle = {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        backdropFilter: "blur(0.5rem)",
        zIndex: 998,
    };

    const tableStyle = {
        width: "100%",
        borderCollapse: "collapse",
        borderRadius: "0.624rem",
        border: "1px solid #ccc", // Add gray border to the table
    };

    const thStyle = {
        backgroundColor: "#FAFAFA", // Gray background color for table headings
        padding: "4px",
        width: "50%", // Equal width for each column
    };

    const tdStyle = {
        padding: "0",
        border: "1px solid #ccc", // Add gray border to table cells
    };

    return (
        <>
            {/* {isLoading && <LoadingOverlay />} */}
            <div className="fixed inset-0 z-100 bg-opacity-25"></div>
            <div ref={popupRef}
                 className="absolute top-0 right-0 h-full w-[47rem] border border-gray-300 bg-[#fff] transition-all duration-700 px-8">
                <h1 className="text-2xl font-serif py-[0.82rem]">Invite candidate</h1>

                <img src={close} className="absolute top-[1rem] right-[1rem] w-[20px] cursor-pointer"
                     onClick={onClose} tabIndex={0}/>
                <hr/>
                <hr className=""></hr>
                <div>
                    <div className="">
                        <p className="mt-4 text-lg itc-fontFamily">
                            Start the Talent Hunt
                        </p>
                        <div className="my-2 mt-2 w-[70%] text-sm font-medium text-gray-500 ">
                            Ready to discover your next star? Invite candidates to the test now.
                            Enter their name and email to send the invites, alternatively, you can upload a CSV.
                        </div>
                    </div>
                </div>
                <div className="mt-[2rem] rounded-lg flex items-center justify-center">
                    <div className="table-container w-[100%]" style={{overflowX: 'hidden', maxHeight: '15.5rem'}}>
                        <table className="rounded-lg" style={tableStyle}>
                            <thead className="text-[#020818] text-[0.85rem]">
                            <tr>
                                <th className="" style={thStyle}>Name</th>
                                <th style={thStyle}>Email</th>
                            </tr>
                            </thead>
                            <tbody style={{overflowY: 'auto'}}>
                            {tableData.slice(1).map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                    {row.map((cell, cellIndex) => (
                                        <td key={cellIndex} style={tdStyle}>
                                            <input
                                                type="text"
                                                value={cell}
                                                onChange={(e) => handleCellChange(rowIndex + 1, cellIndex, e.target.value)}
                                                className='text-sm text-[#6B7280]'
                                                style={{width: '100%', border: "hidden", height: '1.85rem'}}
                                            />
                                        </td>
                                    ))}
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='flex justify-between mb-3'>
                    <div>
                        <div>
                            <input
                                type="checkbox"
                                id="eventCheckbox"
                                className="cursor-pointer"
                                checked={isEvent}
                                onChange={(e) => setIsEvent(e.target.checked)}
                            />
                            <label htmlFor="eventCheckbox" className='ml-2 font-sans'>This is an event</label>
                        </div>
                        <div className="mt-3">
                            <input
                                type="checkbox"
                                id="proctoringCheckbox"
                                className="cursor-pointer"
                                checked={!isProctoringEnabled}
                                onChange={(e) => setIsProctoringEnabled(!e.target.checked)}
                            />
                            <label htmlFor="eventCheckbox" className='ml-2 font-sans'>Disable proctoring</label>
                        </div>
                    </div>
                    <a onClick={handleDownloadSampleCSV}
                       className='flex w-fit text-[#004eec] cursor-pointer'> ↓ Download
                        sample
                        CSV</a>
                </div>

                <div className="flex items-center justify-start mt-[0rem]">
                    <Button className="h-[2rem] text-[0.725rem]"
                            label={isLoading ? "PLEASE WAIT..." : "INVITE"}
                            width="9.188rem"
                            variant="white"
                            onClick={e => handleInvite(e)}
                    />
                    <CsvUploader label="Upload CSV" width="9.188rem" className="ml-[1.188rem]" onUpload={handleUpload}/>
                </div>

                <img alt="man with paper plane" src={sending} className='w-2/5 absolute bottom-0 right-0'/>

                <Modal isOpen={showModal}
                       onClose={() => setShowModal(false)}
                       onConfirm={handleModalConfirm}
                       heading={modalContent.heading}
                       message={modalContent.message}
                       confirmButtonText={modalContent.confirmButtonText}
                />
            </div>
        </>
    )
}

export default InviteCandidates;
