import React, {useEffect, useState} from 'react';
import {Button} from '../button';
import userIcon from '../../../assets/userBlue.svg';

const NotesHandler = ({candidateId, assessmentId}) => {
    const [arrayState, setArrayState] = useState([]);
    const [stringState, setStringState] = useState('');

    const user = localStorage.getItem("adminName");
    const accessToken = localStorage.getItem("accessToken");

    const fetchData = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/getInterviewEvalForCandidate?candidateId=${candidateId}&assessmentId=${assessmentId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            console.log("notes", data.data);
            const notesArray = data.data.notes ? data.data.notes.split(';') : []; // Assuming notes are stored as a semicolon-separated string
            setArrayState(notesArray);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSave = async () => {
        if (stringState.trim() === '') {
            return; // Do not proceed if the note is empty
        }

        const noteWithUser = `${stringState}\`${user}`;
        const newArrayState = [...arrayState, noteWithUser];
        setArrayState(newArrayState);
        setStringState('');

        await saveData(newArrayState);
        fetchData();
    };

    const saveData = async (data) => {
        try {
            const notesString = data.join(';');
            await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/setNotes?candidateId=${candidateId}&notes=${encodeURIComponent(notesString)}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            });
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <div className="w-[90%] ml-8 bg-white h-full flex flex-col justify-start">
            <div className="relative flex items-center bg-white rounded-md overflow-hidden mt-[1rem]">
                <textarea
                    className="bg-white w-[100%] h-[100%] pl-3 text-base rounded-md resize-none focus:outline-none focus:ring-0"
                    // rows="10"
                    placeholder="You can add notes about the candidate here..."
                    value={stringState}
                    onChange={(e) => setStringState(e.target.value)}
                    style={{
                        scrollbarWidth: 'none',
                    }}
                />
                {/*<div className="absolute bottom-2 right-3 ml-2">*/}
                {/*</div>*/}
            </div>

            <Button label="Submit ➔" width="7rem" className='rounded-[30px]' variant="bright-blue"
                    onClick={handleSave}/>
            <div
                className="border bg-[#FAFAFA] border-gray-300 rounded-lg mt-[3rem] mb-5 min-h-[50vh] max-h-[50vh] overflow-y-scroll"
                style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {display: "none"},
                }}>
                <ul>
                    {arrayState.slice().reverse().map((item, index) => {
                        const [note, admin] = item.split('`');
                        return (
                            <>
                                <li className='mb-4 mt-2 pt-2 pl-2 pr-3 font-sans text-md leading-[1.65rem] text-left w-full'
                                    key={index}>
                                    <div className='flex flex-row mb-2'>
                                        <img src={userIcon} alt="User Icon" className='w-[2rem]'/>
                                        <span
                                            className='font-sans flex items-center text-black font-semibold text-s pl-2 tracking-wide'>
                                            {admin}<br/>
                                        </span>
                                    </div>
                                    <div className='font-sans ml-1 text-gray-600 font-medium text-[1.05rem]'
                                         style={{fontFamily: 'helvetica'}}>
                                        {note}
                                    </div>

                                </li>
                                <hr className='mx-3'/>
                            </>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

export default NotesHandler;