// reducer.js
const initialState = {
    userId: null,
    accessToken: null,
};

const authReducer = (state = initialState, action) => {
    // console.log(action.payload.id)
    switch (action.type) {
        case "LOGIN":
            // console.log("New state after login:", {
            //   ...state,
            //   userId: action.payload.id,
            //   accessToken: action.payload.accessToken,
            // });
            return {
                ...state,
                userId: action.payload.id,
                accessToken: action.payload.accessToken,
                email: action.payload.email,
            };
        default:
            return state;
    }
};

export default authReducer;
