import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import App from "./App";
import {registerLicense} from "@syncfusion/ej2-base";
import reportWebVitals from "./reportWebVitals";
import log from "./logger"; // Import the logger
import store from "./redux/store/store";
import {DevSupport} from "@react-buddy/ide-toolbox";
import {ComponentPreviews, useInitial} from "./dev"; // Import your Redux store
import "./index.css";

// Set up global error handling
window.onerror = function (message, source, lineno, colno, error) {
    log.error("Global error:", message, source, lineno, colno, error);
};

window.onunhandledrejection = function (event) {
    log.error("Unhandled promise rejection:", event.reason);
};

registerLicense(
    "Ngo9BigBOggjHTQxAR8/V1NCaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXlfd3RWR2dfV0xzXEs="
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <DevSupport
                ComponentPreviews={ComponentPreviews}
                useInitialHook={useInitial}
            >
                <App/>
            </DevSupport>
        </Provider>
    </React.StrictMode>
);

reportWebVitals();
