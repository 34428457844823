// import React, { useState } from 'react';
// import gallery from '../../assets/gallery-line.svg';

// const ZipFileInput = ({ onFileSelect }) => {
//   const [fileName, setFileName] = useState(null);

//   const handleFileChange = (files) => {
//     const selectedFile = files[0];
//     if (selectedFile) {
//       const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
//       if (fileExtension === 'zip') {
//           setFileName(selectedFile.name);
//           onFileSelect(selectedFile);
//           console.log('Selected ZIP file:', selectedFile);
//       } else {
//         alert('Please select a valid ZIP file.');
//         setFileName(null);
//         onFileSelect(null);
//       }
//     }
//   };

//   return (
//     <div className=''>
//       <div
//         tabIndex={0}
//         className="uploader bg-white hover:text-blue border-textBoxBorders border rounded-md px-2 flex items-center justify-center cursor-pointer"
//         style={{ width: 'full', height: '5rem' }}
//         onClick={() => document.getElementById('zipInput').click()}
//       >
//         <img src={gallery} className='w-[3rem]' alt="Gallery Icon"/>
//         <input
//           id="zipInput"
//           type="file"
//           accept=".zip"
//           className="hidden"
//           onChange={({ target: { files } }) => handleFileChange(files)}
//         />
//         <p className="textUploader text-center text-sm text-[#004EEC] font-semibold font-['Inter'] leading-tight mt-1">
//           {fileName ? fileName : 'Upload ZIP file here'}
//         </p>
//       </div>
//     </div>
//   );
// };

// export default ZipFileInput;


import React, {useState} from 'react';
import gallery from '../../assets/gallery-line.svg';

const ZipFileInput = ({onFileSelect}) => {
    const [fileName, setFileName] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    const handleFileChange = (files) => {
        const selectedFile = files[0];
        if (selectedFile) {
            const fileExtension = selectedFile.name.split('.').pop().toLowerCase();
            const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert size to MB

            if (fileExtension === 'zip') {
                if (fileSizeInMB > 50) {
                    setErrorMessage('Error: The ZIP file size must be 50 MB or smaller.');
                    setFileName(null);
                    onFileSelect(null);
                } else {
                    setErrorMessage('');
                    setFileName(selectedFile.name);
                    onFileSelect(selectedFile);
                    console.log('Selected ZIP file:', selectedFile);
                }
            } else {
                setErrorMessage('Please select a valid ZIP file.');
                setFileName(null);
                onFileSelect(null);
            }
        }
    };

    return (
        <div className=''>
            <div
                tabIndex={0}
                className="uploader bg-white hover:text-blue border-textBoxBorders border rounded-md px-2 flex items-center justify-center cursor-pointer"
                style={{width: 'full', height: '5rem'}}
                onClick={() => document.getElementById('zipInput').click()}
            >
                <img src={gallery} className='w-[3rem]' alt="Gallery Icon"/>
                <input
                    id="zipInput"
                    type="file"
                    accept=".zip"
                    className="hidden"
                    onChange={({target: {files}}) => handleFileChange(files)}
                />
                <p className="textUploader text-center text-sm text-[#004EEC] font-semibold font-['Inter'] leading-tight mt-1">
                    {fileName ? fileName : 'Upload ZIP file here'}
                </p>
            </div>
            {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
            )}
        </div>
    );
};

export default ZipFileInput;
