import React, {useEffect, useState} from "react";
import logo from "../../../assets/logo_ovii_2.svg";
import {Textbox} from "../../components/textbox";
import Checkbox from "../../components/checkbox";
import {Button} from "../../components/button";
import deer from "../../../assets/Affection.svg";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/popup";

const CandidateDetails = () => {
    const navigate = useNavigate();
    const [storedName, setCandidateName] = useState("");
    const [storedEmail, setEmail] = useState("");
    const [storedDecodedLogo, setStoredDecodedLogo] = useState(null);

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    useEffect(() => {
        // Retrieve values from local storage
        const storedCandidateName = localStorage.getItem("candidateName");
        const storedCandidateEmail = localStorage.getItem("email");
        const storedDecodedLogo = localStorage.getItem("decodedLogo");
        const isProctoringEnabled = localStorage.getItem("isProctoringEnabled");

        console.log("id after setting it", localStorage.getItem("candidateId"))

        setCandidateName(storedCandidateName);
        setEmail(storedCandidateEmail);
        setStoredDecodedLogo(storedDecodedLogo);
    }, []);

    return (
        <div>
            <div>
                {typeof storedDecodedLogo === 'string' && storedDecodedLogo.startsWith('img:') ? (
                    <img
                        src={`data:image/;base64,${storedDecodedLogo.substring(4)}`}
                        alt="ovii logo"
                        className="m-[2rem]  w-[10rem]"
                    />
                ) : (
                    <p className="font-poppins m-[2rem] text-[1.75rem] text-labels-black">
                        {storedDecodedLogo}
                    </p>
                )}
            </div>

            <div className="flex flex-col justify-center items-center">
                <p className="font-serif text-[1.75rem] w-[19.938rem] h-[4.375rem] mx-auto mt-20 text-center text-labels-black">
                    Please confirm your name and email
                </p>

                <p className="h-[4.375rem] mt-[1.875rem]">
                    <Textbox
                        label="Name"
                        placeholder="oliva"
                        inputClassName="w-[20rem]"
                        type="text"
                        value={storedName}
                    ></Textbox>
                </p>

                <p className="h-[4.375rem] mt-[1.563rem]">
                    <Textbox
                        disabled={true}
                        label="Email"
                        placeholder="oliva@boltcode.io"
                        inputClassName="w-[20rem]"
                        type="email"
                        value={storedEmail}
                    ></Textbox>
                </p>

                <p className="inline-flex items-center text-[#ea157b] text-[0.95rem] font-serif mt-[1.875rem]">
                    <Checkbox
                        style={{color: "#ea157b"}}
                        onChange={() => setIsCheckboxChecked(!isCheckboxChecked)}
                    ></Checkbox>{" "}
                    Agree to our&nbsp; <a href="https://ovii.ai/terms-of-use/" target={"_blank"} rel="noreferrer"
                                          className="text-decoration-line: underline"> terms of
                    use </a> &nbsp;and&nbsp; <a
                    href="https://ovii.ai/privacy-policy/" target={"_blank"} rel="noreferrer"
                    className="text-decoration-line: underline"> privacy
                    policy</a>
                </p>

                <p className="mt-[1.56rem]">
                    <Button
                        variant="bright-blue"
                        label="NEXT"
                        width="20rem"
                        className='text-[0.735rem] h-[2.25rem]'
                        onClick={(e) => {
                            e.stopPropagation();
                            if (!isCheckboxChecked) {
                                setIsModalOpen(true); // Set modal state to true
                            } else {
                                // Navigate the user to another screen or perform any other action
                                // Replace the following line with your actual navigation logic
                                if (localStorage.getItem("isProctoringEnabled") === "true" && localStorage.getItem("assessmentType") === "1") {
                                    navigate("/Proctoring");
                                } else if (localStorage.getItem("isProctoringEnabled") === "false" && localStorage.getItem("assessmentType") === "1") {
                                    navigate("/skillAssessment");
                                } else {
                                    navigate("/proctoring")
                                }
                            }
                        }}
                    >
                        Next
                    </Button>
                </p>

                <div className="absolute bottom-3">
                    <p className="flex items-center w-[9rem] h-[1.313rem] font-serif text-[1.146rem] text-labels-black font-semibold">
                        Powered by
                        <img
                            src={logo}
                            alt="logo"
                            className="ml-[0.298rem] w-[2.5rem] h-[1.313rem]"
                        />
                    </p>
                </div>
            </div>
            <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                onConfirm={() => setIsModalOpen(false)}
                heading="Error"
                message="Please agree to our terms of use and privacy policy before proceeding."
                confirmButtonText="OK"
                showCancelButton={false}
            />

            <img
                src={deer}
                alt="man saying hello"
                className="absolute bottom-0 right-4 w-[13rem] hidden sm:block"
                style={{maxWidth: "100%", maxHeight: "100%"}}
            />
        </div>
    );
};

export default CandidateDetails;