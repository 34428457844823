import React, {useEffect, useRef} from 'react';
import close from '../../assets/close.svg';
import bird from '../../assets/boltcode_bird.svg';
import {Button} from "./button";

const Modal = ({
                   isOpen,
                   onClose,
                   onConfirm,
                   heading,
                   message,
                   confirmButtonText,
                   cancelButtonText,
                   showCancelButton
               }) => {
    const modalRef = useRef();

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                onClose();
            }
        };

        if (isOpen) {
            window.addEventListener('click', handleClickOutside);
        }

        return () => {
            window.removeEventListener('click', handleClickOutside);
        };
    }, [isOpen, onClose]);

    if (!isOpen) return null;

    return (
        <div className="fixed min-h-screen inset-0 bg-[#00000026] flex justify-center items-center z-50"
             style={{backdropFilter: 'blur(8px)'}}>
            <div ref={modalRef}
                 className="justify-center items-center bg-white pl-[2rem] pr-[2rem] rounded-lg shadow-lg w-[30.8rem] h-[auto] relative">
                <div className="flex justify-center items-center w-full">
                    <img src={bird} alt="bird" className="w-[50px] mt-[1rem]"/>
                </div>
                <h2 className="text-[1.2rem] text-charcoal font-serif font-semibold text-center -mt-[0.75rem] mb-2">{heading}</h2>
                <p className="mb-[4.5rem] font-sans text-charcoal text-[0.95rem] font-thin leading-[1.5rem] text-center break-normal"
                   style={{fontFamily: 'helvetica'}}>{message}</p>
                {/* <img src={close} alt="close" onClick={onClose}
                     className="cursor-pointer absolute top-[1rem] right-[1rem] w-[1.5rem] h-[1.5rem]" tabIndex={0}/> */}
                <div
                    className="flex justify-center items-center space-x-[0.8rem] absolute bottom-[0.1rem] w-[90%] mt-[1rem]">
                    {showCancelButton && (
                        // <button
                        //     className="h-10 flex-grow-0 flex flex-row justify-center items-center gap-2
                        //           ml-59 mr-[1.483rem] text-[1rem] p-3.5 border border-[#004EEC] text-[#004EEC] bg-white rounded w-[50%]"
                        //     onClick={onClose}
                        //     tabIndex={0}
                        // >
                        //     {cancelButtonText}
                        // </button>
                        <>
                        <Button onClick={onClose} label={cancelButtonText} width="50%" variant="white"
                                className="h-[2.25rem]"/>
                        <Button onClick={onConfirm} width="50%" variant="bright-blue" label={confirmButtonText}
                            className="h-[2.25rem]"/>    
                        </>
                                
                    )}
                    {!showCancelButton &&(
                        <Button onClick={onConfirm} width="30%" variant="bright-blue" label={confirmButtonText}
                        className="h-[2.25rem]"/>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Modal;
