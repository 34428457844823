import CodeSnippet from "./codeSnippet";
import MarkdownCodeSnippet from "./richText";
import './markdownStyles.css';
import MarkdownRender from "./richText";

const WyswygComponent = () => {
    const markdownText = ` # This is a heading
    **This text is bold**
            | Syntax      | Description |
            | ----------- | ----------- |
            | Header      | Title       |
            | Paragraph   | Text        |
            `;
    return (
        <div className="wyswyg">
            <CodeSnippet code={`const add = (a, b) => {\n  return a + b;\n};`} className='wyswyg'/>
            {/*<MarkdownCodeSnippet markdown={markdownText} className='wyswyg'/>*/}
            <MarkdownRender content={markdownText} className='wyswyg'/>
        </div>
    )
}

export default WyswygComponent