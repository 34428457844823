import React from 'react';
import sorry from '../../assets/Sorry.svg';

const ErrorComponent = (props) => {
    const {showImage = true} = props;
    const contentToShow = props.content || "Elevate the assessment by adding questions tailored to the role. Click the 'Add question' button above to begin";

    // Check if contentToShow is a string before using .includes

    return (
        <div className='error-component flex-col mt-24 gap-0 flex justify-center items-center'>
            {showImage && <img src={sorry} alt="man apologising" className='w-[10rem]'/>}
            <p className='error-component font-serif text-lg w-[30rem] text-center'>
                {contentToShow}
            </p>
        </div>
    );
};

export default ErrorComponent;
