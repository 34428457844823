import React, { forwardRef } from 'react';

const PrefixedInput = forwardRef(({ label, prefix, value, setValue, width, showLabel, id, placeholder, name }, ref) => {
    // Define the container style, including the border and shadow
    const containerStyle = {
        boxShadow: '0 0 10px rgba(16, 24, 40, 0.1)',
        border: '1.25px solid #ABACAE',
        borderRadius: '3px',
        width: width,
    };

    // Define the prefix style
    const prefixStyle = {
        height: '2.6rem',
        width:'auto',
        display: 'flex',
        alignItems: 'center',
        borderRight: '1.25px solid #ABACAE', // Right border to separate prefix from input
        paddingLeft: '0.5rem', // Adjust padding as needed
        paddingRight: '0.5rem', // Adjust padding as needed
        backgroundColor: '#fff', // Light grey background for the prefix section
        borderTopLeftRadius: '3px', // Match the border radius with the container
        borderBottomLeftRadius: '3px',
        color: '#78798D',
        fontFamily: 'helvetica',
    };

    // Define the input style
    const inputStyle = {
        height: '2.6rem', // Match the height with the prefix
        width:'100%',
        paddingLeft: '0.5rem', // Adjust padding as needed
        paddingRight: '0.5rem', // Adjust padding as needed
        border: 'none', // No border for the input, it's on the container
        fontFamily: 'helvetica',
    };

    return (
        <div className={`flex flex-col items-start ${width}`}>
            {showLabel && <label htmlFor={id} className="font-bold font-sans text-sm text-charcoal tracking-wide">{label}</label>}
            <div className="relative mt-2 flex w-full" style={containerStyle}>
                <span style={prefixStyle}>{prefix}</span>
                <input
                    id={id}
                    name={name}
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    className="flex-1 outline-none text-text-grey w-auto"
                    style={inputStyle}
                    ref={ref}
                />
            </div>
        </div>
    );
});

export default PrefixedInput;
