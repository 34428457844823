import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import BillingInfo from "./billingInfo";
import Modal from '../../../components/popup';
import {Textbox} from '../../../components/textbox';
import {Button} from '../../../components/button';
import fx from 'money';
import LoadingOverlay from '../../../components/loadingOverlay';

const BuyCredits = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [teamId, setTeamId] = useState(null);
    const [billingDetailsDontExist, setBillingDetailsDontExist] = useState(true);
    const [currentCredits, setCurrentCredits] = useState(0);
    const [amount, setAmount] = useState(0);
    const [costPerCredit, setCostPerCredit] = useState(0);
    const [taxPercentage, setTaxPercentage] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [error, setError] = useState('');
    const [currency, setCurrency] = useState('USD');
    const [convertedAmount, setConvertedAmount] = useState(0);
    const [convertedTotal, setConvertedTotal] = useState(0);
    const userId = localStorage.getItem('id');
    const accessToken = localStorage.getItem("accessToken");
    const [email, setEmail] = useState(null)
    const [billingName, setBillingName] = useState(null)

    const location = useLocation();
    const navigate = useNavigate();

    const fetchExchangeRates = async () => {
        try {
            const response = await fetch(`https://api.exchangerate-api.com/v4/latest/INR`);
            const data = await response.json();
            fx.base = data.base;
            fx.rates = data.rates;
        } catch (error) {
            console.error("Failed to fetch exchange rates:", error);
        }
    };

    useEffect(() => {
        fetchExchangeRates();
    }, []);

    const convertCurrency = (amountInINR) => {
        return fx.convert(amountInINR, {from: "INR", to: currency});
    };

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    };

    const handleAmountChange = (e) => {
        const value = Number(e.target.value);

        // TO-DO: Remove the below line when pushing to production
        setAmount(value)
        localStorage.setItem("creditCount", value)

        // TO-DO: Uncomment the below code when pushing to production
        if (Number.isInteger(value) && value >= 85) {
            setAmount(value);
            setError('');
        } else {
            setError('Minimum number of credits must be 85.');
        }
    };

    // useEffect(() => {
    //     // if (amount != null && amount !== 0) {
    //     //     localStorage.setItem("creditCount", amount)
    //     // }
    //     console.log(amount)
    // }, [amount]);

    useEffect(() => {
        // Extract the status from URL query parameters
        const query = new URLSearchParams(location.search);
        const status = query.get('status');

        // Handle payment success
        if (status === 'success') {
            setTimeout(() => {
                handlePaymentSuccess(teamId);
            }, 2000)
        }
    }, [location.search]);

    const handlePayment = async (teamId) => {
        // console.log("teamId:", teamId)
        await fetchBillingDetails();

        try {
            const orderResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/payment/createPayUOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify({
                    amount: (((amount * costPerCredit) + ((amount * costPerCredit) * (taxPercentage / 100)))),
                    teamId: teamId,
                    email: email,
                    firstName: billingName,
                    phone: '9999999999',
                }),
            });
            const orderData = await orderResponse.json();
            const {txnid, key, hash, amount: orderAmount} = orderData;

            if (!key) {
                throw new Error("PayU key is missing");
            }

            const form = document.createElement("form");
            form.setAttribute("action", "https://secure.payu.in/_payment");
            form.setAttribute("method", "POST");
            form.style.display = "none";
            localStorage.setItem("txnId", orderData.txnid)
            localStorage.setItem("amount", orderData.amount)

            const params = {
                key: orderData.key,
                txnid: orderData.txnid,
                amount: orderData.amount,
                firstname: billingName, // Ensure this is correctly populated
                email: email, // Ensure this is correctly populated
                phone: '9999999999',
                productinfo: "platform credits for ovii",
                surl: `${process.env.REACT_APP_BASE_URL}/api/payment/paymentSuccess`, // direction URL for success
                // furl: `${window.location.origin}/company/buyCredits?status=failure`,
                hash: orderData.hash
            };

            // console.log('params:', params);

            Object.keys(params).forEach(key => {
                const input = document.createElement("input");
                input.type = "hidden";
                input.name = key;
                input.value = params[key];
                form.appendChild(input);
            });

            document.body.appendChild(form);

            // setTimeout(() => {
            //     console.log("Delay by 5 seconds for visibility")
            // }, 5000)
            form.submit();

        } catch (error) {
            console.error('Error during payment:', error);
            setModalContent({
                heading: 'Error',
                message: 'Payment initialization failed. Please try again later.',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        }

    };

    const handlePaymentSuccess = async (teamId) => {
        console.log(teamId)

        const teamIdResponse = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${localStorage.getItem('id')}`,
            {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        );

        const teamIdData = await teamIdResponse.json();

        const paymentData = {
            teamId: teamIdData.data.teamId,
            credits: parseInt(localStorage.getItem("creditCount"), 10), // The number of credits to add
            orderId: localStorage.getItem("txnId"), // The transaction/order ID
            amount: localStorage.getItem("amount"), // The payment amount
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/payment/processPayment`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: JSON.stringify(paymentData),
            });

            if (response.ok) {
                console.log('Payment processed successfully');
            } else {
                const errorData = await response.text();
                console.error('Failed to process payment:', errorData);
            }
            navigate("/company/buyCredits")
        } catch (error) {
            console.error('Error occurred during payment processing:', error);
        }
    };


    useEffect(() => {
        if (costPerCredit > 0 && amount > 0) {
            const totalInINR = (amount * costPerCredit) + ((amount * costPerCredit) * (taxPercentage / 100));

            const convertedAmt = convertCurrency(amount * costPerCredit);
            const convertedTotalAmt = convertCurrency(totalInINR);

            setConvertedAmount(convertedAmt);
            setConvertedTotal(convertedTotalAmt);
        }
    }, [currency, amount, costPerCredit, taxPercentage]);

    useEffect(() => {
        fetchBillingDetails();
        fetchPaymentInfo();
    }, []);

    useEffect(() => {
        if (teamId) {
            fetchCredits(teamId);
        }
    }, [teamId]);

    const fetchBillingDetails = async () => {
        setIsLoading(true);
        try {
            const teamIdResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!teamIdResponse.ok) {
                throw new Error(`HTTP error! Status: ${teamIdResponse.status}`);
            }

            const teamIdData = await teamIdResponse.json();
            if (teamIdData.status === 201 && teamIdData.data && teamIdData.data.teamId) {
                setTeamId(teamIdData.data.teamId);

                const billingDetailsResponse = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getBillingDetails?teamId=${teamIdData.data.teamId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (!billingDetailsResponse.ok) {
                    throw new Error(`HTTP error! Status: ${billingDetailsResponse.status}`);
                }

                const billingDetailsData = await billingDetailsResponse.json();

                if (billingDetailsData.status === 200) {
                    console.log("billing details:", billingDetailsData)
                    setBillingDetailsDontExist(false);
                    setEmail(billingDetailsData.data.billingEmail)
                    setBillingName(billingDetailsData.data.billingName)
                } else {
                    setBillingDetailsDontExist(true);
                    setModalContent({
                        heading: 'Error',
                        message: 'Please update your billing details to proceed.',
                        confirmButtonText: 'OK',
                    });
                    setShowModal(true);
                }
            }
        } catch (error) {
            setShowModal(true);
            setModalContent({
                heading: 'Failed to fetch data.',
                message: error.message,
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setIsLoading(false);
        }
    };

    const fetchCredits = async (teamId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/getCurrentCredits?teamId=${teamId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const creditsData = await response.json();

            if (creditsData.status === 200) {
                setCurrentCredits(creditsData.data);
            }
        } catch (error) {
            setShowModal(true);
            setModalContent({
                heading: 'Failed to fetch data.',
                message: error.message,
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        }
    };

    const fetchPaymentInfo = async () => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/payment/getPaymentInfo`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const paymentInfoData = await response.json();

            if (paymentInfoData.status === 200) {
                setCostPerCredit(paymentInfoData.data.costPerCredit);
                setTaxPercentage(paymentInfoData.data.taxPercentage);
            }
        } catch (error) {
            setShowModal(true);
            setModalContent({
                heading: 'Failed to fetch data.',
                message: error.message,
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        }
    };

    if (isLoading) {
        return <LoadingOverlay/>;
    }

    return (
        <div>
            {billingDetailsDontExist ? (
                <div>
                    <Modal isOpen={showModal} onClose={() => setShowModal(false)} onConfirm={() => setShowModal(false)}
                           heading="Error" message="Please update your billing details to proceed"
                           confirmButtonText="Close"/>
                    <BillingInfo/>
                </div>
            ) : (
                <div className=' overflow-y-auto max-h-[85vh]'>
                    <div className='pt-4'>
                        <div className='flex justify-end w-[75%]'>
                            <label htmlFor="currency"
                                   className='text-blue font-sans text-sm flex items-center font-bold tracking-wide mr-2'>Select
                                Currency: </label>
                            <select id="currency" className='h-[2rem] py-0 border-blue rounded-md text-sm'
                                    onChange={handleCurrencyChange} value={currency}>
                                <option value="USD">USD</option>
                                <option value="EUR">EUR</option>
                                <option value="GBP">GBP</option>
                                <option value="INR">INR</option>
                                <option value="SGD">SGD</option>
                                {/* Add more currencies as needed */}
                            </select>
                        </div>
                        <div className='flex justify-between w-[75%] mt-[1rem]'>
                            <p className='font-sans text-sm font-bold tracking-wide text-charcoal'>Current Credits:</p>
                            {currentCredits} Credits
                        </div>

                        <form>
                            <div className='flex justify-between w-[75%] mt-[1rem]'>
                                <p className='font-sans text-sm font-bold tracking-wide text-charcoal'>Credits to
                                    buy:</p>
                                <div className='flex flex-col'>
                                    <Textbox className="mt-[-1rem]" height="2rem" onChange={handleAmountChange} placeholder="85 Credits"/>
                                    {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
                                </div>
                            </div>
                        </form>
                        <div className='flex justify-between w-[75%] mt-[1rem]'>
                            <p className='font-sans text-sm font-bold tracking-wide text-charcoal'>New Credits:</p>
                            {currentCredits + amount} Credits
                        </div>
                        <div className='flex justify-between w-[75%] mt-[1rem]'>
                            <p className='font-sans text-sm font-bold tracking-wide text-charcoal'>Amount:</p>
                            {currency} {convertedAmount.toFixed(2)}
                        </div>

                        <div className='flex justify-between w-[75%] mt-[1rem]'>
                            <p className='font-sans text-sm font-bold tracking-wide text-charcoal'>Taxes:</p>
                            {taxPercentage}%
                        </div>

                        <hr className='mt-[0.5rem] w-[75%]'/>
                        <div className='flex justify-between w-[75%] mt-[0.1rem]'>
                            <p className='font-sans text-sm font-bold tracking-wide text-charcoal'>To pay:</p>
                            {currency} {convertedTotal.toFixed(2)}
                        </div>
                        <Button label="Pay" variant="bright-blue" width="10rem" className="mt-[2rem] px-[1rem]"
                                onClick={() => handlePayment(teamId)}/>
                    </div>
                    <Modal isOpen={showModal} onClose={() => setShowModal(false)} onConfirm={() => setShowModal(false)}
                           heading={modalContent.heading} message={modalContent.message}
                           confirmButtonText={modalContent.confirmButtonText}/>
                </div>
            )}
        </div>
    );
};

export default BuyCredits;