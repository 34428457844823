import PhoneCodeDropDown from '../utilities/phoneCodeDropDown';
import React from 'react';


export const Textbox = (props) => {
    const defaultLabelClassName = 'text-black'; // Corrected the variable name for consistency
    const {disabled = false} = props; // Unused prop, consider removing if not needed
    const shouldShowBoxShadow = true;
    const inputStyle = {
        // Removed width to allow full-width by default, can be overridden by props.width if provided
        height: props.height || '2.75rem',
        borderRadius: props.borderRadius || '3px',
        boxShadow: props.boxShadow,
        border: '1.25px solid #ABACAE',
        width: props.width, // Ensure input field takes the full width of its container
        fontSize: "1rem !important", // todo: actually make the code that adds the .e-error class to the item not do it!
        color: "#78798C",
        fontFamily: "helvetica"
    };

    return (
        <div className={`flex flex-col items-start ${props.className}`}>
            {/* Use label element for better accessibility and associate it with the input field */}
            <label
                htmlFor={props.id}
                className={`text-sm font-bold ${defaultLabelClassName} ${props.labelClassName} font-sans text-charcoal tracking-wide`}
                style={{"font-weight": "700"}}
            >
                {props.label}
            </label>
            {props.content && <p>{props.content}</p>}
            <div className='relative mt-2'>
                {props.type === 'tel' && <PhoneCodeDropDown/>}
                <input
                    id={props.id}
                    name={props.name || props.id}
                    placeholder={props.placeholder}
                    label={props.label}
                    type={props.type}
                    value={props.value}
                    disabled={disabled}
                    onChange={props.onChange}
                    className={`textBoxBorders border rounded-m pr-2 pl-2 h-11 ${props.inputClassName}`}
                    style={inputStyle}
                />
            </div>
        </div>
    );
}