export const SIDEBAR_CONFIG = [
    {
        key: "home",
        label: "Home",
        icon: "home.svg",
        route: "/home",
        hasChildren: false,
    },
    // {
    //   key: "dashboard",
    //   label: "Dashboard",
    //   icon: "dashboard",
    //   route: "/dashboard",
    //   hasChildren: false,
    // },
    {
        key: "jobs",
        label: "Jobs",
        icon: "jobs",
        // route: "/jobs",
        hasChildren: true,
        items: [
            {key: "position", label: "Position", route: "/jobs/position"},
            // {key: "events", label: "Events", route: "/jobs/events"},
        ],
    },
    {
        key: "company",
        label: "Company",
        icon: "company",
        // route: "/company",
        hasChildren: true,
        items: [
            {
                key: "company_profile",
                label: "Company profile",
                route: "/company/companyProfile",
            },
            {
                key: "user_management",
                label: "User management",
                route: "/company/userManagement",
            },
            {
                key: "billing_info",
                label: "Billing Info",
                route: "/company/billingInfo",
            },
            {
                key: "buy_credits",
                label: "Buy credits",
                route: "/company/buyCredits",
            },
            {
                key: "terms_of_use",
                label: "Terms of use",
                route: "https://ovii.ai/customer-terms-of-use/",
            },
        ],
    },
    {
        key: "help",
        label: "Help",
        icon: "help",
        // route: "/",
        hasChildren: true,
        items: [
            {key: "templates", label: "Templates", route: "/help/templates"},
            {key: "help_center", label: "Help center", route: "https://ovii.gitbook.io/ovii/"},
        ],
    },
    {
        key: "settings",
        label: "Settings",
        icon: "settings",
        // route: "/settings",
        hasChildren: true,
        items: [{key: "profile", label: "Profile", route: "settings/profile"}],
    },
    {
        key: "logout",
        label: "Logout",
        icon: "logout",
        route: "/logout",
        hasChildren: false,
    },
];
