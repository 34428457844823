// QuestionDetails.jsx

import React from "react";
import Mcq from "../../mainContent/DashBoard/ManagerView/MCQ.jsx";
import ManagerMCQ from "../../mainContent/DashBoard/ManagerView/ManagerMCQ.jsx";
import ManagerCode from "../../mainContent/DashBoard/ManagerView/ManagerCode.jsx"
import CodeQuestionForm from "../../mainContent/DashBoard/ManagerView/CodeQuestionForm.jsx";
import "../../../assets/font.css"; // Import the custom font styles
import ManagerSubjective from "../../mainContent/DashBoard/ManagerView/ManagerSubjective.jsx";


const QuestionDetails = () => {
  const questionContents = JSON.parse(localStorage.getItem("selectedQuestion"));
  const type=questionContents.questionResponse.questionType.questionTypeId;

//   console.log(questionContents.questionType)
  console.log(type)

  let componentToRender;

  if (questionContents) {
    switch (type) {
      case 1:
        componentToRender = <ManagerMCQ/>;
        break;
      case 2:
        componentToRender = <ManagerSubjective/>;
        break;
      case 3:
        componentToRender = <ManagerCode />;
        break;
      default:
        componentToRender =<>No question selected</>;
        break;
    }
  } else {
    componentToRender = <div>No question selected</div>;
  }

  return <div style={{ fontFamily: '' }}>{componentToRender}</div>;
};

export default QuestionDetails;
