import React, { useEffect, useRef, useState } from 'react';
import downArrow from "../../assets/downarrow.png";
import upArrow from "../../assets/upArrow.png";

const Dropdown = ({ tags, selectedFilter, selectedType, onFilterChange, onTagChange, width = '15rem' }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);
    const [checkedTags, setCheckedTags] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');

    const handleCheckboxChange = (tag, isChecked) => {
        setCheckedTags(prevCheckedTags => {
            const newTags = isChecked
                ? [...prevCheckedTags, tag]
                : prevCheckedTags.filter(t => t !== tag);
            onTagChange(newTags);
            return newTags;
        });
    };

    const toggleDropdown = () => setIsOpen(prev => !prev);

    const handleOptionClick = (value) => {
        onFilterChange(value, selectedType);
        setIsOpen(false);
    };

    const handleTypeChange = (event) => {
        const type = event.target.value;
        onFilterChange(selectedFilter, type);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const clearSelections = () => {
        setCheckedTags([]);
        onTagChange([]);
        setSearchQuery('');
    };

    const filteredTags = tags.filter(tag => tag.toLowerCase().includes(searchQuery.toLowerCase()));

    return (
        <div className="relative inline-block" ref={dropdownRef} style={{ width }}>
            <button
                className="bg-transparent border border-[#004eec] text-xs font-sans text-[#004eec] py-2 px-4 rounded flex items-center justify-between cursor-pointer"
                onClick={toggleDropdown}
                style={{ width }}
            >
                {selectedFilter} - {selectedType}
                <img
                    src={isOpen ? upArrow : downArrow}
                    alt="dropdown arrow"
                    className="w-3"
                />
            </button>
            {isOpen && (
                <div className="absolute bg-white border border-[#004eec] rounded w-full z-10 top-full left-0 mt-1">
                    <div>
                        {["ALL", "EASY", "MEDIUM", "HARD"].map((filter) => (
                            <button
                                key={filter}
                                className={`block py-2 px-4 w-full text-xs text-left ${selectedFilter === filter ? 'bg-[#004eec] text-white' : 'text-[#004eec]'} hover:bg-[#e0e0e0]`}
                                onClick={() => handleOptionClick(filter)}
                            >
                                {filter}
                            </button>
                        ))}
                    </div>
                    <div className="px-3 py-2 border-t mb-1 border-[#004eec]">
                        <div className='flex flex-col space-y-2'>
                            {["ALL", "MCQ", "Subjective", "Code"].map(type => (
                                <label key={type} className="flex items-center text-xs text-[#004eec]">
                                    <input
                                        type="radio"
                                        value={type}
                                        checked={selectedType === type}
                                        onChange={handleTypeChange}
                                        className="mr-2"
                                    />
                                    {type}
                                </label>
                            ))}
                        </div>
                    </div>
                    {filteredTags.length!=0 && (
                        <div className='relative border-t border-[#004eec]'>
                            <div className='relative'>
                                <input
                                    type="text"
                                    placeholder="Search tags..."
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-[95%] px-1 py-1 border border-[#004eec] rounded text-xs mb-1 pr-12 mx-2"
                                />
                                <button
                                    onClick={clearSelections}
                                    className="absolute top-1 right-2 transform px-2 py-1 bg-[#004eec] text-white text-xs rounded"
                                >
                                    Clear
                                </button>
                            </div>
                        
                            <div className='px-1 py-1 max-h-[10rem] overflow-y-scroll'>
                                <ul className='text-xs'>
                                    {filteredTags.map(tag => (
                                        <li key={tag} className='flex items-center pt-1'>
                                            <input
                                                type='checkbox'
                                                id={tag}
                                                checked={checkedTags.includes(tag)}
                                                onChange={(e) => handleCheckboxChange(tag, e.target.checked)}
                                                className='mr-2'
                                            />
                                            <label htmlFor={tag}>{tag}</label>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default Dropdown;
