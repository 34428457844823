// SkillItem.js
import React, {useState} from "react";
import ChevronUp from "../../assets/minus.svg";
import ChevronDown from "../../assets/plus.svg";

const SkillItem = ({skill, onSkillChange, selectedSkill}) => {
    const hasChildren = skill.items && skill.items.length > 0;
    const [isOpen, setIsOpen] = useState(false);

    const handleSkillClick = (e) => {
        e.stopPropagation();
        if (hasChildren) {
            setIsOpen(!isOpen);
        } else {
            onSkillChange(skill.id, skill.label);
        }
    };

    return (
        <li className={`skill-item cursor-pointer`}>
            <div
                className={`font-poppins flex items-center px-2 py-1 ${selectedSkill === skill.id ? 'bg-[#ededed] text-[#595959] rounded-sm w-full' : 'text-[#585f6d]'} font-medium tracking-[0.1px] text-[1rem]`}
                onClick={handleSkillClick}
                style={{marginBottom: '0.1rem'}}>
                {/*{selectedSkill === skill.id && (*/}
                {/*    <img src={Ellipse} style={{marginRight: '10px'}} alt="Selected"/>*/}
                {/*)}*/}
                <span>{skill.label}</span>
                {hasChildren && (
                    <img
                        src={isOpen ? ChevronUp : ChevronDown}
                        alt="Toggle"
                        style={{cursor: 'pointer', marginLeft: 'auto'}}
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsOpen(!isOpen);
                        }}
                    />
                )}
            </div>
            {hasChildren && isOpen && (
                <ul className="ml-4">
                    {skill.items.map((subSkill) => (
                        <SkillItem
                            key={subSkill.id}
                            skill={subSkill}
                            onSkillChange={onSkillChange}
                            selectedSkill={selectedSkill}
                        />
                    ))}
                </ul>
            )}
        </li>
    );
};

export default SkillItem;
