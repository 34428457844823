import React, {useEffect, useState} from "react";
import logo from "../../../assets/logo_ovii_2.svg";
import deer from "../../../assets/Create.svg";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
// Import actions
import {toggleCamera, toggleMic, toggleScreenSharing} from '../../../redux/actions/proctoringActions';
import {Button} from "../../components/button";
import ToggleSwitch from "../../components/ToggleSwitch";
import videoRecorder from "../../../assets/video-recorder.svg";
import Microphone from "../../../assets/microphone-test.svg";
import Monitor from "../../../assets/monitor-test.svg";
import VideoOff from "../../../assets/video-recorder-off.svg";
import VolumeMax from "../../../assets/volume-max.svg";
import Modal from "../../components/popup";

const Proctoring = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch(); // Use dispatch to toggle states

    // State from Redux store
    const isCameraOn = useSelector((state) => state.proctoring.isCameraOn);
    const isMicOn = useSelector((state) => state.proctoring.isMicOn);
    const isScreenSharingOn = useSelector((state) => state.proctoring.isScreenSharingOn);
    const [storedDecodedLogo, setStoredDecodedLogo] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);  // New state to manage the overlay

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    // State for managing permissions
    const [permissionsGranted, setPermissionsGranted] = useState({
        camera: false,
        microphone: false,
        screen: false,
    });

    console.log("id after setting it", localStorage.getItem("candidateId"))

    useEffect(() => {
        const storedDecodedLogo = localStorage.getItem("decodedLogo");
        setStoredDecodedLogo(storedDecodedLogo);
        checkPermissions();  // Request permissions when component mounts
    }, []);

    // Handlers that dispatch toggle actions
    const handleCameraToggle = () => dispatch(toggleCamera());
    const handleMicToggle = () => dispatch(toggleMic());
    const handleScreenSharingToggle = () => dispatch(toggleScreenSharing());

    const checkPermissions = async () => {
        try {
            const mediaStream = await navigator.mediaDevices.getUserMedia({video: true, audio: true});
            setPermissionsGranted(prev => ({...prev, camera: true, microphone: true}));
            setIsOverlayVisible(false);  // Hide the overlay if permissions are granted
        } catch (error) {
            console.error("Error accessing camera or microphone", error);
            setIsOverlayVisible(true);  // Show the overlay if permissions are denied
        }
    };

    const handleNext = async (e) => {
        e.stopPropagation();
        if (!permissionsGranted.camera || !permissionsGranted.microphone) {
            setModalContent({
                heading: 'Permissions Required',
                message: 'Please enable camera and microphone to start the test.',
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
            return;
        }

        if (isMicOn && isScreenSharingOn && !isCameraOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable camera to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (isCameraOn && isScreenSharingOn && !isMicOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable Mic to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (isCameraOn && isMicOn && !isScreenSharingOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable screen sharing to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (isMicOn && !isScreenSharingOn && !isCameraOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable camera and screen sharing to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (isScreenSharingOn && !isMicOn && !isCameraOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable camera and Mic to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (isCameraOn && !isScreenSharingOn && !isMicOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable Mic and screen sharing to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (!isCameraOn && !isScreenSharingOn && !isMicOn) {
            setModalContent({
                heading: 'Error',
                message: `Please enable camera, mic and screen sharing to start the test.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        } else if (isCameraOn && isMicOn && isScreenSharingOn) {
            if (localStorage.getItem("assessmentType") === '1') {
                navigate("/skillAssessment");
            } else if (localStorage.getItem("assessmentType") === '2') {
                navigate("/videoInterview");
            }
        }
    };

    return (
        <div className="max-h flex flex-col">
            {isOverlayVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-75 flex flex-col items-center justify-center z-50">
                    <p className="text-white mb-4">Camera and Microphone access are required to proceed.</p>
                    <Button
                        variant="bright-blue"
                        label="Retry Permissions"
                        width="16rem"
                        onClick={checkPermissions}
                        className='text-[0.735rem] h-[2.25rem]'
                    >
                        Retry
                    </Button>
                </div>
            )}
            <div>
                {typeof storedDecodedLogo === 'string' && storedDecodedLogo.startsWith('img:') ? (
                    <img
                        src={`data:image/;base64,${storedDecodedLogo.substring(4)}`}
                        alt="ovii logo"
                        className="m-[2rem]  w-[10rem]"
                    />
                ) : (
                    <p className="font-poppins m-[2rem] text-[2rem] text-labels-black">
                        {storedDecodedLogo}
                    </p>
                )}
            </div>
            <div className="flex flex-col justify-center items-center flex-grow">
                <p className="font-serif text-[1.75rem] w-[19.938rem] h-[2.188rem] mx-auto mt-[2.188rem] mb-7 text-center text-labels-black">
                    Proctoring requirements
                </p>

                <div className="flex items-center">
                    {/* Camera is on */}

                    <div
                        className="w-250 h-[10.3rem] m-1 p-4 border rounded-lg shadow-md border-gray-300 bg-white flex flex-col">
                        <div className="w-full h-4/5 flex items-center justify-center"> {/* 60% height for image */}
                            <img src={videoRecorder} alt="Video Recorder" className=""/>
                        </div>
                        <hr className="w-full border-gray-300 opacity-20"/>
                        {/* Faint gray line */}
                        <div
                            className="w-full h-1/5 flex items-start justify-center mt-2"> {/* 20% height for text with margin-top */}
                            <img src={VideoOff} alt="VideoOff " className="mr-2 mt-1"/>
                            <span
                                className="w-[7rem] flex-grow-1 pr-2"
                                style={{'fontFamily': 'helvetica'}}>Camera is {isCameraOn ? 'on' : 'off'}</span>
                            <div className="mt-1">
                                <ToggleSwitch
                                    checked={isCameraOn}
                                    onChange={handleCameraToggle}
                                />
                            </div>
                        </div>
                    </div>

                    {/* Speaker, Mic, Screen sharing */}
                    <div className="ml-4 space-y-4">
                        <div
                            className="w-70 h-11 flex-grow-0 m-1 p-3 border rounded-lg shadow-md border-gray-300 bg-white flex items-center justify-between pt-2">
                            <img src={VolumeMax} alt="Microphone" className="mr-2"/>
                            <span className="flex-grow-1"
                                  style={{'fontFamily': 'helvetica'}}>Speaker is {isMicOn ? 'on' : 'off'}</span>
                            <ToggleSwitch
                                checked={isMicOn}
                                onChange={handleMicToggle}
                            />
                        </div>
                        <div
                            className="w-70 h-11 flex-grow-0 m-1 p-3 border rounded-lg shadow-md border-gray-300 bg-white flex items-center justify-between pt-2">
                            <img src={Microphone} alt="Microphone" className="mr-2"/>
                            <span className="flex-grow-1"
                                  style={{'fontFamily': 'helvetica'}}>Mic is {isMicOn ? 'on' : 'off'}</span>
                            <ToggleSwitch
                                checked={isMicOn}
                                onChange={handleMicToggle}
                            />
                        </div>
                        <div
                            className="w-70 h-11 flex-grow-0 m-1 p-3 border rounded-lg shadow-md border-gray-300 bg-white flex  justify-center pt-2">
                            <img src={Monitor} alt="Monitor" className="mr-2"/>
                            <span
                                className="w-[11rem] flex-grow-1 text-center" style={{'fontFamily': 'helvetica'}}>Screen sharing is {isScreenSharingOn ? 'on' : 'off'}</span>
                            <ToggleSwitch
                                checked={isScreenSharingOn}
                                onChange={handleScreenSharingToggle}
                                className="ml-1"
                            />
                        </div>
                    </div>

                </div>

                <p className="font-serif text-[.975rem] w-[32.625rem] h-[5.188rem] mx-auto mt-[2.5rem] text-[#444]">
                    When sharing your screen, be sure to share the entire screen, rather
                    than just a specific window or tab. Failure to adhere to these
                    guidelines may be seen as an attempt to engage in cheating.
                </p>

                <p className="mt-[2.063rem]">
                    <Button
                        variant="bright-blue"
                        label="START TEST"
                        width="16rem"
                        onClick={handleNext}
                        className='text-[0.735rem] h-[2.25rem]'
                    >
                        Start Test
                    </Button>
                </p>
                <div className="fixed bottom-2 flex flex-wrap">
                    <p className="h-[1.313rem] font-serif text-[1.146rem] text-labels-black font-semibold">
                        Powered by
                    </p>
                    <img
                        src={logo}
                        alt="logo"
                        className="mt-[0.15rem] ml-[0.2rem] w-[2.5rem] h-[1.313rem]"
                    />
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
            <img
                src={deer}
                alt="man with a review panel"
                className="absolute bottom-0 right-0 w-[15.375rem] h-[auto]"
                style={{maxWidth: "100%", maxHeight: "100%"}}
            />
        </div>
    );
};

export default Proctoring;
