// import React, { useEffect, useRef, useState } from 'react';
// import { useNavigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import { FormValidator } from '@syncfusion/ej2-inputs';
// import logo from '../../../assets/logo_ovii_2.svg';
// import { Textbox } from '../../components/textbox';
// import { Button } from '../../components/button';
// import hello from '../../../assets/Hello.svg';
// import FooterLinks from '../../components/footerLinks';
// import icon from '../../../assets/icon.svg';
// import Modal from '../../components/popup';
// import error from "./error.css" 

// const ForgotPassword3 = () => {
//     const [password, setPassword] = useState('');
//     const [confirmPassword, setConfirmPassword] = useState('');
//     const [formValidator, setFormValidator] = useState(null);
//     const navigate = useNavigate();
//     const email = useSelector(state => state.forgotPassword.email);
//     const formRef = useRef(null);
//     const [showModal, setShowModal] = useState(false);
//     const [modalContent, setModalContent] = useState({});
//     const success = useState(false);
//     const [isLoading, setIsLoading] = useState(false);

//     const handleModalConfirm = (success) => {
//         if (success) {
//             navigate('/login');
//         }
//         setShowModal(false);
//     };

//     const hasUppercase = (value) => {
//         const newPassword = /[A-Z]/;
//         return newPassword.test(value.value || '');
//     };

//     const hasLowercase = (value) => {
//         const newPassword = /[a-z]/;
//         return newPassword.test(value.value || '');
//     };

//     const hasNumber = (value) => {
//         const newPassword = /\d/;
//         return newPassword.test(value.value || '');
//     };

//     const hasSpecialChar = (value) => {
//         const newPassword = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
//         return newPassword.test(value.value || '');
//     };

//     useEffect(() => {
//         if (formRef.current) {
//             const validator = new FormValidator(formRef.current, {
//                 rules: {
//                     'newPassword': {
//                         required: true,
//                         minLength: [6, 'Password should have at least 6 characters'],
//                         hasUppercase: [hasUppercase, 'Password should contain at least one uppercase letter'],
//                         hasLowercase: [hasLowercase, 'Password should contain at least one lowercase letter'],
//                         hasNumber: [hasNumber, 'Password should contain at least one number'],
//                         // hasSpecialChar: [hasSpecialChar, 'Password should contain at least one special character']
//                     },
//                     'confirmPassword': {
//                         required: true,
//                         compare: [() => password, 'Passwords must match']
//                     }
//                 },
//                 customPlacement: (inputElement, errorElement) => {
//                     inputElement.parentElement.appendChild(errorElement);
//                     errorElement.classList.add('validation-message');
//                 }
//             });

//             setFormValidator(validator);
//         }
//     }, [formRef, password]);

//     /**
//      * Handles the form submission for resetting the password.
//      * @param {Object} e - The event object.
//      * @returns {Promise<void>}
//      */
//     const handleSubmit = async (e) => {
//         e.preventDefault();
//         if (formValidator && formValidator.validate()) {
//             setIsLoading(true);
//             if (password === confirmPassword) {
//                 try {
//                     const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/resetPassword`, {
//                         method: 'POST',
//                         headers: {
//                             'Content-Type': 'application/json'
//                         },
//                         body: JSON.stringify({
//                             email: email,
//                             newPassword: password
//                         })
//                     });

//                     if (response.ok) {
//                         setIsLoading(false);
//                         setModalContent({
//                             heading: 'Success',
//                             message: `Password reset successfully.`,
//                             confirmButtonText: 'OK',
//                             onConfirm: () => success(true),
//                         });
//                         setShowModal(true);
//                     } else {
//                         setIsLoading(false);
//                         setModalContent({
//                             heading: 'Error',
//                             message: `Failed to reset password.`,
//                             confirmButtonText: 'OK',
//                             onConfirm: handleModalConfirm,
//                         });
//                         setShowModal(true);
//                     }
//                 } catch (error) {
//                     setIsLoading(false);
//                     setModalContent({
//                         heading: 'Error',
//                         message: `Failed to reset password`,
//                         confirmButtonText: 'OK',
//                         onConfirm: handleModalConfirm,
//                     });
//                     setShowModal(true);
//                 } finally {
//                     setIsLoading(false);
//                 }
//             }
//         }
//     };

//     return (
//         <div className="h-dvh">
//             <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col items-center justify-center">
//                 <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mt-[9.75rem]" />
//                 <p className="font-serif text-[1.75rem] font-bold tracking-[0.56px] text-charcoal mt-[1.938rem]">Reset your password</p>
//                 <Textbox
//                     label={(
//                         <label className="flex items-center">
//                             New Password
//                             <span className="text-red-500">*</span>
//                         </label>
//                     )}
//                     placeholder='********'
//                     className='mt-[1rem]'
//                     inputClassName='w-[20rem]'
//                     type="password"
//                     name="newPassword"
//                     value={password}
//                     onChange={(e) => setPassword(e.target.value)}
//                 />
//                 <Textbox
//                     label={(
//                         <label className="flex items-center">
//                             Confirm Password
//                             <span className="text-red-500">*</span>
//                         </label>
//                     )}
//                     placeholder='********'
//                     className='mt-[1rem]'
//                     inputClassName='w-[20rem]'
//                     type="password"
//                     name="confirmPassword"
//                     value={confirmPassword}
//                     onChange={(e) => setConfirmPassword(e.target.value)}
//                 />
//                 <div>
//                     <Button label={isLoading ? "LOADING..." : "CONTINUE"} width="20rem" className='mt-[1rem] text-[0.735rem] h-[2.25rem]' variant='bright-blue' type="submit"></Button>
//                     <div className="flex items-center mt-[1.063rem] ml-[6.5rem]">
//                         <img src={icon} alt="Back icon" className="w-[0.75rem] h-[0.625rem]" />
//                         <p className='text-sans text-sm tracking-[0.14px] pl-1'>Back to
//                             <span className='font-semibold cursor-pointer text-blue'
//                                 onClick={() => navigate("/login")} tabIndex={0}> login</span>
//                         </p>
//                     </div>
//                 </div>
//                 <Modal
//                     isOpen={showModal}
//                     onClose={() => setShowModal(false)}
//                     onConfirm={handleModalConfirm}
//                     heading={modalContent.heading}
//                     message={modalContent.message}
//                     confirmButtonText={modalContent.confirmButtonText}
//                 />
//             </form>
//             {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
//             <img src={hello}
//                 alt="man saying hello"
//                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
//                 style={{ maxWidth: '100%', maxHeight: '100%' }} />
//         </div>
//     );
// };

// export default ForgotPassword3;


import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import logo from '../../../assets/logo_ovii_2.svg';
import { Textbox } from '../../components/textbox';
import { Button } from '../../components/button';
import hello from '../../../assets/Hello.svg';
import FooterLinks from '../../components/footerLinks';
import icon from '../../../assets/icon.svg';
import Modal from '../../components/popup';
import error from "./error.css"

const ForgotPassword3 = () => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [formValidator, setFormValidator] = useState(null);
    const navigate = useNavigate();
    const email = useSelector(state => state.forgotPassword.email);
    const formRef = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isSuccess, setIsSuccess] = useState(false);  // State to handle success status
    const [isLoading, setIsLoading] = useState(false);

    const handleModalConfirm = () => {
        if (isSuccess) {
            navigate('/login');
        }
        setShowModal(false);
    };

    const hasUppercase = (value) => {
        const newPassword = /[A-Z]/;
        return newPassword.test(value.value || '');
    };

    const hasLowercase = (value) => {
        const newPassword = /[a-z]/;
        return newPassword.test(value.value || '');
    };

    const hasNumber = (value) => {
        const newPassword = /\d/;
        return newPassword.test(value.value || '');
    };

    const hasSpecialChar = (value) => {
        const newPassword = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return newPassword.test(value.value || '');
    };

    useEffect(() => {
        if (formRef.current) {
            const validator = new FormValidator(formRef.current, {
                rules: {
                    'newPassword': {
                        required: true,
                        minLength: [6, 'Password should have at least 6 characters'],
                        hasUppercase: [hasUppercase, 'Password should contain at least one uppercase letter'],
                        hasLowercase: [hasLowercase, 'Password should contain at least one lowercase letter'],
                        hasNumber: [hasNumber, 'Password should contain at least one number'],
                        // hasSpecialChar: [hasSpecialChar, 'Password should contain at least one special character']
                    },
                    'confirmPassword': {
                        required: true,
                        compare: [() => password, 'Passwords must match']
                    }
                },
                customPlacement: (inputElement, errorElement) => {
                    inputElement.parentElement.appendChild(errorElement);
                    errorElement.classList.add('validation-message');
                }
            });

            setFormValidator(validator);
        }
    }, [formRef, password]);

    /**
     * Handles the form submission for resetting the password.
     * @param {Object} e - The event object.
     * @returns {Promise<void>}
     */
    const handleSubmit = async (e) => {
        e.preventDefault();
        if (formValidator && formValidator.validate()) {
            setIsLoading(true);
            if (password === confirmPassword) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/resetPassword`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            email: email,
                            newPassword: password
                        })
                    });

                    if (response.ok) {
                        setIsLoading(false);
                        setIsSuccess(true);  // Set success status
                        setModalContent({
                            heading: 'Success',
                            message: `Password reset successfully.`,
                            confirmButtonText: 'OK',
                            onConfirm: handleModalConfirm,
                        });
                        setShowModal(true);
                    } else {
                        setIsLoading(false);
                        setIsSuccess(false);  // Set failure status
                        setModalContent({
                            heading: 'Error',
                            message: `Failed to reset password.`,
                            confirmButtonText: 'OK',
                            onConfirm: handleModalConfirm,
                        });
                        setShowModal(true);
                    }
                } catch (error) {
                    setIsLoading(false);
                    setIsSuccess(false);  // Set failure status
                    setModalContent({
                        heading: 'Error',
                        message: `Failed to reset password`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    });
                    setShowModal(true);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                setModalContent({
                    heading: 'Error',
                    message: 'New password and the confirmed password must match. Please try again.',
                    confirmButtonText: 'OK',
                    onConfirm: handleModalConfirm,
                });
                setShowModal(true);
            }
        }
    };

    return (
        <div className="h-dvh">
            <form ref={formRef} onSubmit={handleSubmit} className="flex flex-col items-center justify-center">
                <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mt-[9.75rem]" />
                <p className="font-serif text-[1.75rem] font-bold tracking-[0.56px] text-charcoal mt-[1.938rem]">Reset your password</p>
                <Textbox
                    label={(
                        <label className="flex items-center">
                            New Password
                            <span className="text-red-500">*</span>
                        </label>
                    )}
                    placeholder='********'
                    className='mt-[1rem]'
                    inputClassName='w-[20rem]'
                    type="password"
                    name="newPassword"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <Textbox
                    label={(
                        <label className="flex items-center">
                            Confirm Password
                            <span className="text-red-500">*</span>
                        </label>
                    )}
                    placeholder='********'
                    className='mt-[1rem]'
                    inputClassName='w-[20rem]'
                    type="password"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <div>
                    <Button label={isLoading ? "LOADING..." : "CONTINUE"} width="20rem" className='mt-[1rem] text-[0.735rem] h-[2.25rem]' variant='bright-blue' type="submit"></Button>
                    <div className="flex items-center mt-[1.063rem] ml-[6.5rem]">
                        <img src={icon} alt="Back icon" className="w-[0.75rem] h-[0.625rem]" />
                        <p className='text-sans text-sm tracking-[0.14px] pl-1'>Back to
                            <span className='font-semibold cursor-pointer text-blue'
                                onClick={() => navigate("/login")} tabIndex={0}> login</span>
                        </p>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={handleModalConfirm}
                    heading={modalContent.heading}
                    message={modalContent.message}
                    confirmButtonText={modalContent.confirmButtonText}
                />
            </form>
            {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
            <img src={hello}
                alt="man saying hello"
                className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
    );
};

export default ForgotPassword3;
