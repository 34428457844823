// src/redux/slices/nextUrlSlice.js
import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    fromSite: false, // Default value is null
};

export const urlSlice = createSlice({
    name: 'fromSite',
    initialState,
    reducers: {
        setNextUrl: (state, action) => {
            console.log(state.fromSite)
            state.fromSite = action.payload;
        },
        clearNextUrl: (state) => {
            state.fromSite = false;
        },
    },
});

export const {setNextUrl, clearNextUrl} = urlSlice.actions;

export default urlSlice.reducer;
