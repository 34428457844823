import React from "react";
import { Button } from "../../../components/button";
import logo from "../../../../assets/logo_ovii_2.svg";
import McqOption from "./McqOption";

const Mcq = ({ borderWidth = "auto", borderLength = "35em" }) => {
  const borderStyle = {
    width: "76.625rem",
    height: "39.125rem",
    margin: "0rem 0rem 2.688rem",
    padding: "4.188rem 3.531rem 4.813rem 2.125rem",
    borderRadius: "0.5rem",
    backgroundColor: "#fff",
    border: `${borderWidth} solid #020818`,
    boxSizing: "border-box",
    fontFamily: "HelveticaNeue-Regular",
  };

  return (
    <div className="relative p-">
      <div className="border rounded-lg p-2 flex" style={borderStyle}>
        {/* Left Top - Question */}
        <div className="w-1/2 p-8">
          <h2 className="text-2xl font mb-4">Software Meets Business</h2>
          <p>
            Which phase in the SDLC is crucial for determining whether the
            software will meet business needs and if it's viable for the market?
          </p>
        </div>

        {/* Right Top - Options */}
        <div className="w-1/2 p-8">
          <div className="grid grid-cols-1 gap-4 justify-center items-center">
            {/* Option 1 */}
            <McqOption label="Implementation Phase" />

            {/* Option 2 */}
            <McqOption label="Testing Phase" />

            {/* Option 3 */}
            <McqOption label="A specific scenario that includes a set of actions, conditions, and expected results to verify a particular function of the application" />

            {/* Option 4 */}
            <McqOption label="Maintenance Phase" />
          </div>
        </div>

        {/* Bottom Right - Button */}
        <div className="absolute bottom-20 right-0">
          <Button 
            variant="black"
            type="button"
            width="14rem"
            onClick={() => console.log("Button clicked")}
            label="View Details"
            style={{
              backgroundColor: "#020818",
              color: "#fff",
              borderRadius: "0.5rem",
              // boxShadow: "0 0 12px 0 rgba(0, 0, 0, 0.1)",
              padding: "0.75rem 0.75rem",
            }}
          />
        </div>
      </div>

      {/* Powered by section below the border */}
      <div className="absolute bottom-2 left-1/2 transform -translate-x-1/2 text-center">
        Powered by <img src={logo} alt="Powered by " className="inline" style={{ width: '35px', height: 'auto' }} />
      </div>
    </div>
  );
};

export default Mcq;
 