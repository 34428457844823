import {createSlice} from "@reduxjs/toolkit";

export const codeAnswersSlice = createSlice({
    name: "codeAnswers",
    initialState: {},
    reducers: {
        setCodeAnswer: (state, action) => {
            const {questionId, answer, languageId, languageIdValue} = action.payload;
            console.log("setCodeAnswer", questionId, answer, languageId, languageIdValue)
            // Ensure immutability by returning a new state object
            return {
                ...state,
                [questionId]: {
                    ...state[questionId],
                    answer,
                    languageId,
                    languageIdValue
                },
            };
        },
        removeCodeAnswer: (state, action) => {
            const {questionId} = action.payload;
            // Ensure immutability by returning a new state object
            const newState = {...state};
            delete newState[questionId];
            return newState;
        },
    },
});

export const {setCodeAnswer, removeCodeAnswer} = codeAnswersSlice.actions;

export default codeAnswersSlice.reducer;
