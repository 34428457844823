import React, {useEffect, useRef, useState} from "react";
import logo from "../../../assets/logo_ovii_2.svg";

const QuestionDetailsPage = () => {
    const questionContents = JSON.parse(localStorage.getItem("selectedAsyncVideoQuestion"));
    const [storedDecodedLogo, setStoredDecodedLogo] = useState(null);
    const [showSlider, setShowSlider] = useState(false);
    const popupRef = useRef(null);
    const accessToken = localStorage.getItem('accessToken');
    const candidateId = questionContents?.candidateId || '4202'; // Use candidateId from questionContents or default to '4202'

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowSlider(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);

    useEffect(() => {
        const fetchLogo = async () => {
            try {
                const response = await fetch(`https://api.ovii.ai/api/v1/getLogoForCandidate?candidateId=${candidateId}`, {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }
                });

                if (response.ok) {
                    const data = await response.json();
                    setStoredDecodedLogo(data.data); // Assuming the API response contains a `data` field with the logo text
                } else {
                    console.error('Failed to fetch logo:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching logo:', error);
            }
        };

        fetchLogo();
    }, [candidateId, accessToken]);

    const renderDetails = (details) => {
        return (
            Object.entries(details).map(([key, value]) => {
                if (typeof value === 'object' && !Array.isArray(value)) {
                    return (
                        <div key={key} className="mt-4">
                            <p className="text-lg font-semibold">{key}:</p>
                            {renderDetails(value)}
                        </div>
                    );
                } else if (Array.isArray(value)) {
                    return (
                        <div key={key} className="mt-4">
                            <p className="text-lg font-bold">{key}:</p>
                            <ul>
                                {value.map((item, index) => (
                                    <li key={index} className="text-sm">{item}</li>
                                ))}
                            </ul>
                        </div>
                    );
                } else {
                    return (
                        <p key={key} className="text-sm">{key}: {value}</p>
                    );
                }
            })
        );
    };

    const constructNewVideoUrl = (oldUrl) => {
        const basePath = 'https://storage-zone-1999.b-cdn.net/video-interview-recordings/';
        const fileName = oldUrl.split('/').pop();  // Extracts the filename from the URL
        return basePath + fileName;
    };

    return (
        <div className="max-w">
            <div className="flex justify-center w-full mt-[1.5rem]">
                {/*{storedDecodedLogo ? (*/}
                {/*    <p className="p-[0.5rem] text-[1.75rem] text-labels-black" style={{fontFamily: 'helvetica'}}>*/}
                {/*        {storedDecodedLogo}*/}
                {/*    </p>*/}
                {/*) : (*/}
                {/*    <p className="p-[0.5rem] text-[1.75rem] text-labels-black" style={{fontFamily: 'helvetica'}}>*/}
                {/*        Loading logo...*/}
                {/*    </p>*/}
                {/*)}*/}
                <div className="flex justify-center w-full mt-[1.5rem]">
                    {typeof storedDecodedLogo === "string" &&
                    storedDecodedLogo.startsWith("img:") ? (
                        <img
                            src={`data:image/;base64,${storedDecodedLogo.substring(4)}`}
                            alt="ovii logo"
                            className="absolute top-[1.5rem] w-[7rem]"
                        />
                    ) : (
                        <p className="font-poppins text-[1.75rem] absolute top-3 text-labels-black">
                            {storedDecodedLogo}
                        </p>
                    )}
                </div>
            </div>
            <div className="flex flex-col items-center justify-center mt-[1rem]">
                <div className="border-[0.5px] shadow-md w-[90vw] h-[70vh] rounded-md gap-4 px-8 mt-4 relative">
                    <div className="flex justify-center items-center h-[90%]">
                        <div
                            className="w-[50%] mt-[2rem] mr-[2rem] h-[auto] rounded-lg border-[8px] border-[#D9E2E6] min-h-[20rem] bg-[#D9E2E5]">
                            <video src={constructNewVideoUrl(questionContents.videoPath)} controls/>
                        </div>
                        <div className="w-[50%] flex flex-col justify-center">
                            <div className=" overflow-y-scroll max-h-[23vh]"
                                 style={{
                                     scrollbarWidth: "none",
                                     msOverflowStyle: "none",
                                     "&::-webkit-scrollbar": {display: "none"}
                                 }}>
                                <h1 className="font-medium font-serif text-black text-[1.175rem] break-word mb-[1.25rem]">
                                    {questionContents.question}
                                </h1>
                                <p className="text-blue text-[1.05rem] break-word"
                                   style={{fontFamily: 'helvetica'}}>{questionContents.questionDetails}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="z-0 fixed bottom-0 left-0 w-full text-center">
                <p>
                    Powered by
                    <img className="w-10 h-10 inline pl-2 mb-1"
                         src={logo}
                         alt="ovii logo"
                    ></img>
                </p>
            </div>
        </div>
    );
};

export default QuestionDetailsPage;
