import React, {useState} from 'react';
import grayIcon from "../../assets/userBlue.svg";

const ChatWindow = ({initialPrompt}) => {
    const [inputText, setInputText] = useState('');
    const [conversation, setConversation] = useState([
        {role: 'system', content: initialPrompt}
    ]);
    const accessToken = localStorage.getItem('accessToken');
    const name = localStorage.getItem('adminName');

    const handleInputChange = (event) => {
        setInputText(event.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitMessage();
        }
    };

    const submitMessage = async () => {
        if (!inputText.trim()) {
            return;
        }

        const newConversation = [...conversation, {role: 'user', content: inputText}];
        setConversation(newConversation);
        setInputText('');
        await sendChatMessage(newConversation);
    };

    const sendChatMessage = async (conversationHistory) => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/chat`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${accessToken}`
                },
                body: JSON.stringify(conversationHistory)
            });

            const data = await response.json();

            if (response.ok) {
                if (Array.isArray(data.data)) {
                    setConversation(prev => [...prev, ...data.data.map(msg => ({
                        role: msg.role,
                        content: msg.content
                    }))]);
                } else if (typeof data.data === 'string') {
                    setConversation(prev => [...prev, {role: 'assistant', content: data.data}]);
                }
            } else {
                console.error('Failed to fetch response:', data);
                setConversation(prev => [...prev, {role: 'assistant', content: "Error fetching response."}]);
            }
        } catch (error) {
            console.error('Error calling the chat API:', error);
            setConversation(prev => [...prev, {role: 'assistant', content: "Network error, please try again later."}]);
        }
    };

    return (
        <div
            className="w-[90%] ml-8 bg-white h-full flex flex-col justify-start">
            <div className="border border-gray-300 rounded-lg mb-5 overflow-y-scroll"
                 style={{
                     scrollbarWidth: "none",
                     msOverflowStyle: "none",
                     "&::-webkit-scrollbar": {display: "none"},
                 }}>
                {conversation.map((message, index) => {
                    if (message.role === 'system' && message.content === initialPrompt) {
                        return (
                            <div className='flex flex-col h-full justify-between items-center'>
                                <p className='mt-8 font-medium font-serif text-[1.75rem] text-charcoal flex flex-col items-center'>
                                    Welcome, {name}!
                                </p>
                                <p className='mt-6 font-medium font-serif text-[1rem] text-charcoal items-center text-center w-[70%]'>
                                    Use this AI-powered chat window to search and review specific interview details and
                                    transcripts. Simply type a question related to the candidate's interview for instant
                                    insights.
                                </p>
                                <div className='h-[32vh]'></div>
                                {/* This div will take up the remaining space */}
                                <div className='flex flex-row gap-2 w-[80%] mx-auto'>
                                    <div className='w-1/3 border border-blue rounded-md p-2 text-center'>
                                        <p className='font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem]'>How
                                            confident was the candidate</p>
                                    </div>
                                    <div className='w-1/3 border border-blue rounded-md p-2 text-center'>
                                        <p className='font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem]'>Did
                                            the candidate give sufficient examples</p>
                                    </div>
                                    <div className='w-1/3 border border-blue rounded-md p-2 text-center'>
                                        <p className='font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem]'>What
                                            is the candidate's problem solving approach</p>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    return (
                        <div key={index}
                             className={`flex ${message.role === 'user' ? 'justify-end ml-[2rem]' : 'justify-start mr-[5rem]'}`}>
                            <div
                                className={`message px-4 font-sans text-[#000] text-[0.9rem] tracking-[0.2px] leading-[1.65rem] ${message.role === 'user' ? 'bg-[#6967f4] text-white flex items-center justify-between rounded-l-lg rounded-tr-lg' : 'bg-[#ebebf5] text-left w-fit rounded-r-lg rounded-tl-lg'} m-2.5 p-2 `}>
                                <span className="flex-1">{message.text || message.content}</span>
                            </div>
                            {message.role === "user" && <img className="mr-2" src={grayIcon} alt="User Icon"/>}
                        </div>
                    );
                })}
            </div>
            <div
                className="relative flex items-center bg-[#ededed] rounded-[35px] px-2 overflow-hidden">
                <textarea
                    className="bg-[#ededed] flex-grow px-2.5 py-1 mx-2 h-14 text-base border-none resize-none hover:border-0 focus:border-0 focus:outline-none"
                    rows="2"
                    placeholder="Ask anything about the candidate's interview with ovii"
                    value={inputText}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    style={{
                        scrollbarWidth: 'none',
                    }}
                />
                <div className="absolute top-1/2 right-3 transform -translate-y-1/2 ml-2">
                    <button className='rounded-[30px] bg-[#6967f4] text-white w-[7rem] py-2'
                            onClick={submitMessage}>Submit ➔
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChatWindow;
