import {deleteUser, resendInvite} from "../actions/userManagement";

const initialState = {
  usersList: null,
  invitationsList: null, // Add this line to include invitations in your initial state
  isSendingInvite: false,
  isResendingInvite: false,
  teamId: null,
};

const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_USERS_LIST":
      return {
        ...state,
        usersList: action.payload,
      };
    case "FETCH_INVITATIONS_SUCCESS": // Handle fetching invitations success
      return {
        ...state,
        invitationsList: action.payload,
      };
    case "SENDING_INVITE":
      return {
        ...state,
        isSendingInvite: action.payload.isSendingInvite,
      };
    case "RESENDING_INVITE":
      return {
        ...state,
        isResendingInvite: action.payload.isResendingInvite,
      };
    case "FETCHING_TEAM_ID":
      return {
        ...state,
        teamId: action.payload.teamId,
      };
      // delete User success part testing
      // case "DELETE_USER_SUCCESS":
      //       return {
      //           ...state,
      //           usersList: state.usersList.filter(user => user.userId !== action.payload),
      //       };
    default:
      return state;
  }
};




export default userManagementReducer;



