// videoActions.js

export const START_VIDEO_STREAM = 'START_VIDEO_STREAM';
export const STOP_VIDEO_STREAM = 'STOP_VIDEO_STREAM';

export const startVideoStream = (stream) => ({
    type: START_VIDEO_STREAM,
    payload: { stream },
});

export const stopVideoStream = () => ({
    type: STOP_VIDEO_STREAM,
});
