// reducers/userReducer.js
const initialState = {
    adminEmail: '',
    // include other states as needed
};

export const teamReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_ADMIN_EMAIL':
            return {
                ...state,
                adminEmail: action.payload,
            };
        // handle other actions
        default:
            return state;
    }
};
