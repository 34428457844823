import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const SentimentBarChart = ({positive, neutral, negative}) => {
    const barOptions = {
        chart: {
            type: 'bar',
            height: '18%',
            backgroundColor: '#fff',
        },
        title: {
            text: null,
        },
        xAxis: {
            categories: ['Sentiment'],
            labels: {
                style: {
                    fontSize: '14px',
                    color: '#333',
                },
            },
            visible: false, // Hide x-axis labels
        },
        yAxis: {
            min: 0,
            max: 100,
            lineWidth: 0,
            tickWidth: 0,
            labels: {
                enabled: false, // Hide y-axis labels
            },
            title: {
                text: null, // Remove the title text from y-axis
            },
        },
        tooltip: {
            enabled: false,
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: false,
                    inside: false,
                    format: '{y}%', // Display the value as a percentage
                    style: {
                        color: '#333',
                        fontSize: '12px',
                        fontWeight: 'bold',
                    },
                },
                borderWidth: 0,
            },
            series: {
                stacking: 'percent',
            },
        },
        legend: {
            enabled: true,
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            itemStyle: {
                fontSize: '14px',
                color: '#333',
            },
            itemHoverStyle: {
                color: '#555',
            },
        },
        credits: {
            enabled: false,
        },
        series: [
            {
                name: 'Negative',
                data: [parseFloat(negative)],
                color: '#f54f70',
            },
            {
                name: 'Neutral',
                data: [parseFloat(neutral)],
                color: '#f3d856',
            },
            {
                name: 'Positive',
                data: [parseFloat(positive)],
                color: '#4bb516',
            },
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={barOptions}
        />
    );
};

export default SentimentBarChart;
