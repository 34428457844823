import React, { useRef, useState, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import { Button } from "../../../components/button";
import DataTable from "../../../components/candidateTable";
import CsvUploader from "../../../components/csvUploader";
import sampleCSV from "../../../../assets/sampleCSV.csv";
import InviteCandidates from "./InviteCandidates";
import {getAssesmentsForPositions, getAssesmentsQuestions} from "../../../../redux/actions/positionActions";

function CandidateList() {
    const [currentTab, setCurrentTab] = useState(1);
    const [currentSubTab, setCurrentSubTab] = useState(1);
    const [showPopup, setShowPopup] = useState(false);
    const popupRef = useRef(null);
    const dispatch = useDispatch();
    const positionId = useSelector((state) => state.positionDetails.positionId);
    const accessToken = localStorage.getItem("accessToken");

    useEffect(() => {
        fetchAssesmentsForPositions(positionId);
    }, [positionId]);


    const fetchAssesmentsForPositions = (positionId) => {
        dispatch(getAssesmentsForPositions({positionId: positionId, accessToken: accessToken}))
    }

    const assessments = useSelector((state) => state.position.assessmentsForPosition);

    const assessmentTypeAndId = assessments.map(assessment => ({
        typeID: assessment.typeID,
        type: assessment.type,
        id: assessment.id
    }));

    const [assessmentId, setAssessmentId] = useState(assessmentTypeAndId[0].id);

    const fetchQuestionsForAssessment = (assessmentId) => {
        dispatch(getAssesmentsQuestions({assessmentId: assessmentId, accessToken: accessToken}));
    }

    const assessmentQuestions = useSelector((state) => state.position.assessmentQuestions);

    useEffect(() => {
        fetchQuestionsForAssessment(assessmentId)
    }, [assessmentId]);

    const handleInviteCandidates = () => {
        setShowPopup(true); // Show popup when "Invite candidates" button is clicked
    };

    const handleTabClick = (tabNumber) => {
        setCurrentTab(tabNumber);
        setCurrentSubTab(1);
    };

    const handleSubTabClick = (subTabNumber) => {
        setCurrentSubTab(subTabNumber);
    };

    const closePopup = () => {
        setShowPopup(false);
    };
    const divStyle = {
        borderBottom: "0.0625rem solid #E0E0E9",
        paddingLeft: "0.625rem",
        paddingRight: "0.625",
    };

    const contentStyle = {
        height: "26rem",
        width: "68rem",
        border: "0.0625rem solid #E0E0E9",
        borderRadius: "0 0 4px 4px",
        marginTop: "-1px",
    };

    return (
        <div className="">
            <div className="flex flex-row mt-[1.875rem]">
                <div className="tabs mt-[1.875rem] flex gap-[3.125rem] " style={divStyle}>
                    <button onClick={() => handleTabClick(1)}
                            style={{borderBottom: currentTab === 1 ? "0.0625rem solid #1D4EDE" : null, 
                                    color: currentTab === 1 ? "#1D4EDE" : "black" }}
                    >Skill assessment</button>
                    <button onClick={() => handleTabClick(2)}
                            style={{borderBottom: currentTab === 2 ? "0.0625rem solid #1D4EDE" : null, 
                                    color: currentTab === 2 ? "#1D4EDE" : "black" }}
                    >Asynchronous video interview</button>
                    <button onClick={() => handleTabClick(3)}
                            style={{borderBottom: currentTab === 3 ? "0.0625rem solid #1D4EDE" : null, 
                                    color: currentTab === 3 ? "#1D4EDE" : "black" }}
                    >Online interview</button>
                </div>
                <div className="ml-[19.75rem] mt-[1rem]">
                    <Button className="text-base font-normal font-['Inter']" 
                            label="Invite candidates"
                            width="11.125rem"
                            onClick={handleInviteCandidates}
                    ></Button>
                </div>
            </div>
            <div className="w-[63.188rem] tab-content mt-[3.75rem]">
                {currentTab === 1 && (
                    <div>
                        <div className="sub-tabs flex gap-[0.1rem]">
                            <button onClick={() => handleSubTabClick(1)}
                                    style={{
                                        background: currentSubTab === 1 ? "white" : "#1D4EDE",
                                        color: currentSubTab === 1 ? "#1D4EDE" : "white",
                                        width: "9.5rem",
                                        height: "2.188rem",
                                        borderRadius: `0.25rem 0.25rem ${currentSubTab === 1 ? "0rem" : "0.25rem"} ${currentSubTab === 1 ? "0rem" : "0.25rem"}`,                                        padding: "0 2.188rem",
                                        border: currentSubTab === 1 ? "0.0625rem solid #E0E0E9" : "none",
                                        borderBottom: currentSubTab === 1 ? "none" : "0.0625rem solid transparent",
                                    }}
                            >Candidates</button>
                            <button onClick={() => handleSubTabClick(2)}
                                    style={{
                                        background: currentSubTab === 2 ? "white" : "#1D4EDE",
                                        color: currentSubTab === 2 ? "#1D4EDE" : "white",
                                        width: "9.5rem",
                                        height: "2.188rem",
                                        borderRadius: `0.25rem 0.025rem ${currentSubTab === 2 ? "0rem" : "0.25rem"} ${currentSubTab === 2 ? "0rem" : "0.25rem"}`,                                        padding: "0 2.188rem",
                                        border: currentSubTab === 2 ? "0.0625rem solid #E0E0E9" : "none",
                                        borderBottom: currentSubTab === 2 ? "none" : "1px solid transparent",
                                    }}
                            >Questions</button>
                        </div>
                        {currentSubTab === 1 && (
                            <div className="sub-tab-content" style={contentStyle}>

                                {/* <div style={centerContent}>                                  
                                    <img src={jumpingdeer} alt="Deer jumping"/>
                                    <p>Invite candidates to take your test and you'll see a breakdown of their performance across skills, time management and their attempts during the test</p>
                                </div> */}
                            
                                <DataTable width="61.938rem"/>

                            </div>
                        )}
                        {currentSubTab === 2 && (
                            <div className="sub-tab-content" style={contentStyle}>
                               subTab 2 content
                            </div>
                        )}
                    </div>
                )}
                {currentTab === 2 && (
                    <div>
                        Fill contents for async video interview
                    </div>
                )}
                {currentTab === 3 && (
                    <div>
                        Fill contents for online interview
                    </div>
                )}
            </div>
            {showPopup && (
                <InviteCandidates onClose={()=>setShowPopup(false)}
                />
            )}
        </div>
    );
}

export default CandidateList;

