import React from "react";

const McqOption = ({label, disabled, isSelected, isCorrect, onChange, optionId, className}) => (
    <div
        className={`w-[100%] bg-white p-4 rounded border-solid border-[1.5px] flex items-center ${isCorrect ? "bg-green-100 text-green-700" : isSelected ? "bg-blue-100 text-blue-700" : ""}`}>
        <input
            type="radio"
            name="mcq"
            disabled={disabled}
            checked={isSelected}
            onChange={() => onChange(optionId)}
            className="mr-2"
        />
        <span dangerouslySetInnerHTML={{__html: label}} className='overflow-x-scroll'                    
                     style={{
                        scrollbarWidth: "none", /* Firefox */
                        msOverflowStyle: "none", /* IE and Edge */
                        "&::-webkit-scrollbar": { display: "none" }, /* Chrome, Safari, Opera */
                    }}/>
        <style>{`
      input[type="radio"]:checked{
        color: #1b9b85;
      }
      input[type="radio"]:focus {
        outline-color: white;
      }
    `}</style>
    </div>
);

export default McqOption;