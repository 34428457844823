import React, {useEffect, useState} from "react";
import UserManagement from "./settings/UserManagement/UserManagement";
import {useLocation, useNavigate} from "react-router-dom";
import Profile from "../DashBoard/settings/profile";
import CreatePositions from "./Jobs/createPositions";
import BillingInfo from "./company/billingInfo";
import CompanyProfile from "./company/companyProfile";
import SideBar from "../../sidebar/SideBar";
import TopBar from "../../Topbar";
import Position from "./Jobs/positionsList";
import PositionDetails from "./Jobs/PositionDetails";
import AddQuestionPage from "./Jobs/AddQuestionPage";
import CandidateList from "./Jobs/CandidateList";
import Home from "./home/home";
import CandidateCode from "../writtenTest/candidateComponents/CandidateCode";
import QuestionDetailsPage from "../../components/SlidingLayouts/AsyncQuestionDetails";
import BuyCredits from "./company/buyCredits";
import Templates from "./templates/templates";
import BulkAddQuestions from "./Jobs/BulkAddQuestions";

function DashBoard() {
    const location = useLocation();
    const [heading, setHeading] = useState("Invite your team");
    const accessToken = localStorage.getItem("accessToken");

    const navigate = useNavigate();

    useEffect(() => {
        const {pathname} = location;

        switch (pathname) {
            case "/":
                setHeading("Home");
                break;
            case "/home":
                setHeading("Home");
                break;
            case "/company/userManagement":
                setHeading("Invite your team");
                break;
            case "/settings/profile":
                setHeading("Edit your personal settings");
                break;
            case "/company/billingInfo":
                setHeading("Billing Info");
                break;
            case "/jobs/position":
                setHeading("All Positions");
                break;
            // case "/jobs/position/videointerviewquestions":
            //     setHeading("Video Interview Questions");
            //     break;
            case "/jobs/candidateList":
                setHeading("Candidate List");
                break;
            case "/jobs/create":
                setHeading("Create Position");
                break;
            case "/jobs/position/AddQuestionPage":
                setHeading("Add Question");
                break;
            case "/company/companyProfile":
                setHeading("Company Profile");
                break;
            case "/company/buyCredits":
                setHeading("Buy Credits");
                break;
            case "/logout":
                setHeading("Logout")
                break;
            case "/jobs/positionDetails":
                setHeading("Position Details");
                break;
            case "/help/templates":
                setHeading("Templates");
                break;
            default:
                setHeading("Dashboard");
        }
    }, [location]); // Only re-run the effect if location changes

    async function handleLogoutClick() {
        try {
            console.log("Access token:", accessToken);
            console.log(`Bearer ${accessToken}`);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/logout`, // Assuming this is the correct endpoint for logout
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (response.ok) {
                // Clear local storage and navigate to login page
                localStorage.removeItem("accessToken");
                localStorage.removeItem("id");
                localStorage.removeItem("email");
                localStorage.removeItem("name");
                navigate("/login");
            }
        } catch (error) {
            console.error("Network error:", error);
        }
    }

    const renderPage = () => {
        const {pathname} = location;

        switch (pathname) {
            case "/home":
                return <Home/>;
            case "/":
                return <Home/>;
            case "/candidateCode":
                return <CandidateCode/>;
            case "/company/userManagement":
                return <UserManagement/>;
            case "/settings/profile":
                return <Profile/>;
            case "/company/billingInfo":
                return <BillingInfo/>;
            case "/jobs/position":
                return <Position/>;
            // case "/jobs/position/videointerviewquestions":
            //     return <VideoInterviewQuestions/>;
            case "/question-details":
                return <QuestionDetailsPage/>;
            case "/jobs/candidateList":
                return <CandidateList/>;
            case "/jobs/positionDetails":
                return <PositionDetails/>;
            case "/jobs/position/AddQuestionPage":
                return <AddQuestionPage/>;
            case "/company/companyProfile":
                return <CompanyProfile/>;
            case "/company/buyCredits":
                return <BuyCredits/>;
            case "/bulkAddQuestions":
                return <BulkAddQuestions/>;
            case "/logout":
                // Call handleLogoutClick and return null while waiting for the logout process
                handleLogoutClick();
                return null;
            case "/jobs/create":
                return <CreatePositions/>;
            case "/help/templates":
                return <Templates/>;
            // ... other cases
            default:
                return null;
        }
    };

    return (
        <>
            <div className="flex fixed w-[100%]">
                <div className="w-1/5 h-screen px-4 shadow py-9 overflow-y-auto">
                    <SideBar/>
                </div>
                <div className="w-4/5 h-16 p-5 border shadow border-zinc-100">
                    <TopBar heading={heading}/>
                    <div className="flex flex-col p-8 justify-evenly">{renderPage()}</div>
                </div>
            </div>
        </>
    );
}

export default DashBoard;  