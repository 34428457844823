// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.validation-message {
    max-width: 20rem; /* Match the width of the textboxes */
    white-space: pre-wrap; /* Allows line breaks within the message */
    word-wrap: break-word; /* Break long words to fit within the container */
    color: red; /* Optional: change to your desired color */
    font-size: 0.875rem; /* Adjust font size as needed */
    margin-top: 0.25rem; /* Add some space above the message */
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/forgot_password/error.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB,EAAE,qCAAqC;IACvD,qBAAqB,EAAE,0CAA0C;IACjE,qBAAqB,EAAE,iDAAiD;IACxE,UAAU,EAAE,2CAA2C;IACvD,mBAAmB,EAAE,+BAA+B;IACpD,mBAAmB,EAAE,qCAAqC;AAC9D","sourcesContent":[".validation-message {\n    max-width: 20rem; /* Match the width of the textboxes */\n    white-space: pre-wrap; /* Allows line breaks within the message */\n    word-wrap: break-word; /* Break long words to fit within the container */\n    color: red; /* Optional: change to your desired color */\n    font-size: 0.875rem; /* Adjust font size as needed */\n    margin-top: 0.25rem; /* Add some space above the message */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
