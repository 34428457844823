import { SET_POSITION_ID } from "../actions/positionDetailsActions";
import { SET_POSITION_STATUS_ID } from "../actions/positionDetailsActions";

const initialState = {
    positionId: 0 // Changed from positionsId to positionId for clarity
};

export const positionDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POSITION_ID:
            return {
                ...state,
                positionId: action.payload // Ensure this matches what you're sending from the action creator
            };
        default:
            return state;
    }
}

export const positionStatusReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_POSITION_STATUS_ID:
            return {
                ...state,
                positionStatus: action.payload // Ensure this matches what you're sending from the action creator
            };
        default:
            return state;
    }
}