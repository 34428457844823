import React, {useEffect, useRef, useState} from 'react';
import {Button} from "../../components/button";
import {useNavigate} from "react-router-dom";
import {FormValidator} from '@syncfusion/ej2-react-inputs';
import PrefixedInput from '../../components/prefixedInput';
import hello from '../../../assets/Hello.svg'
import logo from '../../../assets/logo_ovii_2.svg';
import {useDispatch, useSelector} from "react-redux";
import Modal from '../../components/popup';
import {loginUser} from "../../../redux/actions/loginActions";

const TeamCreation = () => {
    const [website, setWebsite] = useState('');
    const navigate = useNavigate();
    const formRef = useRef(null);
    const validatorRef = useRef(null);
    const [teamId, setTeamId] = useState();
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const adminEmail = useSelector(state => state.team.adminEmail);
    const [isLoading, setIsLoading] = useState(false)
    const isSiteRef = useSelector(state => state.urlReducer.fromSite)

    const org = adminEmail.substring(adminEmail.indexOf('@') + 1, adminEmail.indexOf('.'));


    const isValidTeam = (value) => {
        const team = /^[A-Za-z0-9\\-]+$/
        return team.test(value.value)
    }

    const accessToken = localStorage.getItem('accessToken')
    const userId = localStorage.getItem('userId')

    useEffect(() => {
        // Initialize the FormValidator and assign it to validatorRef
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                rules: {
                    'team-input': {
                        required: [true, 'Team name is required'],
                        minLength: [3, 'Minimum length is 3 characters.'],
                        maxLength: [50, 'Maximum length is 50 characters.'],
                        isValidTeam: [isValidTeam, 'Only uppercase and lowercase letters, numbers, and - are allowed.']
                    },
                },
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
            });
        }
    }, [validatorRef]);

    const fetchTeamId = async () => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${userId}`;

        try {
            const response = await fetch(url, {
                method: 'GET',
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            return data.data.teamId;
        } catch (error) {
            setModalContent({
                heading: 'Error',
                message: "Team ID not available.",
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        }
    };


    /**
     * Changes the name of a team.
     *
     * @param {string} teamId - The ID of the team to change the name for.
     * @param {string} teamName - The new name for the team.
     * @returns {Promise<void>} - A promise that resolves when the team name has been changed successfully, or rejects with an error if there was an issue.
     */
    const changeTeamName = async (teamId, teamName) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/changeTeamName?teamId=${teamId}&teamName=${website}`;

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
        } catch (error) {
            setModalContent({
                heading: 'Error',
                message: "Failed to change team name.",
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        } finally {
            setIsLoading(false)
        }
    };

    const dispatch = useDispatch();
    const email = useSelector(state => state.user.email);
    const password = useSelector(state => state.user.password);

    function handleSubmit(e) {
        e.preventDefault();
        if (validatorRef.current && validatorRef.current.validate()) {
            setIsLoading(true)
            fetchTeamId().then(teamId => {
                if (teamId != null) {
                    setIsLoading(false)
                    changeTeamName(teamId, website);
                    const credentials = {email: email.trim(), password: password};

                    // Dispatch the loginUser action
                    dispatch(
                        loginUser(
                            credentials,
                            () => {
                                // Navigation logic inside the completion callback
                                if (isSiteRef === true) {
                                    navigate('/company/buyCredits')
                                } else {
                                    navigate('/home')
                                }
                            },
                            (error) => {
                                alert(`Login failed: ${error.message}`);
                            }
                        )
                    );
                    // navigate('/home');
                } else {
                    setIsLoading(false)
                    setModalContent({
                        heading: 'Error',
                        message: "Failed to fetch team ID",
                        confirmButtonText: 'OK',
                    });
                    setShowModal(true);
                }
            });
        }
    }

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    function handleLoginClick() {
        navigate('/login');
    }

    return (
        <div className="max-h max-w">
            <div className="flex flex-col items-center justify-center">
                <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mt-[8.875rem]"/>
                <p className='font-serif text-[1.75rem] font-medium tracking-[0.035rem] text-charcoal mt-[1.625rem]'>New
                    team creation</p>
                <p className='px-[0.5rem] w-[30%] font-serif text-[1.125rem] font-light tracking-[0.36px] text-charcoal mt-[1.625rem] text-center leading-tight'>An
                    account for this organization already exists under the user {adminEmail}. You can request them
                    to add you to their team, or alternatively, you can create a new team.</p>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <div className="w-[24.616rem] mt-[2.4rem]">
                        <PrefixedInput
                            label={(
                                <label className="flex items-center">
                                    Team Name
                                    <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                </label>
                            )}
                            prefix={org + '/'}
                            value={website}
                            setValue={setWebsite}
                            id="team-input"
                            name="team-input"
                            placeholder="Your team name"
                        />
                    </div>
                    <div className='flex justify-center items-center mt-[1.5rem]'>
                        <Button label={isLoading ? "LOADING..." : "CREATE"} type="submit" variant='bright-blue'
                                className="text-[0.735rem] h-[2.25rem]" width="18.625rem"/>
                    </div>
                    <Modal isOpen={showModal}
                           onClose={() => setShowModal(false)}
                           onConfirm={handleModalConfirm}
                           heading={modalContent.heading}
                           message={modalContent.message}
                           confirmButtonText={modalContent.confirmButtonText}
                    />
                </form>
                <div>
                    <span className="loginOption">Already have an account? </span>
                    <span className='cursor-pointer text-blue loginOptionLlink' onClick={handleLoginClick}
                          tabIndex={0}>Login</span>
                </div>
            </div>
            {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
            <img src={hello}
                 alt="man saying hello"
                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    );
};

export default TeamCreation;
