import React, {useEffect, useMemo, useState} from "react";
import InviteUsers from "./InviteUsers";
import UserProfile from "../../../../../assets/userProfile.svg";
import DeleteIcon from "../../../../../assets/trash.svg";
import Table from "../../../../components/Table";
import {
    deleteUser,
    fetchInvitations,
    fetchTeamId,
    getUsersList,
    resendInvite
} from "../../../../../redux/actions/userManagement";
import {formatDate} from "../../../../utilities/utilFunctions";
import {useDispatch, useSelector} from "react-redux";
import LoadingOverlay from "../../../../components/loadingOverlay";
import Modal from "../../../../components/popup";

function UserManagement() {
    const accessToken = localStorage.getItem("accessToken");
    const usersList = useSelector((state) => state.userManagement.usersList);
    const invitationsList = useSelector((state) => state.userManagement.invitationsList);
    const teamId = useSelector((state) => state.userManagement.teamId);
    const userId = localStorage.getItem("id");
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const dispatch = useDispatch();

    useEffect(() => {
        setIsLoading(true);
        dispatch(fetchTeamId({userId: userId, accessToken: accessToken})).then(() => {
            setIsLoading(false);
        });
    }, [dispatch, userId, accessToken]);

    useEffect(() => {
        if (teamId) {
            setIsLoading(true);
            dispatch(getUsersList({teamId: teamId, accessToken})).then(() => setIsLoading(false));
            dispatch(fetchInvitations({teamId: teamId, accessToken})).then(() => setIsLoading(false));
        }
    }, [teamId, dispatch, accessToken]);

    const combinedList = useMemo(() => {
        return [
            ...(usersList?.usersList || []),
            ...(invitationsList?.map(invite => ({
                email: invite.email,
                role: "Invited",
                joinDate: invite.invitationDate,
                invitationId: invite.invitationId,
            })) || [])
        ];
    }, [usersList, invitationsList]);

    const getRoleClassName = (role) => {
        switch (role) {
            case "ORG_ADMIN":
                return "bg-[#F3F1FD] text-[#4A2AB3]";
            case "Invited":
                return "bg-[#FFF0D9] text-[#BF7508]";
            default:
                return "bg-[#FDF0F5] text-[#E0583F]";
        }
    };

    const tableDetail = {
        columns: ["Email", "Status", "Invited Date", "Resend", "Action"],
        rows: combinedList.map((item) => ({
            cells: [
                {text: item.email, icon: UserProfile},
                {
                    text: item.role === "ORG_ADMIN" ? "Org Admin" : item.role[0].toUpperCase() + item.role.slice(1).toLowerCase(),
                    className: `mt-3 pl-1 -ml-2 w-[76px] h-[21px] rounded-[2px] text-xs font-bold flex items-center justify-center ${getRoleClassName(item.role)}`
                },
                {text: formatDate(item.joinDate), className: "justify-center"},
                {
                    text: "Resend",
                    className: "justify-center cursor-pointer",
                    onClick: () => handleResendInvite(item.invitationId)
                },
                {
                    icon: DeleteIcon,
                    className: "justify-center w-[1.25rem] cursor-pointer",
                    onClick: (e) => handleModalContent(item, e)
                },
            ],
        })),
    };

    const handleModalContent = (item, e) => {
        if (e) e.stopPropagation();
        const actions = {
            "Invited": () => {
                setModalContent({
                    heading: 'Attention',
                    message: `Cannot remove invited user`,
                    confirmButtonText: 'OK',
                    onConfirm: (ev) => {
                        if (ev) ev.preventDefault();  // Prevents the default form submit behavior
                        setShowModal(false);
                    }
                });
            },
            "ORG_ADMIN": () => {
                setModalContent({
                    heading: 'Attention',
                    message: `Cannot remove Organisation Admin`,
                    confirmButtonText: 'OK',
                    onConfirm: (ev) => {
                        if (ev) ev.preventDefault();  // Prevents the default form submit behavior
                        setShowModal(false);
                    }
                });
            },
            "default": () => {
                setModalContent({
                    heading: 'Delete User',
                    message: `Are you sure you want to remove ${item.email}?`,
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Delete',
                    onConfirm: (ev) => {
                        if (ev) ev.preventDefault();  // Prevents the default form submit behavior
                        handleDelete(item);
                    },
                });
            }
        };
        (actions[item.role] || actions["default"])();
        setShowModal(true);
    };

    const handleResendInvite = (inviteId) => {
        setIsLoading(true);
        dispatch(resendInvite({accessToken, inviteId})).then(() => setIsLoading(false));
    };

    const handleDelete = (item) => {
        setIsLoading(true);
        dispatch(deleteUser({accessToken, teamId, userId: item.userId})).then(() => {
            dispatch(getUsersList({teamId, accessToken}));
            dispatch(fetchInvitations({teamId, accessToken}));
            setIsLoading(false);
            setShowModal(false);
        });
    };

    return (
        <div className="overflow-y-scroll h-[80%]"
        style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        }}>
            <style jsx>{`
                .scrollbar-hide::-webkit-scrollbar {
                    width: 0px;
                    background: transparent; /* Chrome/Safari/Webkit */
                }

                .scrollbar-hide:hover::-webkit-scrollbar {
                    width: 12px;
                }

                .scrollbar-hide::-webkit-scrollbar-thumb {
                    background: rgba(0, 0, 0, 0.5);
                }

                .scrollbar-hide::-webkit-scrollbar-track {
                    background: rgba(0, 0, 0, 0.1);
                }
            `}</style>
            {isLoading && <LoadingOverlay/>}
            {teamId && <InviteUsers accessToken={accessToken}/>}
            <Table className="z-0" data={tableDetail} tabindex="0"/>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onCancel={() => setShowModal(false)}
                onConfirm={modalContent.onConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
                showCancelButton={modalContent.heading === "Delete User"}
                cancelButtonText={modalContent.cancelButtonText}
            />
        </div>
    );
}

export default UserManagement;
