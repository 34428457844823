// // features/mcqAnswers/mcqAnswersSlice.js
// import { createSlice } from '@reduxjs/toolkit';

// export const mcqAnswersSlice = createSlice({
//     name: 'mcqAnswers',
//     initialState: {},
//     reducers: {
//         setMcqAnswer: (state, action) => {
//             const { questionId, selectedOptionId } = action.payload;
//             state[questionId] = selectedOptionId;
//         },
//         removeMcqAnswer: (state, action) => {
//             const { questionId } = action.payload;
//             delete state[questionId];
//         },
//     },
// });

// export const { setMcqAnswer, removeMcqAnswer } = mcqAnswersSlice.actions;

// export default mcqAnswersSlice.reducer; 

// mcqAnswersSlice.js

import { createSlice } from '@reduxjs/toolkit';

export const mcqAnswersSlice = createSlice({
  name: 'mcqAnswers',
  initialState: {},
  reducers: {
    setMcqAnswer: (state, action) => {
      const { questionId, selectedOptionId } = action.payload;
      state[questionId] = selectedOptionId;
    },
    removeMcqAnswer: (state, action) => {
      const { questionId } = action.payload;
      delete state[questionId];
    },
    clearMcqAnswers: (state) => {
      return {}; // Reset MCQ answers to empty object
    },
  },
});

export const { setMcqAnswer, removeMcqAnswer, clearMcqAnswers } = mcqAnswersSlice.actions;

export default mcqAnswersSlice.reducer;

 