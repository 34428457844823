import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {SIDEBAR_CONFIG} from "../../constants/sidebar";
import Icons from "../../assets/sidebar-icons";
import ChevronUp from "../../assets/chevronUp.svg";
import ChevronDown from "../../assets/chevronDown.svg";

const Sidebar = React.memo(({
                                sidebarConfig, onSkillChange = () => {
    }
                            }) => {
    const location = useLocation();
    const [openDropdowns, setOpenDropdowns] = useState([]);

    const toggleDropdown = (itemKey) => {
        setOpenDropdowns((prevOpenDropdowns) =>
            Array.isArray(prevOpenDropdowns) && prevOpenDropdowns.includes(itemKey)
                ? prevOpenDropdowns.filter((key) => key !== itemKey)
                : [itemKey] // Close all other dropdowns and open the current one
        );
    };

    const config = sidebarConfig ? sidebarConfig : SIDEBAR_CONFIG;

    const handleItemClick = (item) => {
        if (item.label === "Help center" || item.label === "Terms of use") {
            window.open(item.route, "_blank");
        } else if (item.hasChildren) {
            toggleDropdown(item.key);
        } else {
            onSkillChange(item.id);
        }
    };

    const handleChevronClick = (itemKey) => {
        toggleDropdown(itemKey);
    };

    return (
        <>
            <ul className="flex flex-col gap-2">
                {config?.map((item) => (
                    <li
                        key={item.key}
                        className={`${location.pathname === item.route ? "font-extrabold" : ""}`}
                    >
                        <div
                            className="flex items-start justify-between font-medium cursor-pointer"
                            onClick={() => handleItemClick(item)}
                        >
                            <div className="flex items-center gap-3 flex-grow">
                                {item.icon && (
                                    <img src={Icons[item.key]} alt={item.key} className="w-5 h-5 "/>
                                )}
                                {item.label === "Help center" || item.label === "Terms of use" ? (
                                    <a href={item.route} target="_blank" rel="noopener noreferrer">
                                        <p className="font-bold">{item.label}</p>
                                    </a>
                                ) : (
                                    <Link to={item.route}>
                                        <p className="font-bold">{item.label}</p>
                                    </Link>
                                )}
                            </div>
                            {item.hasChildren && (
                                <div
                                    className="cursor-pointer"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleChevronClick(item.key);
                                    }}
                                >
                                    <img
                                        src={openDropdowns.includes(item.key) ? ChevronUp : ChevronDown}
                                        alt=""
                                        className="cursor-pointer"
                                    />
                                </div>
                            )}
                        </div>
                        {item.hasChildren && openDropdowns.includes(item.key) && (
                            <ul className="flex flex-col gap-3 my-3">
                                {item.items.map((child) => (
                                    <li
                                        key={child.key}
                                        className={`${
                                            location?.pathname === child.route
                                                ? "bg-[#ededed] p-1 rounded-[3px]"
                                                : "ml-8"
                                        }`}
                                        onClick={(e) => {
                                            onSkillChange(child.id);
                                        }}
                                    >
                                        {child.label === "Help center" || child.label === "Terms of use" ? (
                                            <a href={child.route} target="_blank" rel="noopener noreferrer">
                                                {child.icon && <i className={child.icon}></i>}
                                                <p
                                                    className={`text-base font-thin ${
                                                        location.pathname === child.route
                                                            ? "ml-8 text-[#595959]"
                                                            : ""
                                                    }`}
                                                >
                                                    {child.label}
                                                </p>
                                            </a>
                                        ) : (
                                            <Link to={child.route}>
                                                {child.icon && <i className={child.icon}></i>}
                                                <p
                                                    className={`text-base font-thin ${
                                                        location.pathname === child.route
                                                            ? "ml-8 text-[#595959]"
                                                            : ""
                                                    }`}
                                                >
                                                    {child.label}
                                                </p>
                                            </Link>
                                        )}
                                    </li>
                                ))}
                            </ul>
                        )}
                    </li>
                ))}
            </ul>
        </>
    );
});

export default Sidebar;
