import React from 'react'

function LineTabs({tabConfig, handleTabChange}) {
    return (
        <div className='flex gap-8 items-center border-b w-fit border-gray-200'>
            {
                tabConfig.map((tab) =>
                    <div key={tab?.key} onClick={() => handleTabChange(tab?.key)}
                         className={`${tab?.isActive ? "text-blue-700 border-b-2 border-b-blue" : ""} text-[1rem] pb-2 cursor-pointer font-regular`}>{tab.tabName}</div>
                )
            }
        </div>
    )
}

export default LineTabs
