import React, {useEffect, useRef, useState} from 'react';
import {Button} from '../../../components/button';
import Badge from "../../../components/Badge";
import logo from "../../../../assets/logo_ovii_2.svg"
import QuillRTE from '../../../components/Editor/QuillRichTextEditor';


const SubjectiveQuestions = () => {
    const question = JSON.parse(localStorage.getItem("selectedQuestion"));
    console.log(question)
    const answer = question.scoreResponse.answer.text;
    const problem = question.questionResponse.questionDataAsJson.problemStatement;
    const score = question.scoreResponse.score;
    const [showSlider, setShowSlider] = useState(false);
    const popupRef = useRef(null);
    const [isAddEvalExpanded, setIsAddEvalExpanded] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowSlider(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleOpen = () => {
        setShowSlider(true);
    };

    const handleClose = () => {
        setShowSlider(false);
    };

    const [expandedFactors, setExpandedFactors] = useState({});
    const [isScoreExpanded, setScoreExpanded] = useState(false)

    const toggleFactor = (factor) => {
        setExpandedFactors(prevFactor => (prevFactor === factor ? null : factor))
    };

    const renderAdditionalEvaluation = (additionalEval) => {
        return Object.entries(additionalEval).map(([evalKey, evalValue]) => (
            <div key={evalKey} className="border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4"
                 style={{'fontFamily': 'helvetica'}}>
                <div onClick={() => toggleFactor(evalKey)} className='flex justify-between'>
                    <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>{evalKey.replace(/([A-Z])/g, ' $1').trim()}</h3>
                    <p className='text-[#000] font-bold'>+</p>
                </div>
                {expandedFactors === evalKey && (
                    <div>
                        {Object.entries(evalValue).map(([key, value]) => (
                            <p className="text-[0.95rem] tracking-[0.1px] mt-2 text-text-charcoal"
                               style={{'fontFamily': 'helvetica'}}>
                                {key.replace(/([A-Z])/g, ' $1').trim()}: {value}
                            </p>
                        ))}
                    </div>
                )}
            </div>
        ));
    };

    return (
        <div>
            <div className="flex justify-center w-full border items-center py-3 shadow-sm">
                <div className="w-[90%] flex justify-between items-center" style={{marginBottom: 0}}>
                    <div className="text-2xl font-bold" style={{fontFamily: "ITC Clearface"}}>
                        {/*<h1>Answer details</h1>*/}
                    </div>
                    <div className="flex items-center justify-end" style={{marginBottom: 0}}>
                        <img src={logo} alt="logo" className="h-6 w-12"/>
                    </div>
                </div>
            </div>
            <div className="mx-[5%] mt-[3%] max-w-[90vw] min-w-[90vw] px-auto">
                <div
                    className="h-[80vh] mx-auto rounded-lg shadow px-[3.5rem] py-[1rem]">
                    <div>
                        <div className='text-left'>
                            <h3 className='text-3xl font-serif'>Problem Statement</h3>
                            <p className='mt-2 text-[1rem] font-inter'
                               dangerouslySetInnerHTML={{__html: problem}}/>
                        </div>
                        <div className='pt-[4.2%]'>
                            <QuillRTE
                                id="problemStatement"
                                width="100%" //33rem
                                height="46vh"
                                className='z-0 max-h-[47vh] min-h-[40vh] overflow-auto'
                                placeholder={answer}
                                value={answer}/>
                        </div>
                    </div>
                    <div className='mt-6 flex justify-end'>
                        <Button label="View details"
                                width="15rem"
                                onClick={handleOpen}
                                variant="bright-blue"
                        />
                    </div>
                </div>
                {/*<div className='z-0 fixed bottom-0 left-0 w-full text-center'>*/}
                {/*    <p>Powered by<img className="w-10 h-10 inline pl-2 mb-1" src={logo}></img></p>*/}
                {/*</div>*/}
                {showSlider && (
                    <div className="fixed inset-0 z-50 shadow-lg bg-opacity-75">
                        <div ref={popupRef}
                             className="absolute top-0 right-0 h-full w-[50%] bg-white transition-all duration-700 overflow-y-auto max-w-[50%] overflow-x-auto shadow-lg">
                            <div className="">
                                <div>
                                    <div
                                        className="flex items-center justify-between w-full p-0 m-0 border-b-1 border-textBoxBorders shadow px-7 py-4"
                                        style={{'marginBottom': 0}}
                                    >
                                        <h2 className="text-2xl font-serif">Answer
                                            details <span className='text-blue text-xl'>{score}</span></h2>
                                        <button
                                            // onClick={onClose}
                                            className="fixed top-4 right-4 w-6 h-6 p-1 m-0 text-xs text-white bg-blue rounded-full"
                                            onClick={handleClose}
                                        >
                                            X{/* TODO: Use an icon */}
                                        </button>
                                    </div>
                                </div>
                                <div className='px-8'>
                                    <div className='flex justify-start items-center gap-2 mt-8'>
                                        {question.questionResponse.questionDataAsJson.tags.split(',').map((badge) => (
                                            <Badge key={badge.trim()} label={badge.trim()} variant="blue"/>
                                        ))}
                                    </div>
                                    <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">
                                        Problem statement:
                                    </p>
                                    <p className="py-[0.75%]" dangerouslySetInnerHTML={{__html: problem}}/>
                                    {/*<div className='flex flex-row justify-between'>*/}
                                    {/*    <h3 className="font-serif text-[1rem] text-m font-bold tracking-[0.1px]">Score: {score}</h3>*/}
                                    {/*    /!*<p className='text-[#000] font-extrabold mr-2'>+</p>*!/*/}
                                    {/*</div>*/}
                                    {/*<Badge label={`Score:${score}`} variant="blue"/>*/}
                                    {/*<div className="mt-4 border border-gray-300 rounded p-2 " onClick={() => {*/}
                                    {/*    setScoreExpanded(!isScoreExpanded)*/}
                                    {/*}}>*/}

                                    {/*    {isScoreExpanded && (*/}
                                    {/*        <p className="text-sm">{score}</p>*/}
                                    {/*    )}*/}
                                    {/*</div>*/}
                                    <div className="mt-4">
                                        {renderAdditionalEvaluation(question.scoreResponse.chatGPTScore['Additional Evaluation'])}
                                    </div>
                                    {/*<div className="flex justify-start mt-[2.7%] mb-[1.3%]">*/}
                                    {/*    <Button label="Close"*/}
                                    {/*            width="19.7%"*/}
                                    {/*            onClick={handleClose}*/}
                                    {/*            className="cursor-pointer"*/}
                                    {/*            variant="bright-blue"*/}
                                    {/*    />*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default SubjectiveQuestions;