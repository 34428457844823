import React, {useEffect, useReducer, useState} from "react";
import Webcam from "./Webcam";
import logo from "../../../assets/logo_ovii_2.svg"
import {Button} from "../../components/button";

const Phases = Object.freeze({
    Undef: -1, Think: 0, Answer: 1, Complete: 2
});

function formatTime(time) {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
}

function timeReducer(state, action) {
    switch (action.type) {
        case "tick":
            if (state.time === 0) {
                if (state.phase === Phases.Think) {
                    return {...state, phase: Phases.Answer, time: action.timeLimit * 60};
                }
                if (state.phase === Phases.Answer) {
                    return {...state, phase: Phases.Complete, time: 0, complete: true};
                }
            }
            return {...state, time: state.time - 1};

        case "skip-phase":
            if (state.phase === Phases.Think) {
                return {...state, phase: Phases.Answer, time: action.timeLimit * 60};
            }
            if (state.phase === Phases.Answer) {
                return {...state, phase: Phases.Complete, time: 0, complete: true};
            }
            return state;

        default:
            return state;
    }
}

function CustomButton(props) {
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        setDisabled(props.disabled);
        if (props.disabled) {
            const timer = setTimeout(() => {
                setDisabled(false); // Enable the button after 5 seconds
            }, 5000);
            return () => clearTimeout(timer);
        }
    }, [props.disabled]);

    const variant = props.phase === Phases.Think ? "bright-blue" : "bright-red";
    return (
        <Button variant={variant} onClick={props.onClick} width="10rem" className={"p-2"} disabled={disabled}>
            {props.children}
        </Button>
    );
}

export default function VideoInterview(props) {
    const [storedDecodedLogo, setStoredDecodedLogo] = useState(null);
    const [disableStopRecording, setDisableStopRecording] = useState(true);
    const [timeAvailable, dispatch] = useReducer(timeReducer, {
        time: props.question.thinkTime * 60, // Convert minutes to seconds initially
        phase: Phases.Think,
        complete: false,
    });


    useEffect(() => {
        const interval = setInterval(() => {
            dispatch({type: "tick", timeLimit: props.question.timeLimit});
        }, 1000);
        return () => clearInterval(interval);
    }, [props.question.timeLimit]);

    const shouldRecord = timeAvailable.phase === Phases.Answer && !timeAvailable.complete;

    const saveVideo = async (recorded) => {
        console.log("Saving video");
        const blob = new Blob(recorded.blobChunks, {type: recorded.mimeType});
        props.submit({video: blob});
    };

    const skipPhase = () => {
        dispatch({type: "skip-phase", timeLimit: props.question.timeLimit});
    };

    useEffect(() => {
        const storedDecodedLogo = localStorage.getItem("decodedLogo");
        setStoredDecodedLogo(storedDecodedLogo);
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            setDisableStopRecording(false); // Enable the stop recording button after 5 seconds
        }, 5000);

        return () => clearTimeout(timer);
    }, [Phases]);

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault()
            const confirmationMessage = "You are prevented from navigating away"
            alert(confirmationMessage)
        }

        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', handleBackButton)

        return () => {
            window.removeEventListener('popstate', handleBackButton)
        }
    }, [])

    return (
        <div className="max-w">
            <div className="flex justify-center w-full mt-[1.5rem]">
                {typeof storedDecodedLogo === 'string' && storedDecodedLogo.startsWith('img:') ? (
                    <img
                        src={`data:image/;base64,${storedDecodedLogo.substring(4)}`}
                        alt="ovii logo"
                        className="m-[0.5rem] w-[5rem]"
                    />
                ) : (
                    <p className="p-[0.5rem] text-[1.75rem] text-labels-black" style={{'fontFamily': 'helvetica'}}>
                        {storedDecodedLogo}
                    </p>
                )}
            </div>
            <div className="flex flex-col items-center justify-center mt-[1rem]">
                {/*<div className="flex justify-end w-[80rem] mb-[2rem]">*/}
                {/*    /!*<span*!/*/}
                {/*    /!*    className="text-blue font-ans font-semibold">{timeAvailable.phase === Phases.Think ? 'Think Time' : 'Time Remaining'}</span>: {formatTime(timeAvailable.time)}*!/*/}
                {/*</div>*/}
                <div className="border-[0.5px] shadow-md w-[90vw] h-[70vh] rounded-md gap-4 px-8 mt-4 relative">
                    <div className="flex justify-center items-center h-[90%]">
                        <div className="w-[50%] flex flex-col justify-center">
                            <div className=" overflow-y-scroll max-h-[20vh]"
                                 style={{
                                     scrollbarWidth: "none",
                                     msOverflowStyle: "none",
                                     "&::-webkit-scrollbar": {display: "none"}
                                 }}>
                                <h1 className="font-bold font-serif text-xl break-word mb-[1.25rem]">
                                    {props.question.title}
                                </h1>
                                {/*<p className="text-[#444444] text-sm break-word">{props.question.question}</p>*/}
                            </div>
                            <div className="mt-[1rem]">
                                <CustomButton onClick={skipPhase} phase={timeAvailable.phase}
                                              disabled={disableStopRecording || shouldRecord}>
                                    {timeAvailable.phase === Phases.Think ? 'START ANSWER' : 'STOP RECORDING'}
                                </CustomButton>
                            </div>
                        </div>
                        <div
                            className="w-[50%] h-[auto] rounded-lg border-[8px] border-[#D9E2E6] min-h-[20rem] bg-[#D9E2E5]">
                            <Webcam className="rounded-lg" shouldRecord={shouldRecord} saveRecording={saveVideo}/>
                        </div>
                        {/*{shouldRecord ? () : (*/}
                        {/*    <div className="w-[50%] h-[24rem] rounded-lg border-[4px] bg-black border-[#D9E2E6]"></div>*/}
                        {/*)}*/}
                        {/*<div className="absolute bottom-0 left-0 mb-12 ml-12">*/}
                        {/*    <img src={book} className="w-[50%]"/>*/}
                        {/*</div>*/}
                    </div>
                    <div className="w-full text-center">
                        <span className="text-blue text-sans font-[800] text-[1.15rem] tracking-[0.2px]">
                            {timeAvailable.phase === Phases.Think ? 'Think Time' : 'Time Remaining'}
                        </span>: <span className="text-sans font-[800] text-[1.15rem] tracking-[0.2px]">
                            {formatTime(timeAvailable.time)}
                        </span>
                    </div>
                </div>
            </div>
            <div className="z-0 fixed bottom-0 left-0 w-full text-center">
                <p>
                    Powered by
                    <img className="w-10 h-10 inline pl-2 mb-1"
                         src={logo}
                    ></img>
                </p>
            </div>
        </div>
    );
}