// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For code blocks */
.wyswyg pre {
    background-color: #f6f8fa;
    padding: 16px;
    overflow-x: auto;
}

/* For inline code */
.wyswyg code {
    background-color: #f6f8fa;
    padding: 0.2em 0.4em;
    margin: 0;
    font-size: 85%;
    border-radius: 6px;
}

/* For tables */
.wyswyg table {
    border-collapse: collapse;
    margin-top: 16px;
}

.wyswyg th,
.wyswyg td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
}

/* For bold text */
.wyswyg strong {
    font-weight: bold;
}

/* For headings */
.wyswyg h1 {
    font-size: 2em;
}


`, "",{"version":3,"sources":["webpack://./src/components/mainContent/writtenTest/wyswyg/markdownStyles.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,yBAAyB;IACzB,aAAa;IACb,gBAAgB;AACpB;;AAEA,oBAAoB;AACpB;IACI,yBAAyB;IACzB,oBAAoB;IACpB,SAAS;IACT,cAAc;IACd,kBAAkB;AACtB;;AAEA,eAAe;AACf;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;;IAEI,sBAAsB;IACtB,YAAY;IACZ,gBAAgB;AACpB;;AAEA,kBAAkB;AAClB;IACI,iBAAiB;AACrB;;AAEA,iBAAiB;AACjB;IACI,cAAc;AAClB","sourcesContent":["/* For code blocks */\n.wyswyg pre {\n    background-color: #f6f8fa;\n    padding: 16px;\n    overflow-x: auto;\n}\n\n/* For inline code */\n.wyswyg code {\n    background-color: #f6f8fa;\n    padding: 0.2em 0.4em;\n    margin: 0;\n    font-size: 85%;\n    border-radius: 6px;\n}\n\n/* For tables */\n.wyswyg table {\n    border-collapse: collapse;\n    margin-top: 16px;\n}\n\n.wyswyg th,\n.wyswyg td {\n    border: 1px solid #ddd;\n    padding: 8px;\n    text-align: left;\n}\n\n/* For bold text */\n.wyswyg strong {\n    font-weight: bold;\n}\n\n/* For headings */\n.wyswyg h1 {\n    font-size: 2em;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
