import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/button";
import Editor from "@monaco-editor/react";
import {useDispatch, useSelector} from "react-redux";
import {setCodeAnswer} from "../../../../redux/reducers/codeAnswersSlice";
import {Textarea} from "../../../components/textarea";
import LoadingOverlay from "../../../components/loadingOverlay";

const CandidateCode = ({question, onNextQuestion}) => {

    const languages = [
        {
            id: 45,
            value: "Assembly (NASM 2.14.02)",
            label: "Assembly (NASM 2.14.02)",
        },
        {
            id: 46,
            value: "Bash (5.0.0)",
            label: "Bash (5.0.0)",
        },
        {
            id: 75,
            value: "C (Clang 7.0.1)",
            label: "C (Clang 7.0.1)",
        },
        {
            id: 76,
            value: "C++ (Clang 7.0.1)",
            label: "C++ (Clang 7.0.1)",
        },
        {
            id: 50,
            value: "C (GCC 9.2.0)",
            label: "C (GCC 9.2.0)",
        },
        {
            id: 54,
            value: "C++ (GCC 9.2.0)",
            label: "C++ (GCC 9.2.0)",
        },
        {
            id: 86,
            value: "Clojure (1.10.1)",
            label: "Clojure (1.10.1)",
        },
        {
            id: 51,
            value: "C# (Mono 6.6.0.161)",
            label: "C# (Mono 6.6.0.161)",
        },
        {
            id: 77,
            value: "COBOL (GnuCOBOL 2.2)",
            label: "COBOL (GnuCOBOL 2.2)",
        },
        {
            id: 55,
            value: "Common Lisp (SBCL 2.0.0)",
            label: "Common Lisp (SBCL 2.0.0)",
        },
        {
            id: 90,
            value: "Dart (2.19.2)",
            label: "Dart (2.19.2)",
        },
        {
            id: 57,
            value: "Elixir (1.9.4)",
            label: "Elixir (1.9.4)",
        },
        {
            id: 58,
            value: "Erlang (OTP 22.2)",
            label: "Erlang (OTP 22.2)",
        },
        {
            id: 87,
            value: "F# (.NET Core SDK 3.1.202)",
            label: "F# (.NET Core SDK 3.1.202)",
        },
        {
            id: 59,
            value: "Fortran (GFortran 9.2.0)",
            label: "Fortran (GFortran 9.2.0)",
        },
        {
            id: 60,
            value: "Go (1.13.5)",
            label: "Go (1.13.5)",
        },
        {
            id: 95,
            value: "Go (1.18.5)",
            label: "Go (1.18.5)",
        },
        {
            id: 88,
            value: "Groovy (3.0.3)",
            label: "Groovy (3.0.3)",
        },
        {
            id: 61,
            value: "Haskell (GHC 8.8.1)",
            label: "Haskell (GHC 8.8.1)",
        },
        {
            id: 91,
            value: "Java (JDK 17.0.6)",
            label: "Java (JDK 17.0.6)",
        },
        {
            id: 63,
            value: "JavaScript (Node.js 12.14.0)",
            label: "JavaScript (Node.js 12.14.0)",
        },
        {
            id: 93,
            value: "JavaScript (Node.js 18.15.0)",
            label: "JavaScript (Node.js 18.15.0)",
        },
        {
            id: 78,
            value: "Kotlin (1.3.70)",
            label: "Kotlin (1.3.70)",
        },
        {
            id: 64,
            value: "Lua (5.3.5)",
            label: "Lua (5.3.5)",
        },
        {
            id: 89,
            value: "Multi-file program",
            label: "Multi-file program",
        },
        {
            id: 79,
            value: "Objective-C (Clang 7.0.1)",
            label: "Objective-C (Clang 7.0.1)",
        },
        {
            id: 65,
            value: "OCaml (4.09.0)",
            label: "OCaml (4.09.0)",
        },
        {
            id: 66,
            value: "Octave (5.1.0)",
            label: "Octave (5.1.0)",
        },
        {
            id: 67,
            value: "Pascal (FPC 3.0.4)",
            label: "Pascal (FPC 3.0.4)",
        },
        {
            id: 85,
            value: "Perl (5.28.1)",
            label: "Perl (5.28.1)",
        },
        {
            id: 68,
            value: "PHP (7.4.1)",
            label: "PHP (7.4.1)",
        },
        {
            id: 69,
            value: "Prolog (GNU Prolog 1.4.5)",
            label: "Prolog (GNU Prolog 1.4.5)",
        },
        {
            id: 70,
            value: "Python (2.7.17)",
            label: "Python (2.7.17)",
        },
        {
            id: 92,
            value: "Python (3.11.2)",
            label: "Python (3.11.2)",
        },
        {
            id: 80,
            value: "R (4.0.0)",
            label: "R (4.0.0)",
        },
        {
            id: 72,
            value: "Ruby (2.7.0)",
            label: "Ruby (2.7.0)",
        },
        {
            id: 73,
            value: "Rust (1.40.0)",
            label: "Rust (1.40.0)",
        },
        {
            id: 81,
            value: "Scala (2.13.2)",
            label: "Scala (2.13.2)",
        },
        {
            id: 82,
            value: "SQL (SQLite 3.27.2)",
            label: "SQL (SQLite 3.27.2)",
        },
        {
            id: 83,
            value: "Swift (5.2.3)",
            label: "Swift (5.2.3)",
        },
        {
            id: 74,
            value: "TypeScript (3.7.4)",
            label: "TypeScript (3.7.4)",
        },
        {
            id: 94,
            value: "TypeScript (5.0.3)",
            label: "TypeScript (5.0.3)",
        },
        {
            id: 84,
            value: "Visual Basic.Net (vbnc 0.0.0.5943)",
            label: "Visual Basic.Net (vbnc 0.0.0.5943)",
        },
    ];

    const problemStatement = question.questionDataAsJson.problemStatement;
    const title = problemStatement.split(" ").slice(0, 3).join(" ");
    const sampleInput = question.questionDataAsJson.sampleInput;
    const sampleOutput = question.questionDataAsJson.sampleOutput;
    const editor = question.questionDataAsJson.codeStub;
    const savedState = useSelector((state) => state.codeAnswers[question.questionId] || {});
    const [editorContent, setEditorContent] = useState(savedState.answer || question.questionDataAsJson.codeStub);
    const [selectedLanguage, setSelectedLanguage] = useState(savedState.languageId || languages[0].value);
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [submitloading, setSubmitloading] = useState(false);
    
    // let i = 0;
    // useEffect(() => {
    //     console.log("i", i);
    //     console.log("saved state", savedState);
    //     i++;
    // })


    let j = 0;
    useEffect(() => {
        console.log("j", j)
        // This effect runs only once on mount and whenever the question changes
        console.log(savedState)
        if (savedState.answer) {
            setEditorContent(savedState.answer);
        } else {
            // setEditorContent(question.questionDataAsJson.codeStub);
        }
        if (!savedState.languageId) {
            setSelectedLanguage(languages[0].value);
        }
    }, []);


    const token = localStorage.getItem("token");

    const {inputFormat, outputFormat, constraints} =
        question.questionDataAsJson;

    // console.log(question);

    // useEffect(() => {
    //     console.log("answer", savedState.answer)
    //     setEditorContent(savedState.answer || question.questionDataAsJson.codeStub);
    //     setSelectedLanguage(savedState.languageId || languages[0].value);
    // }, [question.questionId, savedState]);


    const candidateId = localStorage.getItem("candidateId");

    const [compileStatus, setCompileStatus] = useState("");
    const [exitStatus, setExitStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [submittedCode, setSubmittedCode] = useState({});

    const editorRef = useRef(null);

    const languageCodeStubs = question.questionDataAsJson.codeStub;

    const [isRunningCode, setIsRunningCode] = useState(false);

    // console.log(typeof(languageCodeStubs))

    // State for the currently selected language

    // Handler for language selection
    const handleLanguageChange = (event) => {
        // event.stopPropagation();
        // setShowModal(true)
        const newLanguage = event.target.value;
        const langId = languages.find((lang) => lang.value === newLanguage).id;
        console.log("langID", langId)
        setSelectedLanguage(newLanguage);
        console.log("new language", newLanguage)
        dispatch(setCodeAnswer({
            questionId: question.questionId,
            languageId: newLanguage,
            languageIdValue: langId
        }));
    };
    // console.log(typeof selectedLanguage);

    const runCode = async () => {
        setIsRunningCode(true);

        const selectedLanguageObject = languages.find(
            (lang) => lang.value === selectedLanguage
        );
        const selectedLanguageId = selectedLanguageObject
            ? selectedLanguageObject.id
            : null;

        if (!selectedLanguageId) {
            console.error("Selected language ID not found.");
            return;
        }

        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/runCode?candidateId=${candidateId}`;

        const requestBody = [
            {
                questionId: question.questionId,
                answer: {
                    answer: editorContent,
                    languageId: selectedLanguageId,
                },
            },
        ];
        setIsLoading(true);
        try {
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }

            const data = await response.json();
            // console.log(data);
            // Update state variables based on response
            if (data.data) {
                setCompileStatus(`Accepted Count: ${data.data.acceptedCount}`);
                setExitStatus(
                    `First Input: ${data.data.firstStdin} | First Output: ${data.data.firstStdout}`
                );
                setErrorMessage(data.data.errorMessage || "No error");
            }
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        } finally {
            setIsLoading(false);
            setIsRunningCode(false);
        }
    };

    const submitAnswer = async () => {
        setSubmitloading(true);
        // Find the selected language object to get its ID
        const selectedLanguageObject = languages.find(
            (lang) => lang.value === selectedLanguage
        );
        const selectedLanguageId = selectedLanguageObject
            ? selectedLanguageObject.id
            : null;

        if (!selectedLanguageId) {
            console.error("Selected language ID not found.");
            return; // Exit the function if we don't have a valid language ID
        }

        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/submitAnswers?candidateId=${candidateId}`; // Ensure this URL is correct

        // Constructing the request body to match the expected structure
        const requestBody = [
            {
                questionId: question.questionId, // Assuming questionId is available directly from question object
                answer: {
                    answer: editorContent, // Assuming editorContent contains the code as a string
                    languageId: selectedLanguageId, // Using the ID of the selected language
                },
            },
        ];

        try {
            // Update the submittedCode state with the current editorContent for the selected language
            setSubmittedCode((prevSubmittedCode) => ({
                ...prevSubmittedCode,
                [selectedLanguage]: editorContent,
            }));

            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`, // Ensure this token is correctly retrieved
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(
                    `Network response was not ok: ${response.statusText}`
                );
            }

            const data = await response.json();
            // console.log(data);
            onNextQuestion(); // Move to the next question if submission is successful
        } catch (error) {
            console.error("There was a problem with the fetch operation:", error);
        }
        finally{
            setSubmitloading(false);
        }
    };
    const getLanguageValue = (selectedLanguage) => {
        switch (selectedLanguage) {
            case "C (GCC 9.2.0)":
                return "c";
            case "C++ (GCC 9.2.0)":
                return "cpp";
            case "Java (JDK 17.0.6)":
                return "java";
            case "Python (3.11.2)":
                return "python";
            case "JavaScript (Node.js 18.15.0)":
                return "javascript";
            // Add more cases for other languages
            default:
                return null; // Or return a default language value if needed
        }
    };
    const handleEditorChange = value => {
        setEditorContent(value);
        const languageIdValue = languages.find((lang) => lang.value === selectedLanguage).id;
        dispatch(setCodeAnswer({
            questionId: question.questionId,
            answer: value,
            languageId: selectedLanguage,
            languageIdValue: languageIdValue
        }));
    };

    const reduxVar = useSelector((state) => state);
    useEffect(() => {
        console.log("Redux Store Contents:", reduxVar);
        setSubmittedCode({}); // Reset submittedCode when the question changes
    }, [question.questionId]);// Add any additional dependencies as needed

    useEffect(() => {
        if (editorRef.current) {
            let editorDefaultValue = languageCodeStubs[selectedLanguage];
            if (editorDefaultValue === undefined) {
                editorDefaultValue = "";
            }
            const submittedCodeForLanguage = submittedCode[selectedLanguage] || editorDefaultValue;
            editorRef.current.setValue(submittedCodeForLanguage);
            if (savedState.answer && savedState.languageId === selectedLanguage) {
                console.log("saved state language", savedState.languageId)
                console.log("selected language", selectedLanguage)
                editorRef.current.setValue(savedState.answer);
            }
        }
    }, [selectedLanguage, submittedCode]);

    return (
        <div className="my-[2rem] w-[100%] mx-[1.5rem] overflow-x-auto h-[100%]">
            <div className="flex gap-8 xl:flex-row 2xl:flex-row lg:flex-col md:flex-col h-[95%]">
                <div className="w-[60%] overflow-auto max-h-[90vh]">
                    {/* <p className="font serif text-xl my-[2rem]">{title}</p> */}
                    <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">
                        Problem statement:
                    </p>
                    <p
                        className="break-normal"
                        dangerouslySetInnerHTML={{
                            __html: problemStatement,
                        }}
                    />
                    <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">Input Format:</p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: inputFormat,
                        }}
                    />
                    {/* Render "Output Format" with outputFormat */}
                    <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">
                        Output Format:
                    </p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: outputFormat,
                        }}
                    />

                    {/* Render constraints */}
                    <p className="font-semibold font-serif pt-[0.75rem] text-[1.25rem]">Constraints:</p>
                    <p
                        dangerouslySetInnerHTML={{
                            __html: constraints,
                        }}
                    />
                    <div className="mt-[1rem] space-y-[1rem]">
                        <Textarea
                            widthOverride="100%"
                            //  height="4.563rem"
                            height="3.313rem"
                            label="Sample Input"
                            disabled={true}
                            placeholder={sampleInput}
                        />
                        <Textarea
                            widthOverride="100%"
                            height="3.313rem"
                            label="Sample output"
                            disabled={true}
                            placeholder={sampleOutput}
                        />
                    </div>
                </div>
                <div className="border border-solid border-gray-100 overflow-auto w-[100%] pr-8 max-h-[90vh]"
                    style={{
                        scrollbarWidth: 'none', /* Firefox */
                        msOverflowStyle: 'none', /* Internet Explorer and Edge */
                        '&::-webkit-scrollbar': {
                        display: 'none', /* Chrome, Safari, and Opera */
                        },
                    }}>
                    <select
                        value={selectedLanguage}
                        onChange={(e) => handleLanguageChange(e)}
                        className="p-2 mb-2 rounded-md"
                    >
                        {languages.map((lang) => (
                            <option key={lang.id} value={lang.value}>
                                {lang.label}
                            </option>
                        ))}
                    </select>
                    <Editor
                        height="65vh"
                        width="auto"
                        language={getLanguageValue(selectedLanguage)} 
                        theme="vs-dark" // This sets the theme to a dark theme called "vs-black"
                        onMount={(editor, monaco) => {
                            editorRef.current = editor;
                        }}
                        onChange={handleEditorChange}
                        options={{minimap: {enabled: false}}}
                    />
                    <div
                        className="mt-[1rem] flex xl:justify-end 2xl:justify-end lg:justify-center md:justify-center w-[100%] gap-4">
                        <Button
                            width="14.781rem"
                            label={isRunningCode ? "PLEASE WAIT ..." : "Run code"}
                            variant="white"
                            onClick={runCode}
                            disabled={isRunningCode}
                        />
                        <Button
                            width="14.781rem"
                            label={submitloading ? "PLEASE WAIT ..." : "SUBMIT"}
                            variant="bright-blue"
                            onClick={submitAnswer}
                        />
                    </div>
                    {/*<div className='flex justify-end'>*/}
                    {/*    <p className="text-[#004EEC] pt-2">Scroll down for compilation status</p>*/}
                    {/*</div>*/}
                    <div>
                        {/* {isLoading && <LoadingOverlay/>} */}

                        <Textarea
                            disabled={true}
                            label="Compilation Status"
                            className="px-[0.5rem] mt-[1rem] w-[90%]"
                            widthOverride="100%"
                            height=""
                            value={compileStatus}
                        />
                        <Textarea
                            disabled={true}
                            label="Exit Status"
                            className="px-[0.5rem] my-[1rem] w-[90%]"
                            widthOverride="100%"
                            value={exitStatus}
                        />
                        {errorMessage && (
                            <Textarea
                                disabled={true}
                                label="Error Message"
                                className="px-[0.5rem] mt-[1rem] w-[90%]"
                                widthOverride="100%"
                                height=""
                                value={errorMessage}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="z-0 fixed bottom-0 left-0 w-full text-center">
                {/*<p>*/}
                {/*  Powered by*/}
                {/*  <img className="w-10 h-10 inline pl-2 mb-1" src={logo}></img>*/}
                {/*</p>*/}
            </div>
            {/*<Modal*/}
            {/*    heading={"Are you sure you want to continue"}*/}
            {/*    content={"Please note that when you switch between languages, all your progress will be lost. Do you want to continue?"}*/}
            {/*    onClose={() => {*/}
            {/*        setShowModal(false)*/}
            {/*    }}*/}
            {/*    isOpen={showModal}*/}
            {/*/>*/}
        </div>
    );
};

export default CandidateCode;