export const getAssesmentsForPositions =
    ({ positionId, accessToken }) =>
        async (dispatch) => {
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/getAssessmentsForPosition?positionId=${positionId}`;
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch({
                        type: "GET_ASSESMENT_FOR_POSITION",
                        payload: data?.data
                    });
                } else {
                    console.error("Error Response:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching users list:", error);
            }
        };

export const getAssesmentsQuestions =
    ({ assesmentId, accessToken }) =>
        async (dispatch) => {
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/getAssessmentQuestions?assessmentId=${assesmentId}`;
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch({
                        type: "GET_ASSESMENTS_QUESTIONS",
                        payload: data?.data
                    });
                } else {
                    console.error("Error Response:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching users list:", error);
            }
        };

export const getQuestionsForSkill =
    ({ skillId, accessToken }) =>
        async (dispatch) => {
            try {
                console.log("skillId", skillId)
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/getQuestionForSkill?skillId=${skillId}`;
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch({
                        type: "GET_QUESTIONS_FOR_SKILLS",
                        payload: data?.data
                    });
                } else {
                    console.error("Error Response:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching users list:", error);
            }
        };

export const getSkills =
    () =>
        async (dispatch) => {
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/getSkills`;
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch({
                        type: "GET_SKILLS",
                        payload: data?.data
                    });
                } else {
                    console.error("Error Response:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching users list:", error);
            }
        };
export const addQuestionsToAssesments =
    ({ assessmentId, questionIds, accessToken }) =>
        async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/addQuestionsToAssessment`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify({
                        assessmentId: assessmentId,
                        questionIds: questionIds
                    })
                });
            } catch (error) {
                console.error("Error adding questions", error);
            }
        };
