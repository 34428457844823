import React from 'react'

function Badge({label, variant}) {
    return (
        <div
            className={`text-xs font-sans font-bold py-1 px-3 rounded-[3px] ${variant === "primary" ? "bg-[#F3F1FD] text-[#4A2AB3]" : variant === "secondary" ? "bg-[#FDF0F5] text-[#E0583F]" : variant === 'grey' ? "bg-[#F3F3F3] text-black" : variant === 'blue' ? "bg-[#EDF6FF] text-blue" : "bg-[#FFF0D9] text-[#BF7508]"}`}>
            {label}
        </div>
    )
}

export default Badge
