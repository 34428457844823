// SkillList.js
import React, {useState} from "react";
import {useLocation} from "react-router-dom";
import {SIDEBAR_CONFIG} from "../../constants/sidebar";
import SkillItem from "./skillItem";
import SearchIcon from "../../assets/search.png";

const SkillList = React.memo(({
                                  sidebarConfig, onSkillChange = () => {
    }
                              }) => {
    const location = useLocation();
    const [selectedSkill, setSelectedSkill] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");

    const handleSkillChange = (skillId, skillName) => {
        setSelectedSkill(skillId);
        onSkillChange(skillId, skillName);
    };

    const config = sidebarConfig || SIDEBAR_CONFIG;

    // Recursive function to search through nested skills
    const searchNestedSkills = (skills, term) => {
        return skills.filter(skill => {
            const skillName = skill.label.toLowerCase();
            const subSkillsMatch = skill.items && searchNestedSkills(skill.items, term).length > 0;
            return skillName.includes(term.toLowerCase()) || subSkillsMatch;
        });
    };

    // Filter skills based on search term
    const filteredConfig = searchTerm ? searchNestedSkills(config, searchTerm) : config;

    return (
        <div>
            <div className="relative self-end my-3 px-2">
                <input
                    type="text"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-[102%] pl-9 pr-4 text-[14px] text-text-grey border-[0px] h-[34px] rounded-sm bg-[#F9F9F9] focus:outline-none focus:border-blue-500"
                    placeholder="Search by Skill"
                    style={{
                        backgroundImage: `url(${SearchIcon})`,
                        backgroundSize: "1rem",
                        backgroundPositionX: "0.5rem",
                        backgroundPositionY: "center",
                        backgroundRepeat: "no-repeat"
                    }}
                />
            </div>
            <ul className="flex flex-col gap-2">
                {filteredConfig.map((item) => (
                    <SkillItem
                        key={item.id}
                        skill={item}
                        onSkillChange={handleSkillChange}
                        selectedSkill={selectedSkill}
                    />
                ))}
            </ul>
        </div>
    );
});

export default SkillList;