import React, {useEffect, useRef, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import QuestionNavigation from "./questionNav";
import QuestionComponent from "./questionCompoent";
import {useSelector} from "react-redux";
import ProctoringComponent from "../../components/camgaze";

const SkillAssessment = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [firstQuestion, setFirstQuestion] = useState(null);
    const [assessment, setAssessment] = useState([]);
    const [currentQuestionId, setCurrentQuestionId] = useState(null);
    const [isAssessmentEnded, setIsAssessmentEnded] = useState(false);
    const [totalTimeTaken, setTotalTimeTaken] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [trustScore, setTrustScore] = useState(0);

    const proctoringRef = useRef(null);
    const videoBlobRef = useRef(null);
    const screenRecorderRef = useSelector((state) => state.screenRecorderRef);
    const webcamRecorderRef = useSelector((state) => state.webcamRecorderRef);
    const screenStreamRef = useSelector((state) => state.screenStreamRef);
    const webcamStreamRef = useSelector((state) => state.webcamStreamRef);
    const answers = useSelector((state) => state.answers);
    const codeAnswers = useSelector((state) => state.codeAnswers);
    const mcqAnswers = useSelector((state) => state.mcqAnswers);

    const assessmentId = localStorage.getItem("assessmentId");
    const token = localStorage.getItem("token");
    const candidateId = localStorage.getItem("candidateId");
    const initialMinutes = parseInt(localStorage.getItem("duration"), 10) || 0;
    const initialSeconds = initialMinutes * 60;

    const isProctoringEnabled = localStorage.getItem("isProctoringEnabled");

    const recordingCompleteRef = useRef(false);

    const selectQuestion = (questionId) => {
        setCurrentQuestionId(questionId);
    };

    const currentQuestion = assessment.find((q) => q.questionId === currentQuestionId);

    useEffect(() => {
        const handleBackButton = (event) => {
            event.preventDefault();
            alert("You are prevented from navigating away");
        };

        window.history.pushState(null, null, location.pathname);
        window.addEventListener('popstate', handleBackButton);

        return () => {
            window.removeEventListener('popstate', handleBackButton);
        };
    }, [location.pathname]);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // This is required for Chrome to show the prompt
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        const fetchTrustScore = () => {
            if (proctoringRef.current) {
                const score = proctoringRef.current.getTrustScore();
                setTrustScore(score);
            }
        };

        const interval = setInterval(fetchTrustScore, 1000);

        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        if (!isAssessmentEnded) {
            getAssessmentDetails();
        }
    }, [isAssessmentEnded]);

    const hasStartedRecording = useRef(false);

    useEffect(() => {
        console.log("hook run again");
        if (!hasStartedRecording.current && proctoringRef.current && proctoringRef.current.startRecording) {
            proctoringRef.current.startRecording();
            hasStartedRecording.current = true; // Set the flag to indicate recording has started
        }

        // No cleanup function to stop the recording automatically
    }, []); // Empty dependency array ensures this runs only once on mount

    const getAssessmentDetails = () => {
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/getSkillAssessment?assessmentId=${assessmentId}`;

        fetch(url, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then((data) => {
                setAssessment(data.data);
                setCurrentQuestionId(data.data[0]?.questionId);
                setFirstQuestion(data.data[0]?.questionId);
            })
            .catch((error) => {
                console.error("Error fetching skill assessment:", error);
            });
    };

    const handleLastQuestion = () => {
        setIsAssessmentEnded(true);
        console.log("assessment ended");
    };

    const handleNextQuestion = () => {
        const currentIndex = assessment.findIndex((q) => q.questionId === currentQuestionId);
        if (currentIndex !== -1 && currentIndex < assessment.length - 1) {
            setCurrentQuestionId(assessment[currentIndex + 1].questionId);
        } else {
            setCurrentQuestionId(firstQuestion);
        }
    };

    const onTimerTick = (remainingTime, initialSeconds) => {
        const timeTaken = initialSeconds - remainingTime;
        setTotalTimeTaken(timeTaken);
    };

    const compileAnswersForSubmission = () => {
        const compiledAnswers = [];

        Object.keys(answers).forEach((questionId) => {
            compiledAnswers.push({
                questionId: parseInt(questionId),
                answer: {text: answers[questionId]},
                timeTaken: 1,
            });
        });

        Object.keys(codeAnswers).forEach((questionId) => {
            compiledAnswers.push({
                questionId: parseInt(questionId),
                answer: {
                    answer: codeAnswers[questionId].answer,
                    languageId: codeAnswers[questionId].languageIdValue,
                    timeTaken: 1,
                },
            });
        });

        Object.keys(mcqAnswers).forEach((questionId) => {
            compiledAnswers.push({
                questionId: parseInt(questionId),
                answer: {selectedOptionId: mcqAnswers[questionId], timeTaken: 1},
            });
        });

        return compiledAnswers;
    };

    const uploadVideoToBunny = async (videoBlob) => {
        console.log("Uploading video to Bunny CDN");
        if (!videoBlob || videoBlob.size === 0) {
            console.error("No videoBlob available for upload or Blob is empty");
            return null;
        }

        const fileName = `video-proctoring-${candidateId}-${new Date().toISOString()}.webm`;
        const uploadUrl = `https://sg.storage.bunnycdn.com/storage-zone-199/${fileName}`;
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            reader.onloadend = async () => {
                const arrayBuffer = reader.result;
                try {
                    const response = await fetch(uploadUrl, {
                        method: "PUT",
                        headers: {
                            'AccessKey': '59ca7d1a-5a50-41cc-9d97b3d70701-bb66-4e61',
                            'Content-Type': 'video/webm',
                        },
                        body: arrayBuffer,
                    });

                    if (response.status === 201) {
                        console.log("Video uploaded successfully");
                        resolve(fileName);
                    } else {
                        throw new Error(`Upload failed with status: ${response.status}`);
                    }
                } catch (error) {
                    console.error("Error during video upload:", error);
                    reject(error);
                }
            };
            reader.readAsArrayBuffer(videoBlob);
        });
    };

    const handleEndTest = async (e) => {
        if (e) e.preventDefault();

        if (isUploading) return; // Prevent multiple upload attempts
        setIsUploading(true);

        // Stop recording
        console.log("Stopping camera and waiting for video to complete recording...");
        if (proctoringRef.current) {
            proctoringRef.current.stopRecording();
        }

        // const blob = videoBlobRef.current;
        // if (!blob) {
        //     await submitAssessment("NA")
        // }

        // Use ref to get the immediate value of videoBlob
        setTimeout(async () => {
            const blob = videoBlobRef.current;
            console.log("videoBlob is set:", blob);

            if (blob) {
                try {
                    const uploadURL = await uploadVideoToBunny(blob);
                    console.log("Video uploaded to Bunny CDN, URL:", uploadURL);
                    await submitAssessment(uploadURL);
                } catch (error) {
                    console.error("Error uploading video:", error);
                }
            } else {
                await submitAssessment("NA");
                console.error("videoBlob is still null after timeout");
            }
        }, 1000); // Adding a slight delay to ensure the blob is set
    };

    const submitAssessment = async (uploadURL) => {
        const requestBody = compileAnswersForSubmission();

        try {
            console.log("Attempting to submit assessment");

            let apiUrl = "";

            if (uploadURL) {
                console.log("Video uploaded successfully");
                apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/submitAssessment?candidateId=${candidateId}&videoURL=${uploadURL}&trustScore=${trustScore}&timeTaken=${totalTimeTaken}`;
            } else {
                console.log("Video not uploaded");
                apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/submitAssessment?candidateId=${candidateId}`;
            }

            console.log("Submitting answers...");
            const response = await fetch(apiUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error(`Failed to submit assessment answers, status: ${response.status}`);
            }

            const data = await response.json();
            console.log("Assessment submission response:", data);

            if (response.ok) {
                console.log("Assessment submitted successfully:", data);
                navigate("/feedback");
            } else {
                console.error("Assessment submission not successful:", data);
            }
        } catch (error) {
            console.error("Error during the assessment submission process:", error);
        }
    };

    const handleVideoRecordingComplete = useRef((blob) => {
        console.log("Inside handleVideoRecordingComplete");
        if (!recordingCompleteRef.current) {
            console.log("Video recording complete:", blob);
            videoBlobRef.current = blob;  // Set the blob to ref for immediate access
            recordingCompleteRef.current = true;
        }
    }).current;

    window.addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });

    window.addEventListener('keydown', (e) => {
        if (e.key === 'F12' || e.keyCode === 123) {
            e.preventDefault();
        }
    });

    useEffect(() => {
        const handleCopyPaste = (e) => {
            e.preventDefault();
        };

        document.body.addEventListener("copy", handleCopyPaste);
        document.body.addEventListener("paste", handleCopyPaste);

        return () => {
            document.body.removeEventListener("copy", handleCopyPaste);
            document.body.removeEventListener("paste", handleCopyPaste);
        };
    }, []);

    return (
        <div className="min-h w-full overflow-x-hidden">
            {isProctoringEnabled === "true" && (
                <div
                    className="proctoring-container"
                    style={{
                        position: "absolute",
                        left: "-10000%",
                        top: "-10000%",
                        visibility: "hidden",
                    }}
                >
                    <ProctoringComponent
                        ref={proctoringRef}
                        onRecordingComplete={handleVideoRecordingComplete}
                        onTrustScoreChange={(score) => setTrustScore(score)}
                    />
                </div>
            )}
            <QuestionNavigation
                className="fixed top-0 left-0"
                questions={assessment}
                onSelectQuestion={selectQuestion}
                // videoBlob={videoBlob}
                onEndTest={handleEndTest}
                onTimerTick={onTimerTick}
                initialSeconds={initialSeconds}
                currentQuestionId={currentQuestionId}
            />
            <div className="question-display-area absolute top-10 left-20">
                {currentQuestion ? (
                    <QuestionComponent
                        question={currentQuestion}
                        onNextQuestion={handleNextQuestion}
                    />
                ) : (
                    <p className="mt-[5rem]">Select a question from the list.</p>
                )}
            </div>
        </div>
    );
};

export default SkillAssessment;

