import React, {useState} from "react";

const Checkbox = (props) => {
    const {checked: initialChecked = false, hoverContent} = props;
    const [checked, setChecked] = useState(initialChecked);
    const [isHovered, setIsHovered] = useState(false);
    const checkboxStyle = {
        height: props.height,
        width: props.width,
        verticalAlign: props.verticalAlign,
        boxShadow: props.boxShadow,
    };

    return (
        <label
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            className="relative"
        >
            <input
                type="checkbox"
                checked={props.checked}
                onChange={props.onChange}
                name={props.name}
                className="font-inter text-xs font-medium tracking-wide text-left text-gray-900 m-1"
                style={checkboxStyle}
            />
            {props.content}
            {isHovered && hoverContent && (
                <div className="absolute top-full left-0 mt-1 p-2 bg-gray-100 rounded shadow-md">
                    {hoverContent}
                </div>
            )}
        </label>
    );
};

export default Checkbox;
