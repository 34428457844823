// src/redux/actions/ForgotPasswordActions.js

// Action Type
export const SAVE_EMAIL = 'SAVE_EMAIL';

// Action Creator
export const saveEmail = (email) => {
    return {
        type: SAVE_EMAIL,
        payload: email
    };
};
