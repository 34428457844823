import React, {useEffect, useRef, useState} from "react";
import {Textbox} from "../../../components/textbox";
import {Button} from "../../../components/button";
import Checkbox from "../../../components/checkbox";
import mark from "../../../../assets/questionmark.png";
import {useNavigate} from 'react-router-dom';
import Modal from '../../../components/popup';
import "./createPositions.css";
import {useDispatch} from "react-redux";
import {FormValidator} from "@syncfusion/ej2-react-inputs";
import release from "../../../../assets/Release.svg";
import {setPositionId} from "../../../../redux/actions/positionDetailsActions";


const CreatePositions = () => {
    const [roles, setRoles] = useState([]);
    const navigate = useNavigate();

    const [name, setName] = useState("");
    const [roleId, setRoleId] = useState('');
    const [hiringManager, setHiringManager] = useState("");
    const [hiringManagerEmail, setHiringManagerEmail] = useState("");
    const [department, setDepartment] = useState("");
    const [location, setLocation] = useState("");
    const [assessmentTypeIds, setAssessmentTypeIds] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [workExperienceFrom, setWorkExperienceFrom] = useState('');
    const [workExperienceTo, setWorkExperienceTo] = useState('');
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);

    const handleCheckboxChange2 = (checkboxId) => {
        setSelectedCheckboxes((prevSelected) => {
            if (prevSelected.includes(checkboxId)) {
                // If already selected, remove it
                return prevSelected.filter(id => id !== checkboxId);
            } else {
                // If not selected, add it
                return [...prevSelected, checkboxId];
            }
        });
    };

    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const nameValidator = (value) => {
        const nameExp = /^[A-Za-z0-9\-]+(?: [A-Za-z0-9\-]+)*$/;
        return nameExp.test(value.value.trim() || '');
    };

    const institutionalEmailValidator = (args) => {
        args.value = args.value.trim(); // Trim the input value
        const disallowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'aol.com',
            'icloud.com', 'protonmail.com', 'zoho.com', 'yandex.com', 'mail.com', 'gmx.com',
            'tutanota.com', 'fastmail.com', 'hushmail.com', 'gawab.com', 'inbox.com', 'aim.com',
            'fastmail.com', 'tutanota.com', 'pobox.com', 'yandex.ru', 'yandex.mail',
            'aol.com', 'gmx.net', 'rediff.com'];
        const emailDomain = args.value.split('@')[1];

        return !disallowedDomains.includes(emailDomain);
    };

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    const accessToken = localStorage.getItem('accessToken');

    useEffect(() => {
        fetchRoles();
    }, []);

    // Fetch roles from the server
    const fetchRoles = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/getRoles`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.json();
            setRoles(data.data); // Adjusted to match your API response structure
        } catch (error) {
            // Handle error response
            // console.error("Error Fetching Roles:", error);
            setShowModal(true);
            setModalContent({
                heading: 'Failed to fetch roles, please try again.',
                message: error.message, // Display specific error message
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        }
    };


    // Handle checkbox change
    const handleCheckboxChange = (testTypeId) => {
        setAssessmentTypeIds((prevIds) => {
            const updatedIds = prevIds.includes(testTypeId)
                ? prevIds.filter((id) => id !== testTypeId)
                : [...prevIds, testTypeId];

            const isSelected = updatedIds.length > 0;
            setIsInterviewLevelSelected(isSelected);

            return updatedIds;
        });
    };

    const handleCancel = () => {
        navigate('/jobs/position')

    };

    const dispatch = useDispatch();

    const handleNextStep = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const updatedAssessmentTypeIds = [...new Set([0, ...assessmentTypeIds])];

        const positionData = {
            name,
            roleId: parseInt(roleId, 10), // Assuming roleId should be an integer
            hiringManager,
            hiringManagerEmail,
            department,
            location,
            assessmentTypeIds: updatedAssessmentTypeIds,
            workExperienceFrom,
            workExperienceTo,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/addPosition`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${accessToken}`,
                },
                body: JSON.stringify(positionData),
            });

            if (!response.ok) {
                throw new Error(`API request failed with status ${response.status}`);
            }
            const data = await response.json();
            dispatch(setPositionId(data.data));
            localStorage.setItem("PosID", data.data)
            // Redirect to position details page upon successful addition
            navigate('/jobs/positionDetails');
        } catch (error) {
            // Handle error response
            // console.error("Error Making API call:", error);
            setShowModal(true);
            setModalContent({
                heading: 'Failed to make API call, please try again in some time.',
                message: error.message, // Display specific error message
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setIsLoading(false);
        }
    };
    
    const [isInterviewLevelSelected, setIsInterviewLevelSelected] = useState(false);


    useEffect(() => {
        if (formRef.current) {
            // Define the validation rules and options
            const options = {
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
                rules: {
                    'name': {
                        required: [true, 'Name is required'],
                        minLength: [2, 'Name should be at least 2 characters long'],
                        maxLength: [100, 'Name should not be more than 100 characters'],
                        nameValidator: [nameValidator, 'Enter a valid name. Special characters are not allowed.']
                    },
                    'hiringManager': {
                        required: [true, 'Hiring manager name is required'],
                        minLength: [2, 'Hiring manager name should be at least 2 characters long'],
                        maxLength: [100, 'Hiring manager name should not be more than 100 characters'],
                        nameValidator: [nameValidator, 'Enter a valid name consisting of letters only']
                    },
                    'location': {
                        required: [true, 'Location name is required'],
                        minLength: [2, 'Location name should be at least 2 characters long'],
                        maxLength: [100, 'Location name should not be more than 100 characters'],
                        nameValidator: [nameValidator, 'Enter a valid location name consisting of letters only']
                    },
                    'department': {
                        required: [true, 'Department name is required'],
                        minLength: [2, 'Department name should be at least 2 characters long'],
                        maxLength: [100, 'Department name should not be more than 100 characters'],
                        nameValidator: [nameValidator, 'Enter a valid name consisting of letters only']
                    },
                    'hiringManagerEmail': {
                        required: [true, 'Email is required'],
                        email: [true, 'Please enter a valid email'],
                        institutionalEmailValidator: [institutionalEmailValidator, 'Please enter a valid organisational email address']
                    },
                },
                validationTrigger: 'manual'
            };
            validatorRef.current = new FormValidator(formRef.current, options);
        }
    }, [formRef, validatorRef]);

    return (
        <div className='overflow-y-auto h-[80vh] scrollbar-none'>
            {/* {isLoading && <LoadingOverlay/>} */}
            <form id="createPosition" ref={formRef}>
                <div className="w-[46.063rem]">
                    <div>
                        <Textbox className='create-positions-input'
                                 label={(
                                     <label className="flex items-center">
                                         Internal Job Title
                                         <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                     </label>
                                 )}
                                 id=''
                                 name='name'
                                 width='42.625rem'
                                 placeholder='e.g.: Senior product manager - Boston'
                                 type='text'
                                 value={name}
                                 onChange={(e) => setName(e.target.value)}
                        />
                        <span style={{
                            color: 'rgba(120, 121, 141, 1)',
                            fontFamily: 'Inter',
                            fontWeight: '400',
                            fontSize: '14px',
                            lineHeight: '20px',
                            letterSpacing: '2%'
                        }}>Here you can give it a more descriptive name for internal use.</span>
                    </div>
                    <div class='mt-[2.5rem] space-y-[2rem] mb-[2.188rem]'>
                        <div class="flex justify-start gap-[1.875rem]">

                            <div className="w-[20.438rem]">
                                <label className="text-sm lableofForm1">
                                    Job Role
                                    <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                </label>
                                <select
                                    className="create-positions-input-small1 inputofForm1"
                                    value={roleId}
                                    onChange={(e) => setRoleId(e.target.value)}
                                >
                                    <option>Select a role</option>
                                    {roles.map((role) => (
                                        <option key={role.jobRoleId} value={role.jobRoleId}>
                                            {role.name}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <Textbox width="20.438rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Hiring Manager
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="Olivia"
                                     name='hiringManager'
                                     value={hiringManager}
                                     onChange={(e) => setHiringManager(e.target.value)}
                            ></Textbox>
                        </div>
                        <div class="flex justify-start gap-[1.875rem]">
                            <Textbox width="20.438rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Hiring Manager Email
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="olivia@boltcode.io"
                                     value={hiringManagerEmail}
                                     name='hiringManagerEmail'
                                     onChange={(e) => setHiringManagerEmail(e.target.value)}
                            ></Textbox>
                            <Textbox width="20.438rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Department
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="Product management"
                                     value={department}
                                     name='department'
                                     onChange={(e) => setDepartment(e.target.value)}
                            ></Textbox>
                        </div>
                        <div className="flex flex-col justify-start ">
                            <label className="text-sm font-semibold">
                                Work Experience in Years
                                {/* <img src={mark} alt="question mark" className="  mt-[-1rem] ml-[11.0rem]"/> */}
                            </label>
                            <div className="flex gap-[1.875rem]">
                                <Textbox
                                    width="20.438rem"
                                    placeholder="From"
                                    value={workExperienceFrom}
                                    onChange={(e) => setWorkExperienceFrom(e.target.value)}
                                />
                                <Textbox
                                    width="20.438rem"
                                    placeholder="To"
                                    value={workExperienceTo}
                                    onChange={(e) => setWorkExperienceTo(e.target.value)}
                                />
                            </div>
                        </div>
                        <div class="flex justify-start gap-[1.875rem]">
                            <Textbox width="20.438rem"
                                     height="4.375"
                                     label={(
                                         <label className="flex items-center">
                                             Location
                                             <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                         </label>
                                     )}
                                     placeholder="Boston"
                                     value={location}
                                     name='location'
                                     onChange={(e) => setLocation(e.target.value)}
                            ></Textbox>
                        </div>
                    </div>
                    <p style={{"font-size": "0.9rem", "font-weight": "700"}} className="content-center">Levels of
                        interview
                        <img src={mark} alt="question mark" className="  mt-[-1rem] ml-[8.2rem]"/>
                    </p>
                    <div className='w-[92%]'>
                        <div className='flex gap-[1.375rem] mb-[2rem]'>
                            <div
                                className={`flex justify-between items-center w-[50%] h-11 border ${selectedCheckboxes.includes(1) ? 'border-blue' : 'border-gray-400'} rounded font-inter mt-2 text-sm font-normal leading-5 tracking-wide text-center text-gray-600 pl-[1rem]`}>
                                <Checkbox
                                    content={
                                        <span className="skill-assessment-text pl-[1rem]">Skill Assessment

                                        </span>
                                    }
                                    className="create-pos-check"
                                    width="16"
                                    height="16"
                                    onChange={() => {
                                        handleCheckboxChange(1);
                                        handleCheckboxChange2(1);
                                    }}
                                    // hoverContent={<span>Skill Assessment: Round 1 - AI Proctored Test</span>}
                                />
                                {/* <img src={mark} alt="question mark" className="mr-[0.4rem]"/> */}
                            </div>

                            <div
                                className={`flex justify-between items-center w-[50%] h-11 border ${selectedCheckboxes.includes(2) ? 'border-blue' : 'border-gray-400'} rounded font-inter mt-2 text-sm font-normal leading-5 tracking-wide text-left text-gray-600 pl-[1rem]`}>
                                <Checkbox
                                    content={
                                        <span className="skill-assessment-text pl-[1rem]">Async Video Interview

                                        </span>
                                    }
                                    className="create-pos-check"
                                    width="16"
                                    height="16"
                                    onChange={() => {
                                        handleCheckboxChange(2);
                                        handleCheckboxChange2(2);
                                    }}
                                    // hoverContent={<span>Async Video Interview: Round 2 - Face-to-Face Interview</span>}
                                />
                                {/* <img src={mark} alt="question mark" className="mr-[0.4rem] "/> */}
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row h-[2.25rem]">                
                        <Button label="CANCEL"
                                variant="white"
                                width="9.375rem"
                            // boxShadow='0 0 10px rgba(16, 24, 40, 0.1)'
                                onClick={handleCancel}
                                className='text-[0.75rem] h-[2.25rem]'
                        />
                        <Button label={isLoading ? "PLEASE WAIT..." : "NEXT STEP"}
                                width="9.375rem"
                                className="ml-[1rem] text-[0.75rem] h-[2.25rem]"
                                variant="bright-blue"
                            // boxShadow='0 0 10px rgba(16, 24, 40, 0.1)'
                                disabled={!name || !hiringManager || !hiringManagerEmail || !department || !location || !isInterviewLevelSelected}
                                onClick={handleNextStep}
                        />
                    </div>
                    <img src={release}
                         alt="man releasing pigeon"
                         className="
                         absolute 
                         bottom-8 
                         right-0 
                         xl:w-[17.313rem] xl:h-[17.313rem]
                         2xl:w-[17.313rem] 2xl:h-[17.313rem]
                         3xl:w-[17.313rem] 3xl:h-[17.313rem]
                         lg:w-[13.313rem] lg:h-[13.313rem] 
                         md:w-[13.313rem] md:h-[13.313rem]"
                    />
                </div>
                <Modal isOpen={showModal}
                       onClose={() => setShowModal(false)}
                       onConfirm={handleModalConfirm}
                       heading={modalContent.heading}
                       message={modalContent.message}
                       confirmButtonText={modalContent.confirmButtonText}
                />
            </form>
        </div>

    );
};


export default CreatePositions;

