import React, {useEffect, useState} from "react";
import stopwatch from "../../../assets/stopwatch.svg";
import {Button} from "../../components/button";
import bird from '../../../assets/boltcode_bird.svg';
import ThreeButtonLoader from "../../components/ThreeButtonLoader.jsx";


const TimeBarTest = ({
                         onEndTest,
                         onTimerTick,
                         initialSeconds,
                     }) => {
    // const answers = useSelector((state) => state.answers);
    // const codeAnswers = useSelector((state) => state.codeAnswers);
    // const mcqAnswers = useSelector((state) => state.mcqAnswers);
    //
    // const navigate = useNavigate();
    // const videoStream = useSelector((state) => state.video.videoStream);
    // const dispatch = useDispatch();
    // const token = localStorage.getItem("token");

    // const compileAnswersForSubmission = () => {
    //     const compiledAnswers = [];
    //
    //     Object.keys(answers).forEach((questionId) => {
    //         compiledAnswers.push({
    //             questionId: parseInt(questionId),
    //             answer: {
    //                 text: answers[questionId],
    //             },
    //             timeTaken: 1,
    //         });
    //     });
    //
    //     Object.keys(codeAnswers).forEach((questionId) => {
    //         compiledAnswers.push({
    //             questionId: parseInt(questionId),
    //             answer: {
    //                 answer: codeAnswers[questionId],
    //                 timeTaken: 20,
    //                 languageId: 70,
    //             },
    //         });
    //     });
    //
    //     Object.keys(mcqAnswers).forEach((questionId) => {
    //         compiledAnswers.push({
    //             questionId: parseInt(questionId),
    //             answer: {
    //                 selectedOptionId: mcqAnswers[questionId],
    //                 timeTaken: 1,
    //             },
    //         });
    //     });
    //
    //     return compiledAnswers;
    // };

    // const uploadVideoToBunny = async (videoBlob) => {
    //     console.log("video: ", videoBlob);
    //     if (!videoBlob) return;
    //
    //     const fileName = `video-${new Date().toISOString()}.webm`;
    //     const uploadUrl = `https://sg.storage.bunnycdn.com/storage-zone-199/${fileName}`;
    //
    //     const reader = new FileReader();
    //     reader.readAsArrayBuffer(videoBlob);
    //     reader.onloadend = async () => {
    //         try {
    //             const arrayBuffer = reader.result;
    //             const response = await fetch(uploadUrl, {
    //                 method: "PUT",
    //                 headers: {
    //                     AccessKey: "59ca7d1a-5a50-41cc-9d97b3d70701-bb66-4e61",
    //                     "Content-Type": "application/octet-stream",
    //                 },
    //                 body: arrayBuffer,
    //             });
    //
    //             if (response.status === 201) {
    //                 console.log("Video uploaded successfully");
    //             } else {
    //                 throw new Error("Upload failed with status: " + response.status);
    //             }
    //         } catch (error) {
    //             console.error("Error during video upload:", error);
    //         }
    //     };
    // };

    const [time, setTime] = useState(initialSeconds);
    const [showEndTestModal, setShowEndTestModal] = useState(false);
    const totalSeconds = initialSeconds;
    const [isSubmitted, setIsSubmitted] = useState(false)

    useEffect(() => {
        if (time < 0) {
            return;
        }

        const interval = setInterval(() => {
            setTime((prevTime) => {
                const newTime = prevTime - 1;
                onTimerTick(newTime, initialSeconds);
                if (newTime <= 0) {
                    clearInterval(interval);
                    onEndTest();
                }
                return newTime;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, [time, onTimerTick, initialSeconds, onEndTest]);

    const minutes = Math.floor(time / 60);
    const seconds = time % 60;

    const progress = 100 - ((totalSeconds - time) / totalSeconds) * 100;

    const style1 = {
        margin: 0,
    };

    const [storedDecodedLogo, setStoredDecodedLogo] = useState(null);
    useEffect(() => {
        const storedDecodedLogo = localStorage.getItem("decodedLogo");
        setStoredDecodedLogo(storedDecodedLogo);
    }, []);

    return (
        <>
            {isSubmitted && <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50"><ThreeButtonLoader/></div>}
            <div
                className="flex justify-center items-center h-[3.875rem] border border-bottom fixed w-[100%] z-10 bg-white"
                style={style1}
            >
                {typeof storedDecodedLogo === "string" &&
                storedDecodedLogo.startsWith("img:") ? (
                    <img
                        src={`data:image/;base64,${storedDecodedLogo.substring(4)}`}
                        alt="ovii logo"
                        className="absolute top-3 left-5  w-[10rem]"
                    />
                ) : (
                    <p className="font-poppins text-[1.75rem] absolute top-3 left-5 text-labels-black">
                        {storedDecodedLogo}
                    </p>
                )}
                <div className="pt-2 flex start cols-2 justify-center items-center">
                    <img
                        src={stopwatch}
                        className="w-[1.1rem] h-[1.1rem] mr-[0.5rem]"
                        alt="stopwatch"
                    />
                    <span className="text-sm text-gray-400 mr-[0.5rem]">0:00</span>
                    <div
                        className="w-[13.313rem] h-[0.3rem] bg-gray-200 rounded-full overflow-hidden border border-gray-400">
                        <div
                            className="h-[0.3rem] bg-green-500"
                            style={{
                                width: `${progress}%`,
                            }}
                        ></div>
                    </div>
                    <span className="text-sm text-gray-400 ml-[0.5rem]">
              {minutes}:{seconds < 10 ? "0" + seconds : seconds}
            </span>
                    <Button
                        label="END TEST"
                        variant={"bright-blue"}
                        width="10rem"
                        className="absolute right-[5rem] text-[0.735rem] h-[2.25rem]"
                        // console.l?og("End Test button clicked");
                        onClick={() => {
                            console.log("End Test button clicked");
                            onEndTest();
                            setIsSubmitted(true);

                        }}
                        style={{zIndex: 1000}}
                    />
                </div>
                {showEndTestModal && (
                    <div
                        className="fixed min-h-screen inset-0 bg-[#00000026] flex justify-center items-center z-50"
                        style={{backdropFilter: "blur(8px)"}}
                    >
                        <div
                            className="justify-center items-center flex flex-col bg-white pl-[2rem] pr-[2rem] rounded-lg shadow-lg w-[30.8rem] h-[auto] relative"
                        >
                            <div className="flex justify-center items-center w-full">
                                <img src={bird} alt="bird" className="w-[50px] mt-[1rem]"/>
                            </div>
                            <h2 className="text-[1.2rem] text-charcoal font-serif font-medium text-center -mt-[0.75rem] mb-2">
                                Congratulations
                            </h2>
                            <p
                                className="mb-[4.5rem] font-sans text-charcoal text-[0.95rem] font-thin leading-[1.5rem] text-center break-normal"
                                style={{fontFamily: "helvetica"}}
                            >
                                You have Ended the Test!
                            </p>
                            <div
                                className="flex justify-center items-center absolute bottom-[0.1rem] w-[90%] mt-[1rem]"
                            >
                                <Button
                                    onClick={onEndTest}
                                    width="30%"
                                    variant="bright-blue"
                                    label="OK"
                                    className="h-[2.25rem] ml-[2px]"
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default TimeBarTest;
