// userActions.js

export const getUsersList =
    ({teamId, accessToken}) =>
        async (dispatch) => {
            try {
                const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/getUsersList?teamId=${teamId}`;
                const response = await fetch(apiUrl, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                if (response.ok) {
                    const data = await response.json();
                    dispatch({
                        type: "GET_USERS_LIST",
                        payload: {
                            usersList: data.data,
                        },
                    });
                } else {
                    console.error("Error Response:", response.statusText);
                }
            } catch (error) {
                console.error("Error fetching users list:", error);
            }
        };

export const inviteUsers =
    ({emails, teamId, accessToken}) =>
        async (dispatch) => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/inviteUsers?email=${encodeURIComponent(emails)}&teamId=${teamId}`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response.ok) {
                    dispatch({
                        type: "SENDING_INVITE",
                        payload: {
                            isSendingInvite: true,
                        },
                    });
                } else {
                    console.error("Error sending invitation");
                }
            } catch (error) {
                console.error("Error sending invitation:", error);
            }
        };

export const resendInvite =
    ({accessToken, inviteId}) =>
        async (dispatch) => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/resendInvite`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            payload: {
                                iniviteId: inviteId,
                            },
                        },
                    }
                );

                if (response.ok) {
                    dispatch({
                        type: "RESENDING_INVITE",
                        payload: {
                            isResendingInvite: true,
                        },
                    });
                } else {
                    console.error("Error sending invitation");
                }
            } catch (error) {
                console.error("Error sending invitation:", error);
            }
        };


        // ADD teamId and userId for call to work

export const deleteUser = ({ accessToken, teamId, userId, onSuccess = () => {} }) => async (dispatch) => {
    try {
        // Correctly append `userId` and `teamId` to the query parameters in the URL
        const url = `${process.env.REACT_APP_BASE_URL}/api/v1/removeUserFromTeam?userId=${userId}&teamId=${teamId}`;

        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
            // The body of the request. Assuming your backend requires it as well, otherwise, this can be omitted if the API only uses URL parameters.
            body: JSON.stringify({
                userId: userId,
                teamId: teamId,
            }),
        });

        if (response.ok) {
            // Handle success case, such as dispatching another action to update the UI accordingly
            onSuccess();
        } else {
            console.error("Error removing user");
        }
    } catch (error) {
        console.error("Error removing user:", error);
    }
};


export const fetchTeamId =
    ({userId, accessToken}) =>
        async (dispatch) => {
            // const userId = 152; // Replace with the actual userId or fetch it as required
            const url = `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${userId}`;

            try {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                const data = await response.json();
                if (response.ok) {
                    dispatch({
                        type: "FETCHING_TEAM_ID",
                        payload: {
                            teamId: data.data.teamId,
                        },
                    });
                }
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
            } catch (error) {
                console.error("Error:", error);
                return null; // or handle error as needed
            }
        };


// Add teamId for API to work


export const fetchInvitations = ({teamId, accessToken}) => async (dispatch) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/getInvites?teamId=${teamId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${accessToken}`,
            },
        });
        if (response.ok) {
            const data = await response.json();
            dispatch({
                type: "FETCH_INVITATIONS_SUCCESS",
                payload: data.data,
            });
        } else {
            console.error("Error Response:", response.statusText);
            // Dispatch an error action if needed
        }
    } catch (error) {
        console.error("Error fetching invitations:", error);
        // Dispatch an error action if needed
    }
};
