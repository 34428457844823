import home from "../sidebar-icons/home.svg";
import dashboard from "../sidebar-icons/dashboard.svg";
import jobs from "../sidebar-icons/jobs.svg";
import company from "../sidebar-icons/company.svg";
import help from "../sidebar-icons/help.svg";
import settings from "../sidebar-icons/settings.svg";
import logout from "../sidebar-icons/logout.svg";

export default {
  home,
  dashboard,
  jobs,
  company,
  help,
  settings,
  logout,
};
