import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/button";
import Badge from "../../../components/Badge";
import close from "../../../../assets/close.svg";
import SearchIcon from "../../../../assets/search.png";
import ErrorComponent from "../../../components/ErrorComponent";
import Modal from "../../../components/popup";

function AssessmentQuestionWidget({
                                      questions,
                                      onSelectedQuestionsChange,
                                      _selectedQuestions,
                                      showButtons,
                                      showDetails,
                                      clickable = true,
                                      showSearchBar,
                                      usePagination = true,
                                  }) {
    const [selectedQuestions, setSelectedQuestions] = useState(
        _selectedQuestions ?? []
    );
    const [showPopup, setShowPopup] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [isOptionsExpanded, setOptionsExpanded] = useState(true);
    const [isCorrectOptionExpanded, setCorrectOptionExpanded] = useState(true);
    const [isExplanationExpanded, setExplanationExpanded] = useState(true);
    const [isNameExpanded, setNameExpanded] = useState(true);
    const [isExpectedExpanded, setExpectedExpanded] = useState(true);
    const [isEvaluationExpanded, setEvaluationExpanded] = useState(true);
    const [isAttributesExpanded, setAttributesExpanded] = useState(true);
    const [isInputFormatExpanded, setInputFormatExpanded] = useState(true)
    const [isOutputFormatExpanded, setOutputFormatExpanded] = useState(true)
    const [isSampleInputFormatExpanded, setSampleInputFormatExpanded] = useState(true)
    const [isSampleOutputFormatExpanded, setSampleOutputFormatExpanded] = useState(true)
    const [isConstaintsExpanded, setConstraintsExpanded] = useState(true)
    const [isExpanded, setIsExpanded] = useState(true);
    const [isDetailsExpanded, setIsDetailsExpanded] = useState(true);

    const popupRef = useRef(null);
    const [showLimitReachedModal, setShowLimitReachedModal] = useState(false);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef, setShowPopup]);

    const handleClick = (question, event) => {
        if (clickable && event) {
            const isAddButtonClicked = event.target.classList.contains("add-button");
            const isRemoveButtonClicked =
                event.target.classList.contains("remove-button");
            if (!isAddButtonClicked && !isRemoveButtonClicked) {
                console.error("calling view details");
                handleViewDetails(question);
            }
        }
    };

    const handleAddQuestion = (questionId, event) => {
        event.stopPropagation();

        const selectedQuestionType = questions.find(q => q.id === questionId)?.typeID;
        const selectedQuestionsCount = selectedQuestions.filter(qId => questions.find(q => q.id === qId)?.typeID === selectedQuestionType).length;
        let limit = 0;

        switch (selectedQuestionType) {
            case 1:
                limit = 100;
                break;
            case 2:
                limit = 10;
                break;
            case 3:
                limit = 5;
                break;
            case 5:
                limit = 10;
                break;
            default:
                limit = 0;
        }

        if (selectedQuestionsCount >= limit) {
            setShowLimitReachedModal(true);
            return;
        }

        const updatedQuestions = [...selectedQuestions, questionId];
        setSelectedQuestions(updatedQuestions);
        onSelectedQuestionsChange(updatedQuestions);
    };

    const handleRemoveQuestion = (questionId, event) => {
        event.stopPropagation();
        const updatedQuestions = selectedQuestions.filter(
            (id) => id !== questionId
        );
        setSelectedQuestions(updatedQuestions);
        onSelectedQuestionsChange(updatedQuestions);
    };

    const handleViewDetails = (question) => {
        console.log("inside view details");
        setSelectedQuestion(question);
        setShowPopup(true);
        openAllExpanded();
    };

    const openAllExpanded = () => {
        setCorrectOptionExpanded(true)
        setEvaluationExpanded(true)
        setNameExpanded(true)
        setOptionsExpanded(true)
        setAttributesExpanded(true)
        setExpectedExpanded(true)
        setExplanationExpanded(true)
        setInputFormatExpanded(true)
        setOutputFormatExpanded(true)
        setConstraintsExpanded(true)
        setSampleInputFormatExpanded(true)
        setSampleOutputFormatExpanded(true)
    }

    const filteredQuestions = searchTerm
        ? questions.filter((question) => {
            const questionName = question.name.toLowerCase();
            return questionName.includes(searchTerm.toLowerCase());
        })
        : questions;

    const questionsPerPage = 5;
    const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);
    const paginatedQuestions = Array.from({length: totalPages}, (_, index) => {
        const start = index * questionsPerPage;
        return filteredQuestions.slice(start, start + questionsPerPage);
    });

    const handlePageChange = (increment) => {
        setCurrentPage((prevPage) => {
            const nextPage = prevPage + increment;
            if (nextPage < 0) {
                return totalPages - 1;
            } else if (nextPage >= totalPages) {
                return 0;
            }
            return nextPage;
        });
    };

    const closeAllExpanded = () => {
        setCorrectOptionExpanded(false)
        setEvaluationExpanded(false)
        setNameExpanded(false)
        setOptionsExpanded(false)
        setAttributesExpanded(false)
        setExpectedExpanded(false)
        setExplanationExpanded(false)
        setInputFormatExpanded(false)
        setOutputFormatExpanded(false)
        setConstraintsExpanded(false)
        setSampleInputFormatExpanded(false)
        setSampleOutputFormatExpanded(false)
    }

    console.log("Count of number of questions add for each typeId:",
        selectedQuestions.filter(id => questions.find(q => q.id === id)?.typeID === 1).length,
        selectedQuestions.filter(id => questions.find(q => q.id === id)?.typeID === 2).length,
        selectedQuestions.filter(id => questions.find(q => q.id === id)?.typeID === 3).length)

    return (
        <div className='overflow-y-auto'>
            {showSearchBar &&
                <div className="absolute top-[9.8rem] right-[2rem] self-end">
                    <input
                        className="w-[90%] h-8 pl-10 bg-white border rounded-sm"
                        placeholder="Search by question name"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <div className="absolute left-0 flex items-center justify-center pl-2 inset-y-4">
                        <img src={SearchIcon} alt="Search Icon" className="w-4 h-4 "/>
                    </div>
                </div>
            }
            {filteredQuestions.length === 0 ? (
                <div className='flex items-center justify-center h-full'>
                    <ErrorComponent content="No questions found"/>
                </div>
            ) : (
                (usePagination ? paginatedQuestions[currentPage] : filteredQuestions).map((question, index) => (
                    <div onClick={(event) => handleClick(question, event)}
                         className={`flex flex-col ${index !== filteredQuestions.length - 1 ? "border-b" : ""}`}
                         style={{cursor: clickable ? 'pointer' : 'default'}} key={question.id}>
                        <div className='flex justify-between items-center' key={question?.id}>
                            <div className=''>
                                <p className='font-poppins text-[#004eec] break-word mt-3 mb-3'
                                >{question?.name}</p>
                                <div className=' text-[1rem]'>
                                    {question.typeID === 1 &&
                                        <p
                                            className="text-[0.95rem] font-poppins text-black line-clamp-2"
                                            dangerouslySetInnerHTML={{__html: question?.description.slice(0, 200) + (question?.description.length > 200 ? '...' : '')}}/>}
                                    {question.typeID === 2 &&
                                        <p className="text-[0.95rem] font-poppins text-black line-clamp-2"
                                           dangerouslySetInnerHTML={{__html: question?.problemStatement.slice(0, 200) + (question?.problemStatement.length > 200 ? '...' : '')}}/>}
                                    {question.typeID === 3 &&
                                        <p className="text-[0.95rem] font-poppins text-black line-clamp-2"
                                           dangerouslySetInnerHTML={{__html: question?.problemStatement.slice(0, 1700) + (question?.problemStatement.length > 200 ? '...' : '')}}/>}
                                </div>
                            </div>
                        </div>
                        <div className='flex justify-between'>
                            <div className='flex flex-wrap items-center gap-2 w-full max-w-[85%]'>
                                <Badge label={`${question?.timeLimit} mins`} variant="grey"/>
                                {question.typeID === 1 ? (
                                    <Badge label={"MCQ"} variant="grey"/>
                                ) : question.typeID === 2 ? (
                                    <Badge label={"Subjective"} variant="grey"/>
                                ) : question.typeID === 3 ? (
                                        <Badge label={"Code"} variant="grey"/>
                                    ) :
                                    <Badge label={"unknown"} variant="grey"/>
                                }
                                <Badge
                                    label={`${question?.difficulty.charAt(0).toUpperCase() + question?.difficulty.substring(1).toLowerCase()} `}
                                    variant="grey"/>
                                {question?.tags?.map((badge) => <Badge key={badge} label={badge} variant="grey"/>)}
                            </div>
                            <div className='flex flex-col'>
                                {selectedQuestions.includes(question.id) ? (
                                    showButtons &&
                                    <Button onClick={(event) => handleRemoveQuestion(question.id, event)} variant="red"
                                            className="px-4 text-sm mb-2 remove-button" label="- Remove"
                                            style={{'fontWeight': '700'}}/>
                                ) : (
                                    showButtons &&
                                    <Button onClick={(event) => handleAddQuestion(question.id, event)} variant="blue"
                                            className="px-7 text-sm mb-2 add-button" label="+ Add"
                                            style={{'fontWeight': '700'}}/>
                                )}
                                {showDetails &&
                                    <Button width="4rem" variant="red" clickable={true}
                                            onClick={(event) => handleClick(question, event)}
                                            label="View" className="px-4 font-bold text-sm mb-2 remove-button"/>}
                            </div>
                            {showLimitReachedModal && (
                                <Modal
                                    isOpen={showLimitReachedModal}
                                    onClose={() => setShowLimitReachedModal(false)}
                                    heading="Limit Reached"
                                    message={`Cannot add more questions of this type. Limit reached.`}
                                    confirmButtonText="OK"
                                    onConfirm={() => setShowLimitReachedModal(false)}
                                />
                            )}

                        </div>
                    </div>
                ))
            )}

            {showPopup && (
                <div ref={popupRef} className="fixed inset-0 z-100 bg-opacity-25">
                    <div
                        className="absolute top-0 right-0 h-full w-[47rem] border border-gray-300 bg-[#fff] transition-all duration-700 ">
                        <div className="p-4 h-[90vh]">
                            <h2 className="text-2xl font-serif mb-[0.75rem] px-[1.5rem]">Question Details</h2>
                            <img src={close} className="absolute top-[1rem] right-[1rem] w-[20px] cursor-pointer"
                                 onClick={() => {
                                     setShowPopup(false)
                                     closeAllExpanded()
                                 }} tabIndex={0}/>
                            <hr/>
                            <div className='px-[1.5rem]' style={{
                                overflowY: 'auto',
                                maxHeight: 'calc(100vh - 4rem)',
                                scrollbarWidth: 'none',
                                msOverflowStyle: 'none',
                                '&::-webkit-scrollbar': {display: 'none',}
                            }}>
                                <div className='flex flex-wrap justify-start items-center gap-2 mt-8'>
                                    <Badge label={`${selectedQuestion?.timeLimit} mins`} variant="secondary"
                                           className="whitespace-nowrap"/>
                                    {selectedQuestion.typeID === 1 ? (
                                        <Badge label={"MCQ"} variant="secondary" className="whitespace-nowrap"/>
                                    ) : selectedQuestion.typeID === 2 ? (
                                        <Badge label={"Subjective"} variant="secondary" className="whitespace-nowrap"/>
                                    ) : selectedQuestion.typeID === 3 ? (
                                        <Badge label={"Code"} variant="secondary" className="whitespace-nowrap"/>
                                    ) : (
                                        <Badge label={"Unknown"} variant="secondary" className="whitespace-nowrap"/>
                                    )}
                                    <Badge
                                        label={`${selectedQuestion?.difficulty.charAt(0).toUpperCase() + selectedQuestion?.difficulty.substring(1).toLowerCase()} `}
                                        variant="tertiary" className="whitespace-nowrap"/>
                                    {selectedQuestion?.tags?.map((badge) => (
                                        <Badge key={badge} label={badge} variant="primary"
                                               className="whitespace-nowrap"/>
                                    ))}
                                </div>

                                <p className="font-serif text-[1rem] text-m font-bold tracking-[0.1px] mt-8">{selectedQuestion.name}</p>
                                <div className="mt-[1rem] mb-6">
                                    <div
                                        className="font-sans text-m"
                                        dangerouslySetInnerHTML={{
                                            __html: selectedQuestion?.typeID === 1
                                                ? selectedQuestion?.popupDescription
                                                : selectedQuestion?.popupProblemStatement,
                                        }}
                                    />
                                </div>
                                {selectedQuestion?.typeID === 3 && selectedQuestion?.options && (
                                    <div>
                                        <div>
                                            <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                                <div className="flex justify-between text-[#16193a] cursor-pointer"
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setInputFormatExpanded(!isInputFormatExpanded)

                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Input
                                                        Format</h3>
                                                    {isInputFormatExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isInputFormatExpanded && (
                                                    <p className="font-serif text-s pt-2"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.inputFormat}}/>
                                                )}
                                            </div>
                                            <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                                <div className="flex justify-between text-[#16193a] cursor-pointer"
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setOutputFormatExpanded(!isOutputFormatExpanded)
                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Output
                                                        Format</h3>
                                                    {isOutputFormatExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isOutputFormatExpanded && (
                                                    <p className="font-sans text-s pt-2"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.outputFormat}}/>
                                                )}
                                            </div>
                                            <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                                <div className="flex justify-between text-[#16193a] cursor-pointer"
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setConstraintsExpanded(!isConstaintsExpanded)
                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Constraints</h3>
                                                    {isConstaintsExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isConstaintsExpanded && (
                                                    <p className="font-sans text-s pt-2"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.constraints}}/>
                                                )}
                                            </div>
                                            <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                                <div className="flex justify-between text-[#16193a] cursor-pointer"
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setSampleInputFormatExpanded(!isSampleInputFormatExpanded)
                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Sample
                                                        Input Format</h3>
                                                    {isSampleInputFormatExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isSampleInputFormatExpanded && (
                                                    <p className="font-sans text-s pt-2"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.sampleInput}}/>
                                                )}
                                            </div>
                                            <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                                <div className="flex justify-between text-[#16193a] cursor-pointer"
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setSampleOutputFormatExpanded(!isSampleOutputFormatExpanded)
                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Sample
                                                        Output Format</h3>
                                                    {isSampleOutputFormatExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isSampleOutputFormatExpanded && (
                                                    <p className="font-sans text-s pt-2"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.sampleOutput}}/>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                {selectedQuestion?.typeID === 1 && selectedQuestion?.options && (
                                    <div>
                                        <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                            <div className="">
                                                <div className='flex justify-between text-[#16193a] cursor-pointer'
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setOptionsExpanded(!isOptionsExpanded)
                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Options</h3>
                                                    {isOptionsExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isOptionsExpanded && (
                                                    <div className="mt-[1.5rem]">
                                                        {selectedQuestion.options.map(option => (
                                                            <div key={option.id}
                                                                 className='flex flex-row items-center border overflow-x-scroll p-2 rounded-[4px]'>
                                                                <span
                                                                    className='font-sans text-s font-bold ml-4 mr-[10px]'>{option.id}.</span>
                                                                <p className="font-sans text-s"
                                                                   dangerouslySetInnerHTML={{__html: option.text}}/>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                            <div>
                                                <div className='flex justify-between text-[#16193a] cursor-pointer'
                                                     style={{'fontFamily': 'helvetica'}}
                                                     onClick={() => {
                                                         setCorrectOptionExpanded(!isCorrectOptionExpanded)
                                                     }}>
                                                    <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Correct
                                                        option</h3>
                                                    {isCorrectOptionExpanded ?
                                                        <p className='text-[#000] font-extrabold'>-</p> :
                                                        <p className='text-[#000] font-extrabold'>+</p>}
                                                </div>
                                                {isCorrectOptionExpanded && (
                                                    <div className="mt-[1.5rem] ">
                                                        {selectedQuestion.options.filter(option => option.id === selectedQuestion.correctOptionId.toString()).map((correctOption) => (
                                                            <div key={correctOption.id} className="flex items-center">
                                                                <span
                                                                    className={`font-sans text-s font-bold ml-4 mr-[10px]`}>{correctOption.id}.</span>
                                                                <p className='font-sans text-s'
                                                                   dangerouslySetInnerHTML={{__html: correctOption.text}}/>
                                                            </div>
                                                        ))}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )}

                                <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                    {selectedQuestion?.attributesTested && (
                                        <div>
                                            <div className='flex justify-between text-[#16193a] cursor-pointer'
                                                 style={{'fontFamily': 'helvetica'}}
                                                 onClick={() => {
                                                     setAttributesExpanded(!isAttributesExpanded)
                                                 }}>
                                                <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Attributes
                                                    tested</h3>
                                                {isAttributesExpanded ?
                                                    <p className='text-[#000] font-extrabold'>-</p> :
                                                    <p className='text-[#000] font-extrabold'>+</p>}
                                            </div>
                                            {isAttributesExpanded && (
                                                <div className="mt-[1.5rem] ">
                                                    <p className="font-sans text-m"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.attributesTested}}/>
                                                </div>
                                            )}
                                        </div>
                                    )}
                                </div>

                                {selectedQuestion?.expectedAnswer && (
                                    <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                        <div>
                                            <div className='flex justify-between text-[#16193a] cursor-pointer'
                                                 style={{'fontFamily': 'helvetica'}}
                                                 onClick={() => {
                                                     setExpectedExpanded(!isExpectedExpanded)
                                                 }}>
                                                <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Expected
                                                    Answer</h3>
                                                {isExpectedExpanded ? <p className='text-[#000] font-extrabold'>-</p> :
                                                    <p className='text-[#000] font-extrabold'>+</p>}
                                            </div>
                                            {isExpectedExpanded && (
                                                <div className="mt-[1.5rem] ">
                                                    <p className="font-sans text-m"
                                                       dangerouslySetInnerHTML={{__html: selectedQuestion?.expectedAnswer}}/>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-2'>
                                    <div>
                                        <div className='flex justify-between text-[#16193a] cursor-pointer'
                                             style={{'fontFamily': 'helvetica'}}
                                             onClick={() => {
                                                 setExplanationExpanded(!isExplanationExpanded)
                                             }}>
                                            <h3 className='font-serif text-[1rem] text-m font-bold tracking-[0.1px]'>Answer
                                                explanation</h3>
                                            {isExplanationExpanded ? <p className='text-[#000] font-extrabold'>-</p> :
                                                <p className='text-[#000] font-extrabold'>+</p>}
                                        </div>
                                        {isExplanationExpanded && (
                                            <div className="mt-[1.5rem] ">
                                                <p className="font-sans text-m"
                                                   dangerouslySetInnerHTML={{__html: selectedQuestion?.answerExplanation}}/>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className="h-6"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default AssessmentQuestionWidget;