import React, { useRef, useState, useCallback } from 'react';
import Webcam from 'react-webcam';

const WebcamStreamCapture = ({ apiKey, storageZoneName, region }) => {
    const webcamRef = useRef(null);
    const mediaRecorderRef = useRef(null);
    const [capturing, setCapturing] = useState(false);
    const [recordedChunks, setRecordedChunks] = useState([]);

    const handleStartCaptureClick = useCallback(() => {
        setCapturing(true);
        mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
            mimeType: 'video/webm',
        });
        mediaRecorderRef.current.addEventListener(
            "dataavailable",
            handleDataAvailable
        );
        mediaRecorderRef.current.start();
    }, [webcamRef]);

    const handleDataAvailable = useCallback(({ data }) => {
        if (data.size > 0) {
            setRecordedChunks((prev) => prev.concat(data));
        }
    }, []);

    const handleStopCaptureClick = useCallback(() => {
        mediaRecorderRef.current.stop();
        setCapturing(false);
    }, [mediaRecorderRef]);

    const handleUpload = useCallback(async () => {
        if (recordedChunks.length) {
            const blob = new Blob(recordedChunks, {
                type: "video/webm"
            });
            const fileName = `video-${new Date().toISOString()}.webm`; // Declare fileName here
            const uploadUrl = `https://sg.storage.bunnycdn.com/storage-zone-199/${fileName}`;

            // Convert the blob to a raw binary string
            const reader = new FileReader();
            reader.readAsArrayBuffer(blob);
            reader.onloadend = async () => {
                const arrayBuffer = reader.result;
                const uint8Array = new Uint8Array(arrayBuffer);

                try {
                    const response = await fetch(uploadUrl, {
                        method: 'PUT',
                        headers: {
                            AccessKey: '59ca7d1a-5a50-41cc-9d97b3d70701-bb66-4e61',
                            'Content-Type': 'application/octet-stream'
                        },
                        body: arrayBuffer // sending raw binary data
                    });

                    if (response.status === 201) {
                        console.log('Video uploaded successfully');
                    } else {
                        throw new Error('Upload failed with status: ' + response.status);
                    }
                } catch (error) {
                    console.error('Error during video upload:', error);
                } finally {
                    setRecordedChunks([]);
                }
            };
        }
    }, [recordedChunks, apiKey, storageZoneName, region]);

    return (
        <>
            <Webcam audio={true} ref={webcamRef} />
            {capturing ? (
                <button onClick={handleStopCaptureClick}>Stop Capture</button>
            ) : (
                <button onClick={handleStartCaptureClick}>Start Capture</button>
            )}
            {recordedChunks.length > 0 && (
                <button onClick={handleUpload}>Upload Video</button>
            )}
        </>
    );
};

export default WebcamStreamCapture;
