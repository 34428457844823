// otpSlice.js

import { createSlice } from '@reduxjs/toolkit';

const otpSlice = createSlice({
  name: 'otp',
  initialState: {
    values: Array(6).fill(''),
  },
  reducers: {
    setOTPValue: (state, action) => {
      const { index, value } = action.payload;
      const newValues = [...state.values];
      newValues[index] = value;
      state.values = newValues;
    },
  },
});

export const { setOTPValue } = otpSlice.actions;
export const selectOTPValues = (state) => state.otp.values;

export default otpSlice.reducer;
