// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rounded-table1 {
    border-radius: 0.75rem 0.75rem 0 0;
    overflow: hidden; 
}

.rounded-table2 {
    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;
    overflow: hidden; 
}

.custom-text-padding {
    padding-right: 2rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/components/candidateTable.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,gBAAgB;AACpB;;AAEA;IACI,8CAA8C;IAC9C,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;EACrB","sourcesContent":[".rounded-table1 {\n    border-radius: 0.75rem 0.75rem 0 0;\n    overflow: hidden; \n}\n\n.rounded-table2 {\n    border-radius: 0.75rem 0.75rem 0.75rem 0.75rem;\n    overflow: hidden; \n}\n\n.custom-text-padding {\n    padding-right: 2rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
