export const setUserCredentials = (email, password) => ({
    type: 'SET_USER_CREDENTIALS',
    payload: { email, password },
});

// reducer.js
const initialState = {
    email: null,
    password: null,
    // ... other states
};