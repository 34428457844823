import React, {useEffect, useState} from "react";
import VideoInterview from "./videoInterview";
import Feedback from "../writtenTest/feedback";
import Modal from "../../components/popup";
import ThreeButtonLoader from "../../components/ThreeButtonLoader";

export function VideoError({onCloseUpper}) {
    const [open, setOpen] = useState(true);
    const onClose = () => {
        setOpen(false);
        onCloseUpper();
    };
    return (
        <Modal
            isOpen={open}
            onClose={onClose}
            message={"Error fetching video interview, please try again"}
            showCancelButton={false}
            confirmButtonText={"Ok"}
            onConfirm={onClose}
        />
    );
}

export default function VideoInterviewOrchestrator() {
    const [questions, setQuestions] = useState({questions: [], details: {}});
    const [curQuestion, setCurQuestion] = useState(-2);
    const [modal, setModal] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(75); // Set default progress to 75
    const [isUploading, setIsUploading] = useState(false);

    const dismissModal = () => {
        setModal(null);
    };

    const candidateId = localStorage.getItem("candidateId");

    useEffect(() => {
        const accessToken = localStorage.getItem("token");
        const fetchData = async () => {
            setIsLoading(true);
            const json_data = await (
                await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getVideoInterview?candidateId=${candidateId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
            ).json();
            setIsLoading(false);
            if (json_data.status === 200) {
                setQuestions({
                    questions: json_data.data.questions,
                    details: {
                        candidateId: json_data.data.candidate,
                        interviewId: json_data.data.videoInterview,
                        assessment: json_data.data.assessment,
                    },
                });
                if (json_data.data.questions.length > 0) {
                    setCurQuestion(0);
                } else {
                    console.log("No questions");
                    setCurQuestion(-1);
                }
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        console.log(process.env.REACT_APP_TRANSCRIPTION_URL)
    }, []);

    const submitQuestionForEval = async (question, args) => {
        console.log("submitting");
        setIsUploading(true);
        setProgress(75); // Set progress to 75 when submitting
        const formData = new FormData();
        formData.set("assessmentId", questions.details.assessment);
        formData.set("candidateId", questions.details.candidateId);
        formData.set("questionId", questions.questions[question].id);
        formData.set("mimeType", "application/webm");
        formData.append("file", args.video, "video.webm");
        console.log(JSON.stringify(formData.entries()));
        setCurQuestion((q) =>
            q + 1 < questions.questions.length ? q + 1 : -3
        );
        try {
            console.log("url", process.env.REACT_APP_TRANSCRIPTION_URL);
            const response = await fetch(
                `${process.env.REACT_APP_TRANSCRIPTION_URL}/v1/video-interviews/upload`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                    body: formData,
                }
            );
            const data = await response.json();
            if (data.status === 200) {
                setProgress(100); // Set progress to 100 on successful response
            } else {
                console.log(data);
                setModal(<VideoError onCloseUpper={dismissModal}/>);
            }
        } catch (e) {
            console.log(e);
            setCurQuestion((q) => q - 1);
            setModal(<VideoError onCloseUpper={dismissModal}/>);
        } finally {
            setIsUploading(false);
        }
    };

    if (modal) {
        return modal;
    }

    if (isLoading) {
        return <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50"><ThreeButtonLoader  message="Please wait while we fetch your interview"/></div>
    } else if (curQuestion === -2 || isUploading) {
        // Loading or submitting, show progress bar with animation
        return <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50"><ThreeButtonLoader 
            message={<div>
                <p><strong>Uploading your video...</strong></p>
                {/*<p>Please do not close or refresh your browser.</p>*/}
                <p style={{color: '#F6004B', 'fontSize': '1rem'}}>Closing or refreshing your
                    browser will result in data
                    loss.</p>
            </div>}/></div>
    } else if (curQuestion === -1) {
        return (
            <div className="flex items-center justify-center h-screen">
                <p className="text-lg font-semibold">No Questions!</p>
            </div>
        );
    } else if (curQuestion === -3) {
        return (
            <div className="flex items-center justify-center h-[100%]">
                <Feedback/>
            </div>
        );
    }

    window.addEventListener('contextmenu', (e) => {
        e.preventDefault();
    });

    window.addEventListener('keydown', (e) => {
        // Disable F12 key
        if (e.key === 'F12' || e.keyCode === 123) {
            e.preventDefault();
        }
    });

    return (
        <>
            <VideoInterview
                question={questions.questions[curQuestion]}
                submit={async (args) => {
                    await submitQuestionForEval(curQuestion, args);
                }}
                key={curQuestion}
            />
        </>
    );
}