import React, { useState } from 'react';

import DOMPurify from 'dompurify';
import QuillRTE from '../../../components/Editor/QuillRichTextEditor';


const EditorWithPreview = () => {
    const [content, setContent] = useState('');

    const handleContentChange = (args) => {
        // Update the state with the sanitized content from the RTE
        // Make sure to access the correct property for the editor's content
        setContent(DOMPurify.sanitize(args.value));
    };

    return (
        <div className="flex mt-12">
            <div className="flex-1">
                <QuillRTE change={handleContentChange} width="100%"
                    height="30vh"
                    className='z-0 max-h-[30vh] min-h-[30vh] overflow-none' />
            </div>
            <div className="flex-1">
                {/* This will render the sanitized HTML content */}
                <div dangerouslySetInnerHTML={{ __html: content }} />
            </div>
        </div>
    );
};

export default EditorWithPreview;