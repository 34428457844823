import React, {useState} from "react";

function CsvUploader(props) {
    const [fileName, setFileName] = useState(null);

    const handleFileUpload = async (file) => {
        console.log("uploader file", file);
        const reader = new FileReader();
        reader.onload = (e) => {
            const text = e.target.result;
            const rows = text.split("\n").map(row => row.split(","));
            props.onUpload(rows); // Pass parsed CSV data to parent component
        };
        reader.readAsText(file);
    };

    return (
        <div tabIndex={0}
             className={`${props.variant === "white" ? "bg-white border border-zinc-200 text-charcoal" : "bg-[#004eec] text-white"} ${props.className} w-[{props.width}] h-[2.25rem] rounded-m text-sans text-base font-regular`}
             style={{width: props.width, height: props.height}}
             onClick={() => document.querySelector('.input-field').click()}
        >
            <input
                type="file"
                accept=".csv" // Only accept CSV files
                className="input-field hidden"
                id="fileInput"
                onChange={({target: {files}}) => {
                    if (files[0]) {
                        setFileName(files[0].name);
                        handleFileUpload(files[0]);
                    }
                }}
            />
            <p className="mt-2.5 textUploader text-center text-xs leading-tight cursor-pointer"
               style={{fontFamily: 'helvetica', fontWeight: '200'}}>
                UPLOAD CSV
            </p>
        </div>
    );
}

export default CsvUploader;
