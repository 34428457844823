import React from 'react';
import './pagination.css'; // Add your styling here

const Pagination = ({currentPage, totalPages, onPageChange}) => {
    const pagesToShow = 5; // Always show 5 pages including the current page
    const halfPagesToShow = Math.floor(pagesToShow / 2);

    const generatePages = () => {
        const pages = [];
        const firstPage = 1;
        const lastPage = totalPages;

        let startPage, endPage;

        if (totalPages <= pagesToShow) {
            // If total pages are less than or equal to pagesToShow, show all pages
            startPage = firstPage;
            endPage = lastPage;
        } else if (currentPage + 1 <= halfPagesToShow + 1) {
            // If the current page is near the beginning, show the first `pagesToShow` pages
            startPage = firstPage;
            endPage = pagesToShow;
        } else if (currentPage + 1 + halfPagesToShow >= totalPages) {
            // If the current page is near the end, show the last `pagesToShow` pages
            startPage = totalPages - pagesToShow + 1;
            endPage = lastPage;
        } else {
            // Otherwise, show pages around the current page
            startPage = currentPage + 1 - halfPagesToShow;
            endPage = currentPage + 1 + halfPagesToShow;
        }

        // Add pages to the array
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // If necessary, add ellipses
        if (startPage > firstPage + 1) {
            pages.unshift('...');
            pages.unshift(firstPage);
        } else if (startPage === 2) {
            pages.unshift(firstPage);
        }

        if (endPage < lastPage - 1) {
            pages.push('...');
            pages.push(lastPage);
        } else if (endPage === lastPage - 1) {
            pages.push(lastPage);
        }

        return pages;
    };

    const pages = generatePages();

    return (
        <div className="pagination-container">
            <button
                className="pagination-arrow"
                onClick={() => onPageChange(currentPage - 1)}
                disabled={currentPage === 0}
            >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7"></path>
                </svg>
            </button>
            {pages.map((page, index) =>
                typeof page === 'number' ? (
                    <button
                        key={index}
                        className={`pagination-number ${page === currentPage + 1 ? 'active' : ''}`}
                        onClick={() => onPageChange(page - 1)}
                    >
                        {page}
                    </button>
                ) : (
                    <span key={index} className="pagination-ellipsis">...</span>
                )
            )}
            <button
                className="pagination-arrow"
                onClick={() => onPageChange(currentPage + 1)}
                disabled={currentPage === totalPages - 1}
            >
                <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24"
                     xmlns="http://www.w3.org/2000/svg">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7"></path>
                </svg>
            </button>
        </div>
    );
};

export default Pagination;
