import OtpComponent from "../../components/otpComponent";
import logo from "../../../assets/logo_ovii_2.svg";
import deer from "../../../assets/deer.png";
import FooterLinks from "../../components/footerLinks";
import icon from "../../../assets/icon.svg";
import React from "react";
import {useNavigate} from "react-router-dom";
import {useSelector} from 'react-redux';

const ForgotPassword2 = () => {
    const navigate = useNavigate();
    const email = useSelector(state => state.forgotPassword.email);

    // for the otp verification logic please refer to the OtpComponent in src/components/components/otpComponent.jsx

    const navigateToNext = () => {
        navigate("/forgotPassword3");
    }

    return (
        <div className="h-dvh">
            <div className="flex flex-col justify-center items-center">
                <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mt-[9.75rem]"/>
                <p className="font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mt-[1.825rem]">OTP
                    Verification</p>
                <OtpComponent email={email} onSubmit={navigateToNext}/>
                <div className="flex items-center">
                    <img src={icon} alt="Back icon" className="w-[0.75rem] h-[0.625rem] right-1"/>
                    <p className='text-sans text-sm tracking-[0.14px] pl-1'>Back to
                        <span className='text-blue font-semibold cursor-pointer'
                              onClick={() => navigate("/login")} tabIndex={0}> login</span>
                    </p>
                </div>
                {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
            </div>
        </div>
    )
}

export default ForgotPassword2;