import React, {useEffect, useState, useRef} from "react";
import '../candidateTable.css';
import '../../../assets/font.css';
import Checkbox from "../checkbox";
import {useDispatch} from "react-redux";
import { useReactToPrint } from 'react-to-print';
import print from "../../../assets/print.svg"
import { Button } from "../button";
import Modal from "../popup";

const CandidateScoreDetailsLayout = ({onRefresh, candidateId, isOpen, onClose}) => {
    const [candidateScoreDetails, setCandidateScoreDetails] = useState();
    const accessToken = localStorage.getItem("accessToken");
    const [evalID, setEvalID] = useState(1)
    const [rating, setRating] = useState(0)
    const [percent, setPercent] = useState(0)
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [selectedEval, setSelectedEval] = useState();
    const [email, setEmail] = useState(null)

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const dispatch = useDispatch();

    const handleRadioChange = (event) => {
        if (event.target.value === "shortlist") {
            const candidateEmail = email
            let shortlistedCandidates = JSON.parse(localStorage.getItem('shortlistedCandidates')) || [];

            if (!shortlistedCandidates.includes(candidateEmail)) {
              shortlistedCandidates.push(candidateEmail);
              localStorage.setItem('shortlistedCandidates', JSON.stringify(shortlistedCandidates));
            }
            handleEvaluationChange(5);
        } else if (event.target.value === "hold") {
            handleEvaluationChange(2);
        } else if (event.target.value === "reject") {
            handleEvaluationChange(4);
        }
    };


    const handleEvaluationChange = async (evalID) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/updateCandidateEvaluation?candidateId=${candidateId}&evaluationId=${evalID}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data && data.status === 200) {
                setModalContent({
                    heading: 'Success', 
                    message: 'Candidate evaluation updated.', 
                    confirmButtonText: 'OK', 
                });
                setShowModal(true);
            } else {
                setModalContent({
                    heading: 'Error', 
                    message: 'Error updating candidate evaluation.', 
                    confirmButtonText: 'CLOSE', 
                });
                setShowModal(true);
            }
        } catch (error) {
            setModalContent({
                heading: 'Error', 
                message: 'Error updating candidate evaluation.', 
                confirmButtonText: 'CLOSE', 
            });
            setShowModal(true);
        }
    };

    useEffect(() => {
        const fetchCandidateScoreDetails = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getCandidateDetails?candidateId=${candidateId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                const data = await response.json();
                if (data && data.status === 200) {
                    setCandidateScoreDetails(data.data);
                    console.log("candidate Score details:", candidateScoreDetails)

                    if (candidateScoreDetails) {
                        const score = data.data.score;
                        console.log("score:", score);
                        const totalScore = calculateTotalScore(data.data);
                        setPercent(parseFloat(((score / totalScore) * 100).toFixed(2)))
                        console.log("total score:", totalScore);
                        const rating = calculateRating(totalScore, score);
                        setRating(rating)
                        console.log("rating:", rating)
                        console.log("type of rating", typeof (rating))
                        setSelectedEval(data.data.evaluation.evaluationStatusId)
                        setEmail(data.data.candidateEmail)
                    }
                } else {
                    console.error("Error fetching candidate score details: ", data.message);
                    setCandidateScoreDetails("The candidate has not yet completed the assessment, please check back in when the candidate has completed this assessment for their score details.");
                }
            } catch (error) {
                console.error("Error fetching candidate score details: ", error);
            }
        };
        if (!!candidateId) fetchCandidateScoreDetails();
    }, [accessToken, candidateId, evalID]);

    console.log("candidate score details:", candidateScoreDetails)

    function calculateTotalScore(data) {
        let totalScore = 0;

        if (data.questions.true) {
            data.questions.true.forEach(item => {
                if (item.questionResponse.questionType.questionTypeId === 3) {
                    const hiddenTestCasesCount = item.questionResponse.questionDataAsJson.hiddenTestCases.length;
                    totalScore += (item.scoreResponse.totalScore || 0) * hiddenTestCasesCount;
                    totalScore += 10;
                } else {
                    totalScore += item.scoreResponse.totalScore || 0;
                }
            });
        }

        if (data.questions.false) {
            data.questions.false.forEach(item => {
                if (item.questionResponse.questionType.questionTypeId === 3) {
                    const hiddenTestCasesCount = item.questionResponse.questionDataAsJson.hiddenTestCases.length;
                    totalScore += (item.scoreResponse.totalScore || 0) * hiddenTestCasesCount;
                    totalScore += 10;
                } else {
                    totalScore += item.scoreResponse.totalScore || 0;
                }
            });
        }
        return totalScore;
    }

    function calculateRating(totalScore, score) {
        let rating = 0;
        rating = (score / totalScore) * 100;
        return (Math.floor(rating)) / 10;
    }

    const UserDetails = ({name, rating, email}) => {
        console.log("rating inside userDetails", rating)
        return (
            <div className="flex items-center m-0 my-0 gap-x-4">
                <div className="flex flex-col gap-y-0">
                    <span className="text-xl font-bold text-slate-800 font-serif leading-[1.26rem]">{name}</span>
                </div>
                <div>
                    {renderStars(rating)}
                </div>
            </div>
        );
    };

    const formatTime = (seconds) => {
        const minutes = Math.ceil(seconds / 60);
        return `${minutes}`;
    }

    function renderStars(rating) {
        const stars = [];
        console.log("rating inside renderStars", rating)
        rating = rating / 2;
        const integerPart = Math.floor(rating);
        const decimalPart = rating - integerPart;
        for (let i = 0; i < 5; i++) {
            if (i < integerPart) {
                stars.push(<span key={i} className="text-blue">&#9733;</span>);
            } else if (i === integerPart && decimalPart > 0) {
                stars.push(<span key={i} className="text-blue">&#9733;</span>);
            } else {
                stars.push(<span key={i} className="text-gray-300">&#9733;</span>);
            }
        }
        return stars;
    }


    const QuestionCard = ({question, type}) => {
        function handleQuestionSelect() {
            localStorage.setItem("selectedQuestion", JSON.stringify(question));
            window.open(`/question`, '_blank');
        }

        return (
            <div className="flex flex-col min-h-24" onClick={handleQuestionSelect}>
                <h2 className="w-full font-semibold text-md" style={{fontFamily: 'HelveticaNeue'}}>
                    {question.questionResponse.questionType.name}
                </h2>
                <div className="flex flex-row items-center justify-between my-2">
                    {question.questionResponse.questionType.questionTypeId === 1 && (
                        <p className="w-4/5 text-sm text-gray-600 line-clamp-2" style={{fontFamily: 'HelveticaNeue'}}
                           dangerouslySetInnerHTML={{__html: question.questionResponse.questionDataAsJson.text}}/>
                    )}
                    {question.questionResponse.questionType.questionTypeId === 2 && (
                        <p className="w-4/5 text-sm text-gray-600 line-clamp-2" style={{fontFamily: 'HelveticaNeue'}}
                           dangerouslySetInnerHTML={{__html: question.questionResponse.questionDataAsJson.problemStatement}}/>
                    )}
                    {question.questionResponse.questionType.questionTypeId === 3 && (
                        <p className="w-4/5 text-sm text-gray-600 line-clamp-2" style={{fontFamily: 'HelveticaNeue'}}
                           dangerouslySetInnerHTML={{__html: question.questionResponse.questionDataAsJson.problemStatement}}/>
                    )}
                    <button
                        className={`h-8 px-5 text-sm font-semibold ${
                            !!type ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                        }  rounded-md w-fit`}
                    >
                        {!!type ? "Right Answer" : "Wrong Answer"}
                    </button>
                </div>
                <hr className="mt-2 border"/>
            </div>
        );
    };

    const QuestionList = ({questions}) => {
        return (
            <div>
                {questions.map((question, index) => (
                    <QuestionCard key={index} question={question}/>
                ))}
            </div>
        );
    };

    const TableRow = ({header, value, tag}) => {
        let tagClass = "";

        if (tag) {
            const tagClasses = {
                "NOT_STARTED": "bg-[#F4F2FD] text-[#4F2EBC]",
                "IN_PROGRESS": "bg-[#FFF8EF] text-[#C28818]",
                "COMPLETED": "bg-[#EDFCF2] text-[#308358]",
            };

            tagClass = tagClasses[value] || "bg-gray-100 text-gray-700";
        }

        return (
            <tr className="bg-white border-b">
                <th
                    scope="row"
                    className="px-6 py-2 font-medium text-black whitespace-nowrap"
                >
                    {header}
                </th>
                {!!tag ? (
                    <td className="px-6 py-2 font-medium text-center">
                        <span className={`rounded-[3px] text-xs uppercase px-2 py-0.5 ${tagClass}`}>
                            {value}
                        </span>
                    </td>
                ) : (
                    <td className="px-6 py-2 font-medium text-black text-center">
                        {value}
                    </td>
                )}
            </tr>
        );
    };

    const SkillsTableRow = ({header, value}) => {
        return (
            <tr className="border border-[#d8d8d8] border-gg h-10">
                <th
                    scope="row"
                    className="font-sans font-bold pl-1 font-14px font-600"
                >
                    {header}
                </th>
                <td className="font-sans font-bold pl-3 font-14px font-600">
                    {value}
                </td>
            </tr>
        );
    };

    const handleModalConfirm = () => {
        setShowModal(false);
        onRefresh();
    };

    return (
        <div 
            className={`fixed top-0 ${
                isOpen ? "-right-0" : "-right-[100%]"
            } transition-all duration-700 w-3/4 h-screen overflow-y-scroll bg-white shadow-2xl rounded-l-2xl z-50`}
        >
            {candidateScoreDetails && (
                <div className="w-[100%]" ref={componentRef}>
                    <div
                        className="flex items-center justify-between px-10 py-5 w-full p-0 m-0 border-b-[1px] shadow-md">
                        <div>
                            <UserDetails
                                name={candidateScoreDetails.candidateName}
                                rating={rating}
                            />
                        </div>
                        <div className="flex justify-end">                
                            <Button width="3rem" variant="no-outline" onClick={handlePrint}><img src={print} alt="download"/></Button>
                            <button
                                onClick={onClose}
                                className="relative top-1 left-7 w-6 h-6 p-1 m-0 text-xs text-white bg-blue rounded-full"
                            >
                                X
                            </button>
                        </div>
                    </div>
                    <div className="grid w-full grid-cols-2 mt-8 gap-x-16 gap-y-8 px-10">
                        <div className="flex flex-col col-span-1 gap-y-6">
                            <div>
                                <h3 className="w-full mb-3 font-bold text-black text-md">
                                    Additional Information
                                </h3>
                                <table
                                    className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-sm border">
                                    <tbody>
                                    <TableRow
                                        header="Status"
                                        tag={true}
                                        value={candidateScoreDetails.status}
                                    />
                                    <TableRow
                                        header="Score"
                                        value={percent + "%"}
                                    />
                                    <TableRow
                                        header="Expected Solve Time"
                                        value={`${candidateScoreDetails.expectedSolveTime} Minutes`}
                                    />
                                    <TableRow
                                        header="Time Taken"
                                        value={`${formatTime(candidateScoreDetails.totalTimeTaken)} mins`}
                                    />
                                    <TableRow
                                        header="Trust Score"
                                        value={candidateScoreDetails.trustScore}
                                    />
                                    </tbody>
                                </table>
                            </div>
                            <div>
                                <h3 className="w-full mb-3 font-bold text-black text-md">
                                    Score By Skills
                                </h3>
                                <div className="overflow-y-auto max-h-[11rem]">
                                    <table
                                        className="w-full text-sm text-left rtl:text-right text-gray-500 rounded-md border">
                                        <thead className="sticky top-0 bg-[#FAFAFA] h-10 border-[#d8d8d8]">
                                        <tr className="">
                                            <th className="text-charcoal text-[13px] text-justify pl-2 font-regular cursor-pointer"
                                                style={{width: "60%"}}>Skill
                                            </th>
                                            <th className="text-charcoal text-[13px] text-justify pl-2 font-regular cursor-pointer"
                                                style={{width: "40%"}}>Score
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {Object.entries(candidateScoreDetails.scoreBySkills).map(([key, value]) => (
                                            <SkillsTableRow key={key} header={key}
                                                            value={parseFloat(value).toFixed(2)}/>
                                        ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col col-span-1 gap-y-4">
                            <div>
                                <div className="mb-4">
                                    <h3 className="mt- mb-3 font-bold text-black text-md">
                                        Evaluation
                                    </h3>
                                    <div className="flex gap-4">
                                        <label className="flex items-center border border-gray-400 w-[10rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                                            <input 
                                                type="radio" 
                                                value="shortlist" 
                                                checked={selectedEval === 5} 
                                                onChange={handleRadioChange} 
                                                className="" 
                                            />
                                            <span className="ml-2">Shortlist</span>
                                        </label>
                                        <label className="flex items-center border border-gray-400 w-[10rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                                            <input 
                                                type="radio" 
                                                value="hold" 
                                                checked={selectedEval === 2} 
                                                onChange={handleRadioChange} 
                                                className=""
                                            />
                                            <span className="ml-2">Hold</span>
                                        </label>
                                        <label className="flex items-center border border-gray-400 w-[10rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                                            <input 
                                                type="radio" 
                                                value="reject" 
                                                checked={selectedEval === 4} 
                                                onChange={handleRadioChange} 
                                                className=""
                                            />
                                            <span className="ml-2">Reject</span>
                                        </label>
                                    </div>
                                </div>
                                <h3 className=" mb-3 font-bold text-black text-md">
                                    Test Video
                                </h3>
                                <div className="flex items-center justify-center w-full m-0">
                                    <video
                                        src={"https://storage-zone-1999.b-cdn.net/" + candidateScoreDetails.videoUrl}
                                        controls
                                        className="w-[100%]"
                                    ></video>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-full col-span-2 gap-y-4">
                            <div className="flex justify-end text-sm font-medium text-blue">
                                Expected Solve Time:
                                <span className="text-blue-700 ms-1.5">
                                    {candidateScoreDetails.expectedSolveTime} Minutes
                                </span>
                            </div>
                            {candidateScoreDetails.questions.false.map((question) => (
                                <QuestionCard key={question.questionResponse.questionId} question={question}
                                              type={false}/>
                            ))}
                            {candidateScoreDetails.questions.true.map((question) => (
                                <QuestionCard key={question.questionResponse.questionId} question={question}
                                              type={true}/>
                            ))}
                        </div>
                    </div>
                </div>
            )}
            <Modal
                isOpen={showModal}
                onClose={handleModalConfirm}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
        </div>
    );
};

export default CandidateScoreDetailsLayout;