import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import { saveEmail } from '../../../redux/actions/ForgotPasswordActions';
import logo from '../../../assets/logo_ovii_2.svg';
import { Textbox } from "../../components/textbox";
import { Button } from "../../components/button";
import hello from '../../../assets/Hello.svg'
import icon from "../../../assets/icon.svg";
import Modal from "../../../components/components/popup"

export const ForgotPassword1 = () => {
    const [email, setEmail] = useState('');
    const [buttonClicked, setButtonClicked] = useState(false); // New state variable
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const validatorRef = useRef(null);
    const [otpGenerated, setOtpGenerated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    useEffect(() => {
        // Initialize FormValidator if the formRef is defined
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                rules: {
                    'email': { required: true, email: true }
                },
            });
        }
    }, [validatorRef]);

    const handleGenerateAndSendOTP = async (e) => {
        e.preventDefault();
        if (!buttonClicked && validatorRef.current && validatorRef.current.validate()) {
            setIsLoading(true)
            setButtonClicked(true); // Disable the button
            if (email != null) {
                try {
                    const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/generateOTP?email=${encodeURIComponent(email)}`, {
                        method: 'POST',
                    });
                    dispatch(saveEmail(email));
                    if (response.ok) {
                        const data = await response.json();
                        if (data.status === 200) {
                            setIsLoading(false)
                            navigate('/forgotPassword2');
                        } else {
                            setIsLoading(false)
                            setModalContent({
                                heading: 'Error',
                                message: `We couldn't find an account with that email address. Please try again.`,
                                confirmButtonText: 'OK',
                                onConfirm: handleModalConfirm,
                            })
                            setShowModal(true);
                        }
                    } else {
                        setIsLoading(false)
                        setModalContent({
                            heading: 'Error',
                            message: `We couldn't find an account with that email address. Please try again.`,
                            confirmButtonText: 'OK',
                            onConfirm: handleModalConfirm,
                        })
                        setShowModal(true);
                    }
                } catch (error) {
                    setIsLoading(false)
                    setModalContent({
                        heading: 'Error',
                        message: `Something went wrong. Please try again.`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    })
                    setShowModal(true);
                } finally {
                    setIsLoading(false)
                    setButtonClicked(false); // Enable the button after API call
                }
            }
        }
    };

    return (
        <div className='h-dvh'>
            <form id='forgotPasswordForm' ref={formRef} onSubmit={handleGenerateAndSendOTP}>
                <div className="flex flex-col items-center justify-center">
                    <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mt-[9.95rem]" />
                    <p className='font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mt-[1.825rem]'>Forgot
                        password?</p>
                    <Textbox
                        label={(
                            <label className="flex items-center">
                                Email
                                <span className="text-red-500" style={{ color: '#E90C44' }}>*</span>
                            </label>
                        )}
                        placeholder='olivia@boltcode.io'
                        className='mt-[1.25rem]'
                        inputClassName='w-[20rem]'
                        type="email"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <Button className="cursor-pointer text-[0.735rem] h-[2.25rem]" variant='bright-blue'
                        label={isLoading ? "LOADING..." : "CONTINUE"} width="20rem" type="submit"></Button>
                    <p className='mt-[1rem] text-sans text-charcoal text-base leading-tight'> New to ovii?
                        <a className='font-medium cursor-pointer text-blue' onClick={() => navigate("/register")}
                            tabIndex={0}> Create an account</a>
                    </p>
                    <div>
                        <img src={icon} alt="Back icon" className="w-[0.75rem] h-[0.625rem] relative top-4 right-4" />
                        <p className='text-sans text-sm tracking-[0.14px]'>Back to
                            <a className='font-semibold cursor-pointer text-blue' onClick={() => navigate("/login")}
                                tabIndex={0}> login</a>
                        </p>
                    </div>
                </div>
            </form>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
            {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
            <img src={hello}
                alt="man saying hello"
                className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </div>
    );
};
