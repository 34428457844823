import React, { useEffect, useRef, useState } from 'react';
import { Button } from '../../../components/button';

import { useDispatch, useSelector } from "react-redux";
import { clearAnswer, setAnswer } from "../../../../redux/reducers/answersSlice";
import QuillRTE from '../../../components/Editor/QuillRichTextEditor';

const CandidateSubjective = ({ question, onNextQuestion }) => {

    // console.log(question)
    const problemStatement = question.questionDataAsJson.problemStatement
    const answer = useSelector(state => state.answers[question.questionId] || '');
    const editorRef = useRef(null);
    const dispatch = useDispatch();
    const [submitloading, setSubmitloading] = useState(false);

    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.value = answer; // Set the editor's content to the stored answer
        }
    }, [answer, editorRef]);

    // Remove below code after
    const reduxVar = useSelector(state => state);
    console.log("Redux Store Contents:", reduxVar);
    // Remove above code after

    const handleAnswerChange = (e) => {
        dispatch(setAnswer({ questionId: question.questionId, answer: e.value }));
    };

    const clearEditorContent = () => {
        dispatch(clearAnswer({ questionId: question.questionId }));
        if (editorRef.current) {
            editorRef.current.value = '';
        }
    }

    // Assuming you have a method to obtain the authToken and questionId
    const authToken = localStorage.getItem("token"); // You need to replace this with the actual token
    const candidateId = localStorage.getItem("candidateId");

    const submitAnswer = async () => {
        setSubmitloading(true)
        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/submitAnswers?candidateId=${candidateId}`; // Replace 'test' with your actual API's base URL
        const questionId = question.questionId; // Assuming this is how you access the question ID

        const requestBody = [
            {
                "questionId": questionId,
                "answer": {
                    "answer": answer,
                    "languageId": 91 // Assuming this is static; replace or adjust as needed
                }
            }
        ];

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}` // Include the auth token in the request headers
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);
            onNextQuestion();
            // Handle successful response here, e.g., show a success message or update the UI accordingly
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            // Handle error here, e.g., show an error message
        } finally {
            setSubmitloading(false)
        }
    };


    return (
        <div className="flex justify-center align-center mt-[4rem] w-[100%] max-w-[90vw] min-w-[90vw]">
            <div
                className="h-[80vh] mr-auto mx-auto rounded-lg shadow px-[3.5rem] py-[1rem] w-[100%]">
                <div className='text-left overflow-y-auto max-h-[27vh]'>
                    <h3 className='font-serif text-3xl'>Problem Statement</h3>
                    <p className='mt-2 text-[1rem] font-inter' dangerouslySetInnerHTML={{ __html: problemStatement }} />
                </div>
                <div className='pt-[2rem] pb-[1rem]'>
                    <QuillRTE disabled={true}
                        width="100%" //33rem
                        height="30vh"
                        className='z-0 max-h-[30vh] min-h-[30vh] overflow-none'
                        ref={editorRef}
                        change={handleAnswerChange}
                        editorWrapperClass="" />
                </div>
                <div className='flex justify-end mt-[2.563rem] gap-2'>
                    <Button label="Clear"
                        width="14.781rem"
                        variant="white"
                        onClick={clearEditorContent}
                    />
                    <Button label={submitloading ? "PLEASE WAIT ..." : "SUBMIT"}
                        width="14.781rem"
                        variant="bright-blue"
                        onClick={submitAnswer}
                    />
                </div>
            </div>
            {/*<div className='fixed bottom-0 left-0 z-0 w-full text-center'>*/}
            {/*    <p>Powered by<img className="inline w-10 h-10 pl-2 mb-1" src={logo}></img></p>*/}
            {/*</div>*/}
        </div>
    );
};

export default CandidateSubjective;
