import React from 'react';
import './loadingOverlay.css'; // Import the CSS file

export default function LoadingOverlay() {
    return (
        <div className="max-h loading-overlay flex items-center justify-center">
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        </div>
    );
}
