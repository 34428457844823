import React, {useEffect, useRef, useState} from "react"
import {useNavigate, useSearchParams} from "react-router-dom"
import logo from '../../../assets/logo_ovii_2.svg'
import {Button} from "../../components/button";
import hello from '../../../assets/Hello.svg'
import Checkbox from "../../components/checkbox";
import {Textbox} from "../../components/textbox";
import {useDispatch} from "react-redux";
import {FormValidator} from "@syncfusion/ej2-react-inputs";
import PhoneNumberInput from "../../utilities/phoneCodeDropDown";
import Modal from "../../components/popup";
import {loginUser} from "../../../redux/actions/loginActions";

export const AddInvitedUser = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const email = searchParams.get("email"); // use this instead of useParams
    const teamId = searchParams.get("teamId");
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [dropDownValue, setDropDownValue] = useState('');
    const [phone, setPhone] = useState('');
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const handleCheckboxChange = (e) => {
        setIsTermsChecked(e.target.checked);
    };

    const hasUppercase = (value) => {
        const password = /[A-Z]/;
        return password.test(value.value || '');
    };
    const hasLowercase = (value) => {
        const password = /[a-z]/
        return password.test(value.value || '');
    };
    const hasNumber = (value) => {
        const password = /\d/
        return password.test(value.value || '');
    };
    const hasSpecialChar = (value) => {
        const password = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        return password.test(value.value || '');
    };

    const phoneValidator = (value) => {
        const phoneExp = /^\d{10}$/;
        return phoneExp.test(value.value.trim() || '');
    };

    const nameValidator = (value) => {
        const nameExp = /^[A-Za-z�-�\u00C0-\u017F'-]+(?: [A-Za-z�-�\u00C0-\u017F'-]+)*$/u;

        return nameExp.test(value.value.trim() || '');
    };

    const institutionalEmailValidator = (args) => {
        args.value = args.value.trim(); // Trim the input value
        const disallowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'aol.com',
            'icloud.com', 'protonmail.com', 'zoho.com', 'yandex.com', 'mail.com', 'gmx.com',
            'tutanota.com', 'fastmail.com', 'hushmail.com', 'gawab.com', 'inbox.com', 'aim.com',
            'fastmail.com', 'tutanota.com', 'pobox.com', 'yandex.ru', 'yandex.mail',
            'aol.com', 'gmx.net', 'rediff.com'];
        const emailDomain = args.value.split('@')[1];

        return !disallowedDomains.includes(emailDomain);
    };

    useEffect(() => {
        if (formRef.current) {
            // Define the validation rules and options
            const options = {
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },

                rules: {
                    'name': {
                        required: [true, 'Name is required'],
                        minLength: [2, 'Name should be at least 2 characters long'],
                        maxLength: [100, 'Name should not be more than 100 characters'],
                        nameValidator: [nameValidator, 'Name cannot contain special characters']
                    },
                    // 'email': {
                    //     required: [true, 'Email is required'],
                    //     email: [true, 'Please enter a valid email'],
                    //     institutionalEmailValidator: [institutionalEmailValidator, 'Please enter a valid work email id']
                    // },
                    'phone': {
                        required: [true, 'Phone number is required'],
                        phoneValidator: [phoneValidator, 'Enter a valid phone number']
                    },
                    'password': {
                        required: true,
                        minLength: [8, 'Password should have at least 8 characters'],
                        hasUppercase: [hasUppercase, 'Password should contain at least one uppercase letter'],
                        hasLowercase: [hasLowercase, 'Password should contain at least one lowercase letter'],
                        hasNumber: [hasNumber, 'Password should contain at least one number'],
                        // hasSpecialChar: [hasSpecialChar, 'Password should contain at least one special character']
                    }
                },
            };
            validatorRef.current = new FormValidator(formRef.current, options);
        }
    }, [formRef, validatorRef]);


    const handleModalConfirm = () => {
        if (modalContent.message == "User already exists, redirecting to login.") {
            setIsLoading(false);
            navigate('/login');
        }
        setShowModal(false);
    };

    const handleLoginFailure = (error) => {
        setModalContent({
            heading: 'Error',
            message: `Login failed. Please check your username or password.`,
            confirmButtonText: 'OK',
            onConfirm: handleModalConfirm,
        });
        setShowModal(true);
    }

    const handleContinueClick = async (e) => {
        e.preventDefault();
        if (validatorRef.current.validate()) {
            setIsLoading(true);
            if (isTermsChecked) {
                // Updated API endpoint from the environment variable
                const addUserToTeamUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/addUserToTeam`;
                try {
                    // Constructing the request body
                    const requestBody = {
                        email: email,
                        teamId: teamId,
                        password: password,
                        phone: phone,
                        name: name
                    };

                    // Making the POST request with the request body
                    const response = await fetch(addUserToTeamUrl, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(requestBody)
                    });

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const responseData = await response.json();
                    // Handle the response data as needed, perhaps dispatching an action
                    // or navigating to another route

                    // If login is part of the success flow:
                    const credentials = {email: email.trim(), password: password};
                    dispatch(loginUser(credentials, () => navigate('/home'), handleLoginFailure));

                    // You might not want to navigate to '/home' immediately if you have to handle the response data
                } catch (error) {
                    // console.error('Error:', error);
                    setIsLoading(false);
                    // Show error modal
                    setModalContent({
                        heading: 'Error',
                        message: `There was a problem adding the user to the team: ${error.message}`,
                        confirmButtonText: 'OK',
                        onConfirm: () => setShowModal(false),
                    });
                    setShowModal(true);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                // Show modal indicating terms checkbox not checked
                setModalContent({
                    heading: 'Terms and Conditions',
                    message: `You must agree to the terms and conditions to continue.`,
                    confirmButtonText: 'OK',
                    onConfirm: () => setShowModal(false),
                });
                setShowModal(true);
            }
        } else {
            setIsLoading(false);
            // Show modal indicating form validation failed
            setModalContent({
                heading: 'Form Validation',
                message: `Please fill all required fields correctly.`,
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
            setShowModal(true);
        }
    };


    function handleLoginClick() {
        navigate('/login');
    }

    return (
        <div class="max-h mt-28">
            {/* {isLoading && <LoadingOverlay/>} */}
            <form id="companyDetails" onSubmit={handleContinueClick} ref={formRef}>
                <div className="flex flex-col justify-center items-center">
                    <img src={logo} alt="ovii logo"/>
                    <p className='font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mt-[1rem]'>Get
                        started with ovii</p>
                    <div className="flex-container">
                        <div class="space-x-[2rem] flex flex-wrap">
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Name
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    placeholder='Your name'
                                    className='mt-[1.25rem]'
                                    inputClassName='w-[20rem]'
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Email
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    className='mt-[1.25rem]'
                                    inputClassName='w-[20rem]'
                                    type="email"
                                    name="email"
                                    value={email}
                                    disabled={true}
                                >
                                </Textbox>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div class="space-x-[2rem] flex flex-wrap">
                            <div className="textbox-container w-[20rem] mt-[1.3rem]">
                                <PhoneNumberInput
                                    label={(
                                        <label className="flex items-center">
                                            Phone Number
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    value={phone} // Assuming 'phone' is the state for the input value
                                    setValue={setPhone} // Function to update 'phone' state
                                    dropDownValue={dropDownValue} // State for the selected country's phone prefix
                                    setDropDownValue={setDropDownValue} // Function to update 'dropDownValue' state
                                    width="20rem"
                                    showLabel={true}
                                    id="phone"
                                    placeholder={'1234567890'}
                                    name="phone"
                                />
                            </div>
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Password
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    placeholder='********'
                                    type="password"
                                    className='mt-[1.25rem]'
                                    inputClassName='w-[20rem]'
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                ></Textbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center mt-[2rem]">
                    <Checkbox name="terms" id="terms" content={(
                        <span className="inline-block align-middle text-[1rem]">
                            Agree to our
                            <a href="https://ovii.ai/terms-of-use/" target='_blank'
                               className="text-blue" rel="noreferrer"> Terms of use </a>
                            and
                            <a href="https://ovii.ai/privacy-policy/" target='_blank' className="text-blue"
                               rel="noreferrer"> Privacy Policy</a>
                        </span>
                    )}
                              className="text-slate-950 text-[13px] font-normal font-['Inter'] tracking-tight"
                              onChange={handleCheckboxChange}/>
                    <Button className="mt-[1.25rem] text-[0.735rem] h-[2.25rem]"
                            type="submit" label={isLoading ? "LOADING..." : "CONTINUE"} variant='bright-blue'
                            width="18.625rem" disabled={isLoading}></Button>
                    <div className="mt-[0.6rem]">
                        <span className="loginOption">Already have an account? </span>
                        <span className='text-blue cursor-pointer loginOptionLlink' onClick={handleLoginClick}
                              tabIndex={0}>Login</span>
                    </div>
                    <Modal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        onConfirm={handleModalConfirm}
                        heading={modalContent.heading}
                        message={modalContent.message}
                        confirmButtonText={modalContent.confirmButtonText}
                    />
                </div>
            </form>
            {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
            <img src={hello}
                 alt="man saying hello"
                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    )
}