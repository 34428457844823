export const SET_POSITION_ID = 'SET_POSITION_ID';
export const SET_POSITION_STATUS_ID = 'SET_POSITION_STATUS_ID';

export const setPositionId = (id) => ({
    type: SET_POSITION_ID,
    payload: id
});

export const setPositionStatusId = (id) => ({
    type: SET_POSITION_STATUS_ID,
    payload: id
});