import React from "react";
import ErrorComponent from "../../../components/ErrorComponent";

const Templates = () => {
    return (
        <div>
            <ErrorComponent content="We are working on this feature, coming soon."/>
        </div>
    )
}

export default Templates