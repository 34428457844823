import React, { useState, forwardRef, useImperativeHandle } from "react";
import "react-quill/dist/quill.snow.css";
import QuillEditor from "react-quill";

const QuillRTE = forwardRef((props, ref) => {
    const [value, setValue] = useState("");

    // Destructure props
    const { disabled, width, height, className, change, editorWrapperClass, ...otherProps } = props;

    // Set up ref forwarding
    const editorRef = ref || null;
    useImperativeHandle(ref, () => ({
        // Expose the value getter and setter
        getValue: () => value,
        setValue: (newValue) => setValue(newValue)
    }));

    return (
        <div className={`p-8 ${editorWrapperClass}`}>
            <QuillEditor
                disabled={disabled}
                style={{ width, height }}
                className={`mt-4 w-full ${className}`}
                theme="snow"
                value={value}
                onChange={(newValue) => {
                    setValue(newValue);
                    // Call the onChange handler if provided
                    if (change) {
                        change({ value: newValue });
                    }
                }}
                {...otherProps}
            />
        </div>
    );
});

export default QuillRTE;
