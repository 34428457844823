import React, { useEffect, useRef, useState } from "react";
import ResumeMatchDetailsLayout from "../../components/SlidingLayouts/ResumeMatchDetails";
import { Button } from "../../components/button";
import { useDispatch } from "react-redux";
import { setPositionId } from "../../../redux/reducers/videoInterviewCreationsSlice";
import { Textbox } from "../../components/textbox";
import { Textarea } from "../../components/textarea";
import click from "../../../assets/lilhelpers-search.svg";
import { FormValidator } from "@syncfusion/ej2-react-inputs";
import logo from "../../../assets/logo_ovii_2.svg"
import ThreeButtonLoader from "../../components/ThreeButtonLoader";


const NavBar = () => {
    return (
        <div className="flex items-center justify-center w-full py-3 border shadow-sm">
            <div className="w-[80%] flex justify-between items-center" style={{ marginBottom: 0 }}>
                <div className="text-2xl font-bold" style={{ fontFamily: "ITC Clearface" }}>
                    <h1>Resume match</h1>
                </div>
                <div className="flex items-center justify-end" style={{ marginBottom: 0 }}>
                    <img src={logo} alt="logo" className="w-12 h-6" />
                </div>
            </div>
        </div>
    );
};

const ResumeMatchPage = () => {
    const [isResumeMatchDetailsOpen, setIsResumeMatchDetailsOpen] = useState(false);
    const [name, setName] = useState(localStorage.getItem("name"));
    const [email, setEmail] = useState(localStorage.getItem("email"));
    const [candidateId, setCandidateId] = useState(localStorage.getItem("candidateId"));
    const [assessmentId, setAssessmentId] = useState(localStorage.getItem("assessmentId"));
    const formRef = useRef(null);
    const validatorRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [JDSubmitloading, setJDSubmitloading] = useState(false);
    const [jobDescription, setJobDescription] = useState("");
    const [resume, setResume] = useState("");
    const [resumeMatchDetails, setResumeMatchDetails] = useState(null);
    const [resumeSubmitted, setResumeSubmitted] = useState(false); // Track if resume has been submitted
    const [isJDLengthValid, setIsJDLengthValid] = useState(false);
    const [jdLengthError, setJdLengthError] = useState('');
    const [isResumeLengthValid, setIsResumeLengthValid] = useState(false);
    const [resumeLengthError, setResumeLengthError] = useState('');
    const token = localStorage.getItem("accessToken");
    const [isJdPrefilled, setIsJdPrefilled] = useState(false);

    useEffect(() => {
        setResume(localStorage.getItem("resume") || "");
        setJobDescription("");
    }, [])

    useEffect(() => {
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                rules: {
                    'name': {
                        required: [true, 'Please enter the candidate\'s name.']
                    },
                    'email': {
                        required: [true, 'Please enter the candidate\'s email.'],
                        email: [true, 'Please enter a valid email address.']
                    },
                    'job_description': {
                        required: [true, 'Please enter the job description.'],
                        // min: [50, "Job description must be a minimum of 50 charachters"]
                    },
                    'resume': {
                        required: [true, 'Please enter the candidates resume.'],
                        // min: [50, "Resume must be a minimum of 50 charachters"]
                    }
                },
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
            });
        }
    }, [formRef, validatorRef]);

    useEffect(() => {
        console.log("Token", token);
    }, []);

    const positionID = localStorage.getItem("positionId");
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchJD = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getJobDescription?positionId=${positionID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                );
                const data = await response.json();
                setJobDescription(data.data || "");
                console.log("JD", data.data);
                // setIsJdPrefilled(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchJD();
    }, []);
    const [isLoading, setIsLoading] = useState(false)
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (validatorRef.current.validate()) {
            if (!resume) return; // If resume is empty, don't proceed
            // If resume has been submitted and its content hasn't changed, open ResumeMatchDetailsLayout directly
            if (resumeSubmitted && resume === localStorage.getItem("resume")) {
                setIsResumeMatchDetailsOpen(!isResumeMatchDetailsOpen);
            } else {
                const formData = new FormData();
                formData.append("jd", jobDescription);
                formData.append("resume", resume);
                setLoading(true);
                try {
                    setIsLoading(true)
                    const response = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/api/v1/getResumeMatchScore`,
                        {
                            method: "POST",
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                            body: formData,
                        }
                    );
                    const data = await response.json();

                    if (data.status === 200) {
                        console.log("Data", data.data);
                        setResumeMatchDetails(data.data);
                        setIsResumeMatchDetailsOpen(!isResumeMatchDetailsOpen);
                        localStorage.setItem("resume", resume);
                        localStorage.setItem("jd", jobDescription);
                        setResumeSubmitted(true); // Mark resume as submitted
                    }
                } catch (error) {
                    console.error("Network error:", error);
                } finally {
                    setLoading(false);
                    setIsLoading(false);
                }
            }
            dispatch(setPositionId(positionID));
        }
    };

    useEffect(() => {
        setName(localStorage.getItem("name") || "");
        setEmail(localStorage.getItem("email") || "");
        setCandidateId(localStorage.getItem("candidateId") || "");
        setAssessmentId(localStorage.getItem("assessmentId") || "");
        setResume(localStorage.getItem("resume") || "");
        setJobDescription(localStorage.getItem("jd") || "");
    }, []);

    const handleJobDescriptionChange = (e) => {
        const newValue = e.target.value;
        setJobDescription(newValue);
        setIsJDLengthValid(newValue.length >= 50);
        if (newValue.length < 50) {
            setJdLengthError('Job description must be a minimum of 50 characters');
        } else {
            setJdLengthError('');
        }

    };

    const handleResumeDescriptionChange = (e) => {
        const newValue = e.target.value;
        setResume(newValue);
        setIsResumeLengthValid(newValue.length >= 50);
        if (newValue.length < 50) {
            setResumeLengthError('Resume must be a minimum of 50 characters');
        } else {
            setResumeLengthError('');
        }

    };

    const handleJDSubmit = async () => {
        if (jobDescription != null) {
            setJDSubmitloading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/saveJobDescription?positionId=${positionID}`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        body: jobDescription,
                    }
                );
                const data = await response.json();
            } catch (error) {
                console.error("Network error:", error);
            } finally {
                setJDSubmitloading(false);
            }
        }
    };

    const handleResumeMatchDetailsSlider = (candidateId) => {
        setIsResumeMatchDetailsOpen(!isResumeMatchDetailsOpen);
        setLoading(false);
    };

    return (
        <>
        {loading && (
            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50">
                <ThreeButtonLoader/>
            </div>
        )}
        <div className="z-10 fixed flex flex-col items-center justify-center w-full">
            <NavBar />
            <div className="flex items-center justify-center w-[80%] pt-3 mx-10">
                <div className="flex flex-col items-center justify-center w-full gap-6">
                    <div className="flex flex-row gap-20">
                        <form id="resumeMatch" ref={formRef}>
                            <div>
                                <div className='flex gap-12 mb-4'>
                                    <Textbox
                                        width="20rem"
                                        label={(
                                            <label className="flex items-center">
                                                Name <span className="text-red-500" style={{ color: '#E90C44' }}>*</span>
                                            </label>
                                        )}
                                        placeholder="Olivia"
                                        name="name"
                                        type="text"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    // required={true}
                                    // disabled={name != null ? true : false}
                                    />
                                    <Textbox
                                        width="20rem"
                                        label={(
                                            <label className="flex items-center">
                                                Email <span className="text-red-500" style={{ color: '#E90C44' }}>*</span>
                                            </label>
                                        )}
                                        placeholder="olivia@boltcode.io"
                                        name="email"
                                        type="text"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    // disabled={email != null ? true : false}
                                    // required={true}
                                    />
                                </div>
                                <div className="grid w-full grid-cols-2">
                                    <div className="flex flex-col col-span-2">
                                        <Textarea
                                            label={(
                                                <label className="flex items-center">
                                                    Job Description <span className="text-red-500"
                                                        style={{ color: '#E90C44' }}>*</span>
                                                </label>
                                            )}
                                            id="job_description"
                                            placeholder="Please enter your job description here."
                                            widthOverride="100%"
                                            name="job_description"
                                            heightOverride="8rem"
                                            value={jobDescription}
                                            onChange={handleJobDescriptionChange}
                                            disabled={loading}
                                            required={true}
                                            textareaClassName="w-full text-sm text-gray-700"
                                            style={{
                                                fontFamily: "helvetica",
                                                fontWeight: '200 !important',
                                                color: '#f00 !important'
                                            }}
                                        />
                                        <div className="container flex flex-col">
                                            {/* Other elements in your container */}
                                            {/*{isJdPrefilled && (*/}
                                            {/*    <span className="text-red-600 mt-auto mb-2 self-end">*/}
                                            {/*        The Jd for this role is already prefilled*/}
                                            {/*    </span>*/}
                                            {/*)}*/}
                                            {jdLengthError &&
                                                <span className="text-red-600 mb-2 self-end">{jdLengthError}</span>}
                                            <Button
                                                variant='bright-blue'
                                                className="h-[2rem] text-[0.725rem] flex-start mt-4"
                                                label={JDSubmitloading ? "PLEASE WAIT ..." : "SAVE"}
                                                onClick={handleJDSubmit}
                                                disabled={JDSubmitloading || !isJDLengthValid}
                                                width="8.625rem"
                                            />
                                        </div>
                                    </div>
                                    <div className="flex flex-col col-span-2 mt-4">
                                        <Textarea
                                            label={(
                                                <label className="flex items-center">
                                                    Resume <span className="text-red-500"
                                                        style={{ color: '#E90C44' }}>*</span>
                                                </label>
                                            )}
                                            id="resume"
                                            placeholder="Please paste the candidate's resume here."
                                            disabled={loading}
                                            onChange={handleResumeDescriptionChange}
                                            name="resume"
                                            rows="6"
                                            widthOverride="100%"
                                            heightOverride="8rem"
                                            required={true}
                                            textareaClassName="w-full text-sm text-gray-700"
                                            style={{
                                                fontFamily: "helvetica",
                                                fontWeight: '200 !important'
                                            }}
                                        />
                                        {resumeLengthError &&
                                            <span className="text-red-600 mb-2">{resumeLengthError}</span>}
                                        <Button
                                            className="h-[2rem] text-[0.725rem] mt-4"
                                            variant='bright-blue'
                                            label={loading && !resumeMatchDetails || isLoading ? "PLEASE WAIT ..." : resumeMatchDetails ? "CONTINUE" : "CONTINUE"}
                                            onClick={handleSubmit}
                                            disabled={loading || !isResumeLengthValid}
                                            width="8.625rem"
                                        />
                                    </div >
                                </div >
                            </div >
                        </form >
                        <div>
                            <h2 className="mt-[2rem] text-[1.25rem] itc-fontFamily">Let the Matching Begin!</h2>
                            <p className="mb-0 mt-3 w-[100%] text-[0.9rem] font-medium text-charcoal text-justify">
                                Simply provide the job description (JD) and the candidate's resume, and our AI engine
                                will work its magic.
                                It expertly matches the resume with the job requirements, giving you a clear insight
                                into how well the
                                candidate aligns with the role.
                            </p>
                            <div className="flex justify-center -mt-6">
                                <img src={click} alt="man clicking on his phone" className='w-[25rem] mt-0' />
                            </div>
                        </div>
                    </div >
                                        
                </div >
                                        
            </div >
            {resumeMatchDetails !== null && (
                <ResumeMatchDetailsLayout
                    isOpen={isResumeMatchDetailsOpen}
                    onClose={handleResumeMatchDetailsSlider}
                    resumeMatchDetails={resumeMatchDetails}
                    name={name}
                    email={email}
                />
            )}
        </div >
        </>
    );
};

export default ResumeMatchPage;
