import React, {useState, useEffect} from "react";
import grayIcon from "../../assets/userBlue.svg";
import CandidateScoreDetailsLayout from "./SlidingLayouts/CandidateScoreDetails";
import './candidateTable.css';
import AsyncVideoSlider from "./SlidingLayouts/AsyncVideoSlider";
import ErrorComponent from "./ErrorComponent";
import {useDispatch} from "react-redux";
import Papa from "papaparse";
import { Button } from "./button";
import download from "../../assets/download.svg"
import Modal from "./popup";
// import {setStatus} from "../../redux/reducers/candidateStatusSlice";

function DataTable({onRefresh, candidates, isLoading, tabType, assesmentId}) {
    // const [candidates, setCandidates] = useState([]);
    const [sortConfig, setSortConfig] = useState({key: null, direction: "asc"});
    const accessToken = localStorage.getItem("accessToken")
    const [isPending, setIsPending] = useState(true);
    // const [isLoading, setIsLoading] = useState(false);
    const [isCandidateScoreDetailsOpen, setIsCandidateScoreDetailsOpen] =
        useState(false);
    const [candidateId, setCandidateId] = useState(null);
    const [tableRefreshFlag, setTableRefreshFlag] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [selectedCandidates, setSelectedCandidates] = useState([]); 

    const handleCheckboxChange = (candidate) => {
        setSelectedCandidates(prev => {
            if(candidate.status==="PENDING"){
                const isAlreadySelected = prev.includes(candidate.candidateId);
            
                if (isAlreadySelected) {
                    return prev.filter(id => id !== candidate.candidateId);
                } else {
                    return [...prev, candidate.candidateId];
                }
            }
        });
    };
    

    useEffect(()=>{
        console.log("selected candidates:", selectedCandidates)
    })

    const handleCandidateClick = async (candidateId, name, email, candidate) => {
        localStorage.setItem("assessmentId", assesmentId);
        let shortlistedCandidates = JSON.parse(localStorage.getItem('shortlistedCandidates')) || [];

        if (tabType===3 && shortlistedCandidates.includes(email) && (candidate.status === "PENDING" || candidate.status === null)) {
            try {
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/getJdAndResumeForCandidate?candidateId=${candidateId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
    
                if (response.ok) {
                    const data = await response.json();
                    const { jobDescription, resume } = data.data;
    
                    localStorage.setItem('jd', jobDescription || null);
                    localStorage.setItem('resume', resume || null);
                } else {
                    console.error('Error fetching JD and resume');
                }
            } catch (error) {
                console.error('Error during API call:', error);
            }

            localStorage.setItem("candidateId", candidateId);
            window.open('jobs/position/videointerviewquestions', '_blank');
            return;
        }
    
        if (tabType === 3 && (candidate.status === "PENDING")) {
            localStorage.setItem("candidateId", candidateId);
            localStorage.setItem("name", name);
            localStorage.setItem("email", email);
            window.open(`/resumeMatch`, "_blank");
    
        } else if (tabType === 3 && candidate.status === null) {
            localStorage.setItem("candidateId", candidateId);
            localStorage.setItem("name", name);
            localStorage.setItem("email", email);
            window.open(`/resumeMatch`, "_blank");
        } else {
            handleCandidateDetailsSlider(candidateId);
        }
    };
    
    const handleCandidateDetailsSlider = (candidateId) => {
        setIsCandidateScoreDetailsOpen(!isCandidateScoreDetailsOpen);
        setCandidateId(candidateId);
    };

    const evaluationStatusMap = {
        1: "Pending",
        2: "Hold",
        5: "Shortlist",
        4: "Reject"
    };

    /**
     * Fetches candidates for the assessment from the server.
     *
     * @returns {Promise<void>} - Promise that resolves when candidates are successfully fetched.
     * @throws {Error} - If there is an error while fetching candidates.
     */

    const requestSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({key, direction});
    };

    const dispatch = useDispatch();

    const handleEvaluationChange = async (index, event) => {
        try {
            const updatedCandidates = [...candidates];
            updatedCandidates[index].evaluation = event.target.value;
            // setCandidates(updatedCandidates);
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/updateCandidateEvaluation?candidateId=${updatedCandidates[index].candidateId}&evaluationId=${event.target.value}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data && data.status === 200) {
                console.log("Candidate evaluation updated successfully");
                // dispatch(setStatus());
                // setTableRefreshFlag(prevFlag => !prevFlag);
            } else {
                console.error("Error updating candidate evaluation:", data.message);
            }
        } catch (error) {
            console.error("Error updating candidate evaluation:", error);
        }
    };

    const getSortIndicator = (key) => {
        if (sortConfig.key === key) {
            return sortConfig.direction === "asc" ? "↑" : "↓";
        }
        return "-"; // Default arrow indicating ascending order
    };

    const sortedCandidates = [...candidates].sort((a, b) => {
        if (sortConfig.key !== null) {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === "asc" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === "asc" ? 1 : -1;
            }
        }
        return 0;
    });

    const exportToCSV = () => {
        const csvData = candidates.map(candidate => ({
            name: candidate.name,
            email: candidate.email,
            status: candidate.status ? candidate.status.toLowerCase().replaceAll("_", " ") : "pending",
            score: candidate.score !== null ? candidate.score : 0,
            timeTaken: candidate.timeTaken === 0 ? 'NA' : candidate.timeTaken || 'NA',
            evaluationStatus: evaluationStatusMap[candidate.evaluationStatus]
        }));
    
        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "candidates.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    // const handleAddQuestions=()=>{
    //     sessionStorage.setItem('selectedCandidates', JSON.stringify(selectedCandidates));
    //     sessionStorage.setItem('assesmentId', assesmentId);
    //     window.open('/bulkAddQuestions', '_blank');
    // }
    
    const handleAddQuestions = () => {
        const eligibleCandidates = candidates.filter(candidate => 
            selectedCandidates.includes(candidate.candidateId) && 
            (candidate.status === "PENDING" || candidate.status === null)
        ).map(candidate => candidate.candidateId);
    
        if (eligibleCandidates.length > 0) {
            sessionStorage.setItem('selectedCandidates', JSON.stringify(eligibleCandidates));
            sessionStorage.setItem('assesmentId', assesmentId);
            window.open('/bulkAddQuestions', '_blank');
        } else {
            setModalContent({
                heading: 'Error',
                message: `Cannot add questions to a candidate with existing questions.`,
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        }
    };

    const filteredCandidates = [];
    const emailSet = new Set();

    sortedCandidates.forEach(candidate => {
        if (!emailSet.has(candidate.email)) {
            emailSet.add(candidate.email);
            filteredCandidates.push(candidate);
        }
    });

    return (
        <div className="" style={{padding: "2rem"}}>
            {tabType === 3 ? (
                <div>
                    <AsyncVideoSlider
                        assesmentId={assesmentId}
                        candidateId={candidateId}
                        isOpen={isCandidateScoreDetailsOpen}
                        onClose={handleCandidateDetailsSlider}
                        onRefresh={onRefresh}
                    />
                </div>
            ) : (
                <CandidateScoreDetailsLayout
                    candidateId={candidateId}
                    isOpen={isCandidateScoreDetailsOpen}
                    onClose={handleCandidateDetailsSlider}
                    onRefresh={onRefresh}
                />
            )}
            {candidates.length === 0 ? (
                <div className="flex flex-col items-center justify-center content-center">
                    {tabType === 3 ? (
                        <ErrorComponent
                            content="Invite candidates to take your interview and you'll see a breakdown of their performance across skills, communication and their experience during the interview."/>
                    ) : (
                        <ErrorComponent
                            content="Invite candidates to take your test and you'll see a breakdown of their performance across skills, time management and their attempts during the test"/>
                    )}
                </div>
            ) : (
                <div className="mt-[rem]">
                    <table
                        className="w-[100%] border-collapse border rounded-[6px] border-solid border-bgrey mt-0 ">
                        <thead className="sticky top-0 bg-[#FAFAFA] h-10">
                        <tr>
                            {tabType===3?
                                (<th className="text-charcoal text-[13px] text-center font-regular cursor-pointer" style={{ width: "3%" }}></th>)
                                :(<></>)
                            }
                            <th className="text-charcoal text-[13px] text-justify pl-5 font-regular cursor-pointer"
                                style={{width: "37%"}}
                                onClick={() => requestSort("name")}>Name {getSortIndicator("name")}</th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "22%"}}
                                onClick={() => requestSort("status")}>Status {getSortIndicator("status")}</th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "13%"}}
                                onClick={() => requestSort("time")}>Time {getSortIndicator("time")}</th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "14%"}}
                                onClick={() => requestSort("score")}>Score {getSortIndicator("score")}</th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "15%"}} onClick={() => requestSort("evaluation")}>Evaluation
                            </th>
                        </tr>
                        </thead>
                    </table>
                    {/* candidate table ui fix */}
                    <div className="mt-[-0.1rem] ml-[0.05rem]"
                         style={{maxHeight: "24rem", maxWidth: "100%", overflowY: "auto"}}>
                        <table className="w-[100%] border-collapse border border-gray-100 cursor-pointer">
                            <tbody>
                            {filteredCandidates.map((candidate, index) => (
                                <tr key={index} className="border-b border-gg h-5">
                                    {tabType === 3 ? (
                                        <td className="items-center justify-center pl-[0.7rem]" style={{ width: "3%" }}>
                                            <input
                                                type="checkbox"
                                                checked={selectedCandidates.includes(candidate.candidateId)}
                                                onChange={(e) => {
                                                    e.stopPropagation();
                                                    handleCheckboxChange(candidate);
                                                }}
                                                disabled={candidate.status!="PENDING"}
                                                className={`form-checkbox text-blue-600 ${selectedCandidates.includes(candidate.candidateId), candidate.status !== 'Pending' ? 'cursor-not-allowed' : 'cursor-pointer'  ? 'bg-blue' : ''}`}
                                            />
                                        </td>
                                        ) : (<></>)
                                    }
                                    <td style={{width: "37%"}}>
                                        <span className="inline-flex items-center rounded-table2">
                                            <span
                                                onClick={() => handleCandidateClick(candidate.candidateId, candidate.name, candidate.email, candidate)}>
                                                <img src={grayIcon} alt="User icon" className="w-1.68rem h-1.68rem mr-2 p-4"/>
                                            </span>
                                            <div className="flex flex-col mt-3">
                                                <span className="font-sans font-bold font-14px font-600"
                                                      onClick={() => handleCandidateClick(candidate.candidateId, candidate.name, candidate.email, candidate)}>
                                                    {candidate.name}
                                                </span>
                                                <span className="font-sans text-sm text-gray-400"
                                                      onClick={() => handleCandidateClick(candidate.candidateId, candidate.name, candidate.email, candidate)}>
                                                    {candidate.email}
                                                </span>
                                            </div>
                                        </span>
                                    </td>
                                    <td style={{width: "22%"}} className="text-center">
                                        <div
                                            className={`flex items-center justify-center mt-5 px-5 py-[0.025rem] text-sm rounded-[3px] cursor-pointer font-inter font-bold ${
                                                candidate.status === "NOT_STARTED" ? "bg-[#F4F2FD] text-[#4F2EBC]" :
                                                    candidate.status === "IN_PROGRESS" ? "bg-[#FFF8EF] text-[#C28818]" :
                                                        candidate.status === "COMPLETED" ? "bg-[#EDFCF2] text-[#308358]" :
                                                            candidate.status === "INTERVIEW_CREATED" ? "bg-[#EDF6FF] text-[#004eec]" :
                                                                candidate.status === "PENDING" || candidate.status === null ? "bg-[#F4F2FD] text-[#4F2EBC]" :
                                                                    ""
                                            }`}
                                            style={{display: "inline-block"}}
                                        >
                                        <span className="">
                                            {(candidate.status ? candidate.status.toLowerCase().replaceAll("_", " ") : "pending")}
                                        </span>
                                        </div>
                                    </td>
                                    <td style={{width: "13%"}} className="text-center">
                                        {candidate.time ? `${candidate.time} mins` : "NA"}
                                    </td>
                                    <td style={{width: "14%"}} className="text-center">
                                        {candidate.score || "NA"}
                                    </td>
                                    <td style={{width: "15%"}} className="text-center">
                                        {isPending ? (
                                            <label onClick={() => setIsPending(false)}
                                                   className={`px-4 py-1 text-sm rounded-[3px] cursor-pointer font-inter font-bold ${
                                                       candidate.evaluationStatus === 1 ? "bg-[#EDF6FF] text-[#004eec]" :
                                                           candidate.evaluationStatus === 2 ? "bg-[#FFF1F0] text-[#E7401B]" :
                                                               candidate.evaluationStatus === 5 ? "bg-[#EFFAF2] text-[#3B8456]" :
                                                                   candidate.evaluationStatus === 4 ? "bg-[#FEF1F6] text-[#C6274E]" :
                                                                       ""
                                                   }`}
                                            >
                                                {evaluationStatusMap[candidate.evaluationStatus]}
                                            </label>
                                        ) : (
                                            <label onClick={() => setIsPending(false)}
                                                   className={`px-4 py-1 text-sm rounded-[3px] cursor-pointer font-inter font-bold ${
                                                       candidate.evaluationStatus === 1 ? "bg-[#EDF6FF] text-[#004eec]" :
                                                           candidate.evaluationStatus === 2 ? "bg-[#FFF1F0] text-[#E7401B]" :
                                                               candidate.evaluationStatus === 5 ? "bg-[#EFFAF2] text-[#3B8456]" :
                                                                   candidate.evaluationStatus === 4 ? "bg-[#FEF1F6] text-[#C6274E]" :
                                                                       ""
                                                   }`}
                                            >
                                                {evaluationStatusMap[candidate.evaluationStatus]}
                                            </label>
                                        )}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="mt-2 flex justify-between h-[2.5rem]">
                        <a onClick={exportToCSV} className="text-[#004EEC] cursor-pointer inline-flex items-center"><img src={download} alt="Download Icon" className="mr-2 h-[1rem]"/> Download to CSV</a>
                        {selectedCandidates.length > 0 ?(<Button className="px-2" variant="bright-blue" label="Add interview questions" onClick={handleAddQuestions}/>):("")}
                    </div>
                </div>
            )}
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={() => setShowModal(false)}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
        </div>
    );
}

export default DataTable;