import React, {useState} from "react";
import {Button} from "../../components/button";
import deers from "../../../assets/Hello.svg";
import logo from "../../../assets/logo_ovii_2.svg";
import Modal from "../../components/popup";

function Feedback() {
    const [selectedOption, setSelectedOption] = useState(null);
    const accessToken = localStorage.getItem('accessToken');
    const [modalContent, setModalContent] = useState({});
    const [showModal, setShowModal] = useState(false);

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    const options = Array.from({length: 10}, (_, index) => index + 1);

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const candidateId = localStorage.getItem("candidateId"); //candidateId
    const assessmentId = localStorage.getItem("assessmentId"); //assessmentID

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (selectedOption === null) {
            setModalContent({
                heading: 'Error',
                message: 'Please select an option',
                confirmButtonText: 'Close',
            });
            setShowModal(true);
        }
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/saveCandidateFeedback?candidateId=${candidateId}&assessmentId=${assessmentId}&rating=${selectedOption}`, //hard-coded assesement id and candidate
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            if (response.ok) {
                setModalContent({
                    heading: "Thank you",
                    message: "Your feedback has been submitted successfully. You may now close this tab.",
                    confirmButtonText: "Close",
                })
                setShowModal(true);
            }
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div>
            <div className="flex justify-center items-center flex-col">
                <img src={deers} className="h-[8rem] mt-[9rem] mb-4"/>
                <p className="font-serif text-2xl text-center">
                    Congratulations on completing your assessment!
                </p>
                <p className="font-serif text-md mt-[0rem] text-center">
                    Your feedback can help us make the experience even better for future talent.
                </p>
                <div className="flex items-center justify-center mt-[2rem]">
                    <form className="grid grid-cols-10 gap-[0.625rem]">
                        {options.map(option => (
                            <div key={option}>
                                <input
                                    type="radio"
                                    className="hidden"
                                    id={`radio_${option}`}
                                    name="radio"
                                    checked={selectedOption === option}
                                    onChange={() => handleOptionChange(option)}
                                />
                                <label
                                    className={`flex items-center justify-center p-4 border-2 cursor-pointer ${
                                        selectedOption === option
                                            ? "border-blue bg-blue text-white"
                                            : "border-[#444444]"
                                    }`}
                                    htmlFor={`radio_${option}`}
                                    style={{width: '2.438rem', height: '2.438rem', borderRadius: '0.5rem'}}
                                >
                            <span className={`text-m font-semibold ${
                                selectedOption === option
                                    ? "text-white"
                                    : "text-[#444444]"
                            }`}>{option}</span>
                                </label>
                            </div>
                        ))}
                    </form>
                </div>
                <div className="space-x-[16.5rem] mt-[-0.5rem] font-serif text-charcoal">
                    <span className=" text-sm ">Not satisfied at all</span>
                    <span className=" text-sm ">Extremely satisfied</span>
                </div>
                {/*<p className="mt-[1.563rem]">Thank you for the feedback</p>*/}
                <div className="mt-[2rem]">
                    <Button label="SUBMIT" variant='bright-blue' width="14.438rem"
                            className='text-[0.735rem] h-[2.25rem]' onClick={handleSubmit}/>
                </div>
                <div className="fixed bottom-5 flex flex-wrap">
                    <p className="mr-[0.2rem] h-[1.313rem] font-serif text-[1.146rem] text-labels-black font-semibold">
                        Powered by
                    </p>
                    <img
                        src={logo}
                        alt="ovii logo"
                        className="w-[2.5rem] h-[1.313rem] mt-[0.1rem]"
                    />
                </div>
            </div>
            {/*<img*/}
            {/*    src={logo}*/}
            {/*    className="fixed top-4 left-4 w-[3.063rem] h-[1.625rem]"*/}
            {/*/>*/}
            <Modal isOpen={showModal}
                   onClose={() => setShowModal(false)}
                   onConfirm={handleModalConfirm}
                   heading={modalContent.heading}
                   message={modalContent.message}
                   confirmButtonText={modalContent.confirmButtonText}
            />
        </div>
    );
}

export default Feedback;
