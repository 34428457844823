import React, {useEffect, useRef, useState} from "react";
import logo from "../../../assets/logo_ovii_2.svg";
import Checkbox from "../../components/checkbox";
import {Button} from "../../components/button";
import deer from "../../../assets/Hello.svg";
import {useNavigate} from "react-router-dom";
import Modal from "../../components/popup";
import LoadingOverlay from "../../components/loadingOverlay";
import check from "../../../assets/check.svg";
import noise from "../../../assets/noise.svg";
import close from '../../../assets/close.svg';
import alarm from "../../../assets/alarm-clock.svg";
import recording from "../../../assets/recording-03.svg";
import lightbulb from "../../../assets/lightbulb-02.svg";
import camera from "../../../assets/camera-plus.svg";
import mic from "../../../assets/microphone-01.svg"

const WelcomeScreen = () => {
    const navigate = useNavigate();
    const [candidateName, setCandidateName] = useState("");
    const [assessmentName, setAssessmentName] = useState("");
    const [duration, setDuration] = useState(0);
    const [email, setEmail] = useState("");
    const [assessmentId, setAssessmentId] = useState();
    const [decodedLogo, setDecodedLogo] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [agreed, setAgreed] = useState(false);
    const [testStatus, setTestStatus] = useState();
    const [modalContent, setModalContent] = useState({})

    let errorModalContent = null;

    const popupRef = useRef(null);

    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");
    localStorage.setItem("token", token);

    const apiEndpoint = `${process.env.REACT_APP_BASE_URL}/api/v1/getAssessmentDetailsForCandidate`;

    useEffect(() => {
        setIsLoading(true);
        fetch(apiEndpoint, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setCandidateName(data.data.name);
                setAssessmentName(data.data.assessmentName);
                setDuration(data.data.duration);
                setEmail(data.data.email);
                setAssessmentId(data.data.assessmentId);
                setDecodedLogo(data.data.logo);
                setTestStatus(data.data.status)
                console.log("test status:", testStatus)
                // setDuration(data.data.duration);

                localStorage.setItem("candidateId", data.data.id);
                // console.log("id after setting it",localStorage.getItem("candidateId"))
                localStorage.setItem("candidateName", data.data.name);
                localStorage.setItem("assessmentName", data.data.assessmentName);
                localStorage.setItem("duration", data.data.duration);
                localStorage.setItem("email", data.data.email);
                localStorage.setItem("assessmentId", data.data.assessmentId);
                localStorage.setItem("decodedLogo", data.data.logo);
                localStorage.setItem("token", token);
                localStorage.setItem("assessmentType", data.data.assessmentType);
                localStorage.setItem("isProctoringEnabled", data.data.isProctoringEnabled);
            })
            .catch((error) => console.error("Error:", error));
        setIsLoading(false)
        // console.log(assessmentId)
    }, [apiEndpoint, token]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowPopup(false);
                setAgreed(false); // Deselect the checkbox
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleAgree = () => {
        setAgreed(true);
        setShowPopup(false);
    }
    const handleClose = () => {
        setShowPopup(false);
        setAgreed(false); // Deselect the checkbox
    }

    return (
        <div>
            {isLoading && <LoadingOverlay/>}
            <div>
                {typeof decodedLogo === 'string' && decodedLogo.startsWith('img:') ? (
                    <img
                        src={`data:image/;base64,${decodedLogo.substring(4)}`}
                        alt="ovii logo"
                        className="m-[2rem]  w-[10rem]"
                    />
                ) : (
                    <p className="font-poppins m-[2rem] text-[1.75rem] text-labels-black">
                        {decodedLogo}
                    </p>
                )}
            </div>
            <div className="flex flex-col justify-center items-center mt-[5rem]">
                <p className="mx-auto my-[15.188rem] font-serif text-[1.688rem] text-labels-black font-bold mb-[0.938rem] mt-20">
                    Hey {candidateName}
                </p>
                <p className="font-serif text-[1.5rem] w-[38.063rem] text-center text-labels-black">
                    Welcome to {assessmentName}
                </p>
                <p className="text-[1.146rem] font-serif text-labels-black mt-[1.15rem]">
                    Test Duration: {duration} mins
                </p>
                {candidateName && assessmentName && duration && (
                    <p className="inline-flex items-center text-[#ea157b] text-[0.95rem] font-serif mt-[1.188rem]">
                        <Checkbox style={{color: "#ea157b"}}
                                  onChange={() => {
                                      setShowPopup(true);
                                      if (agreed) {
                                          setAgreed(false)
                                      }
                                  }}
                                  content="I have read the instructions"
                                  checked={agreed}

                        />
                    </p>
                )}
                {showPopup && localStorage.getItem("assessmentType") === "1" && (
                    <div className="fixed inset-0 z-50 bg-white bg-opacity-25">
                        <div ref={popupRef}
                             className="absolute top-0 right-0 h-full w-[49%] bg-[#fff] transition-all duration-700 shadow-2xl shadow-gray-400">
                            <div className="p-2 m-3 overflow-auto">
                                <div
                                    className="flex flex-row justify-between border border-white border-b-gray-300 pb-2">
                                    <h2 className="text-2xl font-serif mb-[0.75rem] px-[1.5rem]">Instructions before you
                                        begin</h2>
                                    <img src={close}
                                         className="absolute top-[1rem] right-[1rem] w-[20px] cursor-pointer"
                                         onClick={() => {
                                             setShowPopup(false)
                                         }} tabIndex={0}/>
                                    <hr/>
                                </div>
                                <div className='instruction-points mt-[3%] px-6 max-h-[90vh] overflow-auto pb-6'>
                                    <ul>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={alarm} alt="no" className="size-4"/>
                                                <h1>Time Management</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>This is
                                                a timed test.
                                                Please make sure you are not interrupted during the test, as the timer
                                                cannot be paused once started.
                                                Plan your time wisely and ensure that you have enough time to complete
                                                the entire test without any breaks.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={recording} alt="no" className="size-4"/>
                                                <h1>Remove distractions</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>This is
                                                a recorded test.
                                                Ensure you've got a strong Wi-Fi signal and power. Close all the other
                                                tabs and applications to remove the risk of distractions,
                                                like a notification popping up. Make sure your workspace is quiet and
                                                free from interruptions.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={lightbulb} alt="no" className="size-4"/>
                                                <h1>Check your lighting</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Good
                                                lighting is crucial—position yourself in a well-lit area,
                                                preferably with natural light. Avoid backlit setups.
                                                Make sure your face is clearly visible to avoid any issues with the test
                                                recording and ensure the best possible video quality.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={noise} alt="no" className="size-4"/>
                                                <h1>Check your surrounding noise levels</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>
                                                Remove all background noises, ensure you are in a quiet space as this is
                                                a proctored test.
                                                Background noise can be distracting and might interfere with the test
                                                recording, so a quiet environment is essential.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={check} alt="no" className="size-4"/>
                                                <h1>You cannot retake the test</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>
                                                Please be aware that you cannot retake this test. Ensure that you are
                                                fully prepared before starting.
                                                Read all the instructions carefully and make sure you understand them
                                                before you begin the test.</p>
                                        </li>

                                        <br/>
                                    </ul>
                                    <Button label="Agree"
                                            width="13.188rem"
                                            variant="bright-blue"
                                            onClick={handleAgree}
                                            className="mb-2"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {showPopup && localStorage.getItem("assessmentType") === "2" && (
                    <div className="fixed inset-0 z-50 bg-white bg-opacity-25">
                        <div ref={popupRef}
                             className="absolute top-0 right-0 h-full w-[49%] bg-[#fff] transition-all duration-700 shadow-2xl shadow-gray-400">
                            <div className="p-2 m-3">
                                <h2 className="text-2xl font-serif mb-[0.75rem] px-[1.5rem]">Tips before you begin
                                    your interview</h2>
                                <img src={close}
                                     className="absolute top-[1rem] right-[1rem] w-[20px] cursor-pointer"
                                     onClick={() => {
                                         setShowPopup(false)
                                     }} tabIndex={0}/>
                                <hr/>
                                <div className='instruction-points mt-[3%] px-4 max-h-[90vh] overflow-auto pb-6'>
                                    <ul>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center mb-0"
                                                style={{marginBottom: '0'}}>
                                                <img src={alarm} alt="no" className="size-4"/>
                                                <h1>You set the pace</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Complete
                                                the interview at your convenience. You can start as
                                                needed, from anywhere with internet access.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={recording} alt="no" className="size-4"/>
                                                <h1>Remove distractions</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Choose a
                                                quiet, private space to ensure focus. Check your internet connection for
                                                stability and close any unnecessary apps or tabs to avoid
                                                interruptions.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={lightbulb} alt="no" className="size-4"/>
                                                <h1>Ensure proper visibility</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Good
                                                lighting is crucial—position yourself in a well-lit area, preferably
                                                with natural light. Avoid backlit setups. Keep the background neat and
                                                professional to maintain visual focus on you.</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={camera} alt="no" className="size-4"/>
                                                <h1>Act naturally with the camera</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Don't
                                                stress about maintaining eye contact with the camera lens. Treat the
                                                camera as if you're conversing with an interviewer in person—relax and
                                                express yourself naturally.
                                            </p>
                                        </li>
                                        <br/>
                                        {/*<li className="border border-gray-300 rounded-lg p-3">*/}
                                        {/*    <div*/}
                                        {/*        className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"*/}
                                        {/*        style={{marginBottom: '0'}}>*/}
                                        {/*        <img src={trash} alt="no" className="size-4"/>*/}
                                        {/*        <h1>Familiarize yourself with the format</h1>*/}
                                        {/*    </div>*/}
                                        {/*    <p className="text-gray-600 font-semibold text-[0.85rem] pl-6 mt-2">Use the*/}
                                        {/*        practice session to get comfortable with the interview process. This*/}
                                        {/*        will help you manage your responses better during the actual*/}
                                        {/*        interview.</p>*/}
                                        {/*</li>*/}
                                        {/*<br/>*/}
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={alarm} alt="no" className="size-4"/>
                                                <h1>Allocate sufficient time</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Although
                                                you can manage when to take the interview, ensure you allocate enough
                                                uninterrupted time to complete it once started. This is your chance to
                                                impress, so make every second count!</p>
                                        </li>
                                        <br/>
                                        <li className="border border-gray-300 rounded-lg p-3">
                                            <div
                                                className="text-md font-bold flex flex-row gap-x-2 align-middle items-center"
                                                style={{marginBottom: '0'}}>
                                                <img src={mic} alt="no" className="size-4"/>
                                                <h1>Ensure clarity for speech recognition</h1>
                                            </div>
                                            <p className="font-sans text-[0.975rem] leading-6 pl-6 mt-2"
                                               style={{fontFamily: 'HelveticaNeue'}}>Keep
                                                your environment as quiet as possible to ensure the speech-to-text
                                                service accurately captures your responses. Background noise can
                                                interfere with voice recognition, potentially impacting the evaluation
                                                of your answers. Choose a setting that minimizes interruptions and
                                                ambient noise.</p>
                                        </li>
                                    </ul>
                                    <Button label="Agree"
                                            width="13.188rem"
                                            variant="bright-blue"
                                            onClick={handleAgree}
                                            className="mt-4"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <p className="mt-[1.563rem]">
                    <Button
                        variant="bright-blue"
                        label="NEXT"
                        width="16rem"
                        className='text-[0.735rem] h-[2.25rem]'
                        onClick={(e) => {
                            e.stopPropagation();
                            if(candidateName && assessmentName && duration){
                                if (!agreed) {
                                    errorModalContent = {
                                        heading: 'Error',
                                        message: 'Please agree to the instructions',
                                        confirmButtonText: 'OK'
                                    };
                                } else if (testStatus === "COMPLETED" || testStatus === "IN_PROGRESS" && (testStatus !== "PENDING" || testStatus !== "INTERVIEW_CREATED")) {
                                    errorModalContent = {
                                        heading: 'Error',
                                        message: 'It seems that you have already taken this assessment, you cannot restart this assessment. We have recorded your previous responses.',
                                        confirmButtonText: 'CLOSE'
                                    };
                                }
                                if (errorModalContent) {
                                    setModalContent(errorModalContent);
                                    setShowModal(true);
                                } else {
                                    navigate("/candidateDetails");
                                }
                            }
                            else{
                                setModalContent({
                                    heading: 'Error',
                                    message: 'It seems that something is wrong. Please contact sales/ company.',
                                    confirmButtonText: 'CLOSE'
                                });
                                setShowModal(true);
                            }
                        }}
                    >
                        Next
                    </Button>
                </p>
                <div className="fixed bottom-5 flex flex-wrap">
                    <p className="mr-[0.2rem] h-[1.313rem] font-serif text-[1.146rem] text-labels-black font-semibold">
                        Powered by
                    </p>
                    <img
                        src={logo}
                        alt="ovii logo"
                        className="w-[2.5rem] h-[1.313rem] mt-[0.1rem]"
                    />
                </div>
            </div>
            <Modal isOpen={showModal}
                   onClose={() => setShowModal(false)}
                   onConfirm={() => setShowModal(false)}
                   heading={modalContent.heading}
                   message={modalContent.message}
                   confirmButtonText={modalContent.confirmButtonText}
            />
            <img src={deer}
                 alt="man saying hello"
                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    );
};

export default WelcomeScreen;
