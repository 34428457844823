import React from "react";

/**
 *
 * @param {string} variant // if black ? bg-charcoal and text white : if white ? bg-white border-charcoal text-charcoal
 *
 */
export const Button = (props) => {
    const inputStyle = {
        width: props.width,
        fontFamily: "helvetica",
        fontWeight: 200,
        height: "2.23rem",
        fontSize: "0.75rem",
        display: 'flex',               
        alignItems: 'center',          
        justifyContent: 'center'
    }
    return (
        <button
            type={props.type}
            className={` h-[2.5rem] ${
                props.variant === "white"
                    ? "bg-white border-[#004eec] border-[1.5px] text-[#004eec]"
                    : props.variant === "green"
                        ? "bg-[#1b9b85] text-white"
                        : props.variant === "blue"
                            ? "bg-[#eff2f8] text-[#004eec] h-[1.75rem]"
                            : props.variant === "red"
                                ? "bg-[#fdebe6] text-[#c14040] h-[1.75rem]"
                                : props.variant === "bright-blue"
                                    ? "bg-[#004EEC] text-white"
                                    : props.variant === "gray-outline"
                                        ? "bg-white text-charcoal border-gray-200 border-[1.5px]"
                                        : props.variant === "no-outline"
                                            ? "bg-white text-charcoal border-white border-[1.5px]"
                                            : props.variant === "black-outline"
                                                ? "bg-white text-charcoal border-charcoal border-[1.5px]"
                                                : props.variant === "bright-red"
                                                    ? "bg-[#E83B3B] text-white"
                                                        : "bg-charcoal text-white"

            } 
         w-[${
                props.width
            }] rounded-m text-sans text-base font-regular  ${props.className}`}
            onClick={props.onClick}
            style={inputStyle}
            disabled={props.disabled}
        >
            {
                (props.label && props.label.toUpperCase()) || (props.children && props.children)
            }
        </button>
    );
};
