import {createSlice} from "@reduxjs/toolkit";

export const videoInterviewCreationsSlice = createSlice({
    name: 'videoInterviewCreations',
    initialState: {
        assessmentId: null,
        candidateId: null,
        positionId: null,
        email: '',
        name: '',
    },
    reducers: {
       setPositionId: (state, action) => {
           state.positionId = action.payload;
       },
        setCandidateId: (state, action) => {
            console.log("candidateId", action.payload)
            state.candidateId = action.payload;
        },
        setAssessmentId: (state, action) => {
           console.log("assessmentId", action.payload)
            state.assessmentId = action.payload;
        },
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setName: (state, action) => {
            state.name = action.payload;
        },
    },
});

export const { setPositionId, setCandidateId, setAssessmentId, setEmail, setName } = videoInterviewCreationsSlice.actions;
export default videoInterviewCreationsSlice.reducer;
