import React, {useEffect, useRef, useState} from "react";
import {FormValidator} from "@syncfusion/ej2-inputs";
import {Textbox} from "../../../components/textbox";
import PrefixedInput from "../../../components/prefixedInput";
import Uploader from "../../../components/upload";
import {Textarea} from "../../../components/textarea";
import {Button} from "../../../components/button";
import Modal from "../../../components/popup";
import yellingDave from "../../../../assets/Yelling.svg";

const CompanyProfile = () => {

    const [companyName, setCompanyName] = useState("");
    const [website, setWebsite] = useState("");
    const [description, setDescription] = useState("");
    const [facebook, setFacebook] = useState("");
    const [twitter, setTwitter] = useState("");
    const [linkedin, setLinkedin] = useState("");
    const [instagram, setInstagram] = useState("");
    const [filePath, setFilePath] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const accessToken = localStorage.getItem('accessToken');
    const userId = localStorage.getItem('id');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const noSpecialCharValidator = (value) => {
        const specialCharExp = /^[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        return specialCharExp.test(value.value.trim() || '')
    };

    useEffect(() => {
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
                rules: {
                    'companyName': {
                        required: [true, 'Please enter a company name.'],
                        minLength: [3, 'Please enter a valid company name.'],
                        noSpecialCharValidator: [noSpecialCharValidator, 'Company name cannot contain special characters.'],
                        maxLength: [120, 'Company name cannot exceed 120 characters.']
                    },
                    'website': {
                        required: [true, 'Please enter a website.'],
                        url: [true, 'Please enter a valid website.']
                    },
                    'description': {
                        // required: [true, 'Please enter a description.'],
                        maxLength: [5000, 'Description cannot exceed 700 characters.'],
                        minLength: [50, 'Description must be at least 50 characters.']
                    },
                },
            });
        }
    }, []);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const orgIdResponse = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${userId}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!orgIdResponse.ok) {
                setIsLoading(false);
                throw new Error(`HTTP error! Status: ${orgIdResponse.status}`);
            }

            const orgIdData = await orgIdResponse.json();
            if (orgIdData.status === 201 && orgIdData.data && orgIdData.data.orgId) {
                setOrgId(orgIdData.data.orgId);

                const orgDetailsResponse = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/orgDetails?orgId=${orgIdData.data.orgId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (!orgDetailsResponse.ok) {
                    setIsLoading(false);
                    throw new Error(`HTTP error! Status: ${orgDetailsResponse.status}`);
                }

                const orgDetailsData = await orgDetailsResponse.json();

                if (orgDetailsData.status === 200) {
                    setCompanyName(orgDetailsData.data.orgName);
                    setWebsite(orgDetailsData.data.website);
                    setDescription(orgDetailsData.data.description);
                    setFacebook(orgDetailsData.data.facebookLink);
                    setTwitter(orgDetailsData.data.twitterLink);
                    setLinkedin(orgDetailsData.data.linkedinLink);
                    setInstagram(orgDetailsData.data.instagramLink);
                    setIsLoading(false);
                }
            }
        } catch (error) {
            setIsLoading(false);
            setShowModal(true);
            setModalContent({
                heading: 'Failed to fetch data.',
                message: error.message,
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        }
    };

    useEffect(() => {
        fetchData();
    }, [userId, accessToken]);

    const handleFilePathChange = async (newFilePath) => {
        setIsLoading(true)
        await setFilePath(newFilePath);
        setIsLoading(false)
    };


    const handleUpdateClick = async (e) => {
        e.preventDefault();
        if (validatorRef.current && validatorRef.current.validate()) {
            setIsLoading(true);
            if (!orgId) {
                setModalContent({
                    heading: 'Error',
                    message: "Organisation ID is not available", // Display specific error message
                    confirmButtonText: 'OK',
                    onConfirm: () => setShowModal(false),
                });
                setShowModal(true);
                return;
            }
            try {
                const modifyOrgDetails = {
                    orgId: orgId,
                    orgName: companyName,
                    description: description,
                    logolocation: filePath,
                    website: `${website}`,
                    facebookLink: facebook,
                    twitterLink: twitter,
                    instagramLink: instagram,
                    linkedinLink: linkedin,
                };
                console.log("Modify org details:", modifyOrgDetails)

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/modifyOrgDetails`,
                    {
                        method: "POST",
                        headers: {
                            "Authorization": `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(modifyOrgDetails),
                    }
                );

                if (!response.ok) {
                    setIsLoading(false);
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();
                console.log("Organization details updated:", responseData);
                fetchData()
                setIsLoading(false);
                setModalContent({
                    heading: 'Success',
                    message: 'Company details successfully updated',
                    confirmButtonText: 'OK',
                });
                setShowModal(true);
            } catch (error) {
                setIsLoading(false);
                console.error("Error modifying company details:", error);
                setShowModal(true);
                setModalContent({
                    heading: 'Error modifying company details',
                    message: error.message, // Display specific error message
                    confirmButtonText: 'OK',
                    onConfirm: () => setShowModal(false),
                });
            }
        } else {
            setIsLoading(false);
            setModalContent({
                heading: 'Error',
                message: 'Please fill all fields',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        }
    };

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    return (
        <div className="flex flex-col overflow-scroll h-[83vh]" style={{
            scrollbarWidth: 'none',
            msOverflowStyle: 'none',
            '&::-webkit-scrollbar': {
                display: 'none',
            },
        }}>
            <form id="companyProfile" ref={formRef} onSubmit={handleUpdateClick}
                  className="flex-grow overflow-scroll p-4" style={{
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },
                }}>
                <div>
                    <div className="flex justify-start gap-[3.438rem] flex-wrap">
                        <Textbox width="20rem"
                                 height="4.375"
                                 label={(
                                     <label className="flex items-center">
                                         Company Name
                                         <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                     </label>
                                 )}
                                 type="companyName"
                                 name="companyName"
                                 id="companyName"
                                 value={companyName}
                                 onChange={(e) => setCompanyName(e.target.value)}
                        ></Textbox>

                        <PrefixedInput
                            label={(
                                <label className="flex items-center">
                                    Website
                                    <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                </label>
                            )}
                            showLabel={true}
                            prefix="https://"
                            placeholder="Enter your website URL"
                            width="20rem"
                            name="website"
                            id="website"
                            value={website}
                            setValue={setWebsite}
                            onChange={(e) => setWebsite(e.target.value)}
                        ></PrefixedInput>
                    </div>
                    <div className='mt-6'>
                        <Textarea
                            label={(
                                <label className="flex items-center">
                                    Company Description
                                </label>
                            )}
                            id="description"
                            placeholder="Enter a brief description of your company, highlighting your culture, mission, and what makes you stand out as an employer. For example: 'We're a dynamic tech startup committed to innovating in the green energy sector, fostering a creative and inclusive work environment where every team member is valued"
                            widthOverride="43.438rem"
                            name="description"
                            heightOverride="8rem"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        ></Textarea>
                    </div>
                    <br/>
                    <div className="mt-2">
                        <p className="mb-1 font-sans text-sm font-bold tracking-wide text-charcoal">
                            Logo
                        </p>
                        <Uploader
                            label="Logo"
                            width="43.438rem"
                            height="4.563rem"
                            onFilePathChange={handleFilePathChange}
                            token={accessToken}
                        ></Uploader>
                    </div>
                    <p className="font-bold font-sans text-sm text-charcoal tracking-wide mt-[2rem]">
                        Social media
                    </p>
                    <div className="flex justify-start gap-[1.875rem] flex-wrap">
                        <PrefixedInput label="Social media"
                                       showLabel={false}
                                       prefix="facebook.com/"
                                       placeholder="yourcompany"
                                       width="23rem"
                                       name="facebook"
                                       id="facebook"
                                       value={facebook}
                                       setValue={setFacebook}
                                       onChange={(e) => setFacebook(e.target.value)}
                        ></PrefixedInput>
                        <PrefixedInput label="Website"
                                       showLabel={false}
                                       prefix="x.com/"
                                       placeholder="yourcompany"
                                       width="18.563rem"
                                       name="x"
                                       id="x"
                                       value={twitter}
                                       setValue={setTwitter}
                                       onChange={(e) => setTwitter(e.target.value)}
                        ></PrefixedInput>
                    </div>
                    <div className="flex justify-start gap-[1.875rem] flex-wrap">
                        <PrefixedInput label="Website"
                                       showLabel={false}
                                       prefix="linkedin.com/Company/"
                                       placeholder="yourcompany"
                                       width="23rem"
                                       name="linkedin"
                                       id="linkedin"
                                       value={linkedin}
                                       setValue={setLinkedin}
                                       onChange={(e) => setLinkedin(e.target.value)}
                        ></PrefixedInput>
                        <PrefixedInput label="Website"
                                       showLabel={false}
                                       prefix="instagram.com/"
                                       placeholder="yourcompany"
                                       width="18.563rem"
                                       name="instagram"
                                       id="instagram"
                                       value={instagram}
                                       setValue={setInstagram}
                                       onChange={(e) => setInstagram(e.target.value)}
                        ></PrefixedInput>
                    </div>
                    <Button width="11rem"
                            label={isLoading ? "PLEASE WAIT..." : "Update"}
                            type="submit"
                            className="mt-[1.875rem]"
                            variant="bright-blue"
                    ></Button>
                </div>
                <Modal isOpen={showModal}
                       onClose={() => setShowModal(false)}
                       onConfirm={handleModalConfirm}
                       heading={modalContent.heading}
                       message={modalContent.message}
                       confirmButtonText={modalContent.confirmButtonText}
                />
            </form>
            <img src={yellingDave}
                 alt="people speaking to each other"
                 className="absolute bottom-[3rem] right-5 w-[auto] h-[14rem]"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    );
}

export default CompanyProfile;
