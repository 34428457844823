// Import necessary dependencies
import React, {useEffect, useState} from "react";
import trash from "../../../../assets/trash.svg";
import search from "../../../../assets/search.png";
import arrow from "../../../../assets/downarrow.png";
import {Button} from "../../../components/button";
import {useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import {setPositionId} from "../../../../redux/actions/positionDetailsActions";
import Modal from '../../../components/popup';
import LoadingOverlay from '../../../components/loadingOverlay';
import ErrorComponent from "../../../components/ErrorComponent";

function Position({showAddButton = true, showLink = false}) {
    // Define component state variables
    const [positions, setPositions] = useState([]);
    const [myPositions, setMyPositions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("AllPositions");
    const accessToken = localStorage.getItem("accessToken");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [loading, setLoading] = useState(true);
    const [isEmptyDataError, setIsEmptyDataError] = useState(false);
    const [sortConfig, setSortConfig] = useState({key: null, direction: "asc"});
    const [countMyPositions, setCountMyPositions] = useState(0);
    const [countAllPositions, setCountAllPositions] = useState(0);
    const [totalCandidates, setTotalCandidates] = useState(0);
    const [positionIdToDelete, setPositionIdToDelete] = useState(null);
    const [searchedPositions, setSearchedPositions] = useState([]);

    // Function to fetch data
    const fetchData = async () => {
        try {
            setLoading(true);

            // Fetch data for MyPositions
            const myPositionsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/getPositionsForAdmin`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            // Handle response for MyPositions
            if (!myPositionsResponse.ok) {
                throw new Error(`HTTP error! Status: ${myPositionsResponse.status}`);
            }
            const myPositionsRes = await myPositionsResponse.json();
            const myPositionsData = myPositionsRes.data;

            // Fetch data for All positions
            const allPositionsResponse = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/getPositions`, {
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            // Handle response for All positions
            if (!allPositionsResponse.ok) {
                throw new Error(`HTTP error! Status: ${allPositionsResponse.status}`);
            }
            const allPositionsRes = await allPositionsResponse.json();
            const allPositionsData = allPositionsRes.data;

            // Update state with fetched data
            setPositions(allPositionsData);
            setMyPositions(myPositionsData);

            // Update count variables
            setCountMyPositions(myPositionsData.length);
            setCountAllPositions(allPositionsData.length);
            setTotalCandidates(allPositionsData.reduce((acc, pos) => acc + pos.candidates, 0));

            // Set isEmptyDataError flag based on data availability
            setIsEmptyDataError(myPositionsData.length === 0 && allPositionsData.length === 0);
        } catch (error) {
            // Handle errors
            setShowModal(true);
            setModalContent({
                heading: 'Error',
                message: 'Your session has expired. Please log in again.',
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setLoading(false);
        }
    };

    // useEffect hook to fetch data on component mount or when access token changes
    useEffect(() => {
        fetchData();
    }, [accessToken]);

    // Function to handle sorting
    const requestSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({key, direction});
    };

    // Function to handle position deletion
    const handleDeletePosition = async (positionId) => {
        await new Promise(r => setTimeout(r, 0)); // Delay to show loading indicator
        setPositionIdToDelete(positionId);
        setShowModal(true);
        setModalContent({
            heading: 'Delete Position?',
            message: `Are you sure you want to delete this position? This action cannot be undone.`,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No, cancel',
            onConfirm: () => handleModalConfirm(),
            onClose: () => setShowModal(false),
            showCancelButton: true
        });
    };

    const handleModalConfirm = async () => {
        setShowModal(false);
        try {
            setLoading(true);
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/deletePosition?id=${positionIdToDelete}`, {
                method: "DELETE",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            // Handle response for position deletion
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            await fetchData();
            setPositionIdToDelete(null); // Reset positionIdToDelete state after successful deletion
        } catch (error) {
            // Handle errors
            setShowModal(true);
            setModalContent({
                heading: 'Error',
                message: 'Failed to delete position, please try again.',
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setLoading(false);
        }
    };

    // Function to handle click on position
    const handlePositionClick = (e, id) => {
        localStorage.setItem("PosID", id)
        e.preventDefault();
        dispatch(setPositionId(id));
        navigate(`/jobs/positionDetails`);
    };

    // Function to get status color class
    const getStatusColorClass = (statusName) => {
        let color = '';
        switch (statusName.toUpperCase()) {
            case 'OPEN':
                color = 'bg-[#F3F1FD] text-[#4A2AB3]'
                break;
            case 'CLOSED':
                color = 'bg-[#FDF0F5] text-[#E0583F]';
                break;
            case 'ARCHIVED':
                color = 'bg-[#FFF0D9] text-[#BF7508]'
                break;
            default:
                color = '';
        }
        return color;
    };

    // Filter positions based on selected filter
    // const filteredPositions = selectedFilter === 'MyPositions' ? myPositions : positions;
    const filterPositions = () => {
        const filtered =
            searchTerm.trim() === ''
                ? selectedFilter === 'MyPositions'
                    ? myPositions
                    : positions
                : (selectedFilter === 'MyPositions' ? myPositions : positions).filter(
                    (position) =>
                        position.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                        position.adminName.toLowerCase().includes(searchTerm.toLowerCase())
                );
        setSearchedPositions(filtered);
    };

    // this will display the the entire by default for every render
    useEffect(() => {
        setSearchedPositions(selectedFilter === 'MyPositions' ? myPositions : positions);
    }, [myPositions, positions, selectedFilter]);

    //this will call the filterPosition updated table based on search everytime character is typed in the searchbar
    useEffect(() => {
        filterPositions();
    }, [searchTerm, selectedFilter]);

    // Sort filteredPositions based on sortConfig
    const sortedPositions = [...searchedPositions].sort((a, b) => {
        if (sortConfig.direction === 'asc') {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return -1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return 1;
            }
            return 0;
        } else {
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return -1;
            }
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return 1;
            }
            return 0;
        }
    });

    function navigateToJobs() {
        navigate("/jobs/create");
    }

    // Render the component
    return (
        <>
            {/* Count summary */}
            <div className="flex justify-center space-x-4">
                {/* Count of all positions */}
                <div
                    className='w-1/3 h-[6rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                    style={{display: 'flex', alignItems: 'stretch'}}>
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="flex justify-between items-center">
                                <div
                                    className="text-[#000] font-sans text-[0.9rem] font-semibold tracking-[0.5px] -mt-[0.5rem]"
                                    style={{'fontFamily': 'helvetica'}}>Positions
                                    ({countAllPositions})
                                </div>
                            </div>
                            <div
                                className="font-inter text-black font-sans text-[0.9rem] mb-0 -mt-[0.75rem] w-[90%]">This
                                is the count of total positions in your organisation
                            </div>
                        </div>
                    </div>
                </div>
                {/* Count of my positions */}
                <div
                    className='w-1/3 h-[6rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 mx-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                    style={{display: 'flex', alignItems: 'stretch'}}>
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="flex justify-between items-center">
                                <div
                                    className="font-sans text-[#000] text-[0.9rem] font-semibold tracking-[0.5px] -mt-[0.5rem]"
                                    style={{'fontFamily': 'helvetica'}}>My Positions
                                    ({countMyPositions})
                                </div>
                            </div>
                            <div
                                className="font-inter text-black font-sans text-[0.9rem] mb-0 -mt-[0.75rem] w-[90%]">This
                                is the count of positions created by you
                            </div>
                        </div>
                    </div>
                </div>
                {/* Count of total candidates */}
                <div
                    className='w-1/3 h-[6rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                    style={{display: 'flex', alignItems: 'stretch'}}>
                    <div className="flex justify-between items-center">
                        <div>
                            <div className="flex justify-between items-center">
                                <div
                                    className="text-[#000] text-[0.9rem] font-semibold tracking-[0.5px] -mt-[0.5rem]"
                                    style={{'fontFamily': 'helvetica'}}>Candidates
                                    ({totalCandidates})
                                </div>
                            </div>
                            <div
                                className="font-inter text-black font-sans text-[0.9rem] mb-0 -mt-[0.75rem] w-[90%]">This
                                is the count of candidates across all assessments
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Loading overlay */}
            {loading ? (
                <LoadingOverlay/>
            ) : (
                <>
                    {/* Check for empty data */}
                    {isEmptyDataError ? (
                        <>
                            {/* Show add button if required */}
                            {/* {showAddButton && (
                                <div className="responsive flex justify-end  mb-[1.5rem] pt-6 flex-col items-end">
                                    <Button className="ml-4.313rem" width="9.375rem" label="New position"
                                        variant="bright-blue" onClick={() => navigate('/jobs/create')} />
                                </div>
                            )} */}
                            <div className=" mx-auto flex flex-col items-center">
                                <ErrorComponent
                                    content="No positions in sight! Spice up your hiring journey by posting new roles and let the talent parade begin. It's time to build the dream team!"/>
                                <Button className="" width="9.375rem" label="New position"
                                        variant="bright-blue" onClick={() => navigate('/jobs/create')}/>
                            </div>
                        </>
                    ) : (
                        <>
                            {/* Render position data */}
                            <div className="mx-auto mt-[0.5rem] w-full overflow-y-scroll">
                                {/* Search and filter options */}
                                <div className="flex mb-[10px] mt-[3rem] justify-between">
                                    {/* Add button */}
                                    <div className="relative">
                                        {showAddButton && (
                                            <Button className="bg-[#004eec]" width="9.375rem" label="New position"
                                                    variant="bright-blue" onClick={() => navigate('/jobs/create')}/>
                                        )}
                                    </div>
                                    {/* Search input and filter select */}
                                    <div className="flex flex-row gap-x-[1rem]">
                                        <div className="relative">
                                            {/* Search input */}
                                            <input
                                                type="text"
                                                value={searchTerm}
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                                className="mb-2 w-80 pl-9 pr-4 py-2 text-[0.9rem] text-text-grey border-[0px] h-[1.9rem] rounded-sm bg-[#F9F9F9] focus:outline-none focus:border-blue-500 "
                                                placeholder="Search by positions"
                                                style={{
                                                    backgroundImage: `url(${search})`,
                                                    backgroundSize: "1rem",
                                                    backgroundPositionX: "0.5rem",
                                                    backgroundPositionY: "center",
                                                    backgroundRepeat: "no-repeat"
                                                }}
                                            />
                                        </div>
                                        <div className="relative">
                                            {/* Filter select */}
                                            <select
                                                value={selectedFilter}
                                                onChange={(e) => setSelectedFilter(e.target.value)}
                                                className="mb-2 mr-1 pl-5 pr-10 py-1 appearance-none rounded-sm h-[1.9rem] border-[0px] bg-[#F9F9F9] bg-[0px] text-[0.9rem] text-text-grey focus:outline-none focus:border-blue-500"
                                                style={{
                                                    backgroundImage: `url(${arrow})`,
                                                    backgroundSize: "0.8rem",
                                                    backgroundPositionX: "calc(100% - 1.2rem)",
                                                    backgroundPositionY: "center",
                                                    backgroundRepeat: "no-repeat"
                                                }}
                                            >
                                                <option value="Positions">All positions</option>
                                                <option value="MyPositions">My positions</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                {/* Position table */}
                                <div className="">
                                    <div className="rounded-lg overflow-y-scroll"
                                         style={{maxHeight: "19.813rem", maxWidth: '100%', overflowY: "scroll"}}>
                                        <style>
                                            {`
                                        ::-webkit-scrollbar {
                                            width: 0;
                                            height: 0;
                                            display: none;
                                        }
                                    `}
                                        </style>
                                        {/* Render position table */}
                                        {sortedPositions.length === 0 ? (
                                            <div className="flex justify-center items-center flex-col">
                                                <ErrorComponent
                                                    content="Oops! It seems like you're in uncharted territory. Currently, there are no available positions or candidates that match your search criteria. Let's refine and try again for the perfect match"/>
                                            </div>
                                        ) : (
                                            <table
                                                className="w-full border rounded-[6px] border-solid border-bgrey mt-0">
                                                <thead className="sticky top-0 bg-[#FAFAFA] h-10">
                                                <tr>
                                                    {/* Table headers */}
                                                    <th className="text-charcoal text-[0.85rem] text-justify pl-7 font-regular cursor-pointer"
                                                        onClick={() => requestSort("name")}>Position Name
                                                    </th>
                                                    <th className="text-charcoal text-[0.85rem] pr-18 pl-1 text-justify font-regular cursor-pointer"
                                                        onClick={() => requestSort("statusName")}>Status
                                                        <span className="ml-1">
                                                            {sortConfig.direction === 'asc' ? '↑' : '↓'}
                                                        </span>
                                                    </th>
                                                    <th className="text-charcoal text-[0.85rem] pr-1 text-justify font-regular cursor-pointer"
                                                        onClick={() => requestSort("candidates")}>Candidates
                                                    </th>
                                                    <th className="text-charcoal text-[0.85rem] text-start pl-3 font-regular cursor-pointer"
                                                        onClick={() => requestSort("createdDate")}>Created Date
                                                        <span className="ml-1">
                                                            {sortConfig.direction === 'asc' ? '↑' : '↓'}
                                                        </span>
                                                    </th>
                                                    <th className="text-charcoal text-[0.85rem] text-start pl-5 font-regular cursor-pointer"
                                                        onClick={() => requestSort("adminName")}>Creator
                                                    </th>
                                                    <th className="text-charcoal text-[0.85rem] text-start pl-4 font-regular">Action</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {/* Table rows */}
                                                {sortedPositions.map((position, index) => (
                                                    <tr key={index} className="border-b border-gg h-5">
                                                        <td className="py-3 pl-7 ">
                                                            <div
                                                                className="text-[0.85rem] font-normal text-[#020818] overflow-hidden whitespace-nowrap overflow-ellipsis cursor-pointer"
                                                                style={{maxWidth: "15rem"}}
                                                                onClick={(e) => handlePositionClick(e, position.id)}>{position.name}</div>
                                                        </td>
                                                        <td className={`mt-3 pl-1 -ml-2 w-[5rem] h-[21px] rounded-[2px] text-xs font-bold flex items-center justify-center ${getStatusColorClass(position.statusName)}`}>
                                                            <div>{position.statusName[0].toUpperCase() + position.statusName.slice(1).toLowerCase()}</div>
                                                        </td>
                                                        <td className="pl-7 text-[#020818] text-[0.85rem] font-normal">{position.candidates}</td>
                                                        <td className="pl-5 text-[#020818] font-medium text-[0.85rem]">{position.createdDate ? new Date(position.createdDate).toLocaleDateString('en-US', {
                                                            year: 'numeric',
                                                            month: 'short',
                                                            day: 'numeric'
                                                        }) : "NA"}</td>
                                                        <td className="pl-5 text-[#020818] text-[0.85rem] font-normal overflow-hidden whitespace-nowrap overflow-ellipsis"
                                                            style={{maxWidth: "5rem"}}>{position.adminName}</td>
                                                        <td className="pl-4">
                                                            <div className="flex flex-row">
                                                                <div className="pr-2">
                                                                    {/* Edit button */}
                                                                    {/* <button onClick={() => { setEditPositionId(position.id); setCurrentlyEditedPositionId(position.id); }}> <img src={edit} alt="Edit" style={{ width: "1.2rem", height: "1.2rem" }} /></button> */}
                                                                </div>
                                                                <div>
                                                                    {/* Delete button */}
                                                                    <button
                                                                        onClick={() => handleDeletePosition(position.id)}>
                                                                        <img src={trash} alt="Delete Icon"
                                                                             style={{
                                                                                 width: "1.2rem",
                                                                                 height: "1.2rem"
                                                                             }}/>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        )}
                                    </div>
                                </div>
                                {/* Modal for confirmation */}
                                <Modal
                                    isOpen={showModal}
                                    onClose={() => setShowModal(false)}
                                    onConfirm={handleModalConfirm}
                                    heading={modalContent.heading}
                                    message={modalContent.message}
                                    confirmButtonText={modalContent.confirmButtonText}
                                    showCancelButton={modalContent.showCancelButton || false}
                                    cancelButtonText={modalContent.cancelButtonText}
                                />
                                {showLink && (
                                    <div className="">
                                        <a onClick={navigateToJobs} className="text-[#004EEC] mt-8 cursor-pointer">+
                                            new
                                            position</a>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </>
            )}
        </>
    );
}

export default Position;