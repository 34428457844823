const initialState = {
    assesmentsForPosition: null,
    assesmentQuestions: null,
    questionsForSkill: null,
    skills: null,
}

const positionReducer = (state = initialState, action) => {
    switch (action.type) {
        case "GET_ASSESMENT_FOR_POSITION":
            return {
                ...state,
                assesmentsForPosition: action.payload
            }
        case "GET_ASSESMENTS_QUESTIONS":
            return {
                ...state,
                assesmentQuestions: action.payload
            }
        case "GET_QUESTIONS_FOR_SKILLS":
            return {
                ...state,
                questionsForSkill: action.payload
            }
        case "GET_SKILLS":
            return {
                ...state,
                skills: action.payload
            }
        default:
            return null
    }
}
export default positionReducer
