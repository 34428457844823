// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Your custom CSS */

/* Apply general styling to the component container */
div.flex.items-center {
  margin-bottom: 16px;
}

div.flex.items-center.m-0 {
  margin-bottom: 0px;
}
/* Apply styling to the label */
p.text-lg.font-medium {
  margin-bottom: 8px;
}

/* Apply styling to the country code dropdown */
select#countryCode {
  border: none;
  /* border-radius: 0.375rem; */
  padding: 0.25rem 0.5rem;
  height: 3.15rem;
  margin-top: -13px;
  margin-right: 8px;
  position: absolute;
  outline: none;
  margin-left: 3px;
  font-size: 1rem;
  line-height: 1.5;
  /* appearance: none; */
}

/* Optional: Style the dropdown arrow for select elements */
select#countryCode::after {
  content: "\\25BC"; /* Unicode character for down arrow */
  position: absolute;
  top: 50%;
  right: 0.75rem; /* 12px */
  transform: translateY(-50%);
  pointer-events: none; /* Ensure the arrow doesn't interfere with the click on the select */
}

/* Optional: Style the selected option in the dropdown */
select#countryCode option {
  background-color: #fff; /* Set background color */
}
`, "",{"version":3,"sources":["webpack://./src/components/utilities/phoneInputs.css"],"names":[],"mappings":"AAAA,oBAAoB;;AAEpB,qDAAqD;AACrD;EACE,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;AACpB;AACA,+BAA+B;AAC/B;EACE,kBAAkB;AACpB;;AAEA,+CAA+C;AAC/C;EACE,YAAY;EACZ,6BAA6B;EAC7B,uBAAuB;EACvB,eAAe;EACf,iBAAiB;EACjB,iBAAiB;EACjB,kBAAkB;EAClB,aAAa;EACb,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA,2DAA2D;AAC3D;EACE,gBAAgB,EAAE,qCAAqC;EACvD,kBAAkB;EAClB,QAAQ;EACR,cAAc,EAAE,SAAS;EACzB,2BAA2B;EAC3B,oBAAoB,EAAE,oEAAoE;AAC5F;;AAEA,wDAAwD;AACxD;EACE,sBAAsB,EAAE,yBAAyB;AACnD","sourcesContent":["/* Your custom CSS */\n\n/* Apply general styling to the component container */\ndiv.flex.items-center {\n  margin-bottom: 16px;\n}\n\ndiv.flex.items-center.m-0 {\n  margin-bottom: 0px;\n}\n/* Apply styling to the label */\np.text-lg.font-medium {\n  margin-bottom: 8px;\n}\n\n/* Apply styling to the country code dropdown */\nselect#countryCode {\n  border: none;\n  /* border-radius: 0.375rem; */\n  padding: 0.25rem 0.5rem;\n  height: 3.15rem;\n  margin-top: -13px;\n  margin-right: 8px;\n  position: absolute;\n  outline: none;\n  margin-left: 3px;\n  font-size: 1rem;\n  line-height: 1.5;\n  /* appearance: none; */\n}\n\n/* Optional: Style the dropdown arrow for select elements */\nselect#countryCode::after {\n  content: \"\\25BC\"; /* Unicode character for down arrow */\n  position: absolute;\n  top: 50%;\n  right: 0.75rem; /* 12px */\n  transform: translateY(-50%);\n  pointer-events: none; /* Ensure the arrow doesn't interfere with the click on the select */\n}\n\n/* Optional: Style the selected option in the dropdown */\nselect#countryCode option {\n  background-color: #fff; /* Set background color */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
