// Action Types
export const TOGGLE_CAMERA = 'TOGGLE_CAMERA';
export const TOGGLE_MIC = 'TOGGLE_MIC';
export const TOGGLE_SCREEN_SHARING = 'TOGGLE_SCREEN_SHARING';


// Action Creators
export const toggleCamera = () => ({
    type: TOGGLE_CAMERA,
});

export const toggleMic = () => ({
    type: TOGGLE_MIC,
});

export const toggleScreenSharing = () => ({
    type: TOGGLE_SCREEN_SHARING,
});
