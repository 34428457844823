import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { dark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeSnippet = ({ code, language }) => {
    return (
        <SyntaxHighlighter language={language} style={'light'}>
            {code}
        </SyntaxHighlighter>
    );
};

export default CodeSnippet;
