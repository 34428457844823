import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../../components/button";
import {fetchInvitations, fetchTeamId} from "../../../../../redux/actions/userManagement";
import {useDispatch, useSelector} from "react-redux";
import isEmailValid from "../../../../utilities/utilFunctions";
import Modal from "../../../../components/popup";
import LoadingOverlay from "../../../../components/loadingOverlay";
import chat from "../../../../../assets/Chat.svg";
import {FormValidator} from "@syncfusion/ej2-react-inputs";

function InviteUsers({accessToken}) {
    const [emails, setEmails] = useState("");
    const teamId = useSelector((state) => state.userManagement.teamId);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const userId = localStorage.getItem("id");
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const validatorRef = useRef(null);

    useEffect(() => {
        if (formRef.current) {
            const options = {
                rules: {
                    'emails': {
                        email: [true, 'Please enter valid emails separated by commas.']
                    },
                },
            };
            validatorRef.current = new FormValidator(formRef.current, options);
        }
    }, [formRef, validatorRef]);

    useEffect(() => {
        setIsLoading(true); // Set loading to true when fetching teamId
        dispatch(fetchTeamId({userId: userId, accessToken: accessToken}))
            .then(() => {
                dispatch(fetchInvitations({teamId: teamId, accessToken}));
                setIsLoading(false); // Set loading to false after fetching teamId
            });
    }, [dispatch, userId, accessToken, teamId]);

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    const handleSendClick = async () => {
        setIsLoading(true);
        const emailList = emails.split(/[,\s]+/);
        const validEmails = emailList.filter(isEmailValid);
        if (validEmails.length > 0) {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/inviteUsers?email=${encodeURIComponent(emails)}&teamId=${teamId}`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (response.ok) {
                    dispatch({
                        type: "SENDING_INVITE",
                        payload: {
                            isSendingInvite: true,
                        },
                    });
                    setModalContent({
                        heading: 'Success',
                        message: `Invited users successfully!`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    });
                } else {
                    setModalContent({
                        heading: 'Error',
                        message: `A user with this email already exists, please contact them.`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    });
                }
            } catch (error) {
                console.error("Error sending invitation:", error);
                setModalContent({
                    heading: 'Error',
                    message: `An error occurred while sending invitations.`,
                    confirmButtonText: 'OK',
                    onConfirm: handleModalConfirm,
                });
            }
            setShowModal(true);
            setEmails("");
        } else {
            setModalContent({
                heading: 'Error',
                message: `Please enter valid emails`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        }
        setIsLoading(false);
    };

    return (
        <div className="flex">
            {isLoading && <LoadingOverlay/>}
            <div>
                <p className="my-3 text-lg itc-fontFamily">
                    Who's Teaming Up for Your Mission?
                </p>
                <div className="my-2 mt-4 w-[70%] text-sm font-medium text-black">
                    Invite new users by typing their email addresses below. You can
                    separate each email address by adding a comma
                </div>
                <form ref={formRef}>
                    <textarea
                        name="emails"
                        placeholder="Enter emails, separated by commas"
                        rows="4"
                        cols="50"
                        className="w-[70%] mt-4 p-2 border rounded-[4px]"
                        value={emails}
                        id="email"
                        onChange={(e) => setEmails(e.target.value)}
                    />
                </form>
                <div className="flex justify-start gap-4 my-6">
                    <Button
                        type="button"
                        variant="bright-blue"
                        label="SEND INVITES"
                        className="!w-1/3 bg-[#004eec] text-[0.74rem] font-thin h-[2rem]"
                        onClick={handleSendClick}
                    />
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
            <div className="w-[32%]">
                <img src={chat} alt="people chatting" className="mt-8"/>
            </div>
        </div>
    );
}

export default InviteUsers;
