import { SET_USER_CREDENTIALS } from '../actions/userActions';

let initialState = {
    email: null,
    password: null
};
const userReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_USER_CREDENTIALS':
            return {
                ...state,
                email: action.payload.email,
                password: action.payload.password
            };
        // ... other actions
        default:
            return state;
    }
};

export default userReducer;