import React, {useEffect, useRef, useState} from "react"
import {Button} from "../../../components/button";
import {Textbox} from "../../../components/textbox";
import {FormValidator} from "@syncfusion/ej2-react-inputs";
import Modal from '../../../components/popup';
import LoadingOverlay from "../../../components/loadingOverlay";
import {useNavigate} from 'react-router-dom';
import review from "../../../../assets/Review.svg";


export const Profile = () => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");
    const [department, setDepartment] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showDelPopup, setShowDelPopup] = useState(false);
    // console.log("State before:", showDelPopup);

    const navigate = useNavigate();
    const formRef = useRef(null);
    const validatorRef = useRef(null);
    const validatorChangePasslRef = useRef(null);
    const formChangeRef = useRef(null);

    const accessToken = localStorage.getItem("accessToken");
    const emailAdd = localStorage.getItem("email");
    const userId = localStorage.getItem("id");

    const role = localStorage.getItem("role")

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    const hasUppercase = (value) => {
        const password = /[A-Z]/;
        return password.test(value.value || "");
    };
    const hasLowercase = (value) => {
        const password = /[a-z]/;
        return password.test(value.value || "");
    };
    const hasNumber = (value) => {
        const password = /\d/;
        return password.test(value.value || "");
    };
    const hasSpecialChar = (value) => {
        const password = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        return password.test(value.value || "");
    };
    const phoneValidator = (value) => {
        const phoneExp = /^\+\d{1,3}\s?\d{10}$/;
        return phoneExp.test(value.value.trim() || "");
    };

    const nameValidator = (value) => {
        const nameExp = /^[A-Za-z�-�\u00C0-\u017F'-]+(?: [A-Za-z�-�\u00C0-\u017F'-]+)*$/u;
        return nameExp.test(value.value.trim() || '');
    };
    const departmentValidator = (value) => {
        const deptExp = /^[A-Za-z�-�]+(?: [A-Za-z�-�]+)*$/;
        return deptExp.test(value.value.trim() || '');
    };

    useEffect(() => {
        if (formChangeRef.current) {
            const options = {
                rules: {
                    'newPassword': {
                        required: true,
                        minLength: [8, 'Password should have at least 8 characters'],
                        hasUppercase: [hasUppercase, 'Should contain one uppercase and one number'],
                        // hasLowercase: [hasLowercase, 'Password should contain at least one lowercase letter'],
                        hasNumber: [hasNumber, 'Should contain one uppercase and one number'],
                        // hasSpecialChar: [hasSpecialChar, 'Password should contain at least one special character']
                    },
                    'oldPassword': {
                        required: [true, 'Enter old password']
                    },

                },
            };
            validatorChangePasslRef.current = new FormValidator(formChangeRef.current, options);
        }
    }, []);

    useEffect(() => {
        if (formRef.current) {
            const options = {
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error); // todo: get the library patched to allow per field custom error message locations
                },
                rules: {
                    name: {
                        required: [true, "Name is required"],
                        minLength: [2, "Name should be at least 2 characters long"],
                        maxLength: [100, "Name should not be more than 100 characters"],
                        nameValidator: [
                            nameValidator,
                            "Name cannot contain special characters",
                        ],
                    },
                    department: {
                        // required: [true, 'Department name is required'],
                        // minLength: [0, 'Department name should be at least 2 characters long'],
                        maxLength: [
                            100,
                            "Department name should not be more than 100 characters",
                        ],
                        departmentValidator: [
                            departmentValidator,
                            "Enter a valid department",
                        ],
                    },
                    phone: {
                        required: [true, "Phone number is required"],
                        phoneValidator: [phoneValidator, "Enter a valid phone number"],
                    },
                },
            };
            validatorRef.current = new FormValidator(formRef.current, options);
        }
    }, []);

    useEffect(() => {
        setIsLoading(true);
        fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v1/getUserDetails?userId=${encodeURIComponent(userId)}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            }
        )
            .then((response) => {
                if (!response.ok) {
                    // console.error(response);
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                setIsLoading(false);
                return response.json();
            })
            .then((data) => {
                setName(data.data.name);
                setPhone(data.data.phone);
                setDepartment(data.data.dept || " ");
            })
            .catch((error) => {
                // console.error("Error:", error);
            });
    }, [userId, accessToken]);

    const handleUpdateClick = async (e) => {
        e.preventDefault();
        if (validatorRef.current.validate()) {
            setIsLoading(true);
            try {
                const requestBody = {
                    id: userId,
                    name: name,
                    phone: phone,
                    department: department,
                };

                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/modifyUser`,
                    {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: JSON.stringify(requestBody),
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const responseData = await response.json();

                if (response.ok) {
                    setIsLoading(false);
                    setModalContent({
                        heading: 'Success',
                        // message: `Login failed: ${error.message}`,
                        message: `User details updated successfully.`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    });
                    setShowModal(true);
                }
            } catch (error) {
                setIsLoading(false);
                setModalContent({
                    heading: 'Error',
                    message: `Failed to update user details.`,
                    confirmButtonText: 'OK',
                    onConfirm: handleModalConfirm,
                });
                setShowModal(true);
            }
        } else {
            setIsLoading(false);
            setModalContent({
                heading: 'Error',
                message: `Please fill all the fields correctly`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        }
    };

    // Function to initiate the delete process (attached to the Confirm button in the modal)
    const confirmDeleteAccount = async () => {
        setIsLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/deleteUser?email=${encodeURIComponent(emailAdd)}`,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            const data = await response.json();
            if (!response.ok) {
                throw new Error(
                    data.message || "An error occurred while deleting the account."
                );
            }

            navigate("/Login");
        } catch (error) {
            // console.error("Error:", error);
            setModalContent({
                heading: "Error",
                message: error.message || "Failed to delete the account.",
                confirmButtonText: "OK",
            });
            setShowModal(true);
        } finally {
            setIsLoading(false);
            setShowDelPopup(false); // Close the delete confirmation popup
        }
    };

    // Adjust handleDelClick to just show the delete confirmation popup
    const handleDelClick = (e) => {
        e.stopPropagation();
        console.log(role)
        if (role === ("ORG_ADMIN")) {
            setModalContent({
                    heading: "Error",
                    message: "Cannot delete a organisation admin.",
                    confirmButtonText: "OK",
                }
            )
            setShowModal(true)
            return
        }
        setModalContent({
            heading: "Warning",
            message: "Are you sure you want to delete your account?",
            confirmButtonText: "Yes, delete",
            cancelButtonText: "No, cancel",
            onConfirm: confirmDeleteAccount, // This is the key part
        });
        setShowDelPopup(true);
    };

    useEffect(() => {
        // console.log("Current showDelPopup state:", showDelPopup);
    }, [showDelPopup]);

    const handleDelUpdateClick = async (e) => {
        e.preventDefault();
        if (validatorChangePasslRef.current.validate()) {
            setIsLoading(true);
            try {
                const requestBody = {
                    currentPassword: oldPassword,
                    newPassword: newPassword,
                };
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/changePassword`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(requestBody),
                    }
                );

                if (!response.ok) {
                    setIsLoading(false);
                    setModalContent({
                        heading: 'Error',
                        message: `Old password is incorrect, please try again.`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    });
                    setShowModal(true);

                }

                const responseData = await response.json();

                if (response.ok) {
                    setIsLoading(false);
                    setModalContent({
                        heading: "Success",
                        message: `Passwords changed successfully`,
                        confirmButtonText: "OK",
                        onConfirm: handleModalConfirm,
                    });
                    setShowModal(true);
                }
            } catch (error) {
                // console.error("Error updating user details:", error);
            }
        } else {
            setIsLoading(false);
            setModalContent({
                heading: "Error",
                message: `Please fill all the fields correctly`,
                confirmButtonText: "OK",
                onConfirm: handleModalConfirm,
            });
            setShowModal(true);
        }
    };

    return (
        <div className="">
            {isLoading && <LoadingOverlay/>}
            <form id="profile" ref={formRef} className="">
                <div className="space-y-[1.5rem]">
                    <div class="flex justify-start gap-[3.5rem]">
                        <Textbox width="20rem"
                                 height="4.375"
                                 label="Name"
                                 placeholder="Olivia"
                                 type="text"
                                 name="name"
                                 value={name}
                                 onChange={(e) => setName(e.target.value)}
                        ></Textbox>
                        <Textbox disabled={true}
                                 width="20rem"
                                 height="4.375"
                                 label="Email"
                                 placeholder="olivia@boltcode.io"
                                 type="email"
                                 name="email"
                                 value={emailAdd}
                        ></Textbox>
                    </div>
                    <div class="flex justify-start gap-[3.5rem]">
                        <Textbox width="20rem"
                                 height="4.375"
                                 label="Phone"
                                 placeholder="1234567890"
                                 type="text"
                                 name="phone"
                                 value={phone}
                                 onChange={(e) => setPhone(e.target.value)}
                        />
                        <Textbox width="20rem"
                                 height="4.375"
                                 label="Department"
                                 placeholder="Product Engineer"
                                 type="text"
                                 name="department"
                                 value={department}
                                 onChange={(e) => setDepartment(e.target.value)}
                        ></Textbox>
                    </div>
                </div>
                <div class="mt-[2rem] flex justify-start gap-[1.625rem]">
                    <Button width="9.188rem"
                            label="Delete account"
                            type="button"
                            variant="white"
                            onClick={handleDelClick}
                    ></Button>
                    <Button width="9.188rem"
                            label="Update"
                            type="update"
                            variant="bright-blue"
                            onClick={handleUpdateClick}
                    ></Button>
                </div>
            </form>

            <form id="password" ref={formChangeRef}>

                <p className="mt-[1.5rem] text-blue text-[0.9rem] font-normal font-['Inter'] tracking-tight">
                    Deleting your account will remove all data and access permanently. Contact <a
                    href="https://ovii.ai/contact-us/" target="_blank" rel="noreferrer"
                    style={{"textDecoration": "underline"}}>support</a> for assistance.
                </p>
                <div className="mt-[4rem]">
                    <p className="font-serif text-[1.25rem] font tracking-[0.56px] text-charcoal mt-[1rem]">
                        Change password
                    </p>
                    <div class="mt-[1rem] flex justify-start gap-[3.5rem]">
                        <Textbox width="20rem"
                                 label={(
                                     <label className="flex items-center">
                                         Old Password
                                         <span className="text-red-500">*</span>
                                     </label>
                                 )}
                                 placeholder="***************"
                                 name="oldPassword"
                                 type="password"
                                 value={oldPassword}
                                 onChange={(e) => setOldPassword(e.target.value)}
                        ></Textbox>
                        <Textbox width="20rem"
                                 label={(
                                     <label className="flex items-center">
                                         New Password
                                         <span className="text-red-500">*</span>
                                     </label>
                                 )}
                                 placeholder="***************"
                                 name="newPassword"
                                 type="password"
                                 value={newPassword}
                                 onChange={(e) => setNewPassword(e.target.value)}
                        ></Textbox>
                    </div>
                    <Button width="9.188rem"
                            label="Update"
                            variant="bright-blue"
                            className="mt-[1.5rem]"
                            onClick={handleDelUpdateClick}
                    ></Button>
                </div>
            </form>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
            {
                showDelPopup && (
                    <Modal
                        isOpen={showDelPopup}
                        heading={modalContent.heading}
                        message={modalContent.message}
                        confirmButtonText={modalContent.confirmButtonText}
                        cancelButtonText={modalContent.cancelButtonText}
                        onClose={() => setShowDelPopup(false)}
                        onConfirm={() => {
                            modalContent.onConfirm(); // Call the confirmDeleteAccount function
                        }}
                        showCancelButton={true}
                    />
                )
            }
            <div className="mr-[5rem]">
                <img src={review}
                     alt="deer with fawn"
                     className="absolute bottom-16 right-0 w-[auto] h-[16.313rem]"
                     style={{maxWidth: '100%', maxHeight: '100%'}}/>
            </div>
        </div>
    );
}

export default Profile;