import {createSlice} from "@reduxjs/toolkit";

export const candidateStatusSlice = createSlice({
    name: 'candidateStatus',
    initialState: {
        isStatus: false,
    },
    reducers: {
        setStatus: (state) => {
            return {
                ...state,
                isStatus: !state.isStatus
            };
        },
    },
});

export const {setStatus} = candidateStatusSlice.actions;
export default candidateStatusSlice.reducer;
