const FooterLinks = ({ className }) => {
    return (
        <div className={`${className}`}>
            <p className='font-sans text-[0.813rem] text-text-grey'>� 2024 ovii. All rights reserved</p>
            <div className='mt=[0.688em]'>
                <a href='#' className='font-sans text-[0.625rem] text-text-grey mr-[0.938rem]'>Privacy policy</a>
                <a href='#' className='font-sans text-[0.625rem] text-text-grey mr-[0.938rem]'>Terms of service</a>
                <a href='#' className='font-sans text-[0.625rem] text-text-grey mr-[0.938rem]'>Cookie policy</a>
                <a href='#' className='font-sans text-[0.625rem] text-text-grey mr-[0.938rem]'>EULA</a>
            </div>
        </div>
    )
}

export default FooterLinks