import React, {useEffect, useRef, useState} from "react"
import {useNavigate} from "react-router-dom"
import logo from '../../../assets/logo_ovii_2.svg'
import {Button} from "../../components/button";
import hello from '../../../assets/Hello.svg'
import Checkbox from "../../components/checkbox";
import {Textbox} from "../../components/textbox";
import {useDispatch} from "react-redux";
import {setUserCredentials} from "../../../redux/actions/userActions";
import {FormValidator} from "@syncfusion/ej2-react-inputs";
import PhoneNumberInput from "../../utilities/phoneCodeDropDown";
import {setAdminEmail} from "../../../redux/actions/teamActions";

import Modal from "../../components/popup";
import {setNextUrl} from "../../../redux/reducers/urlSlice";


export const YourDetails = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [dropDownValue, setDropDownValue] = useState('');
    const [phone, setPhone] = useState('');
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [showInvalidPopup, setShowInvalidPopup] = useState(false);
    const isMobile = window.innerWidth <= 640;
    const searchParams = new URLSearchParams(window.location.search);
    const fromSite = searchParams.get('fromSite');

    const formRef = useRef(null);
    const validatorRef = useRef(null);

    useEffect(() => {
        if (fromSite != null) {
            dispatch(setNextUrl(fromSite))
        }
        // console.log(fromSite)
    }, [fromSite]);

    const handleCheckboxChange = (e) => {
        setIsTermsChecked(e.target.checked);
    };

    const hasUppercase = (value) => {
        const password = /[A-Z]/;
        return password.test(value.value || '');
    };
    const hasLowercase = (value) => {
        const password = /[a-z]/
        return password.test(value.value || '');
    };
    const hasNumber = (value) => {
        const password = /\d/
        return password.test(value.value || '');
    };
    const hasSpecialChar = (value) => {
        const password = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
        return password.test(value.value || '');
    };

    const phoneValidator = (value) => {
        const phoneExp = /^\d{10}$/;
        return phoneExp.test(value.value.trim() || '');
    };

    const nameValidator = (value) => {
        const nameExp = /^[A-Za-z�-�\u00C0-\u017F'-]+(?: [A-Za-z�-�\u00C0-\u017F'-]+)*$/u;

        return nameExp.test(value.value.trim() || '');
    };

    const institutionalEmailValidator = (args) => {
        args.value = args.value.trim(); // Trim the input value
        const disallowedDomains = ['gmail.com', 'yahoo.com', 'outlook.com', 'aol.com',
            'icloud.com', 'protonmail.com', 'zoho.com', 'yandex.com', 'mail.com', 'gmx.com',
            'tutanota.com', 'fastmail.com', 'hushmail.com', 'gawab.com', 'inbox.com', 'aim.com',
            'fastmail.com', 'tutanota.com', 'pobox.com', 'yandex.ru', 'yandex.mail',
            'aol.com', 'gmx.net', 'rediff.com'];
        const emailDomain = args.value.split('@')[1];

        return !disallowedDomains.includes(emailDomain);
    };

    useEffect(() => {
        if (formRef.current) {
            // Define the validation rules and options
            const options = {
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },

                rules: {
                    'name': {
                        required: [true, 'Name is required'],
                        minLength: [2, 'Name should be at least 2 characters long'],
                        maxLength: [100, 'Name should not be more than 100 characters'],
                        nameValidator: [nameValidator, 'Name cannot contain special characters']
                    },
                    'email': {
                        required: [true, 'Email is required'],
                        email: [true, 'Please enter a valid email'],
                        institutionalEmailValidator: [institutionalEmailValidator, 'Please enter a valid work email id']
                    },
                    'phone': {
                        required: [true, 'Phone number is required'],
                        phoneValidator: [phoneValidator, 'Enter a valid phone number']
                    },
                    'password': {
                        required: true,
                        minLength: [8, 'Password should have at least 8 characters'],
                        hasUppercase: [hasUppercase, 'Should contain one uppercase and one number'],
                        // hasLowercase: [hasLowercase, 'Password should contain at least one lowercase letter'],
                        hasNumber: [hasNumber, 'Should contain one uppercase and one number'],
                        // hasSpecialChar: [hasSpecialChar, 'Password should contain at least one special character']
                    }
                },
            };
            validatorRef.current = new FormValidator(formRef.current, options);
        }
    }, [formRef, validatorRef]);

    const handleContinueClick = async (e) => {
        e.preventDefault();
        if (validatorRef.current.validate()) {
            if (isTermsChecked && validatorRef.current) {
                setIsLoading(true);
                const checkOrgUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/orgExists?email=${encodeURIComponent(email)}`;
                try {
                    let response = await fetch(checkOrgUrl, {method: 'POST'});
                    let data = await response.json();

                    if (data.message === "org exists") {
                        dispatch(setAdminEmail(data.data));
                        const registerStatus = await registerUser();
                        if (registerStatus === 'success') {
                            navigate('/teamCreation');
                            setIsLoading(false)
                        }
                        // If registerStatus is 'user_exists' or 'error', no need to navigate as registerUser already handles it.
                    } else {
                        const registerStatus = await registerUser();
                        if (registerStatus === 'success') {
                            // await generateOTP();
                            navigate('/emailVerification');
                            setIsLoading(false);
                        }
                        // If registerStatus is 'user_exists' or 'error', no need to navigate as registerUser already handles it.
                    }
                } catch (error) {
                    // console.error('Error:', error);
                    setIsLoading(false);
                }
            } else {
                setIsLoading(false);
                setModalContent({
                    heading: 'Error',
                    message: 'Please agree to the Terms of Use and Privacy Policy',
                    confirmButtonText: 'OK',
                });

                setShowModal(true);
            }
        } else {
            // setModalContent({
            //     heading: 'Error',
            //     message: 'Please fill in all fields correctly.',
            //     confirmButtonText: 'OK',
            // });
            // setShowModal(true);
        }
    };

    const registerUser = async () => {
        const registerUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/register`;
        let fullPhone = dropDownValue + phone;
        // Use fullPhone directly in the body of your request
        const body = JSON.stringify({name, email, password, phone: fullPhone});
        setIsLoading(true);
        try {
            const response = await fetch(registerUrl, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: body
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.data === null) {
                console.log(responseData)
                setIsLoading(false)
                // setModalContent({
                //     heading: 'Error',
                //     message: 'User already exists, redirecting to login.',
                //     confirmButtonText: 'OK',
                // });
                // console.log(modalContent)
                // setShowModal(true);
                setShowInvalidPopup(true)
            }

            if (responseData.message === 'user already exists with the given email') {
                setIsLoading(false)
                setModalContent({
                    heading: 'Error',
                    message: 'User already exists, redirecting to login.',
                    confirmButtonText: 'OK',
                    onConfirm: handleLoginClick()
                });
                setShowModal(true);
                return 'user_exists';
            }

            localStorage.setItem("userId", responseData.data.userId)
            dispatch(setUserCredentials(email, password));
            return 'success';
        } catch (error) {
            setIsLoading(false)
            setModalContent({
                heading: 'Error',
                message: 'Please enter a valid organisation email',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
            return 'error';
        }
    };


    function handleLoginClick() {
        navigate('/login');
    }

    const handleModalConfirm = () => {
        if (modalContent.message === "User already exists, redirecting to login.") {
            setIsLoading(false);
            navigate('/login');
        }
        setShowModal(false);
    };

    const handleInvalidPopup = () => {
        navigate("/login");
        setShowInvalidPopup(false);
    };

    return (
        <div classname="h-dvh">
            {/* {isLoading && <LoadingOverlay/>} */}
            <form id="companyDetails" onSubmit={handleContinueClick} ref={formRef}>
                <div className="flex flex-col justify-center items-center mt-[9rem]">
                    <img src={logo} alt="ovii logo"/>
                    <p className='font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mt-[1rem]'>Get
                        started with ovii</p>
                    <div className="flex-container">
                        <div class="flex flex-wrap ml-[2rem]">
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Name
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    placeholder='Your name'
                                    className='mt-[1.25rem] pr-[2rem]'
                                    inputClassName='w-[20rem]'
                                    type="text"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Email
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    placeholder='olivia@boltcode.io'
                                    className='mt-[1.25rem]'
                                    inputClassName='w-[20rem]'
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                >
                                </Textbox>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container">
                        <div class="flex flex-wrap ml-[2rem]">
                            <div className="textbox-container w-[20rem] mt-[1.3rem] mr-[2rem]">
                                <PhoneNumberInput
                                    label={(
                                        <label className="flex items-center">
                                            Phone Number
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    value={phone} // Assuming 'phone' is the state for the input value
                                    setValue={setPhone} // Function to update 'phone' state
                                    dropDownValue={dropDownValue} // State for the selected country's phone prefix
                                    setDropDownValue={setDropDownValue} // Function to update 'dropDownValue' state
                                    width="20rem"
                                    showLabel={true}
                                    id="phone"
                                    placeholder={'1234567890'}
                                    name="phone"
                                />
                            </div>
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Password
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    placeholder='********'
                                    type="password"
                                    className='mt-[1.25rem]'
                                    inputClassName='w-[20rem]'
                                    name="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                ></Textbox>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center mt-[2rem]">
                    <Checkbox name="terms" id="terms" content={(
                        <span className="inline-block align-middle text-[1rem]">
                            Agree to our
                            <a href="https://ovii.ai/customer-terms-of-use/" target="_blank" rel="noopener noreferrer"
                               className="text-blue"> Terms of Use </a>
                            and
                            <a href="https://ovii.ai/privacy-policy/" target="_blank" rel="noopener noreferrer"
                               className="text-blue"> Privacy Policy</a>
                        </span>
                    )}
                              className="text-slate-950 text-[13px] font-normal font-['Inter'] tracking-tight"
                              onChange={handleCheckboxChange}/>
                    <Button className="mt-[1.25rem] text-[0.735rem] h-[2.25rem]"
                            type="submit" label={isLoading ? "LOADING ..." : "CONTINUE"} variant='bright-blue'
                            width="22.5rem" disabled={isLoading}></Button>
                    <div className="mt-[0.9rem]">
                        <span className="loginOption">Already have an account? </span>
                        <span className='cursor-pointer text-blue loginOptionLlink' onClick={handleLoginClick}
                              tabIndex={0}>Login</span>
                    </div>
                    {showInvalidPopup && (
                        <Modal
                            isOpen={showInvalidPopup}
                            heading="Error"
                            message={`User already exists with the given email.`}
                            confirmButtonText="Close"
                            onConfirm={handleInvalidPopup}
                        />
                    )}
                    <Modal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        onConfirm={handleModalConfirm}
                        heading={modalContent.heading}
                        message={modalContent.message}
                        confirmButtonText={modalContent.confirmButtonText}
                    />
                </div>
            </form>
            {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem]"/> */}
            <img src={hello}
                 alt="man saying hello"
                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    )
}