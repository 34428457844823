import React, {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import Position from "../Jobs/positionsList";
import LoadingOverlay from "../../../components/loadingOverlay";

function Home() {
    const [positions, setPositions] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [editPositionId, setEditPositionId] = useState(null);
    const [currentlyEditedPositionId, setCurrentlyEditedPositionId] = useState(null);
    const [totalCandidates, setTotalCandidates] = useState(0); // Variable to store total candidates
    const [totalPositions, setTotalPositions] = useState(0); // Variable to store total positions
    const accessToken = localStorage.getItem("accessToken"); // Set your initial token value here
    let name = localStorage.getItem("adminName");
    const [isLoading, setIsLoading] = useState(false);
    const [myPositions, setMyPositions] = useState([]);
    const [myTotalPositions, setMyTotalPositions] = useState(0);

    useEffect(() => {
        console.log("token in home", accessToken)
    }, []);

    const handleStatusUpdate = async (positionId, statusId) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/updatePositionStatus?positionId=${positionId}&statusId=${statusId}`,
                {
                    method: "PUT",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        "Content-Type": "application/json",
                    },
                }
            );

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Update positions after successful update
            const updatedPositions = positions.map((position) => {
                if (position.id === positionId) {
                    return {...position, statusId};
                }
                return position;
            });
            console.log(currentlyEditedPositionId);

            setPositions(updatedPositions);
            setEditPositionId(null);
            setCurrentlyEditedPositionId(null);

            console.log("Position status updated successfully!");
        } catch (error) {
            console.error("Error updating position status:", error);
        }
    };

    const navigate = useNavigate();

    const navigateToJobs = () => {
        navigate("/jobs/create");
    };

    return (
        <div>
            {isLoading && <LoadingOverlay/>}
            <div className="flex flex-col h-[80vh]">
                <div className="flex flex-row items-center justify-between">
                    <p className="font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mt-[0.25rem]">
                        Welcome, {name}!
                    </p>
                </div>
                <Position showAddButton={false} showLink={true}/>
            </div>
        </div>
    );
}

export default Home;
