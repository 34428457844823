import React, { useState, useEffect, useRef } from "react";
import logo from "../../../../assets/logo_ovii_2.svg";
import plane from "../../../../assets/user-color.svg";
import { Button } from "../../../components/button";
import { Textbox } from "../../../components/textbox";
import { Textarea } from "../../../components/textarea";
import { FormValidator } from "@syncfusion/ej2-react-inputs";
import Modal from "../../../components/popup";
import ErrorComponent from "../../../components/ErrorComponent";
import ThreeButtonLoader from "../../../components/ThreeButtonLoader";

const NavBar = () => {
    return (
        <div className="flex justify-center w-full border items-center py-3 shadow-sm">
            <div
                className="w-[80%] flex justify-between items-center"
                style={{ marginBottom: 0 }}
            >
                <div
                    className="text-2xl font-bold"
                    style={{ fontFamily: "ITC Clearface" }}
                >
                    <h1>Select interview questions</h1>
                </div>
                <div
                    className="flex items-center justify-end"
                    style={{ marginBottom: 0 }}
                >
                    <img src={logo} alt="logo" className="h-6 w-12" />
                </div>
            </div>
        </div>
    );
};

export default function BulkAddQuestions() {
    const accessToken = localStorage.getItem("accessToken");
    const candidateIds = sessionStorage.getItem("selectedCandidates");
    const assessmentId = sessionStorage.getItem("assesmentId");
    const [loading, setLoading] = useState(true)
    const [showAddQuestionLayout, setShowAddQuestionLayout] = useState(false);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [totalAnswerTime, setTotalAnswerTime] = useState(0);
    const [inviteLoading, setInviteloading] = useState(false);

    const [thinkTime, setThinkTime] = useState("");
    const [answerTime, setAnswerTime] = useState("");
    const [title, setTitle] = useState("");
    const [question, setQuestion] = useState("");
    const [videoInterviews, setVideoInterviews] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const resume = "resume";
    const jd = "jd";

    const abortControllerRef = useRef(null);

    useEffect(() => {
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                rules: {
                    Thinktime: {
                        required: true,
                        range: [1, 2, "Think Time should not exceed 2 minutes"],
                        hasNumber: [hasNumber, "Think Time cannot have decimal places"],
                    },
                    AnswerTime: {
                        required: true,
                        range: [1, 5, "Answer Time should be between 1 and 5 minutes"],
                        hasNumber: [hasNumber, "Answer Time cannot have decimal places"],
                    },
                    title: {
                        required: [true, "Title cannot be empty."],
                        maxLength: [50, "The title cannot exceed 50 characters."],
                        minLength: [10, "The title must be a minimum of 10 characters."],
                    },
                    question: {
                        required: [true, "Question cannot be empty."],
                        maxLength: [100, "Question cannot exceed 100 characters."],
                        minLength: [20, "Question must be a minimum of 25 characters."],
                    },
                },
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error);
                },
            });
        }
    }, [formRef, validatorRef]);

    const hasNumber = (value) => {
        const answerTime = /^\d+$/;
        return answerTime.test(value.value || "");
    };
    
    useEffect(() => {
        setLoading(true);
        console.log("right after treu:", loading)
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
    
        const controller = new AbortController();
        abortControllerRef.current = controller;
    
        const formData = new FormData();
        formData.append("resume", resume);
        formData.append("videoInterviewCategory", 15);
        formData.append("jd", jd);
        formData.append("assessmentId", assessmentId);
    
        const fetchCategoryQuestions = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getQuestionsForVideoInterview`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                        body: formData,
                        signal: controller.signal,
                    }
                );
                const data = await response.json();
    
                let questions = [];
                if (Array.isArray(data.data)) {
                    questions = data.data.map((q) => ({
                        title: q.question,
                        question: q.expectedAnswer,
                        timeLimit: q.answerTime/60,
                        thinkTime: q.thinkTime/60,
                        category: 15,
                    }));
                }
                setLoading(false);
                setVideoInterviews(questions);
    
            } catch (error) {
                if (error.name === "AbortError") {
                    console.log("Fetch aborted");
                } else {
                    console.error("Error fetching data:", error);
                }
            }
        };
        
        fetchCategoryQuestions();
    
        return () => {
            controller.abort();
        };
    }, [accessToken]);
    
    useEffect(() => {
        console.log("Updated videoInterviews:", videoInterviews);
    }, [videoInterviews]);

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const handleSaveClick = (e) => {
        e.preventDefault();

        if (validatorRef.current && validatorRef.current.validate()) {
            const newQuestion = {
                title: title,
                question: question,
                timeLimit: parseInt(answerTime, 10),
                thinkTime: parseInt(thinkTime, 10),
                category: 15,
            };

            setVideoInterviews([...videoInterviews, newQuestion]);

            setModalContent({
                heading: "Sucess",
                message: "Question added successfully.",
                confirmButtonText: "OK",
            });
            console.log("modal content:", modalContent);
            setShowModal(true);
        } else {
            console.error(
                "Form validation failed. Please ensure all fields are filled correctly."
            );
        }
    };

    const handleAddQuestion = (question) => {
        setSelectedQuestions((prevSelectedQuestions) => [
            ...prevSelectedQuestions,
            question,
        ]);
    };

    const handleRemoveQuestion = (indexToRemove) => {
        setSelectedQuestions((prevSelectedQuestions) =>
            prevSelectedQuestions.filter((_, index) => index !== indexToRemove)
        );
    };

    const calculateTotalAnswerTime = () => {
        let total = 0;
        selectedQuestions.forEach((question) => {
            total += parseInt(question.timeLimit) || 0;
        });
        return total;
    };

    useEffect(() => {
        const total = calculateTotalAnswerTime();
        setTotalAnswerTime(total);
    }, [selectedQuestions]);

    useEffect(() => {
        console.log("selected questions:", selectedQuestions);
        console.log("assessmentId:", assessmentId);
        console.log("cand:", candidateIds);
    });


    const handleSaveQuestions = async () => {
        if (selectedQuestions.length === 0) {
            setModalContent({
                heading: "Error",
                message: "Please select at least one question to proceed.",
            });
            setShowModal(true);
            setInviteloading(false);
            return;
        }

        setInviteloading(true);

        console.log("questions inside:", selectedQuestions);
        console.log("assessmentId inside:", assessmentId);
        console.log("cand inside:", candidateIds);
        console.log("accessToken:", accessToken)

        const questions = selectedQuestions.map((question) => ({
            title: question.title,
            question: question.question,
            timeLimit: parseInt(question.timeLimit, 10),
            thinkTime: parseInt(question.thinkTime, 10), 
            category: parseInt(15, 10),
        }));

        const requestBody = {
            videoInterviews: questions,
            assessmentId: parseInt(assessmentId, 10),
            candidateIds: Array.isArray(candidateIds) 
            ? candidateIds 
            : JSON.parse(candidateIds)        
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/bulkCreateVideoInterview`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${accessToken}`,
                    },
                    body: JSON.stringify(requestBody),
                }
            );

            if (!response.ok) {
                console.log("response:", response);

                throw new Error("Network response was not ok");
            }

            const result = await response.json();
            console.log("Success:", result);
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setInviteloading(false);
        }
    };

    const handleModalConfirm = () => {
        setShowModal(false);
        setThinkTime("");
        setAnswerTime("");
        setTitle("");
        setQuestion("");
    };

    return (
        <div className="flex flex-col items-center w-full fixed">
            <NavBar />
            <div className="flex items-center justify-center w-[80%] mx-10">
                <div className="flex gap-12 transition-all duration-200">
                    <div className="flex flex-row items-center gap-0 -ml-3">
                        <img src={plane} alt="plane" className="w-[4.5rem]" />
                        <div className="flex-1 pr-6">
                            <p className="break-normal">
                                These questions are generated based on candidate resumes, past
                                experience, and JDs. Select preferred questions by category and
                                seamlessly send interview requests.
                            </p>
                        </div>
                    </div>
                    <div className="flex flex-row items-center pt-[2rem] -mt-8">
                        <Button
                            className="h-[2rem] text-[0.725rem]"
                            label="ADD YOUR OWN"
                            width="10.438rem"
                            variant="white"
                            onClick={() => setShowAddQuestionLayout(true)}
                        />
                        <Button
                            variant="bright-blue"
                            className="h-[2rem] text-[0.725rem] ml-5 cursor-pointer"
                            label={inviteLoading ? "PLEASE WAIT ..." : "SEND INVITE"}
                            width="8.625rem"
                            onClick={handleSaveQuestions}
                        />
                    </div>
                </div>
            </div>
            <div className="w-[80%]">
                <div className="text-blue font-Sans flex justify-start pb-[0.3rem]">
                    {" "}
                    Total Answer Time:{" "}
                    <span className="text-black pl-[0.3rem]">{totalAnswerTime} min</span>
                </div>
                <div className="flex gap-x-[0.5rem]">
                    <div className="w-1/3 flex flex-col gap-y-1">
                        <div className="border-2 border-gray-200 pt-4 px-3 rounded shadow h-[9.5rem] cursor-pointer border-indigo-600">
                            <p className="text-md font-semibold mb-2">Custom questions</p>
                            <p className="text-sm text-gray-600 mb-[2rem]">
                                Custom questions added by admin
                            </p>
                            <hr className="border-t-2 border-dashed border-gray-200" />
                            <div className="flex items-center gap-x-2 mt-4 mb-3">
                                <div className="rounded-[2px] text-xs font-bold">
                                    <span className="bg-[#EDF6FF] text-[#004eec] px-2 py-1">
                                        Recommended
                                    </span>
                                </div>
                                <div className="rounded-[2px] text-xs font-bold">
                                    <span className="bg-[#FFF0D9] text-[#BF7508] font-bold px-2 py-1">
                                        {videoInterviews.length} Questions
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>



                    <div className="w-2/3 max-h-[67vh] overflow-y-scroll flex flex-col gap-y-1">
                        {/* Conditional rendering based on loading state and data */}
                        {loading ? (
                            <ThreeButtonLoader height="30rem"/> // Replace with your loader component
                        ) : videoInterviews.length === 0 ? (
                            <ErrorComponent content="Get started by adding questions to the question bank." />
                        ) : (
                            videoInterviews.map((question, index) => (
                                <div key={index} className="mb-2">
                                    <button
                                        type="button"
                                        className="flex items-center justify-between w-full p-4 border border-gray-200 hover:bg-gray-100 gap-3 rounded-t"
                                        onClick={() => toggleAccordion(index)}
                                    >
                                        <span className="text-[1rem] font-medium text-start w-5/6 line-clamp-1">
                                            {question.title}
                                        </span>
                                        {selectedQuestions.some(
                                            (item) => item.title === question.title
                                        ) && (
                                            <span className="px-2 font-semibold text-xs bg-green-100 rounded-md text-green-700">
                                                Added
                                            </span>
                                        )}
                                        <svg
                                            className={`w-3 h-3 transform ${
                                                activeAccordion === index ? "rotate-0" : "-rotate-180"
                                            } shrink-0`}
                                            aria-hidden="true"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 10 6"
                                        >
                                            <path
                                                stroke="currentColor"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M9 5 5 1 1 5"
                                            />
                                        </svg>
                                    </button>
                                    <div
                                        className={`transition-all duration-200 px-5 pt-5 pb-1 border border-gray-200 flex flex-col gap-y-2 rounded-b ${
                                            activeAccordion === index ? "" : "hidden"
                                        }`}
                                    >
                                        <p
                                            className="font-medium mb-[0.5rem] text-[1rem] text-[#4b515e]"
                                            style={{
                                                fontFamily: "helvetica",
                                                fontWeight: "700",
                                            }}
                                        >
                                            {question.title}
                                        </p>
                                        <p
                                            style={{
                                                fontFamily: "helvetica",
                                                color: "#585f6d",
                                                lineHeight: "1.5rem",
                                                letterSpacing: "0.16px",
                                            }}
                                        >
                                            {question.question}
                                        </p>
                                        <hr className="border-t-2 border-dashed border-gray-200" />
                                        <div className="flex justify-between mt-1 items-center">
                                            <div className="text-xs text-gray-700 font-sans font-bold flex gap-x-2">
                                                <div className="bg-[#EDF6FF] text-[#004eec] px-2 py-1">
                                                    <span>Think Time: {question.thinkTime} min</span>
                                                </div>
                                                <div className="bg-[#EDF6FF] text-[#004eec] px-2 py-1">
                                                    <span>Answer Time: {question.timeLimit} min</span>
                                                </div>
                                            </div>
                                            <div className="flex justify-end gap-x-1">
                                                {selectedQuestions.some(
                                                    (item) => item.title === question.title
                                                ) ? (
                                                    <button
                                                        className="px-4 py-1 font-semibold text-sm bg-red-100 rounded-s text-red-700 mr-1"
                                                        onClick={() =>
                                                            handleRemoveQuestion(
                                                                selectedQuestions.findIndex(
                                                                    (item) => item.title === question.title
                                                                )
                                                            )
                                                        }
                                                    >
                                                        Remove
                                                    </button>
                                                ) : (
                                                    <button
                                                        className={`px-4 py-1 font-semibold text-sm bg-indigo-100 rounded-s text-blue-700 hover:bg-indigo-200 mr-1 ${
                                                            totalAnswerTime >= 45
                                                                ? "disabled:opacity-50 cursor-not-allowed"
                                                                : ""
                                                        }`}
                                                        onClick={() => handleAddQuestion(question)}
                                                        disabled={totalAnswerTime >= 45}
                                                    >
                                                        + Add
                                                    </button>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                </div>
            </div>
            {showAddQuestionLayout && (
                <div className="fixed z-30 top-0 right-0 transition-all duration-700 w-[65%] h-screen overflow-y-scroll bg-white shadow-2xl">
                    <div className="border shadow-sm">
                        <div
                            className="flex items-center justify-between px-8 py-3"
                            style={{ marginBottom: 0 }}
                        >
                            <div>
                                <span className="text-2xl font-bold font-serif">
                                    Add your own
                                </span>
                            </div>
                            <button
                                onClick={() => setShowAddQuestionLayout(false)}
                                className="flex items-center justify-center w-6 h-6 p-1 text-sm text-white bg-[#004eec] rounded-full"
                            >
                                X
                            </button>
                        </div>
                    </div>
                    <div className="px-8 mt-5">
                        <form id="addInterviewQuestion" ref={formRef}>
                            <div className="flex gap-x-[2rem] w-[90%] mb-[1rem]">
                                <div className="w-1/2">
                                    <Textbox
                                        label="Think Time"
                                        key="thinkTime"
                                        type="number"
                                        id="Thinktime"
                                        width="20rem"
                                        placeholder="Think Time (in minutes)"
                                        value={thinkTime}
                                        onChange={(e) => setThinkTime(e.target.value)}
                                    />
                                </div>
                                <div className="w-1/2">
                                    <Textbox
                                        label="Answer Time"
                                        key="answerTime"
                                        type="number"
                                        id="AnswerTime"
                                        width="20rem"
                                        placeholder="Answer Time (in minutes)"
                                        value={answerTime}
                                        onChange={(e) => setAnswerTime(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className="col-span-2 flex flex-col gap-y-2">
                                <Textarea
                                    key="title" // Changed to 'title'
                                    id="title"
                                    label="Title" // Update label to 'Title'
                                    name="title"
                                    widthOverride="90%"
                                    rows="4"
                                    value={title} // Set value to 'title'
                                    onChange={(e) => setTitle(e.target.value)} // Set onChange to update 'title'
                                />
                            </div>
                            <div className="col-span-2 flex flex-col gap-y-2">
                                <Textarea
                                    key="question" // Changed to 'question'
                                    id="question"
                                    label="Question" // Update label to 'Question'
                                    name="question"
                                    widthOverride="90%"
                                    rows="5"
                                    value={question} // Set value to 'question'
                                    onChange={(e) => setQuestion(e.target.value)} // Set onChange to update 'question'
                                />
                            </div>
                            <div className="flex items-center pt-4 gap-x-8">
                                <Button
                                    className="h-[2rem] text-[0.725rem]"
                                    label="SAVE"
                                    variant="bright-blue"
                                    width="10.438rem"
                                    onClick={handleSaveClick}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            )}
            <Modal
                isOpen={showModal}
                onClose={handleModalConfirm}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
        </div>
    );
}
