// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../HelveticaNeue/HelveticaNeue-01.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
    font-family: 'HelveticaNeue';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('truetype');
    /* To do - Make this into a tailwind class */
}`, "",{"version":3,"sources":["webpack://./src/assets/font.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,+DAAuE;IACvE,4CAA4C;AAChD","sourcesContent":["@font-face {\n    font-family: 'HelveticaNeue';\n    src: url('../../HelveticaNeue/HelveticaNeue-01.ttf') format('truetype');\n    /* To do - Make this into a tailwind class */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
