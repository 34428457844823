import React, { useEffect, useState } from 'react';
import { Button } from '../../../components/button';
import { useDispatch, useSelector } from 'react-redux';
import {
    addQuestionsToAssesments,
    getAssesmentsForPositions,
    getAssesmentsQuestions,
    getQuestionsForSkill,
    getSkills
} from '../../../../redux/actions/positionActions';
import { useLocation, useNavigate } from 'react-router-dom';
import AssessmentQuestionWidget from "./QuestionsWidget";
import './createPositions.css';
import SkillList from "../../../sidebar/skillList";
import Pagination from "./pagination";
import Dropdown from '../../../components/dropdown';
import ErrorComponent from '../../../components/ErrorComponent';

function AddQuestionPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const accessToken = localStorage.getItem("accessToken");
    const questionsForSkill = useSelector((state) => state?.position?.questionsForSkill || []);
    const skills = useSelector((state) => state?.position?.skills || []);
    const assesmentsForPosition = useSelector((state) => state?.position?.assesmentsForPosition);
    const positionId = useSelector((state) => state.positionDetails.positionId);
    const assesmentQuestions = useSelector((state) => state?.position?.assesmentQuestions || []);
    const [selectedQuestions, setSelectedQuestions] = useState(assesmentQuestions.map(qn => qn.questionId));
    const [isLoading, setIsLoading] = useState(false);
    const getAssesmentIdforTypeId1 = assesmentsForPosition?.find((item) => item?.typeID === 1);

    const [searchTerm, setSearchTerm] = useState("");
    const [selectedFilter, setSelectedFilter] = useState("ALL");
    const [selectedType, setSelectedType] = useState("ALL");
    const [selectedTags, setSelectedTags] = useState([]);

    const handleSelectedQuestionsChange = (candidates) => {
        setSelectedQuestions(candidates);
    };

    const filteredSkills = skills?.filter((skill) => {
        const includesSearchTerm = skill.name.toLowerCase().includes(searchTerm.toLowerCase());
        const subSkillsMatch = skill.subSkills.some((subSkill) =>
            subSkill.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
        return includesSearchTerm || subSkillsMatch;
    });

    const transformSkills = (skills, parentKey = '') => {
        if (!Array.isArray(skills)) {
            return [];
        }
        return skills.map(skill => {
            const key = parentKey ? `${parentKey}_${skill.name.toLowerCase().replace(/\s+/g, '_')}` : skill.name.toLowerCase().replace(/\s+/g, '_');
            return {
                id: skill.id,
                key: key,
                label: skill.name,
                hasChildren: skill.subSkills && skill.subSkills.length > 0,
                items: skill.subSkills ? transformSkills(skill.subSkills, key) : []
            };
        });
    };

    const transformedSkills = transformSkills(skills);

    const transformedQuestions = questionsForSkill ? questionsForSkill.map(item => ({
        id: item.questionId,
        name: item.questionDataAsJson.title ?? 'No title',
        description: item.questionDataAsJson.text?.replace(/<[^>]+>/g, '') ?? '',
        popupDescription: item.questionDataAsJson.text ?? '',
        timeLimit: typeof item.questionDataAsJson.solveTime === 'number' && !isNaN(item.questionDataAsJson.solveTime)
            ? (item.questionDataAsJson.solveTime / 60)
            : 0,
        typeID: item.questionType.questionTypeId,
        problemStatement: item.questionDataAsJson.problemStatement?.replace(/<[^>]+>/g, '') ?? '',
        popupProblemStatement: item.questionDataAsJson.problemStatement ?? '',
        difficulty: item.difficultyId.level ?? 'EASY',
        tags: item.questionDataAsJson.tags ? item.questionDataAsJson.tags.split(',') : [],
        answerExplanation: item.questionDataAsJson.answerExplanation ?? 'Note that this question does not have an answer explanation.',
        options: item.questionDataAsJson.options?.map(option => ({
            id: option.id,
            text: option.text,
            label: option.label,
        })) ?? [],
        correctOptionId: item.questionDataAsJson.correctAnswerId ?? null,
        attributesTested: item.questionDataAsJson.attributesTested ?? "",
        expectedAnswer: item.questionDataAsJson.expectedAnswer ?? "",
        inputFormat: item.questionDataAsJson.inputFormat ?? "",
        outputFormat: item.questionDataAsJson.outputFormat ?? "",
        constraints: item.questionDataAsJson.constraints ?? "",
        sampleInput: item.questionDataAsJson.sampleInput ?? "",
        sampleOutput: item.questionDataAsJson.sampleOutput ?? "",
    })) : [];

    const handleTagChange = (tags) => {
        setSelectedTags(tags);
    };

    const totalTime = selectedQuestions.reduce((acc, questionId) => {
        const question = transformedQuestions.find(q => q.id === questionId);
        return acc + (question ? question.timeLimit : 0);
    }, 0);

    const handleBackButton = () => {
        navigate("/jobs/positionDetails");
        if (selectedQuestions) {
            dispatch(addQuestionsToAssesments({
                assessmentId: localStorage.getItem("skillAssessmentId"),
                questionIds: selectedQuestions,
                accessToken: accessToken
            }));
        }
    }

    const dispatch = useDispatch();
    const fetchQuestionsForSkill = (skillId) => {
        setIsLoading(true);
        dispatch(getQuestionsForSkill({ skillId, accessToken }))
            .then(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        setIsLoading(true);
        const fetchData = async () => {
            await fetchQuestionsForSkill();
            dispatch(getAssesmentsForPositions({ positionId: positionId, accessToken: accessToken }));
            dispatch(getSkills());
            setIsLoading(false);
        };
        fetchData();
    }, []);

    useEffect(() => {
        getAssesmentIdforTypeId1 && dispatch(getAssesmentsQuestions({
            assesmentId: getAssesmentIdforTypeId1?.id,
            accessToken: accessToken
        }));
    }, []);

    const filteredQuestions = transformedQuestions.filter(question => {
        const typeMatches = selectedType === "ALL" || question.typeID === {
            "MCQ": 1,
            "Subjective": 2,
            "Code": 3
        }[selectedType];
        const filterMatches = selectedFilter === "ALL" || question.difficulty === selectedFilter;
        const tagsMatch = selectedTags.length === 0 || selectedTags.some(tag => question.tags.includes(tag));
        return typeMatches && filterMatches && tagsMatch;
    });

    const [currentPage, setCurrentPage] = useState(0);
    const questionsPerPage = 5;
    const totalPages = Math.ceil(filteredQuestions.length / questionsPerPage);
    const paginatedQuestions = Array.from({ length: totalPages }, (_, index) => {
        const start = index * questionsPerPage;
        return filteredQuestions.slice(start, start + questionsPerPage);
    });

    const handlePageChange = (increment) => {
        setCurrentPage((prevPage) => {
            const nextPage = prevPage + increment;
            if (nextPage < 0) {
                return totalPages - 1;
            } else if (nextPage >= totalPages) {
                return 0;
            }
            return nextPage;
        });
    };

    const allTags = Array.from(new Set(transformedQuestions.flatMap(question => question.tags)));

    return (
        <div className='ml-[-1.5rem]'>
            <div className='flex justify-end items-center gap-[1rem]'>
                <Button label="SAVE" className="h-[2.25rem] text-[0.74rem]" width="10rem" variant='bright-blue'
                        onClick={handleBackButton}/>
                <Dropdown
                    tags={allTags}
                    selectedFilter={selectedFilter}
                    selectedType={selectedType}
                    onFilterChange={(filter, type) => {
                        setSelectedFilter(filter);
                        setSelectedType(type);
                        setCurrentPage(0);
                    }}
                    onTagChange={handleTagChange}
                />
            </div>
            <div className='flex items-start' style={{borderRadius: '0.75rem'}}>
                <div className=''>
                    <div
                        className="max-h-[70vh] overflow-y-scroll 2xl:w-[16rem] xl:w-[16rem] md:w-[12rem] lg:w-[12rem]"
                        style={{
                            scrollbarWidth: "none",
                            msOverflowStyle: "none",
                            "&::-webkit-scrollbar": { display: "none" },
                        }}>
                        {skills ? (
                            <SkillList
                                sidebarConfig={transformedSkills}
                                onSkillChange={(id) => fetchQuestionsForSkill(id)}
                            />
                        ) : (
                            <p>Loading skills...</p> // Or any other fallback UI
                        )}
                    </div>
                </div>
                <div className='flex flex-col gap-y-3 align-middle max-h-[77vh] overflow-y-scroll w-full' style={{
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": { display: "none" },
                }}>
                    <div style={{
                        scrollbarWidth: "none",
                        msOverflowStyle: "none",
                        "&::-webkit-scrollbar": { display: "none" },
                    }}
                        className={`${questionsForSkill ? "mt-[0.8rem] ml-[1rem] border border-neutral-200 px-4 h-[32rem] max-h-[75vh] overflow-y-scroll w-[98%]" : "w-full"}`}>
                        {isLoading && (
                            <div
                                className="px-4 h-[30rem] w-full flex items-center justify-center font-helvetica font-bold text-gray-600">
                                Loading Please Wait ...
                            </div>
                        )}
                        {!isLoading && filteredQuestions.length > 0 ?
                            <AssessmentQuestionWidget questions={paginatedQuestions[currentPage] || []}
                                onSelectedQuestionsChange={handleSelectedQuestionsChange}
                                _selectedQuestions={selectedQuestions}
                                showButtons={true} showDetails={false} />
                            : <ErrorComponent content="No questions found" />

                        }
                    </div>
                    <div className="pl-[0.5rem] h-[2.5rem]">
                        <div className=" ">
                            {totalPages > 1 && (
                                <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={setCurrentPage}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddQuestionPage;