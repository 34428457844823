import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logo_ovii_2.svg';
import { Textbox } from '../../components/textbox';
import { Button } from '../../components/button';
import { useDispatch } from 'react-redux';
import { loginUser } from '../../../redux/actions/loginActions';
import { FormValidator } from '@syncfusion/ej2-react-inputs';
import Modal from '../../components/popup';
import picture from '../../../assets/Chat.svg'

export const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const formRef = useRef(null);
    const formValidator = useRef(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        console.log(process.env.REACT_APP_BASE_URL);
    }, [process.env.REACT_APP_BASE_URL])

    useEffect(() => {
        // Check if the form element is available
        if (formRef.current) {
            // Initialize FormValidator and assign to formValidator ref
            formValidator.current = new FormValidator(formRef.current, {
                rules: {
                    email: { required: true, email: true },
                    password: { required: true }
                }
            });
        }
    }, [formRef]);

    const handleRegisterClick = () => {
        navigate('/register');
    };

    const handleForgotPasswordClick = () => {
        navigate('/forgotpassword1');
    };

    const handleModalConfirm = () => {
        // Handle confirm button click in the modal
        setShowModal(false);
    };

    const handleLoginClick = e => {
        e.preventDefault();
        if (formValidator.current && formValidator.current.validate()) {
            setIsLoading(true); // Set isLoading to true before dispatching the action
            const credentials = { email: email.trim(), password: password };
            dispatch(loginUser(credentials, () => {
                navigate('/home');
                setIsLoading(false); // Set isLoading to false after successful login
            }, handleLoginFailure));
        } else {
            setIsLoading(false);
            setModalContent({
                heading: 'Error',
                message: `Please enter a valid email address and password.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm
            });
            setShowModal(true);
        }
    };

    const handleLoginFailure = error => {
        setIsLoading(false)
        setModalContent({
            heading: 'Error',
            message: `Sorry, we couldn't log you in. Your username or password might be incorrect.`,
            confirmButtonText: 'OK',
            onConfirm: handleModalConfirm
        });
        // setIsLoading(false)
        setShowModal(true);
    };

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return (
        <div className="flex flex-row h-screen overflow-hidden">
            <div
                className="flex items-center justify-center w-full h-full lg:w-1/2 lg:flex lg:items-center lg:justify-center">
                <div className="flex flex-col justify-center items-center -mr-[14%] lg:ml-0">
                    <img src={picture} className="w-[16.25rem] h-[auto] hidden sm:block" />
                    <p className="font-serif max-w-[30rem] text-xl break-word text-center hidden sm:block">
                        Our skill assessments and asynchronous video interviews allow you to efficiently assess
                        candidates at scale, anytime, anywhere.
                    </p>
                </div>
            </div>
            <div className="flex items-center justify-center w-full lg:w-1/2">
                <form id="loginForm" ref={formRef} onSubmit={handleLoginClick}
                    className="flex flex-col items-center justify-center">
                    <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mb-8" />
                    <p className="font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mb-6">
                        Login to your account
                    </p>
                    <Textbox
                        label={(
                            <label className="flex items-center">
                                Email
                                <span className="text-red-500" style={{ color: '#E90C44' }}>
                                    *
                                </span>
                            </label>
                        )}
                        placeholder="olivia@boltcode.io"
                        className="mb-5"
                        inputClassName="w-[22rem]"
                        type="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                    ></Textbox>
                    <Textbox
                        label={(
                            <label className="flex items-center">
                                Password
                                <span className="text-red-500" style={{ color: '#E90C44' }}>
                                    *
                                </span>
                            </label>
                        )}
                        placeholder="********"
                        type="password"
                        className="mb-5"
                        inputClassName="w-[22rem]"
                        name="password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                    ></Textbox>
                    <div className="flex justify-end items-center w-[22rem] mb-5">
                        <a
                            className="tracking-[0.14px] text-[0.9rem] text-blue font-medium cursor-pointer"
                            onClick={handleForgotPasswordClick}
                            tabIndex={0}
                        >
                            Forgot password?
                        </a>
                    </div>
                    <Button label={isLoading ? "LOGGING IN..." : "LOGIN"} width="22rem" variant='bright-blue' />
                    <p className="mt-4 text-sans text-[1rem] tracking-[0.14px] flex justify-center">
                        New to ovii?
                        <a
                            className="ml-1 font-medium cursor-pointer text-blue"
                            onClick={handleRegisterClick}
                            tabIndex={0}
                        >
                            Create an account
                        </a>
                    </p>
                    <Modal
                        isOpen={showModal}
                        onClose={() => setShowModal(false)}
                        onConfirm={handleModalConfirm}
                        heading={modalContent.heading}
                        message={modalContent.message}
                        confirmButtonText={modalContent.confirmButtonText}
                    />
                </form>
            </div>
        </div>
    );
}