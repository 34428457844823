import React, { useEffect, useRef } from 'react';

export default function VideoInterviewManagerView() {
    const videoId = "319b034d-76a7-4511-9c42-bd9d1ade295a-253-video.webm";  // This should be the ID or the specific URL part for your video
    const playerRef = useRef(null);  // This ref will attach to the iframe for player.js to use

    useEffect(() => {
        let player;

        const handlePlayerReady = () => {
            console.log('Player is ready');

            // Auto-play the video when ready
            player.play();
        };

        // if (window.playerjs) {
        //     player = new playerjs.Player(playerRef.current);  // Initialize the player with the iframe ref
        //     player.on('ready', handlePlayerReady);
        // }

        return () => {
            player?.off('ready', handlePlayerReady);  // Clean up event listener
            player?.remove();  // Remove the player instance when the component unmounts
        };
    }, []);  // Empty dependency array means this effect runs once on mount

    return (
        <div>
            <iframe
                ref={playerRef}
                src={`https://video.bunnycdn.com/player/${videoId}`}
                width="640"
                height="360"
                frameBorder="0"
                allowFullScreen>
            </iframe>
        </div>
    );
}
