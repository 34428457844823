// import React from 'react'
// import './ThreeButtonLoader.css'
// import logo from "../../assets/logo_ovii_2.svg";

// export default function ThreeButtonLoader(props) {
//     return (
//         <div class=" flex flex-col justify-center items-center bg-white bg-opacity-0">
//             <div class="loader bg-white p-5 rounded-full flex space-x-3 bg-opacity-0">
//                 <div class="w-5 h-5 bg-[#004eec] rounded-full animate-bounce"></div>
//                 <div class="w-5 h-5 bg-[#004eec] rounded-full animate-bounce"></div>
//                 <div class="w-5 h-5 bg-[#004eec] rounded-full animate-bounce"></div>
//             </div>
//             <div className='font-semibold text-[1.25rem] font-serif text-center'>{props.message}</div>
//             <div className="z-0 fixed bottom-0 left-0 w-full text-center">
//                 <p>
//                     Powered by
//                     <img className="w-10 h-10 inline pl-2 mb-1"
//                          src={logo}
//                     ></img>
//                 </p>
//             </div>
//         </div>
//     )
// }


import React from 'react';
import './ThreeButtonLoader.css';
import logo from "../../assets/logo_ovii_2.svg";

export default function ThreeButtonLoader(props) {
    const { height = '100%', message } = props;

    return (
        <div
            className="flex flex-col justify-between bg-white bg-opacity-0"
            style={{ height }}
        >
            <div className="flex flex-col justify-center items-center flex-grow">
                <div className="loader justify-center items-center bg-white p-5 rounded-full flex space-x-3 bg-opacity-0">
                    <div className="w-5 h-5 bg-[#004eec] rounded-full animate-bounce"></div>
                    <div className="w-5 h-5 bg-[#004eec] rounded-full animate-bounce"></div>
                    <div className="w-5 h-5 bg-[#004eec] rounded-full animate-bounce"></div>
                </div>
                {message && (
                    <div className="font-semibold text-[1.25rem] font-serif text-center mt-4">
                        {message}
                    </div>
                )}
            </div>
            <div className="text-center">
                <p>
                    Powered by
                    <img className="w-10 h-10 inline pl-2" src={logo} alt="Ovii Logo" />
                </p>
            </div>
        </div>
    );
}

