import React, {createRef, useEffect, useState} from "react";
import {Button} from "./button";
import Modal from "./popup";
import LoadingOverlay from "./loadingOverlay";
import hello from "../../assets/Hello.svg"

const OtpComponent = (props) => {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const inputRefs = Array(6).fill().map(() => createRef());
    const [attempts, setAttempts] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [showInvalidPopup, setShowInvalidPopup] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;

        if (element.value === '' && index > 0) {
            const newOtp = [...otp];
            newOtp[index] = ''; // Clear the current element
            setOtp(newOtp);
            if (inputRefs[index - 1].current) {
                inputRefs[index - 1].current.focus(); // Focus on the previous input
            }
        } else {
            const newOtp = [...otp];
            newOtp[index] = element.value;
            setOtp(newOtp);

            if (element.value !== '' && index < otp.length - 1) {
                inputRefs[index + 1].current.focus(); // Focus on the next input
            }
        }
    };

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const handleInvalidPopup = () => {
        setShowInvalidPopup(false);
    };

    async function handleVerifyOTP(enteredOTP) {
        setIsLoading(true);
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/verifyOTP?email=${encodeURIComponent(props.email)}&enteredOTP=${enteredOTP}`, {
                method: 'POST',
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 200) {
                    props.onSubmit();
                } else {
                    setIsLoading(false);
                    setAttempts(attempts + 1);
                    setShowPopup(true)
                }
            } else {
                setIsLoading(false);
                setShowInvalidPopup(true);
            }
        } catch (error) {
            setIsLoading(false);
            setModalContent({
                heading: 'Error',
                message: `An unexpected error occurred.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            })
            setShowModal(true);         }
    }

    async function resendOTP() {
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/resendOTP?email=${encodeURIComponent(props.email)}`, {
                method: 'POST',
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 200) {
                    // props.onSubmit();
                } else {
                    setModalContent({
                        heading: 'Error',
                        message: `Failed to generate OTP. Please try again.`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    })
                    setShowModal(true);                
                }
            } else {
                setModalContent({
                    heading: 'Error',
                    message: `Failed to generate OTP. Please try again.`,
                    confirmButtonText: 'OK',
                    onConfirm: handleModalConfirm,
                })
                setShowModal(true);            
            }
        } catch (error) {
            setModalContent({
                heading: 'Error',
                message: `Failed to generate OTP. Please try again.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            })
            setShowModal(true);         }
    }

    const sendOTP = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/generateOTP?email=${encodeURIComponent(props.email)}`, {
                method: 'POST',
            });

            if (response.ok) {
                const data = await response.json();
                if (data.status === 200) {
                    setIsLoading(false)
                    setShowPopup(true)
                } else {
                    setIsLoading(false)
                    setModalContent({
                        heading: 'Error',
                        message: `Failed to generate OTP. Please try again.`,
                        confirmButtonText: 'OK',
                        onConfirm: handleModalConfirm,
                    })
                    setShowModal(true);                 }
            } else {
                setIsLoading(false)
                setModalContent({
                    heading: 'Error',
                    message: `Failed to generate OTP. Please try again.`,
                    confirmButtonText: 'OK',
                    onConfirm: handleModalConfirm,
                })
                setShowModal(true);             
            }
        } catch (error) {
            setIsLoading(false)
            setModalContent({
                heading: 'Error',
                message: `Failed to generate OTP. Please try again.`,
                confirmButtonText: 'OK',
                onConfirm: handleModalConfirm,
            })
            setShowModal(true);         }
    };

    function handleResendOTP() {
        resendOTP();
        setShowPopup(false)
    }

    const handleVerifyClick = () => {
        const enteredOTP = otp.join(''); // Join the OTP array into a single string
        handleVerifyOTP(enteredOTP); // Verify the OTP
    };

    const handleEnterPress = (event) => {
        if (event.key === 'Enter') {
            handleVerifyClick();
        }
    };

    useEffect(() => {
        // Check if the first input ref is available and focus it
        if (inputRefs[0].current) {
            inputRefs[0].current.focus();
        }
    }, []);

    return (
        <div className='flex flex-col items-center'>
            {/* {isLoading && <LoadingOverlay />} */}
            <p className='text-base tracking-[0.16px] text-text-grey mb-[1.625rem]'>We sent a code to {props.email}</p>
            <div className='flex justify-center space-x-4 mb-[1.875rem]'>
                {otp.map((data, index) => (
                    <input
                        className='w-[2.75rem] h-[2.75rem] border-[1.3px] border-border-grey rounded-xl text-center shadow-md'
                        type="text"
                        name={`otp-${index}`}
                        key={index}
                        value={data}
                        maxLength="1"
                        ref={inputRefs[index]}
                        onChange={(e) => handleChange(e.target, index)}
                        onFocus={(e) => e.target.select()}
                        autoComplete="off"
                        onKeyDown={handleEnterPress}
                    />
                ))}
            </div>
            <Button label={isLoading?"VERIFYING...":"VERIFY"} className="cursor-pointer text-[0.735rem] h-[2.25rem]" variant='bright-blue' onClick={handleVerifyClick} width="21.438rem"/>
            <p className="text-[1rem] text-charcoal tracking-medium mt-4">Didn't receive an email? <span
                className="text-blue cursor-pointer" onClick={sendOTP} tabIndex={0}>Resend</span></p>
            {showPopup && (
                <Modal
                    isOpen={showPopup}
                    heading="Resent the code"
                    message={`Check ${props.email} for the new code.`}
                    confirmButtonText="Resend"
                    cancelButtonText="Got it"
                    onClose={handleClosePopup}
                    onConfirm={handleResendOTP}
                    showCancelButton={true}
                />
            )}
            {showInvalidPopup && (
                <Modal
                    isOpen={showInvalidPopup}
                    heading="Invalid OTP"
                    message={`The OTP entered is invalid. Please try again.`}
                    confirmButtonText="Close"
                    cancelButtonText="Close"
                    onClose={handleResendOTP}
                    onConfirm={handleInvalidPopup}
                />
            )}
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
            <img src={hello}
                 alt="man saying hello"
                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    );
};

export default OtpComponent;
