// reducers.js

import { UPDATE_TAX_VALUES } from '../actions/taxDropdownActions';

const initialState = {
  taxValue: '',
  selectedOption: null,
};

const taxReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_TAX_VALUES:
      return {
        ...state,
        taxValue: action.payload.value,
        selectedOption: action.payload.option,
      };
    default:
      return state;
  }
};

export default taxReducer;
