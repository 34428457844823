import React, {useEffect, useRef, useState} from "react";
import {Button} from "../../../components/button";
import McqOption from "./McqOption";
import Badge from "../../../components/Badge";
import logo from "../../../../assets/logo_ovii_2.svg"


export default function ManagerMCQ() {
    const question = JSON.parse(localStorage.getItem("selectedQuestion"));
    const options = question.questionResponse.questionDataAsJson.options;
    const correctAnswerId =
        question.questionResponse.questionDataAsJson.correctAnswerId;
    const selectedOptionId = question.scoreResponse.answer.selectedOptionId;
    const score = question.scoreResponse.score;
    const chatGPTScore = question.scoreResponse.chatGPTScore;
    const answerExplanation =
        question.questionResponse.questionDataAsJson.answerExplanation;
    const [showSlider, setShowSlider] = useState(false);
    const popupRef = useRef(null);
    const [isQuestionExpanded, setIsQuestionExpanded] = useState(false);
    const [isCorrectAnswerExpanded, setIsCorrectAnswerExpanded] = useState(false);
    const [isScoreExpanded, setIsScoreExpanded] = useState(false);
    const [istimeTakenExpanded, setIsTimeTakenExpanded] = useState(false);
    const [isChatGPTScoreExpanded, setIsChatGPTScoreExpanded] = useState(false);
    const [isAnswerExplanationExpanded, setIsAnswerExplanationExpanded] = useState(false);
    const [isOptionsExpanded, setIsOptionsExpanded] = useState(false);
    const [isAttributesExpanded, setIsAttributesExpanded] = useState(false);

    console.log("question:", question)
    console.log("score", question.scoreResponse.score)

    useEffect(() => {
        function handleClickOutside(event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                setShowSlider(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [popupRef]);

    const handleOpen = () => {
        // Check if popupRef.current is available before setting showSlider to true
        setShowSlider(true);

    };

    const handleClose = () => {
        setShowSlider(false);
        // setCorrectOptionExpanded(false)
        // setScoreExpanded(false)
        // setExplanationExpanded(false)
        // setTimeExpanded(false)
        // setGPTScoreExpectedExpanded(false)
    };

    const Correctness = ({score}) => {
        return (
            <div>
                <button
                    className={`h-8 px-5 text-sm font-semibold mt-12 ${
                        !!score ? "text-green-700 bg-green-100" : "text-red-700 bg-red-100"
                    }  rounded-md w-fit`}
                >
                    {!!score ? "Right Answer" : "Wrong Answer"}
                </button>
                {score ? (
                    <div className="mt-3 text-sm text-blue">The candidate has selected the correct answer, please click
                        on view
                        details to
                        view question details</div>
                ) : (
                    <div className="mt-3 text-sm text-blue">The candidate has selected the wrong answer, please click on
                        view details to view question details</div>
                )}
            </div>
        )
    }

    const {questionResponse, scoreResponse} = question;
    const style1 = "width: 50%";

    return (
        <div className="">
            <div className="flex justify-center w-full items-center py-8">
                <div className="w-[85%] flex justify-between items-center" style={{marginBottom: 0}}>
                    <div className="text-2xl font-bold" style={{fontFamily: "ITC Clearface"}}>
                        {/*<h1>Answer details</h1>*/}
                    </div>
                    <div className="flex items-center justify-end" style={{marginBottom: 0}}>
                        <img src={logo} alt="logo" className="w-12"/>
                    </div>
                </div>
            </div>
            <div
                className="">
                <div
                    className="mx-[5%] mt-[4%] max-w-[90vw] min-w-[90vw] px-10 max-h-[80vh] py-8 rounded-lg border-[1.5px] shadow flex flex-row">
                    <div className="w-[50%] pr-[1.5rem]">
                        <h2 className="font-serif text-[1.3rem]">Question</h2>
                        <p dangerouslySetInnerHTML={{__html: questionResponse.questionDataAsJson.text}}/>
                        <Correctness score={score}/>
                    </div>
                    <div className="w-[50%] space-y-[1rem]">
                        {options.map((option) => (
                            <McqOption
                                key={option.id}
                                label={option.text}
                                disabled={true}
                                isSelected={selectedOptionId === option.id}
                                isCorrect={correctAnswerId === option.id}
                                optionId={option.id}
                                className={
                                    option.id === correctAnswerId
                                        ? "text-green-700 bg-green-100 !important"
                                        : selectedOptionId === option.id
                                            ? "text-black bg-blue !important"
                                            : ""
                                }
                                style={style1}
                            />
                        ))}
                        <div
                            className="pt-[3.563rem] flex xl:justify-end 2xl:xl:justify-end md:justify-start lg:justify-start">
                            <Button
                                label="View Details"
                                width="13rem"
                                onClick={handleOpen}
                                variant="bright-blue"
                            />
                        </div>
                    </div>
                </div>
            </div>
            {showSlider && (
                <div className="fixed inset-0 z-50 bg-opacity-75 shadow-lg">
                    <div ref={popupRef}
                         className="absolute top-0 right-0 h-full w-[50%] bg-white transition-all duration-700 overflow-y-auto max-w-[50%] overflow-x-auto shadow-lg">
                        <div className="">
                            {/*<h2 className="text-2xl font-serif border-gray-300 border-0 border-b-2">Answer details</h2>*/}
                            <div>
                                <div
                                    className="flex items-center justify-between w-full p-0 m-0 border-b-1 border-textBoxBorders shadow px-7 py-4"
                                    style={{'marginBottom': 0}}
                                >
                                    <h2 className="text-2xl font-serif">Answer
                                        details</h2>
                                    <button
                                        // onClick={onClose}
                                        className="fixed top-4 right-4 w-6 h-6 p-1 m-0 text-xs text-white bg-blue rounded-full"
                                        onClick={handleClose}
                                    >
                                        X{/* TODO: Use an icon */}
                                    </button>
                                </div>
                            </div>
                            {/*<hr/>*/}
                            <div className="px-6 mt-6 flex-col">
                                <div className='flex justify-start items-center gap-2 mt-8'>
                                    {questionResponse.questionDataAsJson.tags.split(',').map((badge) => (
                                        <Badge key={badge.trim()} label={badge.trim()} variant="primary"/>
                                    ))}
                                </div>
                                <h3 className="font-serif text-[1rem] text-m font-bold tracking-[0.1px] mt-8">Question</h3>
                                <div className="mt-[1rem] mb-6">
                                    <p className="font-sans text-m"
                                       dangerouslySetInnerHTML={{
                                           __html: questionResponse.questionDataAsJson.text,
                                       }}
                                    />
                                </div>
                                {/*<div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-8'>*/}
                                {/*    <div className='flex justify-between text-[#16193a] cursor-pointer'*/}
                                {/*         style={{'fontFamily': 'helvetica'}}*/}
                                {/*         onClick={() => {*/}
                                {/*             setIsQuestionExpanded(!isQuestionExpanded)*/}
                                {/*             setIsScoreExpanded(false)*/}
                                {/*             setIsCorrectAnswerExpanded(false)*/}
                                {/*             setIsTimeTakenExpanded(false)*/}
                                {/*             setIsChatGPTScoreExpanded(false)*/}
                                {/*             setIsAnswerExplanationExpanded(false)*/}
                                {/*             setIsOptionsExpanded(false)*/}
                                {/*         }}>*/}
                                {/*        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Question</h3>*/}
                                {/*        <p className='text-[#F462BA]'>+</p>*/}
                                {/*    </div>*/}
                                {/*    {isQuestionExpanded && (*/}
                                {/*        <p*/}
                                {/*            dangerouslySetInnerHTML={{*/}
                                {/*                __html: questionResponse.questionDataAsJson.text,*/}
                                {/*            }}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>
                                    <div className='flex justify-between text-[#16193a] cursor-pointer'
                                         style={{'fontFamily': 'helvetica'}}
                                         onClick={() => {
                                             setIsQuestionExpanded(false)
                                             setIsScoreExpanded(false)
                                             setIsCorrectAnswerExpanded(false)
                                             setIsTimeTakenExpanded(false)
                                             setIsChatGPTScoreExpanded(false)
                                             setIsAnswerExplanationExpanded(false)
                                             setIsOptionsExpanded(!isOptionsExpanded)
                                         }}>
                                        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Options</h3>
                                        <p className='text-[#000] font-bold'>+</p>
                                    </div>
                                    {isOptionsExpanded && (
                                        <div className="mt-[1.5rem] ">
                                            {questionResponse.questionDataAsJson.options.map(option => (
                                                <div key={option.id} className='flex flex-row'>
                                                                <span
                                                                    className='font-sans text-s font-bold ml-4 mr-[10px]'>{option.id}.</span>
                                                    <p className="font-sans text-s"
                                                       dangerouslySetInnerHTML={{__html: option.text}}/>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                                {/*<div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>*/}
                                {/*    <div className='flex justify-between text-[#16193a] cursor-pointer'*/}
                                {/*         style={{'fontFamily': 'helvetica'}}*/}
                                {/*         onClick={() => {*/}
                                {/*             setIsQuestionExpanded(false)*/}
                                {/*             setIsScoreExpanded(!isScoreExpanded)*/}
                                {/*             setIsCorrectAnswerExpanded(false)*/}
                                {/*             setIsTimeTakenExpanded(false)*/}
                                {/*             setIsChatGPTScoreExpanded(false)*/}
                                {/*             setIsAnswerExplanationExpanded(false)*/}
                                {/*         }}>*/}
                                {/*        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Score</h3>*/}
                                {/*        <p className='text-[#F462BA]'>+</p>*/}
                                {/*    </div>*/}
                                {/*    {isScoreExpanded && (*/}
                                {/*        <p className="font-sans text-m text-black">{score}</p>*/}
                                {/*    )}*/}
                                {/*</div>*/}
                                <div className="border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4">
                                    <div className='flex justify-between text-[#16193a] cursor-pointer'
                                         style={{'fontFamily': 'helvetica'}}
                                         onClick={() => {
                                             setIsQuestionExpanded(false)
                                             setIsScoreExpanded(false)
                                             setIsCorrectAnswerExpanded(!isCorrectAnswerExpanded)
                                             setIsTimeTakenExpanded(false)
                                             setIsChatGPTScoreExpanded(false)
                                             setIsAnswerExplanationExpanded(false)
                                             setIsOptionsExpanded(false)
                                         }}>
                                        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Correct
                                            Answer</h3>
                                        <p className='text-[#000] font-bold'>+</p>
                                    </div>
                                    {isCorrectAnswerExpanded && (
                                        <p
                                            className="font-sans text-m"
                                            dangerouslySetInnerHTML={{
                                                __html: options[correctAnswerId - 1]?.text
                                            }}
                                        />
                                    )}
                                </div>

                                <div className="border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4">
                                    <div className='flex justify-between text-[#16193a] cursor-pointer'
                                         style={{'fontFamily': 'helvetica'}}
                                         onClick={() => {
                                             setIsQuestionExpanded(false)
                                             setIsScoreExpanded(false)
                                             setIsCorrectAnswerExpanded(false)
                                             setIsTimeTakenExpanded(false)
                                             setIsChatGPTScoreExpanded(false)
                                             setIsAnswerExplanationExpanded(false)
                                             setIsOptionsExpanded(false)
                                             setIsAnswerExplanationExpanded(!isAnswerExplanationExpanded)
                                         }}>
                                        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Answer
                                            explanation</h3>
                                        <p className='text-[#000] font-bold'>+</p>
                                    </div>
                                    {isAnswerExplanationExpanded && (
                                        <p
                                            className="font-sans text-m"
                                            dangerouslySetInnerHTML={{
                                                __html: questionResponse.questionDataAsJson.answerExplanation
                                            }}
                                        />
                                    )}
                                </div>
                                {/*<div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>*/}
                                {/*    <div className='flex justify-between text-[#16193a] cursor-pointer'*/}
                                {/*         style={{'fontFamily': 'helvetica'}}*/}
                                {/*         onClick={() => {*/}
                                {/*             setIsQuestionExpanded(false)*/}
                                {/*             setIsScoreExpanded(false)*/}
                                {/*             setIsCorrectAnswerExpanded(false)*/}
                                {/*             setIsTimeTakenExpanded(!istimeTakenExpanded)*/}
                                {/*             setIsAnswerExplanationExpanded(false)*/}
                                {/*         }}>*/}
                                {/*        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Time*/}
                                {/*            Taken</h3>*/}
                                {/*        <p className='text-[#F462BA]'>+</p>*/}
                                {/*    </div>*/}
                                {/*    {istimeTakenExpanded && (*/}
                                {/*        <p className="font-sans text-m">*/}
                                {/*            {scoreResponse.answer.timeTaken} mins*/}
                                {/*        </p>*/}
                                {/*    )}*/}
                                {/*</div>*/}

                                {chatGPTScore && (
                                    <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>
                                        <div className='flex justify-between text-[#16193a] cursor-pointer'
                                             style={{'fontFamily': 'helvetica'}}
                                             onClick={() => {
                                                 setIsQuestionExpanded(false)
                                                 setIsScoreExpanded(false)
                                                 setIsCorrectAnswerExpanded(false)
                                                 setIsTimeTakenExpanded(false)
                                                 setIsChatGPTScoreExpanded(!isChatGPTScoreExpanded)
                                                 setIsAnswerExplanationExpanded(false)
                                                 setIsOptionsExpanded(false)
                                             }}>
                                            <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>ChatGPT
                                                Score</h3>
                                            <p className='text-[#F462BA]'>+</p>
                                        </div>
                                        {isChatGPTScoreExpanded && (
                                            <p className="font-sans text-m">{chatGPTScore}</p>
                                        )}
                                    </div>
                                )}

                                <div className='border-[1px] border-[#e5e5e5] px-4 py-2 rounded-md mt-4'>
                                    <div className='flex justify-between text-[#16193a] cursor-pointer'
                                         style={{'fontFamily': 'helvetica'}}
                                         onClick={() => {
                                             setIsQuestionExpanded(false)
                                             setIsScoreExpanded(false)
                                             setIsCorrectAnswerExpanded(false)
                                             setIsTimeTakenExpanded(false)
                                             setIsChatGPTScoreExpanded(false)
                                             setIsAnswerExplanationExpanded(false)
                                             setIsAttributesExpanded(!isAttributesExpanded)
                                             setIsOptionsExpanded(false)
                                         }}>
                                        <h3 className='font-sans text-[1rem] text-m font-bold tracking-[0.1px]'>Attributes
                                            tested</h3>
                                        <p className='text-[#000] font-bold'>+</p>
                                    </div>
                                    {isAttributesExpanded && (
                                        <p className="font-sans text-m"
                                           dangerouslySetInnerHTML={{__html: questionResponse.questionDataAsJson.attributesTested}}/>
                                    )}
                                </div>

                                {/*<div className="mt-4 mb-[1rem]">*/}
                                {/*    <Button*/}
                                {/*        label="Close"*/}
                                {/*        width="13rem"*/}
                                {/*        className=""*/}
                                {/*        onClick={handleClose}*/}
                                {/*        variant="bright-blue"*/}
                                {/*    />*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/*<div className="z-0 fixed bottom-0 left-0 w-full text-center">*/}
            {/*    <p>*/}
            {/*        Powered by*/}
            {/*        <img className="w-10 h-10 inline pl-2 mb-1" src={logo}></img>*/}
            {/*    </p>*/}
            {/*</div>*/}
        </div>
    );
}