import React, {useEffect, useRef, useState} from "react";
import logo from "../../assets/logo_ovii_2.svg";
import bellPlus from "../../assets/bellPlus.svg";
import ProfileIcon from "../../assets/profileIcon.svg";
import chevronDown from "../../assets/chevronDown.svg";
import chevronUp from "../../assets/chevronUp.svg"; // New import for the upward arrow
import {useNavigate} from "react-router-dom";

function TopBar({heading}) {
    // const accessToken = localStorage.getItem("accessToken");
    const navigate = useNavigate();

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const dropdownRef = useRef(null);

    const toggleDropdown = () => {
        setDropdownVisible((prevVisible) => !prevVisible);
    };

    const handleProfileClick = () => {
        setDropdownVisible(false);
        navigate('/settings/profile');
    };

    async function handleLogoutClick() {
        navigate('/logout');
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdownVisible(false);
            }
        };

        window.addEventListener("click", handleClickOutside);
        return () => {
            window.removeEventListener("click", handleClickOutside);
        };
    }, []);

    return (
        <div className="">
            <div className="flex items-start justify-between">
                <p className="px-8 text-2xl text-bold text-black font-serif itc-fontFamily">{heading}</p>
                <div className="flex items-center gap-5">
                    <a href="/home">
                        <img src={logo} className="w-12 h-6" alt="ovii"/>
                    </a>
                    <img src={bellPlus} alt="bellplus"/>
                    <div className="flex gap-1" ref={dropdownRef}>
                        <img src={ProfileIcon} alt="user_name"/>
                        <img src={dropdownVisible ? chevronUp : chevronDown} alt="down_icon" onClick={toggleDropdown}/>
                        {dropdownVisible && (
                            <div
                                className="absolute right-0 mt-11 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10">
                                <div className="py-1">
                                    <div className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                         onClick={handleProfileClick}>
                                        Profile
                                    </div>
                                    <div className="px-4 py-2 cursor-pointer hover:bg-gray-100"
                                         onClick={handleLogoutClick}>
                                        Logout
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
