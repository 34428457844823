import CandidateMCQ from "./candidateComponents/CandidateMCQ";
import CandidateSubjective from "./candidateComponents/CandidateSubjective";
import CandidateCode from "./candidateComponents/CandidateCode";
import React from "react";

const QuestionComponent = ({question, onNextQuestion}) => {
    // This function determines which type of question component to render
    const renderQuestionTypeComponent = (question) => {
        switch (question.questionType.name) {
            case "Multiple Choice":
                return <CandidateMCQ question={question} onNextQuestion={onNextQuestion}/>;
            case "Subjective":
                return <CandidateSubjective question={question} onNextQuestion={onNextQuestion}/>;
            case "Code":
                return <CandidateCode question={question} onNextQuestion={onNextQuestion}/>;
            default:
                return null;
        }
    };

    return (
        <div className="question-component">
            {renderQuestionTypeComponent(question)}
        </div>
    );
};

export default QuestionComponent;