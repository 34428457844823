import React, {useEffect, useRef, useState} from 'react';
import {Button} from './button';
import print from "../../assets/print.svg"
import userBlue from "../../assets/userBlue.svg"
import {useReactToPrint} from 'react-to-print';
import download from "../../assets/download.svg";
import Papa from "papaparse";
import Modal from './popup';

const Slider = ({onRefresh, candidate, onClose, onShortlist, constraints}) => {
    const componentRef = useRef();
    const [selectedOption, setSelectedOption] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const selectedEval = candidate?.eval
    const accessToken = localStorage.getItem("accessToken")

    const handleOptionChange = (option) => {
        setSelectedOption(option);

        if (option === 'shortlist') {
            onShortlist(candidate);
        }
    };


    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const handleRadioChange = (event) => {
        if (event === "shortlist") {
            console.log("first")
            if (constraints) {
                handleEvaluationChange(5);
            } else {
                setModalContent({
                    heading: 'Error',
                    message: 'Error updating candidate evaluation.',
                    confirmButtonText: 'CLOSE',
                });
                setShowModal(true);
            }

        } else if (event === "hold") {
            handleEvaluationChange(2);
        } else if (event === "reject") {
            handleEvaluationChange(4);
        }
    };

    // const handleCheckboxChange2 = async (checkboxId) => {
    //     setSelectedCheckbox(checkboxId === selectedCheckbox ? null : checkboxId);
    //     const evalIDForCheckbox = getEvalIDForCheckbox(checkboxId);
    //     setEvalID(evalIDForCheckbox);
    //     if (checkboxId==1){
    //         console.log("first log")
    //         if(constraints){
    //             console.log("second log")
    //             handleEvaluationChange(evalIDForCheckbox);
    //         }
    //         else{
    //             console.log("third log")
    //         }
    //         return
    //     }
    //     else{
    //         console.log("fourth log")
    //         handleEvaluationChange(evalIDForCheckbox);
    //     }
    //     };

    const handleEvaluationChange = async (evalID) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/updateCandidateEvaluation?candidateId=${candidate.id}&evaluationId=${evalID}`,
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            console.log("data:", data);

            if (data && data.status === 200) {
                console.log("Candidate evaluation updated successfully.");
                if (data.data === 2 || data.data === 4) {
                    setModalContent({
                        heading: 'Success',
                        message: 'Candidate evaluation updated.',
                        confirmButtonText: 'OK',
                    });
                    setShowModal(true);
                }
            } else {
                setModalContent({
                    heading: 'Error',
                    message: 'Error updating candidate evaluation.',
                    confirmButtonText: 'CLOSE',
                });
                setShowModal(true);
            }
        } catch (error) {
            setModalContent({
                heading: 'Error',
                message: 'Error updating candidate evaluation.',
                confirmButtonText: 'CLOSE',
            });
            setShowModal(true);
        }
    };


    // END OF NEW SHORTLISTING EVAL MAPPING AND UPDATION

    if (!candidate) return null;

    const {
        summary = "N/A",
        assessment = "N/A",
        role_specific_experience = "N/A",
        recommendations = "N/A",
        areas_for_further_probing = "N/A",
        educational_alignment = "N/A",
        total_score = "N/A",
        match_score = "0%",
        keyword_match_score = "0%",
        average_tenure = "N/A",
        notice_period = "N/A",
        total_experience = "N/A",
        matching_skills = [],
        details = {}
    } = candidate?.matchScore || {};


    const renderStars = () => {
        const rating = parseFloat(String(match_score).replace('%', ''));
        const filledStars = Math.round((rating / 100) * 5);

        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < filledStars) {
                stars.push(<span key={i} className="text-[#004eec]">&#9733;</span>);
            } else {
                stars.push(<span key={i} className="text-gray-300">&#9733;</span>);
            }
        }
        return stars;
    };

    const UserDetails = () => {
        return (
            <div className="flex items-center mb-[0.5rem] gap-x-4">
                <div className="flex flex-col">
                    <span
                        className="text-xl font-bold text-slate-800 font-serif leading-[1.26rem]">{candidate.candidateName?.replace(/['"]+/g, '') || "No Name"}</span>
                </div>
                <div>
                    {renderStars()}
                </div>
            </div>
        );
    };

    const renderInfoBox = (label, value) => {
        return (
            <div className="bg-[#F9F8F6] p-4 rounded-lg w-1/3">
                <h4 className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]">{label}</h4>
                <p className="py-0 mr-1 text-[0.95rem] font-bold text-[#20456E] rounded-[3px]">{value || "N/A"}</p>
            </div>
        );
    };

    // let skillsArray = [];

    const skillsArray = matching_skills?.length > 0
        ? matching_skills[0]?.split(', ') || []
        : ['No matching skills'];


    const handleModalConfirm = () => {
        setShowModal(false);
        onRefresh();
    };

    return (
        <div
            className="fixed top-0 right-0 h-full w-80 bg-white border-l border-gray-300 shadow-lg transition-all duration-700 w-[60%] h-screen pt- overflow-y-scroll bg-white shadow-2xl rounded-l-2xl">
            <div ref={componentRef}>
                <div className='border-b shadow px-[2rem] pt-[1.5rem] pb-[1rem] flex justify-between'>
                    <div>
                        <UserDetails/>
                        <p>Email: {candidate.email?.replace(/['"]+/g, '') || "N/A"}</p>
                        <p>Phone No: {candidate.phoneNumber?.replace(/['"]+/g, '') || "N/A"}</p>
                    </div>
                    <div className='flex gap-x-2'>
                        <Button width="3rem" variant="no-outline" onClick={handlePrint} className="mr-2">
                            <img src={print} alt="print"/>
                        </Button>
                        <button onClick={onClose}
                                className="mt-1 w-6 h-6 p-1 m-0 text-xs text-white bg-blue rounded-full">
                            X
                        </button>
                    </div>
                </div>
                <div className='px-[2rem] pb-[1rem]'>
                    <div className="flex justify-between gap-x-2 my-[2rem]">
                        {renderInfoBox("Match Score", match_score)}
                        {renderInfoBox("Keyword Match Score", keyword_match_score)}
                        {renderInfoBox("Average Tenure", average_tenure)}
                    </div>
                    <h3 className="w-[auto] mb-2 font-bold font-serif text-charcoal text-md">
                        Matching Keywords:
                    </h3>
                    <div className="flex flex-wrap w-full">
                        {skillsArray.map((skill, index) => (
                            <span
                                key={index}
                                className={`inline-block px-2 py-1 mr-1 text-xs font-bold bg-[#EDF6FF] text-[#004EEC] rounded-[3px] gap-3 mb-[0.55rem]`}
                            >
                                {skill}
                            </span>
                        ))}
                    </div>
                    <div>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Summary
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {summary || "N/A"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Assessment
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {assessment || "N/A"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Role specific experience:
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {role_specific_experience || "N/A"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Recommendations:
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {recommendations || "N/A"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Areas for further probing:
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {areas_for_further_probing || "N/A"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Educational alignment
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {educational_alignment || "N/A"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Experience details:
                        </h3>
                        <div>
                            {Object.keys(details || {}).map((key) => (
                                <div key={key} className='mb-[1rem]'>
                                    <h4 className="font-semibold text-black mt-[1rem]">{key}:</h4>
                                    <p className="w-full text-s text-black mb-[0.2rem]" style={{
                                        fontFamily: "helvetica",
                                        fontWeight: '200 !important'
                                    }}>{details[key]?.candidate}</p>
                                    <p className="w-full text-s text-blue"
                                       style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                                        <strong>Assessment:</strong> {details[key]?.assessment}</p>
                                </div>
                            ))}
                        </div>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Notice period:
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {notice_period || "Not mentioned"}
                        </p>
                        <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                            Total experience:
                        </h3>
                        <p className="w-full text-s text-black"
                           style={{fontFamily: "helvetica", fontWeight: '200 !important'}}>
                            {total_experience || "N/A"}
                        </p>
                    </div>
                </div>
            </div>
            <div className='px-[2rem]'>
                <h3 className="mt-4 mb-3 font-bold text-black text-md">
                    Evaluation
                </h3>
                <div className=''>
                    <div className="flex w-[100%] gap-x-[1.5rem] mb-[1rem] justify-between">
                        <label
                            className="flex items-center border border-gray-400 w-[20rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                            <input
                                type="radio"
                                value="shortlist"
                                checked={selectedEval === 5}
                                onClick={() => {
                                    handleRadioChange('shortlist')
                                    handleOptionChange('shortlist')
                                }}
                                className=""
                            />
                            <span className="ml-2">Shortlist</span>
                        </label>
                        <label
                            className="flex items-center border border-gray-400 w-[20rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                            <input
                                type="radio"
                                value="hold"
                                checked={selectedEval === 2}
                                onClick={() => {
                                    handleRadioChange('hold')
                                    handleOptionChange('hold')
                                }}
                                className=""
                            />
                            <span className="ml-2">Hold</span>
                        </label>
                        <label
                            className="flex items-center border border-gray-400 w-[20rem] rounded-md p-2 cursor-pointer hover:bg-gray-100">
                            <input
                                type="radio"
                                value="reject"
                                checked={selectedEval === 4}
                                onClick={() => {
                                    handleRadioChange('reject')
                                    handleOptionChange('reject')
                                }}
                                className=""
                            />
                            <span className="ml-2">Reject</span>
                        </label>
                    </div>
                </div>
            </div>
            {/* <div className='px-[2rem]'>
                <h3 className="mt-8 mb-3 font-bold text-black text-md">
                    Evaluation
                </h3>
                <div className='flex gap-[1.375rem] w-[100%] items-center justify-center'>
                    <div className="w-[100%] flex gap-2">
                        <div
                            className={`flex justify-between items-center w-[50%] h-11 border ${selectedCheckboxes.includes(1) ? 'border-blue' : 'border-gray-400'} rounded font-inter mt-2 text-sm font-normal leading-5 tracking-wide text-center text-gray-600 pl-[0.6rem]`}>
                            <Checkbox
                                content={
                                    <span className="skill-assessment-text pl-[0.6rem]">
                                    Shortlist
                                </span>
                                }
                                className="create-pos-check"
                                width="16"
                                height="16"
                                checked={selectedCheckbox === 1}
                                onChange={() => {
                                    handleCheckboxChange2(1)
                                    handleOptionChange('shortlist')
                                }}
                            />
                        </div>
                        <div
                            className={`flex justify-between items-center w-[50%] h-11 border ${selectedCheckboxes.includes(1) ? 'border-blue' : 'border-gray-400'} rounded font-inter mt-2 text-sm font-normal leading-5 tracking-wide text-center text-gray-600 pl-[1rem]`}>
                            <Checkbox
                                content={
                                    <span className="skill-assessment-text pl-[1rem]">
                                    Hold
                                </span>
                                }
                                className="create-pos-check"
                                width="16"
                                height="16"
                                checked={selectedCheckbox === 2}
                                onChange={() => {
                                    handleCheckboxChange2(2)
                                    handleOptionChange('hold')
                                }}
                            />
                        </div>
                        <div
                            className={`flex justify-between items-center w-[50%] h-11 border ${selectedCheckboxes.includes(1) ? 'border-blue' : 'border-gray-400'} rounded font-inter mt-2 text-sm font-normal leading-5 tracking-wide text-center text-gray-600 pl-[1rem]`}>
                            <Checkbox
                                content={
                                    <span className="skill-assessment-text pl-[1rem]">
                                    Reject
                                </span>
                                }
                                className="create-pos-check"
                                width="16"
                                height="16"
                                checked={selectedCheckbox === 3}
                                onChange={() => {
                                    handleCheckboxChange2(3)
                                    handleOptionChange('reject')
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div> */}
            <Modal
                isOpen={showModal}
                onClose={handleModalConfirm}
                onConfirm={handleModalConfirm}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText={modalContent.confirmButtonText}
            />
        </div>
    );
};


const ResumeMatchTable = ({
                              onRefresh,
                              candidates,
                              assessmentIdForSkillAssessment,
                              assessmentIdForVideoInterview,
                              skillAssessmentExists,
                              videoInterviewExists,
                              questionsExist
                          }) => {
    const [selectedCandidate, setSelectedCandidate] = useState(null);
    const [sortConfig, setSortConfig] = useState({key: null, direction: null});
    const accessToken = localStorage.getItem(("accessToken"))
    const [showPopup, setShowPopup] = useState(false);
    const [popupContent, setPopupContent] = useState({});
    const [inviteModal, setInviteModal] = useState(false);

    const [selectedCandidates, setSelectedCandidates] = useState([]);

    const handleCheckboxChange = (candidate) => {
        setSelectedCandidates(prev => {
            const isAlreadySelected = prev.find(c => c.email === candidate.email);

            if (isAlreadySelected) {
                return prev.filter(c => c.email !== candidate.email);
            } else {
                return [...prev, {
                    name: candidate.candidateName?.replace(/['"]+/g, ''),
                    email: candidate.email?.replace(/['"]+/g, '')
                }];
            }
        });
    };

    useEffect(() => {
        console.log("selected candidates:", selectedCandidates)
    })

    const sortedCandidates = [...candidates].sort((a, b) => {
        if (!sortConfig.key) return 0;
        const {key, direction} = sortConfig;

        const compare = (x, y) => {
            if (x < y) return -1;
            if (x > y) return 1;
            return 0;
        };

        if (key === 'name') {
            return direction === 'asc' ? compare(a.candidateName, b.candidateName) : compare(b.candidateName, a.candidateName);
        } else if (key === 'score') {
            return direction === 'asc' ? compare(a.matchScore.match_score, b.matchScore.match_score) : compare(b.matchScore.match_score, a.matchScore.match_score);
        }

        return 0;
    });

    const filteredCandidates = [];
    const emailSet = new Set();

    sortedCandidates.forEach(candidate => {
        if (!emailSet.has(candidate.email)) {
            emailSet.add(candidate.email);
            filteredCandidates.push(candidate);
        }
    });

    const handleSort = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({key, direction});
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return '-';
        return sortConfig.direction === 'asc' ? '↑' : '↓';
    };

    const handleRowClick = (candidate) => {
        setSelectedCandidate(candidate);
        console.log("selecetd acaididate:", selectedCandidate)
    };

    const handleCloseSlider = () => {
        setSelectedCandidate(null);
    };

    const exportToCSV = () => {

        const isValidEmail = (email) => {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        };

        const isValidScore = (score) => {
            return typeof score === 'string' && score.trim().endsWith('%');
        };

        const csvData = candidates
            .filter(candidate =>
                candidate.candidateName &&
                isValidEmail(candidate.email) &&
                isValidScore(candidate.matchScore?.match_score)
            )
            .map(candidate => ({
                "name": candidate.candidateName?.replace(/['"]+/g, ''),
                "email": candidate.email?.replace(/['"]+/g, ''),
                "matchScore": candidate.matchScore?.match_score
            }));

        if (csvData.length === 0) {
            alert('No valid data to export.');
            return;
        }

        const csv = Papa.unparse(csvData);
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
        const link = document.createElement("a");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "resumeMatch.csv");
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleShortlist = (candidate) => {
        if (skillAssessmentExists) {
            if (questionsExist.length > 0) {
                inviteToSkillAssessment(candidate, assessmentIdForSkillAssessment);
            } else {
                setPopupContent({
                    heading: 'Error',
                    message: 'Please add questions before inviting candidates to skill assessment.',
                    confirmButtonText: 'CLOSE',
                });
                console.log("Popup content set:", popupContent);
                setInviteModal(true);

                console.log("Show modal:", inviteModal);

            }
        } else if (videoInterviewExists) {
            inviteToVideoInterview(candidate, assessmentIdForVideoInterview);
            const candidateEmail = candidate.email?.replace(/['"]+/g, '')
            let shortlistedCandidates = JSON.parse(localStorage.getItem('shortlistedCandidates')) || [];

            if (!shortlistedCandidates.includes(candidateEmail)) {
                shortlistedCandidates.push(candidateEmail);
                localStorage.setItem('shortlistedCandidates', JSON.stringify(shortlistedCandidates));
            }
        } else {
            console.log('Neither skill assessment nor video interview exists.');
        }

    };

    const inviteToSkillAssessment = async (candidate, assessmentIdForSkillAssessment) => {

        const currentDate = new Date();
        const expiryDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)).toISOString();

        const body = {
            assessmentId: assessmentIdForSkillAssessment,
            email: candidate.email?.replace(/['"]+/g, ''),
            name: candidate.candidateName?.replace(/['"]+/g, ''),
            expiryDate: expiryDate
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/inviteCandidate`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${accessToken}`
                    },
                    body: JSON.stringify(body),
                });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            if (data.status === 200) {
                setPopupContent({
                    heading: 'Success',
                    message: 'Candidate has been invited to skill assessment.',
                    confirmButtonText: 'OK',
                });
                setShowPopup(true);
                return data;
            }
        } catch (error) {
            setPopupContent({
                heading: 'Error',
                message: 'Error while adding candidate to skill assessment.',
                confirmButtonText: 'CLOSE',
            });
            setShowPopup(true);
        }
    };

    const inviteToVideoInterview = async (candidate, assessmentIdForVideoInterview) => {
        const email = candidate.email?.replace(/['"]+/g, '');
        const name = candidate.candidateName?.replace(/['"]+/g, '');

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/addCandidateToVideoInterview?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&assessmentId=${encodeURIComponent(assessmentIdForVideoInterview)}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
            });

            const data = await response.json();
            if (response.ok) {
                setPopupContent({
                    heading: 'Success',
                    message: 'Candidate has been added to online interview.',
                    confirmButtonText: 'OK',
                });
                setShowPopup(true);
            } else {
                setPopupContent({
                    heading: 'Error',
                    message: 'Error while adding candidate to online interview.',
                    confirmButtonText: 'CLOSE',
                });
                setShowPopup(true);
            }
        } catch (error) {
            setPopupContent({
                heading: 'Error',
                message: 'Error while adding candidate to online interview.',
                confirmButtonText: 'CLOSE',
            });
            setShowPopup(true);
        }
    };

    const bulkInviteCandidates = async (e) => {
        e.preventDefault();

        if (selectedCandidates.length === 0) {
            console.log("No candidates selected.");
            return;
        }

        const currentDate = new Date();
        const expiryDate = new Date(currentDate.setFullYear(currentDate.getFullYear() + 1)).toISOString();

        const body = {
            assessmentId: assessmentIdForSkillAssessment,
            expiryDate: expiryDate,
            candidates: selectedCandidates,
            isProctoringEnabled: true,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/inviteCandidatesInBulk`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`
                },
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`Error: ${response.statusText}`);
            }

            const data = await response.json();
            setPopupContent({
                heading: 'Success',
                message: 'Candidates has been invited to skill assessment.',
                confirmButtonText: 'OK',
            });
            setShowPopup(true);
            return data;
        } catch (error) {
            setPopupContent({
                heading: 'Error',
                message: 'An error occured while sending invites.',
                confirmButtonText: 'OK',
            });
            setShowPopup(true);
        }
    };

    const evaluationStatusMap = {
        1: "Pending",
        2: "Hold",
        5: "Shortlist",
        4: "Reject"
    };

    const constraints = skillAssessmentExists && Array.isArray(questionsExist) && questionsExist.length > 0;

    const handleModalConfirm = () => {
        setShowPopup(false);
        setInviteModal(false);
        onRefresh();
    };

    return (
        <div>
            <div
                className="w-[7rem] border rounded-t-m flex justify-center items-center font-sans text-blue p-2 px-4 text-[0.75rem] mt-8">
                RESUMES
            </div>
            <div className='border border-solid rounded-b rounded-tr px-10 py-8 mt-[-1.05rem]'>
                <div className='rounded-md border border-solid' style={{
                    maxHeight: "24rem",
                    maxWidth: "100%",
                    overflowY: "auto",
                    scrollbarWidth: 'none',
                    msOverflowStyle: 'none',
                    '&::-webkit-scrollbar': {display: 'none'}
                }}>
                    <table className="w-[100%]">
                        <thead className="sticky top-0 bg-[#FAFAFA] h-10">
                        <tr>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "3%"}}></th>
                            <th className="text-charcoal text-[13px] text-left pl-1 font-regular cursor-pointer"
                                style={{width: "27%"}} onClick={() => handleSort('name')}>
                                Name {getSortIcon('name')}
                            </th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "33%"}}>
                                Email
                            </th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "19%"}} onClick={() => handleSort('score')}>
                                Match Score {getSortIcon('score')}
                            </th>
                            <th className="text-charcoal text-[13px] text-center font-regular cursor-pointer"
                                style={{width: "18%"}}>
                                Status
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredCandidates.map((candidate, index) => (
                            <tr key={index} className="border-b border-gg h-4 cursor-pointer text-center"
                                onClick={() => handleRowClick(candidate)}>
                                <td className="items-center justify-center pl-[0.3rem]" style={{width: "3%"}}>
                                    <input
                                        type="checkbox"
                                        checked={selectedCandidates.some(c => c.email === candidate.email)}
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevents opening the slider when checkbox is clicked
                                            handleCheckboxChange(candidate); // Handles candidate selection logic
                                        }}
                                    />
                                </td>
                                <td style={{width: "27%"}}>
                                    <div className="flex flex-col ml-1">
                                            <span className="inline-flex items-center rounded-table2">
                                                <img src={userBlue} alt="User icon"
                                                     className="w-1.68rem h-1.68rem p-3"/>
                                                <div className="flex flex-col">
                                                    <span className="font-sans font-semibold font-14px font-600">
                                                        {candidate.candidateName?.replace(/['"]+/g, '')}
                                                    </span>
                                                </div>
                                            </span>
                                    </div>
                                </td>
                                <td style={{width: "33%"}}>
                                        <span className="font-sans font-14px font-600">
                                            {candidate.email?.replace(/['"]+/g, '') || "N/A"}
                                        </span>
                                </td>
                                <td style={{width: "19%"}}>
                                        <span className="font-sans font-14px font-600">
                                            {candidate.matchScore?.match_score || "N/A"}
                                        </span>
                                </td>
                                <td className="" style={{width: "18%"}}>
                                    {/* <span className={`px-4 py-1 text-sm rounded-[3px] cursor-pointer font-inter font-bold ${
                                                       candidate.eval === 1||null ? "bg-[#EDF6FF] text-[#004eec]" :
                                                           candidate.eval === 2 ? "bg-[#FFF1F0] text-[#E7401B]" :
                                                               candidate.eval === 5 ? "bg-[#EFFAF2] text-[#3B8456]" :
                                                                   candidate.eval === 4 ? "bg-[#FEF1F6] text-[#C6274E]" :
                                                                       ""
                                                   }`}>
                                           {evaluationStatusMap[candidate.eval]}
                                        </span> */}
                                    <span
                                        className={`px-4 py-1 text-sm rounded-[3px] cursor-pointer font-inter font-bold ${
                                            candidate.eval === 1 || candidate.eval === null ? "bg-[#EDF6FF] text-[#004eec]" :
                                                candidate.eval === 2 ? "bg-[#FFF1F0] text-[#E7401B]" :
                                                    candidate.eval === 5 ? "bg-[#EFFAF2] text-[#3B8456]" :
                                                        candidate.eval === 4 ? "bg-[#FEF1F6] text-[#C6274E]" :
                                                            ""
                                        }`}>
                                          {evaluationStatusMap[candidate.eval] || "Pending"}
                                        </span>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <div className="mt-2 flex justify-between">
                    <a onClick={exportToCSV}
                       className="text-[#004EEC] h-[2.5rem] cursor-pointer inline-flex items-center"><img src={download}
                                                                                                          alt="Download Icon"
                                                                                                          className="mr-2 h-[1rem]"/> Download
                        to CSV</a>
                    {selectedCandidates.length > 0 && skillAssessmentExists && questionsExist.length > 0 ? (
                        <Button className="px-2" variant="bright-blue" label="Invite candidates to skill assessment"
                                onClick={bulkInviteCandidates}/>) : ("")}
                </div>
            </div>
            <Slider onRefresh={onRefresh} candidate={selectedCandidate} onClose={handleCloseSlider}
                    onShortlist={handleShortlist} constraints={constraints}/>
            <Modal
                isOpen={showPopup}
                onClose={handleModalConfirm}
                onConfirm={handleModalConfirm}
                heading={popupContent.heading}
                message={popupContent.message}
                confirmButtonText={popupContent.confirmButtonText}
            />
            {inviteModal &&
                <Modal
                    isOpen={inviteModal}
                    onClose={handleModalConfirm}
                    onConfirm={handleModalConfirm}
                    heading={popupContent.heading}
                    message={popupContent.message}
                    confirmButtonText={popupContent.confirmButtonText}
                />
            }
        </div>
    );
};

export default ResumeMatchTable;
