import React, { useState } from "react";
import SearchIcon from "../../assets/search.png";
import search from "../../assets/search.png";

const Table = ({ data, searchInTable = true }) => {
    const rows = data?.rows || [];
    const [searchTerm, setSearchTerm] = useState("");

    if (!data || !data.columns || !data.rows) {
        return null;
    }

    const filteredRows = rows.filter((row) =>
        row.cells.some(
            (cell) =>
                cell.text &&
                cell.text.toLowerCase().includes(searchTerm.toLowerCase())
        )
    );

    return (
        <>
            {searchInTable ? (
                <div className="flex justify-end my-3 z-0">
                    <input
                        className="mb-2 w-80 pl-9 pr-4 py-2 text-[14px] text-text-grey border-[0px] h-[34px] rounded-sm bg-[#F9F9F9] focus:outline-none focus:border-blue-500 "
                        style={{
                            backgroundImage: `url(${search})`,
                            backgroundSize: "1rem",
                            backgroundPositionX: "0.5rem",
                            backgroundPositionY: "center",
                            backgroundRepeat: "no-repeat"
                        }}
                        placeholder="Search user"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {/*<div className="absolute left-0 flex items-center justify-center pl-2 inset-y-4">*/}
                    {/*    <img*/}
                    {/*        src={SearchIcon}*/}
                    {/*        alt="Search Icon"*/}
                    {/*        className="w-4 h-4"*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            ) : null}
            <div>
                 <div>
                     <table className="w-full border rounded-[6px] border-solid border-bgrey mt-0">
                     <thead className="sticky top-0 bg-[#FAFAFA] h-10">
                         <tr className="">
                             <th className="text-charcoal text-[13px] text-justify pl-7 font-regular cursor-pointer" style={{ width: '42.5%' }}>Email</th>
                             <th className="text-charcoal text-[13px] pl-12 text-justify font-regular cursor-pointer" style={{ width: '13.1%' }}>Role</th>
                             <th className="text-charcoal text-[13px] pl-24 text-justify font-regular cursor-pointer" style={{ width: '20.3%' }}>Date</th>
                             <th className="text-charcoal text-[13px] pl-12 text-justify font-regular cursor-pointer" style={{ width: '14%' }}>Resend</th>
                             <th className="text-charcoal text-[13px] pr-12 text-justify font-regular cursor-pointer" style={{ width: '10%' }}>Actions</th>
                         </tr>
                     </thead>
                     </table>
                 </div>
                <div
                    className="max-h-[10rem] overflow-y-scroll"
                    style={{
                        scrollbarWidth: "none", /* Firefox */
                        msOverflowStyle: "none", /* IE and Edge */
                        "&::-webkit-scrollbar": { display: "none" }, /* Chrome, Safari, Opera */
                    }}
                >
                    <table className="w-full border rounded-[6px] border-solid border-bgrey mt-0">
                        <tbody>
                            {filteredRows.map((row, rowIndex) => (
                                <tr key={rowIndex} className="border-b border-gg h-5">
                                    {row.cells.map((cell, cellIndex) => (
                                        <td
                                            key={cellIndex}
                                            className="px-4 py-2 text-start text-[#020818] text-[14px] font-normal"
                                            style={{ width: cellIndex === 0 ? '42.5%' : 
                                                     cellIndex === 1 ? '13.1%' :
                                                     cellIndex === 2 ? '20.3%' :
                                                     cellIndex === 3 ? '14%' : '10%' }}
                                            tabIndex={0}
                                        >
                                            <div
                                                className={`${cell.className} flex items-center gap-3 !mb-0`}
                                                onClick={cell.onClick}
                                            >
                                                {cell.icon && (
                                                    <img
                                                        src={cell.icon}
                                                        alt="user icon"
                                                        className="w-[1.7rem] h-[1.7rem] "/>
                                                )}
                                                {cell.text}
                                            </div>
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default Table;
