import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from 'react';
import * as faceapi from '@vladmandic/face-api';

const ProctoringComponent = ({onRecordingComplete, onTrustScoreChange}, ref) => {
    const videoRef = useRef(null);
    const canvasRef = useRef(null);
    const [alert, setAlert] = useState(false);
    const [trustScore, setTrustScore] = useState(100);
    const mediaRecorderRef = useRef(null);
    const chunks = useRef([]);
    const detectionInProgress = useRef(false);
    const detectionIntervalRef = useRef(null);
    const loggingIntervalRef = useRef(null);
    const streamRef = useRef(null);
    const recordingStartedRef = useRef(false);
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const recordingCompleteRef = useRef(false);

    useImperativeHandle(ref, () => ({
        startRecording,
        stopRecording,
        getTrustScore: () => trustScore,
    }));

    useEffect(() => {
        const loadModels = async () => {
            try {
                await faceapi.nets.tinyFaceDetector.loadFromUri('/models/tiny_face_detector');
                await faceapi.nets.faceLandmark68Net.loadFromUri('/models/face_landmark_68');
                await faceapi.nets.faceRecognitionNet.loadFromUri('/models/face_recognition');
                startVideo();
            } catch (error) {
                console.error('Error loading face-api models:', error);
            }
        };

        const startVideo = async () => {
            try {
                const video = videoRef.current;
                const stream = await navigator.mediaDevices.getUserMedia({
                    video: {width: {ideal: 640}, height: {ideal: 420}, frameRate: {ideal: 5}},
                    audio: true,
                });

                if (stream instanceof MediaStream) {
                    video.srcObject = stream;
                    streamRef.current = stream;
                    setupAudioContext(stream);
                    console.log("MediaStream obtained:", stream);
                } else {
                    console.error("Stream is not of type MediaStream:", stream);
                    return;
                }

                await new Promise(resolve => (video.onloadedmetadata = () => resolve()));
                startRecording(stream);
            } catch (error) {
                console.error('Error starting video:', error);
            }
        };

        const setupAudioContext = (stream) => {
            const audioContext = new (window.AudioContext || window.webkitAudioContext)();
            const analyser = audioContext.createAnalyser();
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyser);
            analyser.fftSize = 2048;
            audioContextRef.current = audioContext;
            analyserRef.current = analyser;
        };

        loadModels();

        return () => {
            if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
                mediaRecorderRef.current.stop();
            }
            if (audioContextRef.current) {
                audioContextRef.current.close();
            }
        };
    }, []);

    const adjustTrustScore = amount => {
        setTrustScore(prevScore => {
            const newScore = Math.max(0, Math.min(100, prevScore + amount));
            console.log(`Adjusting trust score: ${prevScore} -> ${newScore}`);
            onTrustScoreChange(newScore);
            return newScore;
        });
    };

    useEffect(() => {
        const detectFaces = async () => {
            const video = videoRef.current;
            const canvas = canvasRef.current;
            const displaySize = {width: video.width, height: video.height};

            faceapi.matchDimensions(canvas, displaySize);

            const runDetection = async () => {
                console.log("run detection called")
                if (!detectionInProgress.current) {
                    detectionInProgress.current = true;
                    try {
                        const detections = await faceapi.detectAllFaces(video, new faceapi.TinyFaceDetectorOptions()).withFaceLandmarks().withFaceDescriptors();

                        console.log('Detections:', detections);

                        if (detections.length === 0) {
                            console.log("No faces detected");
                            setAlert(true);
                            adjustTrustScore(-0.2);
                        } else {
                            console.log("Faces detected:", detections);
                            setAlert(false);
                            adjustTrustScore(2);
                        }

                        canvas.getContext('2d').clearRect(0, 0, canvas.width, canvas.height);
                        faceapi.draw.drawDetections(canvas, detections);
                        faceapi.draw.drawFaceLandmarks(canvas, detections);
                    } catch (error) {
                        console.error('Error detecting faces:', error);
                    } finally {
                        detectionInProgress.current = false;
                    }
                }
            };

            detectionIntervalRef.current = setInterval(runDetection, 5000);
        };

        const detectVoice = () => {
            if (analyserRef.current) {
                const dataArray = new Uint8Array(analyserRef.current.fftSize);
                analyserRef.current.getByteFrequencyData(dataArray);
                const sum = dataArray.reduce((a, b) => a + b, 0);
                const average = sum / dataArray.length;
                console.log('Voice average:', average);
                if (average > 1) { // You can lower this threshold for more sensitivity
                    adjustTrustScore(-2); // Drastically reduce trust score on slight noise
                    console.log("Trust score drastically reduced due to noise");
                } else {
                    adjustTrustScore(2); // Slightly increase trust score when no noise is detected
                }
            }
        };

        detectFaces();
        const voiceDetectionInterval = setInterval(detectVoice, 5000);

        return () => {
            clearInterval(detectionIntervalRef.current);
            clearInterval(voiceDetectionInterval);
        };
    }, []);

    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                adjustTrustScore(-5); // Reduce trust score by 5 when the user switches tabs
            }
        };

        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    useEffect(() => {
        loggingIntervalRef.current = setInterval(() => {
            console.log(`Trust Score: ${trustScore}`);
        }, 3000);

        return () => {
            clearInterval(loggingIntervalRef.current);
        };
    }, [trustScore]);

    const startRecording = (stream) => {
        if (recordingStartedRef.current) {
            console.log("Recording already started");
            return;
        }

        if (!stream) {
            stream = streamRef.current;
            if (!stream) {
                console.error("No stream available to start recording.");
                return;
            }
        }

        if (stream instanceof MediaStream) {
            mediaRecorderRef.current = new MediaRecorder(stream, {
                mimeType: 'video/webm',
                videoBitsPerSecond: 1 * 480 * 480,
            });

            mediaRecorderRef.current.ondataavailable = e => {
                chunks.current.push(e.data);
                console.log("Data available:", e.data);
            };

            mediaRecorderRef.current.onstop = () => {
                if (!recordingCompleteRef.current) {
                    const completeBlob = new Blob(chunks.current, {type: 'video/webm'});
                    console.log("Complete blob created:", completeBlob)
                    if (completeBlob.size > 0) {
                        onRecordingComplete(completeBlob);
                        recordingCompleteRef.current = true;
                    }
                    console.log("Recording stopped and complete blob created:", completeBlob);
                }
            };

            mediaRecorderRef.current.onerror = (event) => {
                console.error('MediaRecorder error:', event.error);
            };

            mediaRecorderRef.current.start();
            recordingStartedRef.current = true;
            console.log("Recording started");
        } else {
            console.error("startRecording called with non-MediaStream parameter:", stream);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && mediaRecorderRef.current.state === 'recording') {
            mediaRecorderRef.current.stop();
            console.log('Recording stopped by user action');
        }
    };

    return (
        <div>
            <video ref={videoRef} autoPlay muted playsInline/>
            <canvas ref={canvasRef}/>
            {alert && <div>Alert: No face detected</div>}
            <div>Trust Score: {trustScore}</div>
        </div>
    );
};

export default forwardRef(ProctoringComponent);
