import logo from '../../../assets/logo_ovii_2.svg'
import OtpComponent from "../../components/otpComponent";
import {useNavigate} from "react-router-dom";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {loginUser} from "../../../redux/actions/loginActions";

const EmailVerification = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const email = useSelector(state => state.user.email);
    const password = useSelector(state => state.user.password);

    const navigateToNext = () => {
        const credentials = {email: email.trim(), password: password};

        // Dispatch the loginUser action
        dispatch(
            loginUser(
                credentials,
                () => {
                    // Navigation logic inside the completion callback
                    navigate('/companyDetails');
                },
                (error) => {
                    alert(`Login failed: ${error.message}`);
                }
            )
        );
    };

    return (
        <div className="h- dvh">
            <div className="flex flex-col justify-center items-center">
                <img src={logo} alt="logo" className="w-[3.688rem] h-[2rem] mt-[13rem]"/>
                <p className="font-serif text-[1.75rem] font-medium tracking-[0.56px] text-charcoal mt-[1.25rem]">Email
                    Verification</p>
                <OtpComponent email={email} onSubmit={navigateToNext}/>
                {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem] w-full"/> */}
            </div>
        </div>
    );
};
export default EmailVerification;