import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import logo from "../../../assets/logo_ovii_2.svg";
import {Button} from "../../components/button";
import hello from '../../../assets/Hello.svg'
import {Textbox} from "../../components/textbox";
import PrefixedInput from "../../components/prefixedInput";
import {Textarea} from "../../components/textarea";
import Uploader from "../../components/upload";
import {FormValidator} from "@syncfusion/ej2-react-inputs";
import Checkbox from "../../components/checkbox";
import Modal from "../../components/popup";
import {useSelector} from "react-redux";


export const CompanyDetails = () => {
    const [companyName, setCompanyName] = useState("");
    const [website, setWebsite] = useState("");
    const [description, setDescription] = useState("");
    const [filePath, setFilePath] = useState(null);
    const [orgId, setOrgId] = useState(null);
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isLoading, setIsLoading] = useState(false)

    const navigate = useNavigate();
    const formRef = useRef(null);
    const validatorRef = useRef(null);

    const accessToken = localStorage.getItem("accessToken");
    const userId = localStorage.getItem("id");

    const isSiteRef = useSelector(state => state.urlReducer.fromSite)

    useEffect(() => {
        console.log(isSiteRef)
    }, [isSiteRef])

    const handleCheckboxChange = (e) => {
        setIsTermsChecked(e.target.checked);
    };

    const noSpecialCharValidator = (value) => {
        const specialCharExp = /^[^!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/;
        return specialCharExp.test(value.value.trim() || '')
    };

    useEffect(() => {
        if (formRef.current) {
            validatorRef.current = new FormValidator(formRef.current, {
                rules: {
                    'name': {
                        required: [true, 'Please enter a company name.'],
                        minLength: [3, 'Please enter a valid company name.'],
                        noSpecialCharValidator: [noSpecialCharValidator, 'Cannot contain special characters.'],
                        maxLength: [120, 'Company name cannot exceed 120 characters.']
                    },
                    'website': {
                        required: [true, 'Please enter a website.'],
                        url: [true, 'Please enter a valid website.']
                    },
                    'description': {
                        maxLength: [700, 'Cannot exceed 700 characters.'],
                        minLength: [50, 'Must be a minimum of 50 characters.']
                    },
                },
                customPlacement: (inputElement, error) => {
                    inputElement.parentElement.parentElement.appendChild(error) // todo: get the library patched to allow per field custom error message locations
                },
            });
        }
    }, [formRef, validatorRef]);


    useEffect(() => {
        const fetchOrgId = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/userTeamDetails?userId=${userId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }

                const data = await response.json();
                if (data.status === 201 && data.data && data.data.orgId) {
                    setOrgId(data.data.orgId);
                }
            } catch (error) {
                setModalContent({
                    heading: 'Error',
                    message: 'Failed to fetch organisation ID. Please try logging in again.',
                    confirmButtonText: 'OK',
                });
                setShowModal(true);
            }
        };

        fetchOrgId();
    }, [userId, accessToken]);

    const handleFilePathChange = (newFilePath) => {
        setFilePath(newFilePath);
    };

    const handleDoneClick = async (e) => {
        e.preventDefault();
        if (validatorRef.current.validate()) {
            if (isTermsChecked && validatorRef.current) {
                setIsLoading(true)
                if (!orgId) {
                    setIsLoading(false)
                    setModalContent({
                        heading: 'Error',
                        message: 'Organisation ID not available.',
                        confirmButtonText: 'OK',
                    });
                    setShowModal(true);
                    return;
                }

                try {
                    const modifyOrgDetails = {
                        orgId: orgId,
                        orgName: companyName,
                        description: description,
                        logolocation: filePath,
                        website: `${website}`,
                        facebookLink: "",
                        twitterLink: "",
                        instagramLink: "",
                        linkedinLink: "",
                    };

                    const response = await fetch(
                        `${process.env.REACT_APP_BASE_URL}/api/v1/modifyOrgDetails`,
                        {
                            method: "POST",
                            headers: {
                                "Authorization": `Bearer ${accessToken}`,
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify(modifyOrgDetails),
                        }
                    );

                    if (!response.ok) {
                        throw new Error(`HTTP error! Status: ${response.status}`);
                    }

                    const responseData = await response.json();
                    // console.log("Organization details updated:", responseData);
                    if (isSiteRef) {
                        navigate("/company/buyCredits")
                    } else {
                        navigate("/home");
                    }
                } catch (error) {
                    setModalContent({
                        heading: 'Error',
                        message: 'Failed to modify organisation details.',
                        confirmButtonText: 'OK',
                    });
                    setShowModal(true);
                }
            } else {
                setIsLoading(false)
                // Show modal instead of alert
                setModalContent({
                    heading: 'Error',
                    message: "Please accept to display the company profile during assessments",
                    confirmButtonText: 'OK',
                });
                setShowModal(true);
            }
        } else {
            setIsLoading(false)
            // // Show modal instead of alert
            // setModalContent({
            //     heading: 'Error',
            //     message: "Please accept to display the company profile during assessments",
            //     confirmButtonText: 'OK',
            // });
            // setShowModal(true);
        }

    };

    const handleModalConfirm = () => {
        setShowModal(false);
    };

    return (
        <div class="h-max mt-[4.5rem]">
            <form id="companyDetails" ref={formRef} onSubmit={handleDoneClick}>
                <div className="flex flex-col items-center justify-center">
                    <img src={logo} alt="ovii logo"/>
                    <p className="font-serif text-[1.75rem] font-bold tracking-[0.56px] text-charcoal mt-[1rem]">
                        Company details
                    </p>
                    <div className="flex-container">
                        <div class="space-x-[2rem] flex flex-wrap">
                            <div className="textbox-container">
                                <Textbox
                                    label={(
                                        <label className="flex items-center">
                                            Company Name
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    placeholder="ABC Corp"
                                    className="mt-[1.25rem]"
                                    inputClassName="w-[20rem]"
                                    type="name"
                                    name="name"
                                    id="name"
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                ></Textbox>
                            </div>
                            <div className="textbox-container mt-[1.25rem]">
                                <PrefixedInput
                                    label={(
                                        <label className="flex items-center">
                                            Website
                                            <span className="text-red-500" style={{color: '#E90C44'}}>*</span>
                                        </label>
                                    )}
                                    showLabel={true}
                                    prefix="https://"
                                    placeholder="Enter your website URL"
                                    value={website}
                                    setValue={setWebsite}
                                    width="w-[20rem]"
                                    name="website"
                                    id="website"
                                    onChange={(e) => setWebsite(e.target.value)}
                                ></PrefixedInput>
                            </div>
                        </div>
                        <div className="mt-[1.25rem]">
                            <Textarea
                                label={(
                                    <label className="flex items-center">
                                        Description
                                    </label>
                                )}
                                id="description"
                                placeholder="Enter a brief description of your company, highlighting your culture, mission, and what makes you stand out as an employer. For example: 'We're a dynamic tech startup committed to innovating in the green energy sector, fostering a creative and inclusive work environment where every team member is valued"
                                widthOverride="42.25rem"
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                                name="description"
                            ></Textarea>
                        </div>
                        <br/>
                    </div>
                    <div>
                        <div>
                            <p className="mb-1 font-sans text-sm font-bold">Company logo</p>
                        </div>
                        <Uploader
                            label="Description"
                            width="42.25rem"
                            height="4.563rem"
                            onFilePathChange={handleFilePathChange}
                            token={accessToken}
                        ></Uploader>
                    </div>
                    <Checkbox name="terms" id="terms"
                              content=" Please agree to display the company profile during assessments."
                              className="text-slate-950 text-[13px] font-normal font-['Inter'] tracking-tight"
                              onChange={handleCheckboxChange}/><br/>
                    <Button variant='bright-blue'
                            className="text-[0.735rem] h-[2.25rem]"
                            label={isLoading ? "LOADING..." : "DONE"}
                            width="22.375rem"
                            type="submit"
                    ></Button>
                </div>
                <br/>
                <Modal isOpen={showModal}
                       onClose={() => setShowModal(false)}
                       onConfirm={handleModalConfirm}
                       heading={modalContent.heading}
                       message={modalContent.message}
                       confirmButtonText={modalContent.confirmButtonText}
                />
            </form>
            {/* <FooterLinks className="absolute left-[3.438rem] bottom-[2.688rem] w-max"/> */}
            <img src={hello}
                 alt="man saying hello"
                 className="absolute bottom-6 right-4 w-[11.5rem] hidden sm:block"
                 style={{maxWidth: '100%', maxHeight: '100%'}}/>
        </div>
    );
};
