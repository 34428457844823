import React from 'react';

const ToggleSwitch = ({label, checked, onChange}) => {
    return (
        <label className="flex items-center cursor-pointer pt-0.5">
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
                className="hidden"
            />
            <div className="toggle-switch relative w-8 h-4 bg-gray-200 rounded-full shadow-inner pt-2">
                <div
                    className={`toggle-dot absolute w-4 h-4 rounded-full shadow inset-y-0 left-0 transform transition-transform ${checked ? 'translate-x-full bg-teal-600' : 'bg-green-500'}`}
                ></div>
            </div>
            <div className="ml-0 text-gray-700">{label}</div>
        </label>
    );
};

export default ToggleSwitch;