import React, { forwardRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { updateTaxValues } from '../../redux/actions/taxDropdownActions';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import '../../../node_modules/@syncfusion/ej2-inputs/styles/material.css';
import '../../../node_modules/@syncfusion/ej2-dropdowns/styles/material.css';

const TaxDropdown = forwardRef(({ label, value, width, showLabel, id, placeholder, name, onTaxTypeChange }, ref) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [inputValue, setInputValue] = useState(value);
  const dispatch = useDispatch();
  const [taxType, setTaxType] = useState('GST');

  const handleOptionChange = (e) => {
    const newTaxType = e.itemData.value;
    setTaxType(newTaxType);
    onTaxTypeChange(newTaxType); // Call the callback function passed from the parent component
  };

  const handleChange = (e) => {
    setInputValue(e.target.value);
    dispatch(updateTaxValues({ id, value: e.target.value }));
  };

  const containerStyle = {
    // boxShadow: '0 0 10px rgba(16, 24, 40, 0.1)',
    border: '1.25px solid #ABACAE',
    borderRadius: '3px',
    width: width,
  };

  const prefixStyle = {
    height: '2.6rem',
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '0.5rem', // Adjust padding as needed
    paddingRight: '0.5rem', // Adjust padding as needed
    backgroundColor: '#fff', // Light grey background for the prefix section
    borderTopLeftRadius: '3px', // Match the border radius with the container
    borderBottomLeftRadius: '3px',
  };

  const inputStyle = {
    height: '2.6rem', // Match the height with the prefix
    width: '100%',
    paddingLeft: '0.5rem', // Adjust padding as needed
    paddingRight: '0.5rem', // Adjust padding as needed
    border: 'none', // No border for the input, it's on the container
  };

  const taxLabels = [
    { text: 'GST', value: 'GST', label: 'GST' },
    { text: 'VAT', value: 'VAT', label: 'VAT' },
    { text: 'Salestax', value: 'Salestax', label: 'Salestax' },
  ];

  return (
    <div className={`flex flex-col items-start ${width}`}>
      {showLabel && <label htmlFor={id} className="font-sans text-sm font-bold tracking-wide text-charcoal">{label}</label>}
      <div className="relative flex items-center w-full mt-2" style={containerStyle}>
        <span style={prefixStyle}>
          {<DropDownListComponent
            dataSource={taxLabels}
            fields={{ text: 'text', value: 'value' }}
            width={"7rem"}
            popupWidth={"7rem"}
            value={taxType}
            change={handleOptionChange}
          />}</span>
        <input
          id={id}
          name={name}
          type="text"
          placeholder={placeholder}
          value={inputValue}
          onChange={handleChange}
          className="flex-1 w-auto outline-none text-text-grey"
          style={inputStyle}
          ref={ref}
        />
      </div>
    </div>
  );
});

export default TaxDropdown;
