// videoReducer.js

import { START_VIDEO_STREAM, STOP_VIDEO_STREAM } from '../actions/videoActions';

const initialState = {
    videoStream: null,
};

const videoReducer = (state = initialState, action) => {
    switch (action.type) {
        case START_VIDEO_STREAM:
            return {
                ...state,
                videoStream: action.payload.stream,
            };
        case STOP_VIDEO_STREAM:
            return {
                ...state,
                videoStream: null,
            };
        default:
            return state;
    }
};

export default videoReducer;
