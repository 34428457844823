import React, {useEffect, useRef, useState} from "react";
import {Button} from '../../../components/button';
import AddInterviewQuestionLayout from "../../../components/SlidingLayouts/AddInterviewQuestion";
import plane from '../../../../assets/user-color.svg';
import Modal from '../../../components/popup';
import logo from "../../../../assets/logo_ovii_2.svg";
import ThreeButtonLoader from "../../../components/ThreeButtonLoader";

const NavBar = () => {
    return (
        <div className="flex justify-center w-full border items-center py-3 shadow-sm">
            <div className="w-[80%] flex justify-between items-center" style={{marginBottom: 0}}>
                <div className="text-2xl font-bold" style={{fontFamily: "ITC Clearface"}}>
                    <h1>Select interview questions</h1>
                </div>
                <div className="flex items-center justify-end" style={{marginBottom: 0}}>
                    <img src={logo} alt="logo" className="h-6 w-12"/>
                </div>
            </div>
        </div>
    );
};

export default function VideoInterviewQuestions() {
    const [categories, setCategories] = useState([]);
    const [categorySelected, setCategorySelected] = useState(true);
    const [categoryQuestions, setCategoryQuestions] = useState([]);
    const [jobDescription, setJobDescription] = useState(localStorage.getItem("jd") || "");
    const [resume, setResume] = useState(localStorage.getItem("resume") || "");
    const [showAddQuestionLayout, setShowAddQuestionLayout] = useState(false);
    const [activeAccordion, setActiveAccordion] = useState(null);
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const accessToken = localStorage.getItem("accessToken");
    const [selectedCategoryID, setSelectedCategoryID] = useState(1);
    const [candidateId, setCandidateId] = useState(localStorage.getItem("candidateId"));
    const [assessmentId, setAssessmentId] = useState(localStorage.getItem("assessmentId"));
    const [totalAnswerTime, setTotalAnswerTime] = useState(0);
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestionsByCategory, setSelectedQuestionsByCategory] = useState({});
    const [successfulShowModal, setSuccessfulShowModal] = useState(false);
    const [errorShowModal, setErrorShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [inviteLoading, setInviteloading] = useState(false);
    const [loading, setLoading] = useState(true)
    const abortControllerRef = useRef(null);
    const cacheRef = useRef({});

    const toggleAccordion = (index) => {
        setActiveAccordion(activeAccordion === index ? null : index);
    };

    const handleCategoryClick = (category) => {
        setLoading(true);
        setSelectedCategoryID(category.videoInterViewCategories);
    };

    const handleAddQuestion = (question) => {
        console.log(question)
        const questionWithCategory = {...question, category: selectedCategoryID};
        setSelectedQuestions([...selectedQuestions, questionWithCategory]);
        console.log(selectedQuestions)
        const newSelectedQuestions = [
            ...(selectedQuestionsByCategory[selectedCategoryID] || []),
            questionWithCategory,
        ];
        setSelectedQuestionsByCategory({
            ...selectedQuestionsByCategory,
            [selectedCategoryID]: newSelectedQuestions,
        });
    };

    const handleRemoveQuestion = (categoryId, index) => {
        const newQuestions = [...selectedQuestions];
        newQuestions.splice(index, 1);
        setSelectedQuestions(newQuestions);
        const newSelectedQuestions = [
            ...(selectedQuestionsByCategory[categoryId] || []),
        ];
        newSelectedQuestions.splice(index, 1);
        setSelectedQuestionsByCategory({
            ...selectedQuestionsByCategory,
            [categoryId]: newSelectedQuestions,
        });
    };

    const handleAddCustomQuestion = (customQuestion) => {
        customQuestion.category = 15; // Ensure category ID is 15 for custom questions
        setSelectedQuestions([...selectedQuestions, customQuestion]);
        const newSelectedQuestions = [
            ...(selectedQuestionsByCategory[15] || []),
            customQuestion,
        ];
        setSelectedQuestionsByCategory({
            ...selectedQuestionsByCategory,
            15: newSelectedQuestions,
        });
    };

    useEffect(() => {
        console.log(errorShowModal);
    }, [errorShowModal]);

    const handleSaveQuestions = async () => {
        setInviteloading(true);

        const questionsData = selectedQuestions.map(question => ({
            title: question.Question,
            question: question.ExpectedAnswer,
            timeLimit: question.AnswerTime || 3,
            thinkTime: 1,
            category: question.category || selectedCategoryID,
        }));

        if (selectedQuestions.length === 0) {
            setModalContent({
                heading: 'Error',
                message: 'Please select at least one question to proceed.'
            });
            setErrorShowModal(true);
            setInviteloading(false);
            return;
        }

        const data = {
            assessmentId: assessmentId,
            candidateId: candidateId,
            questions: questionsData,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/createVideoInterview`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${accessToken}`,
                },
                body: JSON.stringify(data),
            });

            const result = await response.json();
            if (result.status === 200) {
                setSuccessfulShowModal(true);
            } else {
                let heading = 'Error';
                let message = "Failed to send invitation, please try again.";
                if (response.status === 400) {
                    heading = "Not enough credits";
                    message = "You do not have enough credits to complete this action, please buy more credits to continue!";
                }
                setModalContent({
                    heading: heading,
                    message: message,
                });
                setErrorShowModal(true);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setInviteloading(false);
        }
    };

console.log("category selected:", categoryQuestions)

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getVideoInterviewCategories`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                const data = await response.json();
                const categories = data.data; // Get only the first 13 categories
                setCategories(categories);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchCategories();
    }, [accessToken]);

    useEffect(() => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        const controller = new AbortController();
        abortControllerRef.current = controller;
        setLoading(true);

        if (cacheRef.current[selectedCategoryID]) {
            // Use cached data if available
            setCategoryQuestions(cacheRef.current[selectedCategoryID]);
            setLoading(false);
            setCategorySelected(false)
            return;
        }

        const formData = new FormData();
        formData.append("resume", resume);
        formData.append("videoInterviewCategory", selectedCategoryID);
        formData.append("jd", jobDescription);
        formData.append("assessmentId", assessmentId);

        const fetchCategoryQuestions = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getQuestionsForVideoInterview`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                        },
                        body: formData,
                        signal: controller.signal
                    }
                );
                const data = await response.json();

                // Normalize the data structure
                let questions = [];
                setLoading(true);
                if (Array.isArray(data.data)) {
                    questions = data.data.map(q => ({
                        Question: q.question,
                        ExpectedAnswer: q.expectedAnswer,
                        AnswerTime: q.answerTime,
                        ThinkTime: q.thinkTime,
                        category: selectedCategoryID // Attach the category ID to each question
                    }));
                } else if (data.data["Interview Questions"]) {
                    questions = data.data["Interview Questions"].map(q => ({
                        ...q,
                        category: selectedCategoryID // Attach the category ID to each question
                    }));
                }
                
                setLoading(true);
                setCategoryQuestions(questions);
                setCategorySelected(false);
                cacheRef.current[selectedCategoryID] = questions; // Cache the response
                
                const selectedQuestionsForCategory = selectedQuestionsByCategory[selectedCategoryID] || [];
                setCategoryQuestions([
                    ...selectedQuestionsForCategory,
                    ...questions,
                ]);
                setCategorySelected(false);
            } catch (error) {
                if (error.name === 'AbortError') {
                    console.log('Fetch aborted');
                } else {
                    console.error("Error fetching data:", error);
                }
            } finally {
                setLoading(false);
            }
        };
        fetchCategoryQuestions();

        return () => {
            controller.abort();
        };
    }, [selectedCategoryID, accessToken, jobDescription, resume]);

    const calculateTotalAnswerTime = () => {
        let total = 0;
        selectedQuestions.forEach(question => {
            total += parseInt(question.AnswerTime) || 0;
        });
        return total;
    };

    useEffect(() => {
        const total = calculateTotalAnswerTime();
        setTotalAnswerTime(total);
    }, [selectedQuestions]);

    const checkTotalAnswerTime = () => {
        if (totalAnswerTime > 45) {
            setShowModal(true);
        }
    };

    useEffect(() => {
        checkTotalAnswerTime();
    }, [totalAnswerTime]);

    const onErrorShowModalClose = (e) => {
        e.preventDefault();
        setErrorShowModal(false);
        setInviteloading(false);
    }

    return (
        <div className="flex flex-col items-center justify-center w-full fixed">
            <NavBar/>
            <div className="flex items-center justify-center w-[80%] mx-10">
                <div>
                    <div className="flex gap-12 transition-all duration-200">
                        <div className='flex flex-row items-center gap-0 -ml-3'>
                            <img src={plane} alt="plane" className="w-[4.5rem]"/>
                            <div className="flex-1 pr-6">
                                <p className="break-normal">
                                    These questions are generated based on candidate resumes, past experience, and JDs.
                                    Select preferred questions by category and seamlessly send interview requests.
                                </p>
                            </div>
                        </div>
                        {categories.length > 0 && (
                            <div className="flex flex-row items-center pt-[2rem] -mt-8">
                                <Button
                                    className="h-[2rem] text-[0.725rem]"
                                    label="ADD YOUR OWN"
                                    width="10.438rem"
                                    variant="white"
                                    onClick={() => setShowAddQuestionLayout(true)}
                                />
                                <Button
                                    variant='bright-blue'
                                    className="h-[2rem] text-[0.725rem] ml-5 cursor-pointer"
                                    label={inviteLoading ? "PLEASE WAIT ..." : "SEND INVITE"}
                                    width="8.625rem"
                                    onClick={handleSaveQuestions}
                                />
                            </div>
                        )}
                    </div>
                    <div>
                        <div className='text-blue font-Sans'> Total Answer Time: <span
                            className="text-black">{totalAnswerTime} min</span></div>
                        <div className='flex pt-[1.375rem] gap-x-2'>
                            {showAddQuestionLayout &&
                                <AddInterviewQuestionLayout
                                    isOpen={showAddQuestionLayout}
                                    onClose={() => setShowAddQuestionLayout(false)}
                                    onSave={handleAddCustomQuestion}
                                />
                            }
                            <div className='w-1/3 max-h-[67vh] overflow-y-scroll flex flex-col gap-y-1'>
                                {categories.length > 0 && categories.map((category) => (
                                    <div key={category.videoInterViewCategories}
                                         className={`border-2 border-gray-200 pt-4 px-3 rounded shadow cursor-pointer ${selectedCategoryID !== null && selectedCategoryID === category.videoInterViewCategories
                                             ? 'border-indigo-600'
                                             : ''
                                         }`} onClick={() => handleCategoryClick(category)}>
                                        <p className='text-md font-semibold mb-2'>{category.videoInterViewCategoriesName}</p>
                                        <p className='text-sm text-gray-600 mb-2'>{category.videoInterViewCategoriesDescription}</p>
                                        <hr className='border-t-2 border-dashed border-gray-200'/>
                                        <div className='flex items-center gap-x-2 mt-2 mb-3'>
                                            <div className='rounded-[2px] text-xs font-bold'>
                                                <span
                                                    className='bg-[#EDF6FF] text-[#004eec] px-2 py-1'>Recommended
                                                </span>
                                            </div>
                                            <div>
                                                <span
                                                    className='text-xs bg-[#FFF0D9] text-[#BF7508] font-bold px-2 py-1'>7 Questions</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {categorySelected ? (
                                    <div className="w-2/3 max-h-[67vh] overflow-y-scroll flex flex-col gap-y-1">
                                            <ThreeButtonLoader height="30rem"/>
                                    </div>
                                ) : (
                                    <div className="w-2/3 max-h-[67vh] overflow-y-scroll flex flex-col gap-y-1">
                                        {loading ? (
                                            <ThreeButtonLoader height="30rem"/>
                                            ) : (
                                                categoryQuestions.map((question, index) => (
                                                    <div key={index} className="mb-2">
                                                        <button
                                                            type="button"
                                                            className="flex items-center justify-between w-full p-4 border border-gray-200 hover:bg-gray-100 gap-3 rounded-t"
                                                            onClick={() => toggleAccordion(index)}
                                                        >
                                                            <span className='text-[1rem] font-medium text-start w-5/6 line-clamp-1'>
                                                                {question.Question}
                                                            </span>
                                                            {(selectedQuestionsByCategory[selectedCategoryID] || []).some(
                                                                (item) => item.Question === question.Question
                                                            ) && (
                                                                <span
                                                                    className="px-2 font-semibold text-xs bg-green-100 rounded-md text-green-700">
                                                                    Added
                                                                </span>
                                                            )}
                                                            <svg
                                                                className={`w-3 h-3 transform ${activeAccordion === index ? 'rotate-0' : '-rotate-180'} shrink-0`}
                                                                aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                                fill="none"
                                                                viewBox="0 0 10 6">
                                                                <path stroke="currentColor" strokeLinecap="round"
                                                                      strokeLinejoin="round"
                                                                      strokeWidth="2" d="M9 5 5 1 1 5"/>
                                                            </svg>
                                                        </button>
                                                        <div
                                                            className={`transition-all duration-200 px-5 pt-5 pb-1 border border-gray-200 flex flex-col gap-y-2 rounded-b ${activeAccordion === index ? '' : 'hidden'}`}>
                                                            <p className='font-medium mb-[0.5rem] text-[1rem] text-[#4b515e]'
                                                               style={{
                                                                   'fontFamily': 'helvetica',
                                                                   'fontWeight': '700'
                                                               }}>{question.Question}</p>
                                                            <p style={{
                                                                'fontFamily': 'helvetica',
                                                                'color': '#585f6d',
                                                                'lineHeight': '1.5rem',
                                                                'letterSpacing': '0.16px'
                                                            }}>{question.ExpectedAnswer}</p>
                                                            <hr className='border-t-2 border-dashed border-gray-200'/>
                                                            <div className='flex justify-between mt-1 items-center'>
                                                                <div
                                                                    className="text-xs text-gray-700 font-sans font-bold flex gap-x-2">
                                                                    <div className='bg-[#EDF6FF] text-[#004eec] px-2 py-1'>
                                                                        <span>Think Time: {question.Thinktime} min</span>
                                                                    </div>
                                                                    <div className='bg-[#EDF6FF] text-[#004eec] px-2 py-1'>
                                                                        <span>Answer Time: {question.AnswerTime} min</span>
                                                                    </div>
                                                                </div>
                                                                <div className="flex justify-end gap-x-1">
                                                                    {(selectedQuestionsByCategory[selectedCategoryID] || []).some(
                                                                        (item) => item.Question === question.Question
                                                                    ) ? (
                                                                        <button
                                                                            className="px-4 py-1 font-semibold text-sm bg-red-100 rounded-s text-red-700 mr-1"
                                                                            onClick={() =>
                                                                                handleRemoveQuestion(
                                                                                    selectedCategoryID,
                                                                                    (selectedQuestionsByCategory[selectedCategoryID] || []).findIndex(
                                                                                        (item) => item.Question === question.Question
                                                                                    )
                                                                                )
                                                                            }
                                                                        >
                                                                            Remove
                                                                        </button>
                                                                    ) : (
                                                                        <button
                                                                            className={`px-4 py-1 font-semibold text-sm bg-indigo-100 rounded-s text-blue-700 hover:bg-indigo-200 mr-1 ${totalAnswerTime >= 45 ? 'disabled:opacity-50 cursor-not-allowed' : ''
                                                                            }`}
                                                                            onClick={() => handleAddQuestion(question)}
                                                                            disabled={totalAnswerTime >= 45}
                                                                        >
                                                                            + Add
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            )}
                                    </div>
                                )}
                            {/* <div className="w-2/3 max-h-[67vh] overflow-y-scroll flex flex-col gap-y-1">
                                {loading ? (
                                    <div className="max-h-[67vh]">
                                        <ThreeButtonLoader/>
                                    </div>
                                    ) : !categoryQuestions ? (
                                        <span>{selectedCategoryID ? "Loading..." : "Select a category"}</span>
                                    ) : (
                                        categoryQuestions.map((question, index) => (
                                            <div key={index} className="mb-2">
                                                <button
                                                    type="button"
                                                    className="flex items-center justify-between w-full p-4 border border-gray-200 hover:bg-gray-100 gap-3 rounded-t"
                                                    onClick={() => toggleAccordion(index)}
                                                >
                                                    <span className='text-[1rem] font-medium text-start w-5/6 line-clamp-1'>
                                                        {question.Question}
                                                    </span>
                                                    {(selectedQuestionsByCategory[selectedCategoryID] || []).some(
                                                        (item) => item.Question === question.Question
                                                    ) && (
                                                        <span
                                                            className="px-2 font-semibold text-xs bg-green-100 rounded-md text-green-700">
                                                            Added
                                                        </span>
                                                    )}
                                                    <svg
                                                        className={`w-3 h-3 transform ${activeAccordion === index ? 'rotate-0' : '-rotate-180'} shrink-0`}
                                                        aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 10 6">
                                                        <path stroke="currentColor" strokeLinecap="round"
                                                              strokeLinejoin="round"
                                                              strokeWidth="2" d="M9 5 5 1 1 5"/>
                                                    </svg>
                                                </button>
                                                <div
                                                    className={`transition-all duration-200 px-5 pt-5 pb-1 border border-gray-200 flex flex-col gap-y-2 rounded-b ${activeAccordion === index ? '' : 'hidden'}`}>
                                                    <p className='font-medium mb-[0.5rem] text-[1rem] text-[#4b515e]'
                                                       style={{
                                                           'fontFamily': 'helvetica',
                                                           'fontWeight': '700'
                                                       }}>{question.Question}</p>
                                                    <p style={{
                                                        'fontFamily': 'helvetica',
                                                        'color': '#585f6d',
                                                        'lineHeight': '1.5rem',
                                                        'letterSpacing': '0.16px'
                                                    }}>{question.ExpectedAnswer}</p>
                                                    <hr className='border-t-2 border-dashed border-gray-200'/>
                                                    <div className='flex justify-between mt-1 items-center'>
                                                        <div
                                                            className="text-xs text-gray-700 font-sans font-bold flex gap-x-2">
                                                            <div className='bg-[#EDF6FF] text-[#004eec] px-2 py-1'>
                                                                <span>Think Time: {question.Thinktime} min</span>
                                                            </div>
                                                            <div className='bg-[#EDF6FF] text-[#004eec] px-2 py-1'>
                                                                <span>Answer Time: {question.AnswerTime} min</span>
                                                            </div>
                                                        </div>
                                                        <div className="flex justify-end gap-x-1">
                                                            {(selectedQuestionsByCategory[selectedCategoryID] || []).some(
                                                                (item) => item.Question === question.Question
                                                            ) ? (
                                                                <button
                                                                    className="px-4 py-1 font-semibold text-sm bg-red-100 rounded-s text-red-700 mr-1"
                                                                    onClick={() =>
                                                                        handleRemoveQuestion(
                                                                            selectedCategoryID,
                                                                            (selectedQuestionsByCategory[selectedCategoryID] || []).findIndex(
                                                                                (item) => item.Question === question.Question
                                                                            )
                                                                        )
                                                                    }
                                                                >
                                                                    Remove
                                                                </button>
                                                            ) : (
                                                                <button
                                                                    className={`px-4 py-1 font-semibold text-sm bg-indigo-100 rounded-s text-blue-700 hover:bg-indigo-200 mr-1 ${totalAnswerTime >= 45 ? 'disabled:opacity-50 cursor-not-allowed' : ''
                                                                    }`}
                                                                    onClick={() => handleAddQuestion(question)}
                                                                    disabled={totalAnswerTime >= 45}
                                                                >
                                                                    + Add
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    )}
                            </div> */}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                isOpen={showModal}
                onClose={() => setShowModal(false)}
                onConfirm={() => setShowModal(false)}
                heading="Hold on"
                message="For optimal candidate experience, we suggest that you keep the total interview duration under 45 mins."
                confirmButtonText="OK"
            />
            <Modal
                isOpen={successfulShowModal}
                onClose={() => setSuccessfulShowModal(false)}
                onConfirm={() => setSuccessfulShowModal(false)}
                heading="Successful"
                message="Successfully invited the candidate. You may now close this window."
                confirmButtonText="OK"
            />
            <Modal
                isOpen={errorShowModal}
                onClose={(e) => onErrorShowModalClose(e)}  // Add onClose handler here
                onConfirm={() => setErrorShowModal(false)}
                heading={modalContent.heading}
                message={modalContent.message}
                confirmButtonText="OK"
            />
        </div>
    );
}
