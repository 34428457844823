export const formatDate = (dateString) => {
    const options = {year: "numeric", month: "short", day: "numeric"};
    const formattedDate = new Date(dateString).toLocaleDateString(
        "en-US",
        options
    );
    return formattedDate;
};

export const isEmailValid = (email) => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

export default isEmailValid;
