import React, { useState } from 'react';
import Editor from '@monaco-editor/react';

function CodeEditor() {
  const [language, setLanguage] = useState('javascript'); // Default language set to 'javascript'

  return (
    <div>
      <select onChange={(e) => setLanguage(e.target.value)} value={language}>
        <option value="javascript">JavaScript</option>
        <option value="python">Python</option>
        <option value="html">HTML</option>
        <option value="css">CSS</option>
        <option value="typescript">TypeScript</option>
        <option value="java">Java</option>
        {/* Add more languages as needed */}
      </select>
      <Editor
        height="90vh"
        language={language} // Use the state variable 'language' for language
        defaultValue="// Write your code here"
        theme="vs-dark"
        options={{
          autoClosingBrackets: "always",
          autoIndent: "full", // Enables automatic indentation
        }}
      />
    </div>
  );
}

export default CodeEditor;
