// import React, { useState, useEffect, useRef } from "react";
// // import { DropdownField, TextBox, RichTextEditorField } from "../formFields";
// import { Textbox } from "../../../components/textbox";
// import { DropdownField } from "../../../components/DropdownField";
// import { RichTextEditor } from "@syncfusion/ej2-react-richtexteditor";

// import Editor from "@monaco-editor/react";

// export default function CodeQuestionForm() {
//   const [question, setQuestion] = useState("");
//   const [sampleInput, setSampleInput] = useState("");
//   const [sampleOutput, setSampleOutput] = useState("");
//   const [inputFormat, setInputFormat] = useState("");
//   const [outputFormat, setOutputFormat] = useState("");
//   const [constraints, setConstraints] = useState("");
//   const [difficultyId, setDifficultyId] = useState(0);
//   const [expectedSolveTimeMinutes, setExpectedSolveTimeMinutes] = useState(0);
//   const [expectedSolveTimeSeconds, setExpectedSolveTimeSeconds] = useState(0);
//   const [title, setTitle] = useState("");
//   const [tags, setTags] = useState("");
//   const [codeStub, setCodeStub] = useState({});
//   const [testCases, setTestCases] = useState([]);
//   const [languages, setLanguages] = useState([
//     "Javascript",
//     "Python",
//     "C++",
//     "Typescript",
//     "Java",
//     "HTML",
//     "CSS",
//   ]);
//   const [selectedLanguage, setSelectedLanguage] = useState(null);
//   const [languageEditors, setLanguageEditors] = useState([]);

//   const prevFormDataRef = useRef();

//   useEffect(() => {
//     const currentFormData = {
//       title,
//       question,
//       difficultyId,
//       expectedSolveTimeMinutes,
//       expectedSolveTimeSeconds,
//       tags,
//       testCases,
//       languages,
//     };
//     prevFormDataRef.current = currentFormData;
//   }, [
//     title,
//     question,
//     difficultyId,
//     expectedSolveTimeMinutes,
//     expectedSolveTimeSeconds,
//     tags,
//     testCases,
//     languages,
//   ]);

//   const difficulties = [
//     { id: 1, label: "Easy" },
//     { id: 2, label: "Medium" },
//     { id: 3, label: "Hard" },
//   ];

//   const handleSubmit = (e) => {
//     e.preventDefault();
//     const expectedSolveTimeInt =
//       parseInt(expectedSolveTimeMinutes, 10) * 60 +
//       parseInt(expectedSolveTimeSeconds, 10);
//     if (isNaN(expectedSolveTimeInt) || expectedSolveTimeInt <= 0) {
//       alert("Expected Solve Time must be a positive integer.");
//       return;
//     }

//     const codeData = {
//       questionTypeId: 3,
//       questionData: {
//         title,
//         problemStatement: question,
//         inputFormat,
//         outputFormat,
//         sampleInput,
//         sampleOutput,
//         constraints,
//         codeStub: codeStub,
//         languages: languageEditors.map(({ lang }) => lang),
//         hiddenTestCases: testCases,
//         tags,
//         solveTime: expectedSolveTimeInt,
//       },
//       difficultyId:
//         difficultyId === "Hard" ? 3 : difficultyId === "Medium" ? 2 : 1,
//     };
//     console.log(difficultyId);
//     console.log(codeData);
//     // Here you would make an API call to submit the codeData
//   };

//   const addTestCase = (e) => {
//     e.preventDefault();
//     setTestCases([...testCases, { input: "", output: "" }]);
//   };

//   const removeTestCase = (e, index) => {
//     e.preventDefault();

//     const updatedTestCases = [...testCases];
//     updatedTestCases.splice(index, 1);
//     setTestCases(updatedTestCases);
//   };

//   const handleTestCaseChange = (e, index, field, value) => {
//     e.preventDefault();

//     const updatedTestCases = [...testCases];
//     updatedTestCases[index][field] = value;
//     setTestCases(updatedTestCases);
//   };

//   const addLanguage = (selectedLanguage) => {
//     setLanguages((prevLanguages) =>
//       prevLanguages.filter((lang) => lang !== selectedLanguage)
//     );
//     if (!languageEditors.some((editor) => editor.lang === selectedLanguage)) {
//       addLanguageEditor(selectedLanguage);
//     }
//   };

//   const addLanguageEditor = (lang) => {
//     const handleEditorChange = (value) => {
//       setCodeStub((prevCodeStub) => ({
//         ...prevCodeStub,
//         [lang]: value,
//       }));
//     };

//     const newEditor = {
//       lang,
//       editor: (
//         <Editor
//           key={lang}
//           language={lang}
//           height={"10rem"}
//           defaultValue={`// Write your ${lang} code here`}
//           theme="vs-dark"
//           options={{
//             autoClosingBrackets: "always",
//             autoIndent: "full",
//           }}
//           onChange={handleEditorChange}
//         />
//       ),
//     };

//     setLanguageEditors((prevLanguageEditors) => [
//       ...prevLanguageEditors,
//       newEditor,
//     ]);
//   };

//   const removeLanguage = (e, langToRemove) => {
//     e.preventDefault();
//     setLanguageEditors((prevLanguageEditors) =>
//       prevLanguageEditors.filter(({ lang }) => lang !== langToRemove)
//     );
//     setLanguages((prevLanguages) => [...prevLanguages, langToRemove]);
//     setCodeStub((prevCodeStub) => {
//       const updatedCodeStub = { ...prevCodeStub };
//       delete updatedCodeStub[langToRemove];
//       return updatedCodeStub;
//     });
//     if (selectedLanguage === langToRemove) {
//       setSelectedLanguage(null);
//     }
//   };

//   return (
//     <form
//       onSubmit={handleSubmit}
//       className="grid w-full grid-cols-1 gap-6 transition-all duration-300 md:grid-cols-2"
//     >
//       <div className="flex flex-col col-span-1 space-y-4">
//         <Textbox
//           id="title"
//           label="Title"
//           placeholder="Enter the title"
//           value={title}
//           onChange={(e) => setTitle(e.target.value)}
//           inputClassName="w-full"
//         />
//         <RichTextEditor
//           label="Question"
//           id="question"
//           name="question"
//           placeholder="Enter the question"
//           value={question}
//           change={(e) => setQuestion(e.value)}
//           className="w-full"
//         />
//         <div className="flex flex-col gap-y-4">
//           <Textbox
//             id="sampleInput"
//             name="sampleInput"
//             label="Sample Input"
//             placeholder="Enter the Sample Input"
//             value={sampleInput}
//             onChange={(e) => setSampleInput(e.target.value)}
//             inputClassName="w-full"
//           />
//           <Textbox
//             name="sampleOutput"
//             id="sampleOutput"
//             label="Sample Output"
//             placeholder="Enter the Sample Output"
//             value={sampleOutput}
//             onChange={(e) => setSampleOutput(e.target.value)}
//             inputClassName="w-full"
//           />
//           <Textbox
//             id="inputFormat"
//             name="inputFormat"
//             label="Input Format"
//             placeholder="Enter the Input Format"
//             value={inputFormat}
//             onChange={(e) => setInputFormat(e.target.value)}
//             inputClassName="w-full"
//           />
//           <Textbox
//             id="outputFormat"
//             name="outputFormat"
//             label="Output Format"
//             placeholder="Enter the Output Format"
//             value={outputFormat}
//             onChange={(e) => setOutputFormat(e.target.value)}
//             inputClassName="w-full"
//           />
//           <Textbox
//             id="constraints"
//             name="constraints"
//             label="Constraints"
//             placeholder="Enter the Constraints"
//             value={constraints}
//             onChange={(e) => setConstraints(e.target.value)}
//             inputClassName="w-full text-sm"
//           />
//         </div>

//         <div className="flex flex-row flex-wrap justify-between w-full gap-4">
//           <DropdownField
//             id={"option-dropdown"}
//             value={difficultyId}
//             label="Difficulty Level"
//             onChange={(e) => setDifficultyId(e.target.value)}
//             mapOptions={difficulties}
//           />
//           <div className="flex flex-col items-start">
//             <label className="font-sans font-semibold tracking-wide text-md text-charcoal">
//               Expected Solve Time
//             </label>
//             <div className="flex flex-row items-center my-3 gap-x-4">
//               <label className="font-sans font-medium tracking-wide text-md text-charcoal">
//                 Mins
//               </label>
//               <input
//                 id="expectedSolveTimeMinutes"
//                 name="expectedSolveTimeMinutes"
//                 placeholder="m"
//                 type="number"
//                 value={expectedSolveTimeMinutes}
//                 onChange={(e) => setExpectedSolveTimeMinutes(e.target.value)}
//                 className="w-16 px-3 py-1 border border-gray-300 rounded-md outline-none focus:border-gray-800"
//               />
//               <label className="font-sans font-medium tracking-wide text-md text-charcoal">
//                 Secs
//               </label>
//               <input
//                 id="expectedSolveTimeSeconds"
//                 name="expectedSolveTimeSeconds"
//                 placeholder="s"
//                 type="number"
//                 value={expectedSolveTimeSeconds}
//                 onChange={(e) => setExpectedSolveTimeSeconds(e.target.value)}
//                 className="w-16 px-3 py-1 border border-gray-300 rounded-md outline-none focus:border-gray-800"
//               />
//             </div>
//           </div>
//         </div>
//         <div className="flex flex-col items-start w-full">
//           <label className="font-sans text-lg font-semibold tracking-wide text-charcoal">
//             Test Cases
//           </label>
//           <hr className="w-full mt-2 mb-1 border border-gray-300" />
//           <div className="relative w-full">
//             {testCases.map((testCase, index) => (
//               <div
//                 key={index}
//                 className="flex flex-row items-start justify-between w-full mt-2 mb-4 gap-x-6"
//               >
//                 <div className="flex flex-col gap-y-4">
//                   <label className="w-24 mt-2 font-sans text-sm font-medium tracking-wide text-charcoal">
//                     Test Case {index + 1}
//                   </label>
//                   <button
//                     onClick={() => removeTestCase(index)}
//                     className="text-xs font-semibold text-red-800 uppercase bg-red-200 rounded-full"
//                   >
//                     Remove
//                   </button>
//                 </div>
//                 <div className="flex flex-col w-full gap-3">
//                   <Textbox
//                     id={`testcaseInput-${index}`}
//                     name="testcaseInput"
//                     label="Test case-Input"
//                     placeholder="Enter Test case Input"
//                     value={testCase.input}
//                     onChange={(e) =>
//                       handleTestCaseChange(e, index, "input", e.target.value)
//                     }
//                     inputClassName="w-full text-sm my-0 py-0"
//                   />
//                   <Textbox
//                     id={`testcaseOutput-${index}`}
//                     name="testcaseOutput"
//                     label="Test case-Output"
//                     placeholder="Enter Test case Output"
//                     value={testCase.output}
//                     onChange={(e) =>
//                       handleTestCaseChange(e, index, "output", e.target.value)
//                     }
//                     inputClassName="w-full"
//                   />
//                 </div>
//               </div>
//             ))}
//           </div>
//           <button
//             onClick={addTestCase}
//             className="w-full text-sm py-1.5 font-medium text-white bg-black rounded-md my-1"
//           >
//             Add Test case +
//           </button>
//         </div>
//         <Textbox
//           id="tags"
//           name="tags"
//           label="Tags"
//           placeholder="Enter Tags"
//           value={tags}
//           onChange={(e) => setTags(e.target.value)}
//           inputClassName="w-full"
//         />

//         <div className="grid grid-cols-2 gap-8">
//           <button
//             type="submit"
//             className="w-full py-2 my-1 font-medium text-white bg-black rounded-md text-md"
//           >
//             Submit Question
//           </button>
//           <button className="w-full py-2 my-1 font-medium text-gray-900 border border-black rounded-md text-md">
//             Add Another Question
//           </button>
//         </div>
//       </div>
//       <div className="flex flex-col col-span-1 gap-y-3">
//         <div className="flex flex-col w-full mt-2 mb-0">
//           <label className="font-sans text-lg font-semibold tracking-wide text-charcoal">
//             Add Language
//           </label>
//           <hr className="w-full mt-2 mb-1 border border-gray-300" />

//           <DropdownField
//             id={"language-dropdown"}
//             label=""
//             onChange={(e) => {
//               const lang = e.target.value;
//               setSelectedLanguage(lang);
//               addLanguage(lang);
//             }}
//             mapOptions={languages.map((lang) => ({
//               value: lang,
//               label: lang.charAt(0).toUpperCase() + lang.slice(1),
//             }))}
//             excludeOptions={selectedLanguage ? [selectedLanguage] : []}
//             disabled={languages.length === 0}
//           />
//         </div>
//         {languageEditors.map(({ lang, editor }, index) => (
//           <div
//             key={index}
//             className="flex flex-col items-center w-full mt-2 mb-4"
//           >
//             <div className="flex flex-row items-center justify-between w-full mb-3">
//               <div className="font-semibold">{lang}</div>
//               <button
//                 onClick={(e) => removeLanguage(e, lang)} // pass lang as argument
//                 className="h-auto px-2 py-1 text-xs font-semibold text-red-800 uppercase bg-red-200 rounded-full"
//               >
//                 Remove
//               </button>
//             </div>
//             {editor}
//           </div>
//         ))}
//       </div>
//     </form>
//   );
// }