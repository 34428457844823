// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader div {
    animation-duration: 0.5s;
  }
  
  .loader div:first-child {
    animation-delay: 0.1s;
  }
  
  .loader div:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .loader div:nth-child(3) {
    animation-delay: 0.6s;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/components/ThreeButtonLoader.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;EAC1B;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB","sourcesContent":[".loader div {\n    animation-duration: 0.5s;\n  }\n  \n  .loader div:first-child {\n    animation-delay: 0.1s;\n  }\n  \n  .loader div:nth-child(2) {\n    animation-delay: 0.3s;\n  }\n  \n  .loader div:nth-child(3) {\n    animation-delay: 0.6s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
