import React, {useEffect, useRef, useState} from "react";
import "../candidateTable.css";
import "../../../assets/font.css";
import {useNavigate} from "react-router-dom";
import {Button} from "../button";
import alertTriangle from '../../../assets/alert-triangle.svg'
import Modal from "../popup";
import {useReactToPrint} from 'react-to-print';
import print from "../../../assets/print.svg"

const ResumeMatchDetailsLayout = ({
                                      resumeMatchDetails,
                                      isOpen,
                                      onClose,
                                      name,
                                      email
                                  }) => {

    useEffect(() => {
        console.log("ResumeMatchDetailsLayout mounted", resumeMatchDetails);
    }, []);

    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const [assessmentId, setAssessmentId] = useState(localStorage.getItem("assessmentId"));
    const [candidateId, setCandidateId] = useState(localStorage.getItem("candidateId"));
    const starRating = resumeMatchDetails.match_score;
    const {match_score, average_tenure, keyword_match_score, notice_period} = resumeMatchDetails;
    const skillsArray = resumeMatchDetails.matching_skills[0].split(', ');
    const probingAreasArray = resumeMatchDetails.areas_for_further_probing.split('\n');
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({heading: "", message: ""});

    const renderStars = () => {
        const rating = parseFloat(String(starRating).replace('%', ''));
        const filledStars = Math.round((rating / 100) * 5);

        const stars = [];
        for (let i = 0; i < 5; i++) {
            if (i < filledStars) {
                stars.push(<span key={i} className="text-[#004eec]">&#9733;</span>);
            } else {
                stars.push(<span key={i} className="text-gray-300">&#9733;</span>);
            }
        }
        return stars;
    };

    const UserDetails = () => {
        return (
            <div className="flex items-center m-0 my-0 gap-x-2">
                <div className="flex flex-col gap-y-0">
                    <span className="text-xl font-bold text-slate-800 font-serif leading-[1.26rem]">{name}</span>
                </div>
                <div>
                    {renderStars()}
                </div>
            </div>
        );
    };

    const navigate = useNavigate();

    const handleContinueClick = async () => {
        if (!candidateId) {
            try {
                console.log(name);
                console.log(email);
                console.log(assessmentId);
                const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/addCandidateToVideoInterview?name=${encodeURIComponent(name)}&email=${encodeURIComponent(email)}&assessmentId=${encodeURIComponent(assessmentId)}`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
                    },
                });

                const data = await response.json();
                if (response.ok) {
                    console.log("Candidate added to video interview successfully:", data);
                    localStorage.setItem("candidateId", data.data);
                    navigate("/jobs/position/videointerviewquestions");
                } else {
                    handleModalContent(data);
                }
            } catch (error) {
                console.error("Error adding candidate to video interview:", error);
                setModalContent({
                    heading: "Error",
                    message: "An unexpected error occurred while adding the candidate. Please try again later."
                });
                setShowModal(true);
            }
        } else {
            navigate("/jobs/position/videointerviewquestions");
        }
    };

    const handleModalContent = (data) => {
        let heading = "Error";
        let message = "A candidate with this email has already been added to the interview. Please check the candidate list and try again.";
        if (data && data.message) {
            message = data.message;
        } else if (data.status === 500) {
            message = "A candidate with this email has already been added to the interview. Please check the candidate list and try again.";
        } else if (data.status === 400) {
            message = "An internal server error occurred. Please try again later.";
        }

        setModalContent({heading, message});
        setShowModal(true);
    };

    const colors = [
        'bg-[#F4F2FD] text-[#4F2EBC]',
        'bg-[#FFF1F0] text-[#E7401B]',
        'bg-[#F6F9FA] text-[#1972AF]',
        'bg-[#FEF1F6] text-[#C6274E]',
        'bg-[#FFF8EF] text-[#C28818]',
    ];
    const getRandomColorClass = () => {
        const randomIndex = Math.floor(Math.random() * colors.length);
        return colors[randomIndex];
    };

    return (
        <div
            className={`fixed top-0 ${isOpen ? "-right-0" : "-right-[72rem]"
            } transition-all duration-700 w-[60%] h-screen pt- overflow-y-scroll bg-white shadow-2xl rounded-l-2xl`}
        >
            {resumeMatchDetails && (
                <div ref={componentRef}>
                    <div
                        className="flex items-center justify-between w-full p-0 m-0 border-b-1 border-textBoxBorders shadow px-7 py-4"
                    >
                        <UserDetails name={name} email={email}/>

                        <div className="flex justify-end mr-[rem]">
                            <Button width="3rem" variant="no-outline" onClick={handlePrint}><img src={print} alt="download"/></Button>
                            <div className="mt-[0.3rem] ml-[1rem]">
                                {isOpen && (
                                    <button
                                        onClick={onClose}
                                        className="w-6 h-6 p-1 m-0 text-xs text-white bg-blue rounded-full"
                                    >
                                        X
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="grid w-full grid-cols-2 mt-8 gap-x-16 gap-y-7 px-7">
                        <div className="flex flex-col col-span-2 gap-y-4">
                            <div className="flex flex-row gap-4">
                                <div
                                    className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                                    style={{display: 'flex', alignItems: 'stretch'}}>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className="flex items-center">
                                                <div
                                                    className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                >
                                                    OVERALL MATCH SCORE
                                                </div>
                                            </div>
                                            <div
                                                className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"
                                                style={{'fontFamily': 'helvetica'}}
                                            >
                                                <span
                                                    className='py-0 mr-1 text-[0.95rem] font-bold text-[#20456E] rounded-[3px]'>
                                                    {match_score}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                                    style={{display: 'flex', alignItems: 'stretch'}}>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className="flex items-center">
                                                <div
                                                    className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                >
                                                    KEYWORD MATCH SCORE
                                                </div>
                                            </div>
                                            <div
                                                className="font-inter text-text-grey font-sans text-[0.9rem] w-[90%] -mt-3"
                                                style={{'fontFamily': 'helvetica'}}
                                            >
                                                <span
                                                    className='py-0 mr-1 text-[0.95rem] font-bold text-[#20456E] rounded-[3px]'>
                                                    {keyword_match_score}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className='w-1/3 h-[5rem] bg-[#F9F8F6] border-[0px] rounded-lg pl-4 pr-0 pt-4 pb-4 flex flex-col justify-between border-[#004EEC]'
                                    style={{display: 'flex', alignItems: 'stretch'}}>
                                    <div className="flex justify-between items-center">
                                        <div>
                                            <div className="flex items-center">
                                                <div
                                                    className="text-[#33363C] text-[0.85rem] font-semibold tracking-[0.5px]"
                                                >
                                                    AVERAGE TENURE
                                                </div>
                                            </div>
                                            <div
                                                className="font-inter text-text-grey font-sans text-[0.9rem] -mt-3"
                                                style={{'fontFamily': 'helvetica'}}
                                            >
                                                <span
                                                    className='py-0 mr-1 text-[0.95rem] font-bold text-[#20456E] rounded-[3px]'>
                                                    {average_tenure}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex flex-col w-full gap-x-2 my-2">
                                <h3 className="w-[auto] mb-2 font-bold font-serif text-charcoal text-md">
                                    Matching Keywords:
                                </h3>
                                <div className="flex flex-wrap w-full">
                                    {skillsArray.map((skill, index) => (
                                        <span
                                            key={index}
                                            className={`inline-block px-2 py-1 mr-1 text-xs font-bold bg-[#EDF6FF] text-[#004EEC] rounded-[3px] gap-3 mb-[0.55rem]`}
                                        >
                                            {skill}
                                        </span>
                                    ))}
                                </div>
                                <div>
                                    <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                                        Summary
                                    </h3>
                                    <p className="w-full text-s text-black" style={{
                                        fontFamily: "helvetica",
                                        fontWeight: '200 !important'
                                    }}>{resumeMatchDetails.summary}</p>
                                </div>

                                <div>
                                    <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                                        Assessment
                                    </h3>
                                    <p className="w-full text-s text-black" style={{
                                        fontFamily: "helvetica",
                                        fontWeight: '200 !important'
                                    }}>{resumeMatchDetails.assessment}</p>
                                </div>

                                <div>
                                    <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                                        Recommendations
                                    </h3>
                                    <p className="w-full text-s text-black" style={{
                                        fontFamily: "helvetica",
                                        fontWeight: '200 !important'
                                    }}>{resumeMatchDetails.recommendations}</p>
                                </div>

                                <div>
                                    <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                                        Role specific experience
                                    </h3>
                                    <p className="w-full text-s text-black" style={{
                                        fontFamily: "helvetica",
                                        fontWeight: '200 !important'
                                    }}>{resumeMatchDetails.role_specific_experience}</p>
                                </div>

                                <div>
                                    <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                                        Highest education
                                    </h3>
                                    <p className="w-full text-s text-black" style={{
                                        fontFamily: "helvetica",
                                        fontWeight: '200 !important'
                                    }}>{resumeMatchDetails.educational_alignment}</p>
                                </div>
                            </div>
                            {Object.keys(resumeMatchDetails.details).map((key, index) => (
                                <div className="flex flex-col w-full" key={index}>
                                    <h3 className="w-full mb-1 font-bold font-serif text-charcoal text-md">
                                        {(key.charAt(0).toUpperCase() + key.slice(1)).replaceAll("_", " ")}
                                    </h3>
                                    {Object.keys(resumeMatchDetails.details[key]).map(
                                        (subKey, subIndex) => (
                                            <div className={`flex flex-row w-full gap-x-2 mb-1.5`} key={subIndex}>
                                                {subKey === 'candidate' ? (
                                                    <p className="w-full text-s text-black" style={{
                                                        fontFamily: "helvetica",
                                                        fontWeight: '200 !important'
                                                    }}>
                                                        {resumeMatchDetails.details[key][subKey]}
                                                    </p>
                                                ) : (
                                                    <span
                                                        className={`py-1 rounded-[3px] ${subKey === 'candidate' ? '' : 'text-blue text-s'}`}
                                                        style={{
                                                            fontFamily: "helvetica",
                                                            fontWeight: '200 !important'
                                                        }}>
                                                        {resumeMatchDetails.details[key][subKey]}
                                                    </span>
                                                )}
                                            </div>
                                        )
                                    )}
                                </div>
                            ))}
                            <div>
                                <h3 className="w-full mt-4 mb-1 font-bold font-serif text-charcoal text-md">
                                    Areas for further probing
                                </h3>
                                <div className="text-s text-black" style={{
                                    fontFamily: "helvetica",
                                    fontWeight: '200 !important'
                                }}>
                                    {probingAreasArray.map((line, index) => (
                                        <p key={index}>{line}</p>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col col-span-2 gap-y-4">
                            {resumeMatchDetails.match_score < 50 && (
                                <span className="flex items-center w-full font-semibold text-red-600 text-md">
                                    So, the overall resume match score for the provided JD and
                                    Resume is {"<"} 50%
                                </span>
                            )}
                            <div className="flex items-center w-full px-2 py-3 bg-[#EDF6FF] rounded-md">
                                <img src={alertTriangle} className="w-6 mx-2" alt="info"/>
                                <div className="w-full">
                                    <p className="mx-2 text-sm text-blue">
                                        Note that the Resume Match Score is an AI-powered tool. While helpful, it
                                        shouldn't replace thorough candidate review and consideration of the position's
                                        unique needs.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="flex col-span-2 gap-4 mb-8">
                            <Button
                                variant='white'
                                onClick={onClose}
                                width="10rem"
                                className="h-[2rem] text-[0.725rem]"
                                label="REJECT"
                            >
                            </Button>
                            <Button
                                label="CONTINUE"
                                width="10rem"
                                variant='bright-blue'
                                className="h-[2rem] text-[0.725rem]"
                                onClick={handleContinueClick}
                            >
                            </Button>
                        </div>
                    </div>
                </div>
            )}
            {showModal && (
                <Modal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={() => setShowModal(false)}
                    heading={modalContent.heading}
                    message={"A candidate with this email has already been added to the interview. Please check the candidate list and try again."}
                    confirmButtonText="OK"
                />
            )}
        </div>
    );
};

export default ResumeMatchDetailsLayout;