// src/redux/reducers/forgotPasswordReducer.js

import { SAVE_EMAIL } from '../actions/ForgotPasswordActions';

const initialState = {
    email: ''
};

const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EMAIL:
            return {
                ...state,
                email: action.payload
            };
        // handle other actions
        default:
            return state;
    }
};

export default forgotPasswordReducer;
