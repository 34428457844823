import React, {useEffect, useState} from "react";
import {Button} from "../../../components/button";
import McqOption from "../../DashBoard/ManagerView/McqOption";
import {useDispatch, useSelector} from "react-redux";
import {removeMcqAnswer, setMcqAnswer} from "../../../../redux/reducers/mcqSlice";


export default function CandidateMCQ({question, onNextQuestion}) {

    const questionText = question.questionDataAsJson.text;
    const options = question.questionDataAsJson.options;
    const [submitloading, setSubmitloading] = useState(false);

    const dispatch = useDispatch();
    const selectedOptionId = useSelector(state => state.mcqAnswers[question.questionId]);

    const handleOptionSelect = (optionId) => {
        dispatch(setMcqAnswer({questionId: question.questionId, selectedOptionId: optionId}));
    };

    const handleClear = () => {
        console.log("Clear button clicked")
        dispatch(removeMcqAnswer({questionId: question.questionId}));
    }

    const token = localStorage.getItem("token");
    const candidateId = localStorage.getItem("candidateId");

    useEffect(() => {
        if (selectedOptionId) {
            dispatch(setMcqAnswer({questionId: question.questionId, selectedOptionId}));
        }
    }, [dispatch, question.questionId, selectedOptionId]);


    // Remove below code once finished
    const reduxVar = useSelector(state => state);
    useEffect(() => {
        // Log Redux store contents when the component mounts or when the Redux store changes
        console.log("Redux Store Contents:", reduxVar);
    }, [selectedOptionId]);
    // Remove above code once finished

    const submitAnswer = async () => {
        setSubmitloading(true);
        // onNextQuestion();

        const apiUrl = `${process.env.REACT_APP_BASE_URL}/api/v1/submitAnswers?candidateId=${candidateId}`; // Replace 'test' with your actual API's base URL
        const authToken = token; // Replace this with the actual auth token

        const requestBody = [
            {
                "questionId": question.questionId, // Assuming this is how you get the question ID
                "answer": {
                    "selectedOptionId": selectedOptionId // Replace with the actual selected option ID
                }
            }
        ];

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`, // Include the auth token in the request headers
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            console.log(data);
            onNextQuestion()
            // Handle successful response here, e.g., show a success message or update the UI accordingly
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            // Handle error here, e.g., show an error message
        } finally {
            setSubmitloading(false);
        }
    };

    return (
        <div className="mx-[4rem] my-[5rem] relative max-w-[80vw] min-w-[80vw]">
            <div
                className="gap-[2rem] rounded-lg shadow px-[2rem] py-[2rem] flex justify-center align-center w-[100%]">
                <div className="pr-[1rem] overflow-y-auto max-h-[70vh] w-[50%] ">
                    <h2 className="font-serif text-[1.5rem] mb-4">Question</h2>
                    <p className="font-serif" dangerouslySetInnerHTML={{__html: questionText}}/>
                </div>
                <div className="space-y-[1rem] w-[50%] max-w-[51%] max-h-[60vh] ">
                    <div className="overflow-y-scroll overflow-x-scroll" 
                     style={{
                        scrollbarWidth: "none", /* Firefox */
                        msOverflowStyle: "none", /* IE and Edge */
                        "&::-webkit-scrollbar": { display: "none" }, /* Chrome, Safari, Opera */
                    }}>
                        {options.map((option) => (
                            <McqOption
                                key={option.id}
                                label={option.text}
                                optionId={option.id}
                                isSelected={option.id === selectedOptionId}
                                onChange={handleOptionSelect}
                                className={"mr-8"}
                            />
                        ))}
                    </div>
                    <div
                        className=" flex xl:justify-end 2xl:justify-end lg:justify-start md:justify-start gap-6">
                        <Button
                            label="CLEAR"
                            width="14.781rem"
                            className='text-[0.735rem] h-[2.25rem]'
                            variant="white"
                            onClick={handleClear}
                        />
                        <Button
                            label={submitloading ? "PLEASE WAIT ..." : "SUBMIT"}
                            width="14.625rem"
                            className='text-[0.735rem] h-[2.25rem]'
                            variant="bright-blue"
                            onClick={submitAnswer}
                        />
                    </div>
                </div>
            </div>
            {/*<div className='z-0 fixed bottom-0 left-0 w-full text-center'>*/}
            {/*    <p>Powered by <img className="w-10 h-10 inline pl-2 mb-1" src={logo}></img></p>*/}
            {/*</div>*/}
        </div>

    );
}