import React, { useState, useEffect } from "react";
import logo from "../../../../assets/logo_ovii_2.svg";
import click from "../../../../assets/lilhelpers-search.svg";
import { Textarea } from "../../../components/textarea";
import ThreeButtonLoader from "../../../components/ThreeButtonLoader";
import ZipFileInput from "../../../components/ZipFileInput";
import Modal from "../../../components/popup";
import { Button } from "../../../components/button";

const NavBar = ({ onClose }) => {
    return (
        <div className="flex w-full py-3 border shadow-sm">
            <div className="w-[80%]" style={{ marginBottom: 0 }}>
                <div className="text-2xl pl-[2rem]" style={{ fontFamily: "ITC Clearface" }}>
                    <h1>Resume match</h1>
                </div>
            </div>
            <button className="w-6 h-6 p-1 absolute top-4 right-4 text-xs text-white bg-blue rounded-full" onClick={onClose}>
                X
            </button>
        </div>
    );
};

const ResumeMatchSlider = ({ onRefresh, onClose, positionId, assessmentId }) => {
    const [jobDescription, setJobDescription] = useState("");
    const [selectedFile, setSelectedFile] = useState(null);
    const [isJDLengthValid, setIsJDLengthValid] = useState(false);
    const [jdLengthError, setJdLengthError] = useState("");
    const [loading, setLoading] = useState(false);
    const accessToken = localStorage.getItem("accessToken")
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [JDSubmitloading, setJDSubmitloading] = useState(false);

    useEffect(() => {
        console.log('Updated selected file:', selectedFile);
    }, [selectedFile]);

    useEffect(() => {
        const fetchJD = async () => {
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/getJobDescription?positionId=${positionId}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                );
                const data = await response.json();
                setJobDescription(data.data || "");
                console.log("JD", data.data);
                // setIsJdPrefilled(true);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchJD();
    }, []);

    const handleJDSubmit = async () => {
        if (jobDescription != null) {
            setJDSubmitloading(true);
            try {
                const response = await fetch(
                    `${process.env.REACT_APP_BASE_URL}/api/v1/saveJobDescription?positionId=${positionId}`,
                    {
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                        body: jobDescription,
                    }
                );
                const data = await response.json();
            } catch (error) {
                console.error("Network error:", error);
            } finally {
                setJDSubmitloading(false);
            }
        }
    };

    const handleJobDescriptionChange = (e) => {
        const newValue = e.target.value;
        setJobDescription(newValue);
        setIsJDLengthValid(newValue.length >= 50);
        if (newValue.length < 50) {
            setJdLengthError("Job description must be a minimum of 50 characters");
        } else {
            setJdLengthError("");
        }
        const savedDescriptions = JSON.parse(localStorage.getItem("jobDescriptions") || "{}");
        savedDescriptions[positionId] = newValue;
        localStorage.setItem("jobDescriptions", JSON.stringify(savedDescriptions));
    };

    const handleFileSelect = (file) => {
        setSelectedFile(file);
    };
    
    const handleSubmit = async () => {
        if (!selectedFile || !isJDLengthValid) {
            alert('Please ensure the job description is valid and a ZIP file is selected.');
            return;
        }
    
        setLoading(true);
    
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('positionId', positionId);
        formData.append('assessmentId', assessmentId);
        console.log("body", formData)
    
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/uploadZipAndGetResumeMatch`, 
                {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
                body: formData
            });
    
            if (response.ok) {
                onClose();
                onRefresh();
            } else {
                console.error('API request failed:', response.statusText);
                // alert('Failed to submit data. Please try again.');
                setModalContent({
                    heading: 'Error',
                    message: `Failed to submit data. Please try again.`,
                    confirmButtonText: 'OK',
                    onConfirm: setShowModal(false),
                })
                setShowModal(true);
            }
        } catch (error) {
            console.error('API request error:', error);
            // alert('An error occurred while submitting the data.');
            setModalContent({
                heading: 'Error',
                message: `An error occurred while submitting the data.`,
                confirmButtonText: 'OK',
                onConfirm: setShowModal(false),
            })
            setShowModal(true);
        } finally {
            setLoading(false);
        }
    };


    return (
        <>
            {loading && (
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-white bg-opacity-75 z-50">
                    <ThreeButtonLoader />
                </div>
            )}
            <div className="rounded-md fixed right-0 top-0 h-full w-[45%] bg-white shadow-lg z-20">
                <NavBar onClose={onClose} />
                <div className="rounded-md flex items-center justify-center pt-3 mx-10">
                    <div className="w-full gap-6">
                        <div className="w-full">
                            <Textarea
                                label={(
                                    <label className="flex items-center">
                                        Job Description <span className="text-red-500">*</span>
                                    </label>
                                )}
                                id="job_description"
                                placeholder="Please paste the job description here."
                                disabled={loading}
                                onChange={handleJobDescriptionChange}
                                value={jobDescription}
                                name="job_description"
                                rows="6"
                                widthOverride="100%"
                                heightOverride="8rem"
                                required={true}
                                textareaClassName="w-full text-sm text-gray-700"
                                style={{ fontFamily: "helvetica", fontWeight: "200 !important" }}
                            />
                            {jdLengthError && (
                                <span className="text-red-600 mb-2">{jdLengthError}</span>
                            )}
                            <Button
                                variant='bright-blue'
                                className="h-[2rem] flex-start mt-4 mb-[2rem] cursor-pointer"
                                label={JDSubmitloading ? "PLEASE WAIT ..." : "SAVE"}
                                onClick={handleJDSubmit}
                                disabled={JDSubmitloading || !isJDLengthValid}
                                width="8.625rem"
                            />
                            <label className="flex items-center font-bold text-sm mb-[0.5rem]">
                                Upload Zip file: <span className="text-red-500">*</span>
                            </label>
                            <ZipFileInput onFileSelect={handleFileSelect} />
                            <button
                                type="button"
                                onClick={handleSubmit}
                                className="w-[7rem] h-[2.25rem] px-4 mt-[1rem] text-blue text-sm bg-white rounded-m border border-blue"
                            >
                                SUBMIT
                            </button>
                            {/* <div className="flex">
                                <div className="flex justify-center -mt-6 ml-[-1rem]">
                                    <img src={click} alt="man clicking on his phone" className="w-[40rem]" />
                                </div>
                                <div>
                                    <h2 className="mt-[2rem] text-[1.25rem] itc-fontFamily">Let the Matching Begin!</h2>
                                    <p className="mb-0 mt-3 w-[100%] text-[0.9rem] font-medium text-charcoal text-justify">
                                        Simply provide the job description, and our AI engine will work its magic.
                                        It expertly matches the job description with the candidate's resume, giving you a clear insight
                                        into how well the candidate aligns with the role.
                                    </p>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <Modal
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    onConfirm={()=> setShowModal(false)}
                    heading={modalContent.heading}
                    message={modalContent.message}
                    confirmButtonText={modalContent.confirmButtonText}
                />
            </div>
        </>
    );
};

export default ResumeMatchSlider;
