import React, {useEffect, useState} from "react";
import LineTabs from "../../../components/LineTabs";
import ErrorComponent from "../../../components/ErrorComponent";
import {Button} from "../../../components/button";
import {useDispatch, useSelector} from "react-redux";
import {
    addQuestionsToAssesments,
    getAssesmentsForPositions,
    getAssesmentsQuestions
} from "../../../../redux/actions/positionActions";
import AssessmentQuestionWidget from "./QuestionsWidget";
import {useNavigate} from "react-router-dom";
import CandidateTable from "../../../components/candidateTable";
import InviteCandidates from "./InviteCandidates";
import LoadingOverlay from "../../../components/loadingOverlay";
import Modal from "../../../components/popup";
import ResumeMatchSlider from "./resumeMatchSlider";
import ResumeMatchTable from "../../../components/ResumeMatchTable";

function PositionDetails() {

    const accessToken = localStorage.getItem("accessToken")
    const assesmentsForPosition = useSelector((state) => state?.position?.assesmentsForPosition);
    // const positionId = useSelector((state) => state.positionDetails.positionId);
    const positionId = localStorage.getItem('PosID');
    const assesmentQuestions = useSelector((state) => state?.position?.assesmentQuestions)
    const includeSkillAssesment = assesmentsForPosition?.some((item) => item?.typeID === 1);
    const includeVideoInterview = assesmentsForPosition?.some((item) => item?.typeID === 2);
    const [showPopup, setShowPopup] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [isQuestionsLoading, setIsQuestionsLoading] = useState(false);
    const [positionStatus, setPositionStatus] = useState(useSelector((state) => state.positionStatus.positionStatus));
    const [skillAssessmentCandidates, setSkillAssessmentCandidates] = useState([]);
    const [videoInterviewCandidates, setVideoInterviewCandidates] = useState([]);
    const [isCandidatesLoading, setIsCandidatesLoading] = useState(false);
    const [hasCandidates, setHasCandidates] = useState(false);
    const [videoCandidates, setVideoCandidates] = useState([]);
    const [hasVideoCandidates, setHasVideoCandidates] = useState(true);
    const [showResumeSlider, setShowResumeSlider] = useState(false);
    const [isResumeLoading, setIsResumeLoading] = useState(false);

    const getAssesmentIdforTypeId0 = assesmentsForPosition?.find((item) => item?.typeID === 0)
    const getAssesmentIdforTypeId1 = assesmentsForPosition?.find((item) => item?.typeID === 1)
    const getAssesmentIdforTypeId2 = assesmentsForPosition?.find((item) => item?.typeID === 2)

    const [resumeMatchCandidates, setResumeMatchCandidates] = useState([]);
    const [error, setError] = useState(false);

    const handleModalConfirm = () => {
        setShowModal(false)
    }

    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const dispatch = useDispatch();


    useEffect(() => {
        console.log("getAssesmentIdforTypeId2", getAssesmentIdforTypeId2)
    }, [getAssesmentIdforTypeId2])

    localStorage.setItem("assessmentId", getAssesmentIdforTypeId2?.id || 0)
    localStorage.setItem("skillAssessmentId", getAssesmentIdforTypeId1?.id || 0)


    const [isActiveLineTab, setIsActiveLineTab] = useState({
        1: true,
        2: false,
        3: false
    });

    const [isActiveBlockTabs, setIsActiveBlockTabs] = useState({
        1: true,
        2: false
    })

    const [activeBlockTab, setActiveBlockTab] = useState(1);

    const handleBlockTabChange = (key) => {
        setActiveBlockTab(key);
        setIsActiveBlockTabs({[key]: true});
    }

    const [selectedQuestions, setSelectedQuestions] = useState(assesmentQuestions?.map(qn => qn.questionId));

    const tabConfig = [
        {
            key: 1,
            tabName: "Resume Match",
            isActive: isActiveLineTab[1],
        },
        {
            key: 2,
            tabName: "Skill assesment",
            isActive: isActiveLineTab[2],
        },
        {
            key: 3,
            tabName: "Async video interview",
            isActive: isActiveLineTab[3]
        }
    ];

    const blockTabsConfig = [
        {
            key: 1,
            tabName: isQuestionsLoading ? "PLEASE WAIT..." : "QUESTIONS",
            isActive: isActiveBlockTabs[1],
        },
        {
            key: 2,
            tabName: isCandidatesLoading ? "PLEASE WAIT..." : "CANDIDATES",
            isActive: isActiveBlockTabs[2],
        }
    ]

    const handleTabChange = (key) => {
        setIsActiveLineTab({[key]: true});
    }

    const handleSelectedQuestionsChange = (candidates) => {
        setSelectedQuestions(candidates);
        dispatch(addQuestionsToAssesments({
            assessmentId: getAssesmentIdforTypeId1?.id,
            questionIds: candidates,
            accessToken: accessToken
        }))
    };
    const fetchAssesmentsForPositions = (positionId) => {
        setIsLoading(true)
        dispatch(getAssesmentsForPositions({positionId: positionId, accessToken: accessToken})).finally(() => {
            setIsLoading(false);
            console.log("position Id:", positionId)
        })
    }

    const fetchAssesmentQuestions = async () => {
        setIsQuestionsLoading(true)
        const assessmentId = getAssesmentIdforTypeId1?.id;
        if (!assessmentId) return;
        await (dispatch(getAssesmentsQuestions({assesmentId: assessmentId, accessToken: accessToken})));
        setIsQuestionsLoading(false)
    }

    const transformedQuestions = assesmentQuestions?.map(item => {
        const questionData = item.questionDataAsJson;
        return {
            id: item.questionId,
            name: questionData.title ?? 'No title',
            description: questionData.text?.replace(/<[^>]+>/g, '') ?? '',
            popupDescription: questionData.text ?? '',// Safely attempt to replace, default to empty string if text is missing
            problemStatement: questionData.problemStatement?.replace(/<[^>]+>/g, '') ?? '',
            popupProblemStatement: questionData.problemStatement ?? '',// For subjective/code questions
            timeLimit: typeof questionData.solveTime === 'number' && !isNaN(questionData.solveTime)
                ? (questionData.solveTime / 60)
                : 0,
            typeID: item.questionType.questionTypeId,
            tags: questionData.tags ? questionData.tags.split(', ') : [], // Split string into an array by commas
            difficulty: item.difficultyId.level ?? 'EASY',
            answerExplanation: questionData.answerExplanation ?? '', // For subjective/code questions
            options: questionData.options?.map(option => ({
                id: option.id,
                text: option.text,
                label: option.label,
            })) ?? [],
            correctOptionId: questionData.correctAnswerId ?? null, // Assuming the ID is stored directly under the correctAnswerId property
            attributesTested: questionData.attributesTested ?? "",
            expectedAnswer: questionData.expectedAnswer ?? '', // For subjective/code questions
            inputFormat: questionData.inputFormat ?? '', // For subjective/code questions
            outputFormat: questionData.outputFormat ?? '', // For subjective/code questions
            constraints: questionData.constraints ?? '', // For subjective/code questions
            sampleInput: questionData.sampleInput ?? '', // For subjective/code questions
            sampleOutput: questionData.sampleOutput ?? '', // For subjective/code questions
        };
    });

    const totalTime = transformedQuestions?.reduce((acc, item) => acc + item.timeLimit, 0);


    const [invitationSent, setInvitationSent] = useState(false);
    const handleInvitationSent = () => {
        setInvitationSent(prev => !prev); // Toggle to ensure a change even if invitations are sent multiple times
    };

    const fetchCandidates = async () => {
        setIsCandidatesLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/getCandidatesForAssessment?assessmentId=${getAssesmentIdforTypeId1?.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data && data.status === 200) {
                setSkillAssessmentCandidates(data.data);
                setHasCandidates(data.data.length > 0);

            } else {
                // console.error("Error fetching candidates:", data.message);
                setModalContent({
                    heading: 'Error',
                    message: 'Error fetching candidates',
                    confirmButtonText: 'OK',
                });
                setShowModal(true);
                setHasCandidates(false);
                setSkillAssessmentCandidates([]);
            }
        } catch (error) {
            // console.error("Error fetching candidates:", error);
            setModalContent({
                heading: 'Error',
                message: 'Error fetching candidates',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
            setHasCandidates(false);
            setSkillAssessmentCandidates([]);
        } finally {
            setIsCandidatesLoading(false);
        }
    };


    const fetchCandidatesForVideoInterview = async () => {
        setIsCandidatesLoading(true);
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/getCandidatesForAssessment?assessmentId=${getAssesmentIdforTypeId2?.id}`,
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );
            const data = await response.json();
            if (data && data.status === 200) {
                setVideoInterviewCandidates(data.data);
                setHasCandidates(data.data.length > 0);

            } else {
                // console.error("Error fetching candidates:", data.message);
                setModalContent({
                    heading: 'Error',
                    message: 'Error fetching candidates',
                    confirmButtonText: 'OK',
                });
                setShowModal(true);
                setHasVideoCandidates(false);
                setVideoCandidates([]);
            }
        } catch (error) {
            // console.error("Error fetching candidates:", error);
            setModalContent({
                heading: 'Error',
                message: 'Error fetching candidates',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
            setHasCandidates(false);
            setVideoCandidates([]);
        } finally {
            setIsCandidatesLoading(false);
        }
    };


    const navigateToAddQuestionPage = (event) => {
        event.stopPropagation();
        event.preventDefault();
        if (positionStatus === 2 || positionStatus === 3) {
            setModalContent({
                heading: 'Position closed',
                message: 'You cannot add questions to a closed or archived position.',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        } else if (hasCandidates) {
            setModalContent({
                heading: 'You already have candidates',
                message: 'You cannot add questions to an assessment that already has candidates. Please create a new assessment to add questions.',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        } else {
            navigate('/jobs/position/AddQuestionPage');
        }
    };

    const handleRefresh = () => {
        fetchResumeMatchScores();
        if (getAssesmentIdforTypeId1) {
            fetchCandidates();
        }
        if (getAssesmentIdforTypeId2) {
            fetchCandidatesForVideoInterview();
        }
    };

    useEffect(() => {
        handleRefresh();
    }, [getAssesmentIdforTypeId1, getAssesmentIdforTypeId2, accessToken, setHasCandidates, invitationSent]);

    useEffect(() => {
        fetchAssesmentQuestions();
    }, [getAssesmentIdforTypeId1])

    useEffect(() => {
        fetchAssesmentsForPositions(positionId);
    }, [positionId]);

    useEffect(() => {
        fetchResumeMatchScores();
    }, [getAssesmentIdforTypeId0, accessToken]);

    const fetchResumeMatchScores = async () => {
        setIsResumeLoading(true);
        console.log("loading is true")
        try {
            const response = await fetch(
                `${process.env.REACT_APP_BASE_URL}/api/v1/getResumeMatchForPosition?assessmentId=${getAssesmentIdforTypeId0.id}`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            );

            if (!response.ok) {
                setIsResumeLoading(false);
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const responseData = await response.json();

            if (responseData.status === 200 && responseData.data) {
                setResumeMatchCandidates(responseData.data);
                setIsResumeLoading(false);
            } else {
                console.error('Unexpected response structure', responseData);
                setIsResumeLoading(false);
            }
        } catch (error) {
            console.error('Error fetching resume match scores:', error);
            setIsResumeLoading(false);
        }
    };

    const handleInviteCandidates = (event) => {
        event.stopPropagation();
        if (positionStatus === 2 || positionStatus === 3) {
            setModalContent({
                heading: 'Position closed',
                message: 'You cannot invite candidates to a closed or archived position. Please open the position to invite candidates.',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        } else if (transformedQuestions.length === 0) {
            setModalContent({
                heading: 'No Questions Available',
                message: 'Please add questions to the assessment to continue. Click the "Add Questions" button to add questions.',
                confirmButtonText: 'OK',
            });
            setShowModal(true);
        } else {
            setShowPopup(true)
        }
    };

    const handleStatusUpdate = async (positionId, statusId) => {
        try {
            setIsLoading(true)
            const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v1/updatePositionStatus?positionId=${positionId}&statusId=${statusId}`, {
                method: "PUT",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-Type": "application/json",
                },
            });

            // To-do: Handle error response
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
            setPositionStatus(statusId);
            setShowModal(true);
            setModalContent({
                heading: 'Success',
                message: 'Position status updated successfully.',
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            })
        } catch (error) {
            // console.error("Error updating position status:", error);
            setShowModal(true);
            setModalContent({
                heading: 'Error',
                message: 'Failed to update position status.', // Display specific error message
                confirmButtonText: 'OK',
                onConfirm: () => setShowModal(false),
            });
        } finally {
            setIsLoading(false);
        }
    };

    if (isLoading) {
        return <LoadingOverlay/>
    }

    const handleResumeMatch = () => {
        // console.log("positionId", positionId)
        localStorage.removeItem("candidateId")
        localStorage.removeItem("positionId")
        localStorage.setItem("positionId", positionId)
        localStorage.removeItem("assessmentId")
        localStorage.setItem("assessmentId", getAssesmentIdforTypeId2?.id || 0)
        localStorage.removeItem("name")
        localStorage.removeItem("email")
        window.open(`/resumeMatch`, "_blank");
    }


    return (
        <div className="h-[19rem]">
            {/* {isLoading && <LoadingOverlay/>}
            {isQuestionsLoading && <LoadingOverlay/>} */}
            {showModal && (
                <Modal
                    isOpen={showModal}
                    onClose={handleModalConfirm}
                    onConfirm={handleModalConfirm}
                    heading={modalContent.heading}
                    message={modalContent.message}
                    confirmButtonText={modalContent.confirmButtonText}
                />
            )}
            <div className="flex flex-row justify-between ">
                <div className="flex justify-between items-start">
                    <LineTabs handleTabChange={handleTabChange} tabConfig={tabConfig}/>
                </div>
                {isActiveLineTab[1] && (
                    <div>
                        <Button label="Add Resumes" variant="bright-blue"
                                onClick={() => setShowResumeSlider(true)} width="8rem"/>
                    </div>
                )}
                {isActiveLineTab[2] && (
                    <div
                        className="flex xl:flex-row 2xl:flex-row lg:flex-col md:flex-col justify-end 2xl:space-x-2 2xl:space-y-0 xl:space-x-2 xl:space-y-0 lg:space-x-0 lg:space-y-2 md:space-x-0 md:space-y-2">
                        <div>
                            {activeBlockTab === 1 && includeSkillAssesment && (
                                <>
                                    <Button variant='bright-blue' label="ADD QUESTIONS" width="8rem"
                                            className="!w-fit px-4 right-[13rem] h-[2rem] text-[0.725rem] top-[6rem]"
                                            onClick={navigateToAddQuestionPage}/>
                                </>
                            )}
                            {activeBlockTab === 2 && includeSkillAssesment && (
                                <Button variant='bright-blue' label="INVITE CANDIDATES" width="8rem"
                                        className="!w-fit px-6 h-[2rem] text-[0.725rem] right-[13rem] top-[6rem]"
                                        onClick={handleInviteCandidates}/>
                            )}
                        </div>
                        <div>
                            <select
                                value={positionStatus}
                                onChange={(e) =>
                                    handleStatusUpdate(positionId, e.target.value)}
                                className="pl-5 pr-10 py-1 appearance-none rounded-sm h-[2.25rem] border-[1px] border-[#004eec] bg-[0px] text-[0.75rem] text-[#004eec] shadow-md focus:outline-none focus:border-blue-500"
                            >
                                <option value="1">OPEN</option>
                                <option value="2">CLOSED</option>
                                <option value="3">ARCHIVED</option>
                            </select>
                        </div>
                    </div>
                )}
                {isActiveLineTab[3] && (
                    !getAssesmentIdforTypeId1 && getAssesmentIdforTypeId2 &&
                    <Button variant='bright-blue' label="INVITE CANDIDATES" width="10rem" onClick={handleResumeMatch}/>
                    // <Button variant='bright-blue' label="INVITE CANDIDATES" width="8rem"
                    //         className="!w-fit px-4 h-[2rem] text-[0.725rem] right-[13rem] top-[6rem]"
                    //         onClick={handleResumeMatch}
                    // />
                )}
            </div>
            {isActiveLineTab[1] && (
                // <div>
                //     <div>
                //         {resumeMatchCandidates.length > 0  ? (
                //             <ResumeMatchTable candidates={resumeMatchCandidates} 
                //                               assessmentIdForResumeMatch={getAssesmentIdforTypeId0?.id}
                //                               assessmentIdForSkillAssessment={getAssesmentIdforTypeId1?.id} 
                //                               assessmentIdForVideoInterview={getAssesmentIdforTypeId2?.id} 
                //                               questionsExist={transformedQuestions} 
                //                               skillAssessmentExists={includeSkillAssesment} 
                //                               videoInterviewExists={includeVideoInterview}
                //                               onRefresh={handleRefresh} />
                //         ) : (
                //             <ErrorComponent content="Add resumes and JD to show resume match scores" />
                //         )}
                //         {showResumeSlider && (
                //             <ResumeMatchSlider 
                //                 onRefresh={handleRefresh}
                //                 positionId={positionId}
                //                 assessmentId={getAssesmentIdforTypeId0.id}
                //                 onClose={() => setShowResumeSlider(false)} 
                //             />
                //         )}
                //     </div>
                // </div>
                <div>
                    {isResumeLoading ? (<LoadingOverlay/>) : (
                        !getAssesmentIdforTypeId0 ? (
                            <div>
                                <ErrorComponent
                                    content="Resume match is unavailable for positions added before October 1st. To use the resume match feature please create a new position."/>
                            </div>) : (
                            <div>
                                {resumeMatchCandidates.length > 0 ? (
                                    <ResumeMatchTable candidates={resumeMatchCandidates}
                                                      assessmentIdForResumeMatch={getAssesmentIdforTypeId0?.id}
                                                      assessmentIdForSkillAssessment={getAssesmentIdforTypeId1?.id}
                                                      assessmentIdForVideoInterview={getAssesmentIdforTypeId2?.id}
                                                      questionsExist={transformedQuestions}
                                                      skillAssessmentExists={includeSkillAssesment}
                                                      videoInterviewExists={includeVideoInterview}
                                                      onRefresh={handleRefresh}/>
                                ) : (
                                    <ErrorComponent content="Add resumes and JD to show resume match scores"/>
                                )}

                                {showResumeSlider && (
                                    <ResumeMatchSlider
                                        onRefresh={handleRefresh}
                                        positionId={positionId}
                                        assessmentId={getAssesmentIdforTypeId0.id}
                                        onClose={() => setShowResumeSlider(false)}
                                    />
                                )}
                            </div>
                        ))}
                </div>
            )}
            {isActiveLineTab[2] && (
                <div>
                    {assesmentsForPosition?.length && includeSkillAssesment ? (
                        <div className="">
                            <div className="flex justify-between items-center gap-2">
                                <div className="flex justify-start items-center gap-2">
                                    {blockTabsConfig.map((tab) => (
                                        <div
                                            className={`border cursor-pointer border-b-0 p-2 px-4 rounded-t text-[0.75rem] mt-8 ${
                                                tab?.isActive ? 'bg-blue-700 text-white' : 'text-blue-700 border-b-0'
                                            }`}
                                            key={tab?.key}
                                            onClick={() => handleBlockTabChange(tab?.key)}
                                        >
                                            {tab?.tabName}
                                        </div>
                                    ))}
                                </div>
                                {activeBlockTab === 1 ? (
                                    <div className='font-sans text-[#004eec] text-[0.9rem] mt-8 mr-4 font-bold'>
                                        Expected solve time: <span
                                        className='text-[#004eec]'>{totalTime ?? 0} mins</span>
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                            <div
                                className="border p- -mt-8 max-h-[70vh] z-0 rounded-b-lg rounded-r-lg  overflow-y-scroll"
                                style={{
                                    scrollbarWidth: "none", /* Firefox */
                                    msOverflowStyle: "none", /* IE and Edge */
                                    "&::-webkit-scrollbar": {display: "none"}, /* Chrome, Safari, Opera */
                                }}>
                                {isActiveBlockTabs[1] ? (
                                    transformedQuestions?.length ? (
                                        <div style={{
                                            scrollbarWidth: "none", /* Firefox */
                                            msOverflowStyle: "none", /* IE and Edge */
                                            "&::-webkit-scrollbar": {display: "none"}, /* Chrome, Safari, Opera */
                                        }}
                                             className="py-2 px-5 max-h-[65vh] w-full">

                                            <AssessmentQuestionWidget
                                                questions={transformedQuestions}
                                                onSelectedQuestionsChange={handleSelectedQuestionsChange}
                                                _selectedQuestions={selectedQuestions}
                                                showButtons={false}
                                                showDetails={true}
                                                clickable={true} // Set clickable to false here
                                                usePagination={false}
                                            />
                                        </div>
                                    ) : (
                                        // Invite candidates to take your test and you'll see a breakdown of their performance across skills, time management and their attempts during the test
                                        <div>
                                            <ErrorComponent
                                                content="Elevate the assessment by adding questions tailored to the role. Click the 'Add question' button above to begin"/>
                                        </div>
                                    )
                                ) : (
                                    <CandidateTable candidates={skillAssessmentCandidates}
                                                    isLoading={isCandidatesLoading}
                                                    tabType={2}
                                                    onRefresh={handleRefresh}/>
                                )}
                            </div>
                        </div>
                    ) : !includeSkillAssesment ? (
                        <ErrorComponent
                            content="This position does not include a skill assessment. Candidates will proceed directly to the async video interview. To add a skill assessment to future positions, select it during position setup."/>
                    ) : (
                        <div className="pb-[80rem]">
                            <ErrorComponent
                                content="Elevate the assessment by adding questions tailored to the role. Click the 'Add question' button above to begin"/>
                        </div>
                    )}
                    {showPopup && <InviteCandidates onClose={() => setShowPopup(false)}
                                                    assessmentId={getAssesmentIdforTypeId1?.id}
                                                    onInvitationSent={handleInvitationSent}/>}
                </div>
            )}
            {isActiveLineTab[3] && (
                <div>
                    {assesmentsForPosition?.some(item => item.typeID === 2) ? (
                        <div>
                            {videoInterviewCandidates ? (
                                <div>
                                    <div>
                                        <div className="flex justify-between w-[100%]">
                                            <div
                                                className="border-r border-l border-t rounded-t-m flex justify-center items-center font-sans text-blue p-2 px-4 rounded-t text-[0.75rem] mt-8">CANDIDATES
                                            </div>
                                        </div>
                                        <div
                                            className="w-[100%] border p- border-gray mt-[-1rem] rounded-b-lg rounded-r-lg">
                                            <CandidateTable candidates={videoInterviewCandidates}
                                                            assesmentId={getAssesmentIdforTypeId2?.id}
                                                            isLoading={isCandidatesLoading} tabType={3}
                                                            onRefresh={handleRefresh}/>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <ErrorComponent content="Invite candidates to take the video interview."/>
                                </div>
                            )}
                        </div>
                    ) : (
                        <ErrorComponent
                            content="Please note that this position does not include an asynchronous video interview. Please add it next time."/>
                    )}
                </div>
            )}
        </div>
    );
}

export default PositionDetails;