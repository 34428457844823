// rootReducer.js

import {combineReducers} from "redux";
import otpReducer from "../authSlice";
import authReducer from "./authReducer";
import userManagementReducer from "./userManagementReducer";
import forgotPasswordReducer from "./ForgotPasswordReducer";
import userReducer from "./userReducer";
import taxReducer from "./taxDropdownReducer";
import positionReducer from "./positionReducer";
import {positionDetailsReducer, positionStatusReducer} from "./positionDetailsReducer";
import proctoringReducer from "./proctoringReducer";
import {answersSlice} from "./answersSlice";
import {codeAnswersSlice} from "./codeAnswersSlice";
import {mcqAnswersSlice} from "./mcqSlice";
import videoReducer from "./videoReducer";
import {teamReducer} from "./teamReducer";
import {videoInterviewCreationsSlice} from "./videoInterviewCreationsSlice";
import editorContentReducer from "./editorContentReducer";
import {candidateStatusSlice} from "./candidateStatusSlice";
import {urlSlice} from "./urlSlice";

const rootReducer = combineReducers({
    otp: otpReducer,
    auth: authReducer,
    userManagement: userManagementReducer,
    forgotPassword: forgotPasswordReducer,
    user: userReducer,
    taxReducer: taxReducer,
    position: positionReducer,
    positionDetails: positionDetailsReducer,
    proctoring: proctoringReducer,
    answers: answersSlice.reducer,
    codeAnswers: codeAnswersSlice.reducer,
    mcqAnswers: mcqAnswersSlice.reducer,
    video: videoReducer,
    team: teamReducer,
    positionStatus: positionStatusReducer,
    videoInterviewCreation: videoInterviewCreationsSlice.reducer,
    editorContentReducer: editorContentReducer,
    candidateStatus: candidateStatusSlice.reducer,
    urlReducer: urlSlice.reducer,
});

export default rootReducer;
