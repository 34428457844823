import React from "react";
import {BrowserRouter as Router, Navigate, Route, Routes,} from "react-router-dom";
import WebcamStreamCapture from "./components/mainContent/VideoInterview/video.jsx";
import DevicePermissionsTester from "./components/mainContent/VideoInterview/test.jsx";
import CodeEditor from "./components/mainContent/writtenTest/codeEditor.jsx";
import {Login} from "./components/auth/Login/login.jsx";
import WyswygComponent from "./components/mainContent/writtenTest/wyswyg/wyswygComponent.jsx";
import EditorWithPreview from "./components/mainContent/writtenTest/wyswyg/editor.jsx";
import DashBoard from "./components/mainContent/DashBoard/index.js";
import UserManagement from "./components/mainContent/DashBoard/settings/UserManagement/UserManagement.jsx";
import ForgotPassword2 from "./components/auth/forgot_password/forgotPassword2.jsx";
import {ForgotPassword1} from "./components/auth/forgot_password/ForgotPassword1.jsx";
import ForgotPassword3 from "./components/auth/forgot_password/forgotPassword3.jsx";
import EmailVerification from "./components/auth/registration/emailVerification.jsx";
import TeamCreation from "./components/auth/registration/teamCreation.jsx";
import {CompanyDetails} from "./components/auth/registration/companyDetails.jsx";
import {YourDetails} from "./components/auth/registration/yourDetails1.jsx";
import Profile from "./components/mainContent/DashBoard/settings/profile.jsx";
import BillingInfo from "./components/mainContent/DashBoard/company/billingInfo.jsx";
import CompanyProfile from "./components/mainContent/DashBoard/company/companyProfile.jsx";
import Position from "./components/mainContent/DashBoard/Jobs/positionsList.jsx";
import VideoInterviewQuestions from "./components/mainContent/DashBoard/Jobs/VideoInterviewQuestions.jsx";
import CreatePositions from "./components/mainContent/DashBoard/Jobs/createPositions.jsx";
import AddQuestionPage from "./components/mainContent/DashBoard/Jobs/AddQuestionPage.js";
import PositionDetails from "./components/mainContent/DashBoard/Jobs/PositionDetails.js";
import CandidateList from "./components/mainContent/DashBoard/Jobs/CandidateList.jsx";
import QuestionDetails from "./components/components/SlidingLayouts/questionDetails.jsx";
import WelcomeScreen from "./components/mainContent/writtenTest/welcomeScreen.jsx";
import CandidateDetails from "./components/mainContent/writtenTest/candidateDetails.jsx";
import Proctoring from "./components/mainContent/writtenTest/Proctoring.jsx";
import SkillAssessment from "./components/mainContent/writtenTest/SkillAssessment.jsx";
import ManagerCode from "./components/mainContent/DashBoard/ManagerView/ManagerCode.jsx";
import VideoInterview from "./components/mainContent/VideoInterview/VideoInterviewOrchestrator.jsx";
import ProctoringComponent from "./components/components/camgaze.jsx";
import Feedback from "./components/mainContent/writtenTest/feedback.jsx";
import {AddInvitedUser} from "./components/auth/registration/addInvitedUser.jsx";
import Home from "./components/mainContent/DashBoard/home/home.jsx";
import CandidateCode from "./components/mainContent/writtenTest/candidateComponents/CandidateCode.jsx";
import ResumeMatch from "./components/mainContent/ResumeMatch/index.jsx";
import VideoInterviewManagerView from "./components/mainContent/VideoInterview/VideoInterviewManagerView.jsx";
import QuestionDetailsPage from "./components/components/SlidingLayouts/AsyncQuestionDetails.jsx";
import BuyCredits from "./components/mainContent/DashBoard/company/buyCredits.jsx";
import Payment from "./components/mainContent/DashBoard/company/buyCredits";
import Templates from "./components/mainContent/DashBoard/templates/templates";
import BulkAddQuestions from "./components/mainContent/DashBoard/Jobs/BulkAddQuestions.jsx";

import "./App.css";

// import Templates from "./components/mainContent/DashBoard/templates/templates";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Navigate to="/login"/>}/>
                <Route path="/register" element={<YourDetails/>}/>
                <Route path="/login" element={<Login/>}/>
                <Route path="/video" element={<WebcamStreamCapture/>}/>
                <Route path="/testDevices" element={<DevicePermissionsTester/>}/>
                <Route path="/codeEditor" element={<CodeEditor/>}/>
                <Route path="/wyswyg" element={<WyswygComponent/>}/>
                <Route path="/rte" element={<EditorWithPreview/>}/>
                <Route path="/forgotPassword2" element={<ForgotPassword2/>}/>
                <Route path="/forgotpassword1" element={<ForgotPassword1/>}/>
                <Route path="/forgotPassword3" element={<ForgotPassword3/>}/>
                <Route path="/emailVerification" element={<EmailVerification/>}/>
                <Route path="/teamCreation" element={<TeamCreation/>}/>
                <Route path="/details" element={<YourDetails/>}/>
                <Route path="/companyDetails" element={<CompanyDetails/>}/>
                <Route path="/feedback" element={<Feedback/>}/>
                <Route path="/candidateCode" element={<CandidateCode/>}/>

                <Route path="/" element={<DashBoard/>}>
                    <Route path="home" element={<Home/>}/>
                    <Route path="dashboard" element={<div>DashBoard</div>}/>
                    <Route path="jobs" element={<div>Jobs</div>}/>
                    <Route path="jobs/position" element={<Position/>}/>
                    <Route path="jobs/positionDetails" element={<PositionDetails/>}/>
                    <Route path="jobs/candidateList" element={<CandidateList/>}/>
                    <Route
                        path="jobs/position/AddQuestionPage"
                        element={<AddQuestionPage/>}
                    />
                    <Route path="jobs/events" element={<div>Events</div>}/>
                    <Route path="company" element={<div>Company</div>}/>
                    <Route path="company/companyProfile" element={<CompanyProfile/>}/>
                    <Route path="company/billingInfo" element={<BillingInfo/>}/>
                    <Route path="company/buyCredits" element={<BuyCredits/>}/>
                    <Route path="company/terms" element={<div>Terms of use link</div>}/>
                    <Route path="company/userManagement" element={<UserManagement/>}/>
                    <Route path="help" element={<div>Help</div>}/>
                    {/*<Route path="help/templates" element={<div>Templates</div>}/>*/}
                    <Route path="help/helpCenter" element={<div>Help center</div>}/>
                    <Route path="settings" element={<div>Settings</div>}/>
                    <Route path="settings/profile" element={<Profile/>}/>
                    <Route path="jobs/create" element={<CreatePositions/>}/>
                    <Route path="help/templates" element={<Templates/>}/>
                </Route>

                <Route path="/question" element={<QuestionDetails/>}/>
                <Route path="/code" element={<CodeEditor/>}/>
                <Route path="/question-details" element={<QuestionDetailsPage/>}/>
                <Route path="/welcome" element={<WelcomeScreen/>}/>
                <Route path="/candidateDetails" element={<CandidateDetails/>}/>
                <Route path="/proctoring" element={<Proctoring/>}/>
                <Route path="/skillAssessment" element={<SkillAssessment/>}/>
                <Route path="/videoInterview" element={<VideoInterview/>}/>
                <Route path="/camera" element={<ProctoringComponent/>}/>
                <Route path="/logout" element={<DashBoard/>}/>
                <Route path="/managercode" element={<ManagerCode/>}/>
                <Route path="/accept-invitation" element={<AddInvitedUser/>}/>
                <Route path="/resumeMatch" element={<ResumeMatch/>}/>
                <Route path="/managerVideo" element={<VideoInterviewManagerView/>}/>
                <Route
                    path="jobs/position/videointerviewquestions"
                    element={<VideoInterviewQuestions/>}
                />
                <Route path="/bulkAddQuestions" element={<BulkAddQuestions/>}/>
                <Route path="/pay" element={<Payment></Payment>}/>
            </Routes>
        </Router>
    );
}

export default App;
